import { Box, Container, HStack, Heading, Spinner, Text } from '@chakra-ui/react'
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api'
import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useMemo, useState } from 'react'


export default function SingleSalonMap(props: {shopUid: string}) {

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
  const {isLoaded} = useLoadScript({googleMapsApiKey: configValue})  
    
  const [location, setLocation] = useState<{
    placeAddress: string, placeId: string, placeLat: string, placeLng: string, placeName: string
  }>()

  function fetchLocation() {

    const dbRef = getDatabase()     
    const locationsRef = ref(dbRef, `shops/auth_read/${props.shopUid}/shop_address`)
    
    onValue(locationsRef, async (snapshot) => {
      
    const locationDetails: {placeAddress: string, placeId: string, placeLat: string, placeLng: string, placeName: string } = {
      placeAddress: snapshot.child('placeAddress').val(),
      placeId: snapshot.child('placeId').val(),
      placeLat: snapshot.child('placeLat').val(),
      placeLng: snapshot.child('placeLng').val(),
      placeName: snapshot.child('placeName').val()
    }

    console.log(`Name is: ${locationDetails.placeName} -- Address is :${locationDetails.placeAddress}`)  

    setLocation(locationDetails)
    
    })
  }
  
  useEffect(() => {
    fetchLocation()
    console.log(`Shop ID is ${props.shopUid}`)
    console.log(`Location is :${location}`)
  },[]) 

  
  return (
    <div>
    {!isLoaded &&
      <div>
        <Spinner
          color='brand.medium'
          size='xl'
          thickness='5px'
          speed='0.65s'/>
        <Text>Loading search</Text>
      </div>
    }

    {(location && isLoaded) &&
    <Map location={location}  />
    }
    </div>
  )  
}

interface locationProps {
  location: {
    placeAddress: string;
    placeId: string;
    placeLat: string;
    placeLng: string;
    placeName: string;
  }
}

function Map({ location }: locationProps) {

  const [zoom, setZoom] = useState(13)
  const [mapRef, setMapRef] = useState<google.maps.Map>()

  console.log(`Use memo: ${location.placeLat} == ${location.placeLng}`)

  const center = useMemo(() => (new google.maps.LatLng(parseFloat(location.placeLat), parseFloat(location.placeLng))), []);

  const containerStyle = {
    width: '100%',
    height: '45vh',
    padding: '0px'
  }

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map) 
    console.log(`Map loaded`)    
  } 


  return (

    <Box position='relative' padding='0px' margin='0px'>

      <GoogleMap
        id='map'
        zoom={zoom}
        center={center}    
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        options={{
          streetViewControl: false,        
          mapTypeControl: false,
          fullscreenControl: false,
          keyboardShortcuts: false,
          zoomControl: false,
          restriction:{
            latLngBounds: {
              north: -10,
              south: -40,
              east: 160,
              west: 100,
            }
          }          
        }}      
        clickableIcons={false}>
          <MarkerF position={center} clickable={false}></MarkerF>                    
      </GoogleMap>  

        <Box position='absolute' padding='5px' backgroundColor='blackAlpha.600' bottom='0px' left='0px' height='50px' width='100%'>
          <Heading marginStart='5px' size='5em' color='white' >{location.placeAddress}</Heading>
        </Box>        
    </Box>



  )

}


