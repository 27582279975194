import { VStack, FormControl, InputGroup, Input, InputLeftElement, FormErrorMessage, HStack, Spacer, Button, Textarea, Flex, Spinner, Text, FormLabel, Checkbox, Icon, useDisclosure, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Formik, Field, useFormik, FormikProvider } from 'formik';
import { MdInfo, MdPerson, MdSave, MdTaskAlt } from 'react-icons/md';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { useEffect, useState } from "react";
import { child, get, getDatabase, ref, ref as ref_database, set } from "firebase/database";
import { stepsProps, stylistProfile } from '../../Utils/Interfaces';
import { fetchStylistProfile } from '../../tools/ReturnFunctions';
import * as Yup from 'yup';

export default function StylistProfile({ activeStep, setActiveStep, submitRef }: stepsProps) {

  const firebaseUser = useFirebaseAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [profileDetails, setProfileDetails] = useState<stylistProfile>()
  const [dataSaved, setDataSaved] = useState<boolean>(false)  
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function fetchProfile() {
    console.log(`Fetching profile`)
    const stylistProfile = await fetchStylistProfile(`stylists/auth_read/${firebaseUser?.uid}/profile_settings`)
    setProfileDetails(stylistProfile)
    console.log(`Fetched profile: ${stylistProfile}`)
    setLoading(false)
  }
 
  useEffect(() => {
    fetchProfile()
  },[firebaseUser]) 

  const formik = useFormik({
        //These need to match the Field id values
        initialValues: {
            firstName: profileDetails?.firstName,
            lastName: profileDetails?.lastName,
            hairdressingCertificate: profileDetails?.hairdressingCertificate,
            hairdressingCertYear: profileDetails?.hairdressingCertYear,
            profileDescription: profileDetails?.profileDescription,
            listedProfile: profileDetails?.listedProfile
        },
        enableReinitialize: true,

        onSubmit: async (values) => { 
                            
            const dbRef = getDatabase()

            set(ref_database(dbRef, `stylists/auth_read/${firebaseUser?.uid}/profile_settings`), { 
            userFirebaseUid: firebaseUser?.uid,
            emailAddress: firebaseUser?.email,
            firstName: values.firstName,
            lastName: values.lastName,
            hairdressingCertificate: values.hairdressingCertificate ? values.hairdressingCertificate : false ,
            hairdressingCertYear: values.hairdressingCertYear ? values.hairdressingCertYear : "" ,
            profileDescription: values.profileDescription,
            listedProfile: values.listedProfile ? values.listedProfile : false            
            })
            .then(() => {
                console.log(`Profile updated`)
                setDataSaved(true)
                if (submitRef) setActiveStep!(2)
               
            })
        },

        //https://blog.shahednasser.com/how-to-create-and-validate-forms-in-react-using-formik-and-yup/
        validationSchema: Yup.object().shape({
            firstName: Yup.string().label('First name').required(),
            lastName: Yup.string().label('Last name').required(),
            hairdressingCertificate: Yup.boolean(),
            hairdressingCertYear: Yup.number().when('hairdressingCertificate', {
                 is: true,
                 then(schema) {
                    const currentYear = new Date().getFullYear()                                                  
                    return schema.required().min(currentYear - 100).max(currentYear).label('Year').required()
                 }
                }),   
                               
            profileDescription: Yup.string().label('Profile description').required(),
          })
  })

  
   return (

    <div>
        {loading == true &&

            <Flex alignItems="center" minHeight='60vh'>
                <VStack>
                    <Spinner
                        color='brand.medium'
                        size='xl'
                        marginBottom='20px'
                        thickness='5px'
                        speed='0.65s'/>
                    <Text>Loading</Text>
                </VStack>
            </Flex>
        }

        {loading == false &&
            <Flex alignItems="center">
                <VStack alignItems="center" id='form-stack' width={{base: '90vw', sm: '100%'}}>

                    <FormikProvider value={formik}>

                        <form onSubmit={formik.handleSubmit}>

                            {/* First name */}
                            <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                                <VStack align="flex-start">
                                    <FormLabel htmlFor='firstName' marginBottom='2px'>*First name</FormLabel>
                                    <InputGroup>
                                        <Field
                                            as={Input}
                                            id='firstName'
                                            type='text'
                                            name='firstName'
                                            width={{base: '100%', sm: '400px'}}
                                            borderColor='brand.medium'
                                            focusBorderColor='brand.medium'
                                            placeholder='First name'
                                            paddingLeft='2.5rem'  
                                            onChange={formik.handleChange}
                                        />
                                        <InputLeftElement pointerEvents='none'>
                                            <MdPerson color='brand.medium' />
                                        </InputLeftElement>

                                    </InputGroup>

                                </VStack>
                                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                            </FormControl>


                            {/* Last name */}
                            <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.lastName && formik.touched.lastName}>

                                <VStack align="flex-start">
                                    <FormLabel htmlFor='lastName' marginBottom='2px'>*Last name</FormLabel>
                                    <InputGroup>

                                        <Field
                                            as={Input}
                                            id='lastName'
                                            type='text'
                                            name='lastName'
                                            borderColor='brand.medium'
                                            focusBorderColor='brand.medium'
                                            placeholder='Last name'
                                            paddingLeft='2.5rem'  
                                            onChange={formik.handleChange}
                                        />

                                        <InputLeftElement pointerEvents='none'>
                                            <MdPerson color='brand.medium' />
                                        </InputLeftElement>

                                    </InputGroup>
                                </VStack>
                                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                            </FormControl>

                            {/* Hairdressing certificate */}
                            <FormControl onChange={() => setDataSaved(false)} mb="20px" >

                                <HStack>
                                    <FormLabel htmlFor='hairdressingCertificate'>I hold a hairdressing certificate:</FormLabel>
                                    <Spacer/>                                    
                                        <Field
                                            as={Checkbox}
                                            id='hairdressingCertificate'
                                            name='hairdressingCertificate'
                                            borderColor='brand.medium'
                                            focusBorderColor='brand.medium'
                                            isChecked={formik.values.hairdressingCertificate}
                                            onChange={formik.handleChange}                                                    
                                        />                         
                                </HStack>
                            </FormControl>


                            {formik.values.hairdressingCertificate &&
                            
                                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.hairdressingCertYear && formik.touched.hairdressingCertYear}>
                                    <HStack>
                                        <FormLabel htmlFor='hairdressingCertYear'>*Year certificate obtained:</FormLabel>
                                        <Spacer/>                                                   
                                            <Field
                                                as={Input}
                                                id='hairdressingCertYear'
                                                name='hairdressingCertYear'
                                                type='number'
                                                borderColor='brand.medium'
                                                focusBorderColor='brand.medium'
                                                min="1900" max="2099" step="1" placeholder="YYYY"
                                                maxW='90px'
                                                onChange={formik.handleChange}                 
                                            />                      
                                    </HStack>
                                    <FormErrorMessage>{formik.errors.hairdressingCertYear}</FormErrorMessage>
                                </FormControl>
                            }


                            {/* Profile description */}
                            <FormControl onChange={() => setDataSaved(false)} paddingBottom="20px" isInvalid={!!formik.errors.profileDescription && formik.touched.profileDescription}>
                                <VStack align="flex-start">
                                    <FormLabel htmlFor='profileDescription' marginBottom='2px'>*Profile description</FormLabel>
                                    <InputGroup>

                                        <Field
                                            as={Textarea}
                                            size='md'
                                            resize='resize'
                                            id='profileDescription'
                                            type='text'
                                            height='20vh'
                                            name='profileDescription'
                                            borderColor='brand.medium'
                                            focusBorderColor='brand.medium'
                                            placeholder='Profile description'
                                            onChange={formik.handleChange}
                                        />
                                </InputGroup>
                                </VStack>
                                <FormErrorMessage>{formik.errors.profileDescription}</FormErrorMessage>

                                {/* Publically listed */}
                                <FormControl onChange={() => setDataSaved(false)} marginY="20px" >

                                    <HStack>
                                        <Icon onClick={onOpen} as={MdInfo} w={6} h={6} color='grey' _hover={{ cursor:'pointer'}}/>  
                                        <FormLabel htmlFor='listedProfile'>Allow my profile to be listed publically:</FormLabel>
                                        <Spacer/>                                    
                                            <Field
                                                as={Checkbox}
                                                id='listedProfile'
                                                name='listedProfile'
                                                borderColor='brand.medium'
                                                focusBorderColor='brand.medium'
                                                isChecked={formik.values.listedProfile}
                                                onChange={formik.handleChange}                                                    
                                            />                         
                                    </HStack>
                                </FormControl>

                                {submitRef ? 
                                    <button ref={submitRef} type="submit" style={{ display: 'none' }} />
                                :                            
                                    <HStack marginTop='20px'>
                                        <Spacer></Spacer>
                                        <Button
                                            type='submit'
                                            rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
                                            color='brand.medium'
                                            variant='outline'>
                                            {dataSaved ? "Saved" : "Save"}
                                        </Button>
                                    </HStack>
                                }

                            </FormControl>
                        </form>

                    </FormikProvider>

                </VStack>
            </Flex>
        }

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Profile listing</ModalHeader>
            <ModalBody>
                <Text marginBottom='10px'>Your profile may be listed on the Electric Studio homepage. Enabling clients to contact your for bookings.</Text>
                <Text>A profile image and working suburbs need to be completed for listing to be enabled. </Text>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>OK</Button>
            </ModalFooter>
            </ModalContent>
      </Modal>
    </div>
  )
}
