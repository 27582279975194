import { VStack, FormControl, InputGroup, Input, InputLeftElement, FormErrorMessage, Textarea, Flex, FormLabel, Button, HStack, Spacer, Checkbox, Divider, Radio, RadioGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, Alert, AlertDescription, AlertIcon, Container, Spinner, Image, Icon } from '@chakra-ui/react';
import { Field, useFormik, FormikProvider, useFormikContext } from 'formik';
import { MdAccessTime, MdAttachMoney, MdEventSeat, MdImageSearch, MdSave, MdTaskAlt } from 'react-icons/md';
import { useCallback, useEffect, useState } from "react";
import { enableLogging, getDatabase, ref as ref_database, set } from "firebase/database";
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { ref as ref_storage, uploadBytes } from 'firebase/storage';
import { firebaseStorage } from '../../firebaseSetup';
import { useDropzone } from 'react-dropzone';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { seatProfile, stepsProps } from '../../Utils/Interfaces';
import * as Yup from 'yup';
import { compressImage, fetchSeats } from '../../tools/ReturnFunctions';




export default function SeatProfileNew({ activeStep, setActiveStep, submitRef }: stepsProps) {

  //Image dropzone
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  const [imageUrl, setImageUrl] = useState<{urlString: string, blobUrl: File}>()
  const [dataSaved, setDataSaved] = useState<boolean>(false) 
  const [modalContent, setModalContent] = useState<{heading: string, message: string}>()
  const [hourlyBookings, setHourlyBookings] = useState<boolean>(false)
  const navigate = useNavigate() 

  //https://pablorocha.me/blog/firebase-storage-react-dropzone-2

  const uploadFromBlobAsync = async () => {

    console.log(`Uploading image`)
    const imageRef = ref_storage(firebaseStorage, `shops/${firebaseUser?.uid}/seats/${formik.values.seatName}_image.jpg`);
    uploadBytes(imageRef, imageUrl!.blobUrl).then(() => {
      console.log('Uploaded a blob!');
      setDataSaved(true)
      if (submitRef) {
        navigate("/shopdashboard")
      } else {
        onOpenCreated()
      }
    }) 
  }


  //https://pablorocha.me/blog/firebase-storage-react-dropzone-2
  const onDrop = useCallback(async (files: File[]) => {

    setIsLoading(true)
    setDataSaved(false)

    const file = files?.[0]

    const compressedFile = await compressImage(file)

    if (compressedFile) {
      setImageUrl({urlString: URL.createObjectURL(compressedFile), blobUrl: compressedFile})      
    } else {
      setMessage("Error loading image") 
      setError(true)      
      return
    }

    setIsLoading(false)

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': []
    },    
    onDrop,
    multiple: false
  })

 

  


  //Fomik code
  const firebaseUser = useFirebaseAuth()
  const { isOpen: isOpenMessage, onOpen: onOpenMessage, onClose: onCloseMessage } = useDisclosure()
  const { isOpen: isOpenCreated, onOpen: onOpenCreated, onClose: onCloseCreated } = useDisclosure()


  const formik = useFormik({
    //These need to match the Field id values
    initialValues: {
      seatName: "",
      hourlyRateEnabled: true,
      hourlyRate: "",
      minimumHours: "",
      dailyRate: "",
      seatDescription: "",
      amenityBasinShampooConditioner:  false,
      amenityTowels: false,
      amenityTeaCoffee:  false,
      amenityHairDryer: false,
      amenityStorageLocker:  false,
      amenityParking:  false,
      amenityOther: "",
      cleaningProvided: "stylist",
      insuranceRequired: true,
      businessRegistrationRequired: true,
      houseCancellationPolicy: '24_hours',
      seatBookable: true
  },
    enableReinitialize: true,

    onSubmit: async (values) => { 

      console.log(`Submitting form`)

        //Scroll to first error in form
  // useEffect(() => {
    // if (formik.errors && Object.keys(formik.errors).length > 0) {
    //   console.log(`Errors found`)
    //   const firstErrorField = Object.keys(formik.errors)[0];
    //   const errorField = document.getElementById(firstErrorField);
    //   if (errorField) {
    //     errorField.scrollIntoView({  behavior: "smooth", block: "center" })
    //   }
    //   return;
    // }
  // }, [formik.errors]);
      
      

   

      //Check if the seat name already exists
        const allSeats = await fetchSeats(`shops/auth_read/${firebaseUser?.uid}/seats`)
        let seatExists = false
  
        await Promise.all(allSeats.map(async(seat) => {
          if (seat.seatName == values.seatName) seatExists = true
        }))
  
        if (seatExists) {
          setModalContent({heading: "Seat already exists", message: "A seat with this name already exists, please use a different name" })
          onOpenMessage()
          return
        } 

        //Check if image has been selected
        if (!imageUrl) {
          setModalContent({heading: "Seat image", message: "Please select a seat image"})
          onOpenMessage() 
          return   
        } 

      console.log(`Saving to database`)
                      
      const dbRef = getDatabase()
  
      set(ref_database(dbRef, `shops/auth_read/${firebaseUser?.uid}/seats/${values.seatName}`), { 
        userFirebaseUid: firebaseUser?.uid,
        seatName: values.seatName,
        hourlyRateEnabled: values.hourlyRateEnabled,
        hourlyRate: values.hourlyRate,
        minimumHours: values.minimumHours,
        dailyRate: values.dailyRate,
        seatDescription: values.seatDescription,
        amenityBasinShampooConditioner: values.amenityBasinShampooConditioner,
        amenityTowels: values.amenityTowels,
        amenityTeaCoffee: values.amenityTeaCoffee,
        amenityHairDryer: values.amenityHairDryer,
        amenityStorageLocker: values.amenityStorageLocker,
        amenityParking: values.amenityParking,
        amenityOther: values.amenityOther,
        cleaningProvided: values.cleaningProvided,
        insuranceRequired: values.insuranceRequired,
        businessRegistrationRequired: values.businessRegistrationRequired,
        houseCancellationPolicy: "24_hours",
        seatBookable: values.seatBookable
      })
      .then(() => {
        console.log(`Saved chair profile`)   
        
        if (imageUrl) {
          uploadFromBlobAsync()
        } else {
          setDataSaved(true)
          onOpenCreated()          
        }
      })      
    },

    //https://blog.shahednasser.com/how-to-create-and-validate-forms-in-react-using-formik-and-yup/
    validationSchema: Yup.object().shape({
      seatName: Yup.string().label('Seat name').required(),
      hourlyRateEnabled: Yup.boolean(),
      hourlyRate: Yup.number().when('hourlyRateEnabled', {
        is: true,
        then: (schema) => schema.label('Hourly rate').moreThan(0).required(),
        // otherwise: (schema) => schema.min(0),
      }),
      minimumHours: Yup.number().when('hourlyRateEnabled', {
        is: true,
        then: (schema) => schema.label('Minimum hours').moreThan(0).integer().required(),
        // otherwise: (schema) => schema.min(0),
      }),      
      dailyRate: Yup.number().moreThan(0).label('Daily rate').required(),
      seatDescription: Yup.string().label('Chair description').required(),
    })
  })

  //Scroll to first error in form on submit
  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].scrollIntoView({  behavior: "smooth", block: "center" })      
    }
  }, [formik.isSubmitting]);

  
  
  return (
    <VStack marginTop='5vh' paddingX={{base: '20px', sm: '0'}}>

      <FormLabel width={{base: '100%', sm: '480px'}} marginBottom='0px'>*Chair image</FormLabel>
      <Container textAlign="center" borderColor='brand.medium' borderWidth={1} marginTop='0px' borderStyle='dashed' width={{base: '100%', sm: '480px'}} p={5} marginBottom={40} borderRadius={10} _hover={{bg:"brand.mediumAlpha", cursor:'pointer'}}  {...getRootProps()}>

          <VStack>

            {!imageUrl &&
              <Icon as={MdImageSearch} w={24} h={24} color='brand.medium'/> 
            }

            {imageUrl &&              
              <Image mb='10px' height={{base: '26vh', sm: '26vh'}} width={{base: '100%', sm: '480px'}} objectFit='cover' src={imageUrl?.urlString} backgroundColor='brand.medium' borderRadius={10} />
            }

            <input {...getInputProps()} />
            {isLoading ? <Spinner color='brand.medium'/> : isDragActive ? <Text>Drop the file here...</Text> : <Text>Drop a single image here, or click to select</Text>}

            {error && 
              <Alert status={error ? 'error' : 'success'} w={250} borderRadius={5} m={2}>
                <AlertIcon />
                <AlertDescription w={200}>{message}</AlertDescription>
              </Alert>              
            }

          </VStack>  
      </Container>


      {/* Setup Form */}
      <Flex alignItems="center">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
            {/* <FormikEffect
           onError={scrollToFirstError}
            /> */}
            {/* <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors}/> */}
        

                {/* Chair name */}
                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.seatName && formik.touched.seatName}>
                  <FormLabel htmlFor='seatName' marginTop='15px' marginBottom='2px'>*Chair name (You cant change this once created)</FormLabel>
                    <InputGroup>

                        <Field
                            as={Input}
                            id='seatName'
                            type='text'
                            name='seatName'
                            width={{base: '100%', sm: '480px'}}
                            placeholder='*Chair name'
                            paddingLeft='2.5rem'  
                            borderColor='brand.medium'
                            onChange={formik.handleChange}
                        />

                        <InputLeftElement pointerEvents='none'>
                            <MdEventSeat color='brand.medium' />
                        </InputLeftElement>

                    </InputGroup>

                    <FormErrorMessage>{formik.errors.seatName}</FormErrorMessage>

                </FormControl>


              {/* Hourly rate enabled */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <FormLabel htmlFor='hourlyRateEnabled' marginBottom='5px'>Hourly Rate</FormLabel>
                  <Checkbox
                      id='hourlyRateEnabled'
                      name='hourlyRateEnabled'   
                      onChange={formik.handleChange}
                      isChecked={formik.values.hourlyRateEnabled}                                                       
                    >Hourly rate enabled
                  </Checkbox>
              </FormControl>

              
                {/* Hourly rate */}
                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.hourlyRate && formik.touched.hourlyRate} hidden={!formik.values.hourlyRateEnabled}>
                  <FormLabel htmlFor='hourlyRate' marginBottom='2px'>*Hourly rate (Including GST)</FormLabel>
                    <InputGroup>

                        <Field
                            as={Input}
                            id='hourlyRate'
                            type='number'
                            name='hourlyRate'
                            borderColor='brand.medium'
                            focusBorderColor='brand.medium'
                            placeholder='*Hourly rate'
                            paddingLeft='2.5rem'  
                            onChange={formik.handleChange}    
                        />

                        <InputLeftElement pointerEvents='none'>
                            <MdAttachMoney color='brand.medium' />
                        </InputLeftElement>

                    </InputGroup>
                    <FormErrorMessage>{formik.errors.hourlyRate}</FormErrorMessage>
                </FormControl>

                  {/* Minimum hours*/}
                  <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.minimumHours && formik.touched.minimumHours} hidden={!formik.values.hourlyRateEnabled}>
                  <FormLabel htmlFor='minimumHours' marginBottom='2px'>*Minimum hours</FormLabel>
                    <InputGroup>

                        <Field
                            as={Input}
                            id='minimumHours'
                            type='number'
                            name='minimumHours'
                            borderColor='brand.medium'
                            focusBorderColor='brand.medium'
                            placeholder='*Minimum hours'
                            paddingLeft='2.5rem'  
                            onChange={formik.handleChange}
                            isChecked={formik.values.minimumHours}      
                        />

                        <InputLeftElement pointerEvents='none'>
                            <MdAccessTime color='brand.medium' />
                        </InputLeftElement>

                    </InputGroup>
                    <FormErrorMessage>{formik.errors.minimumHours}</FormErrorMessage>
                </FormControl>


                <Divider borderColor='brand.medium' marginY='30px'/>


                {/* Daily rate */}
                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.dailyRate && formik.touched.dailyRate}>
                  <FormLabel htmlFor='dailyRate' marginBottom='2px'>*Daily rate (Including GST)</FormLabel>
                    <InputGroup>

                        <Field
                            as={Input}
                            id='dailyRate'
                            type='number'
                            name='dailyRate'
                            borderColor='brand.medium'
                            focusBorderColor='brand.medium'
                            placeholder='*Daily rate'
                            paddingLeft='2.5rem'  
                            onChange={formik.handleChange}    
                        />

                        <InputLeftElement pointerEvents='none'>
                            <MdAttachMoney color='brand.medium' />
                        </InputLeftElement>

                    </InputGroup>
                    <FormErrorMessage>{formik.errors.dailyRate}</FormErrorMessage>
                </FormControl>

                {/* Chair description */}
                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.seatDescription && formik.touched.seatDescription}>
                <FormLabel htmlFor='seatDescription' marginBottom='2px'>*Chair description</FormLabel>
                    <InputGroup>
                        <Field
                            as={Textarea}
                            size='sm'
                            resize='resize'
                            id='seatDescription'
                            type='text'
                            name='seatDescription'
                            borderColor='brand.medium'
                            focusBorderColor='brand.medium'
                            placeholder='*Chair description'
                            borderRadius='6px'
                            onChange={formik.handleChange}
                            isChecked={formik.values.seatDescription}      
                          />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.seatDescription}</FormErrorMessage>                            
                </FormControl>

                <Divider borderColor='brand.medium' marginY='30px'/>

              {/* Basin Shampoo Conditioner */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <FormLabel htmlFor='amenityBasinShampooConditioner' marginBottom='5px'>Amenities</FormLabel>
                  <Checkbox
                      id='amenityBasinShampooConditioner'
                      name='amenityBasinShampooConditioner'   
                      onChange={formik.handleChange}
                      isChecked={formik.values.amenityBasinShampooConditioner}                                                       
                    >Basin with shampoo and conditioner
                  </Checkbox>
              </FormControl>

              {/* Towels */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='amenityTowels'
                    name='amenityTowels'  
                    onChange={formik.handleChange}
                    isChecked={formik.values.amenityTowels}                                        
                  >Towels provided
                </Checkbox>
              </FormControl>

              {/* Tea & Coffee */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='amenityTeaCoffee'
                    name='amenityTeaCoffee'  
                    onChange={formik.handleChange}
                    isChecked={formik.values.amenityTeaCoffee}                                        
                  >Tea and coffee provided
                </Checkbox>
              </FormControl>

              {/* Hair dryer */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='amenityHairDryer'
                    name='amenityHairDryer' 
                    onChange={formik.handleChange}
                    isChecked={formik.values.amenityHairDryer}                                       
                  >Use of hair dryer
                </Checkbox>
              </FormControl>

              {/* Storage locker */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='amenityStorageLocker'
                    name='amenityStorageLocker'  
                    onChange={formik.handleChange}
                    isChecked={formik.values.amenityStorageLocker}                                      
                  >Storage locker
                </Checkbox>
              </FormControl>

              {/* Parking */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='amenityParking'
                    type='checkbox'
                    name='amenityParking'      
                    onChange={formik.handleChange}
                    isChecked={formik.values.amenityParking}                                  
                  >Free parking
                </Checkbox>
              </FormControl>

              {/* Other amenities */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!formik.errors.amenityOther && formik.touched.amenityOther}>
                <FormLabel htmlFor='amenityOther' marginBottom='2px'>Other amenities</FormLabel>
                    <Textarea
                        size='sm'
                        id='amenityOther'
                        name='amenityOther'
                        borderColor='brand.medium'
                        focusBorderColor='brand.medium'
                        borderRadius='6px'
                        placeholder='Other amenities provided or not provided.'
                        onChange={formik.handleChange}
                        value={formik.values.amenityOther}  
                    />
                    <FormErrorMessage>{formik.errors.amenityOther}</FormErrorMessage>                            
                </FormControl>

                <Divider borderColor='brand.medium' marginY='30px'/>

              {/* Cleaning */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
              <FormLabel htmlFor='cleaningProvided' marginBottom='5px'>Cleaning</FormLabel>
                    <RadioGroup id='cleaningProvided' defaultValue={formik.values.cleaningProvided}>
                      <VStack alignItems='start'>
                          <Radio
                              value='salon'                           
                              width={{base: '100%', sm: '480px'}}                       
                              onChange={(e) => formik.values.cleaningProvided = e.target.value}                   
                              >Salon will take care of cleaning the station between customers.
                          </Radio>
                          <Radio
                              value='stylist'                        
                              width={{base: '100%', sm: '480px'}}
                              onChange={(e) => formik.values.cleaningProvided = e.target.value}  
                              >Stylist is responsible for cleaning their station. Cleaning equiment and products provided by salon.
                          </Radio>
                      </VStack>
                    </RadioGroup>
                </FormControl>

                <Divider borderColor='brand.medium' marginY='30px'/>

              {/* Insurance */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <FormLabel htmlFor='stylistMustObtain' marginBottom='5px'>Stylist must hold</FormLabel>
                  <Checkbox
                      id='insuranceRequired'
                      name='insuranceRequired'   
                      onChange={formik.handleChange}
                      isChecked={formik.values.insuranceRequired}                                                       
                    >Liability insurance
                  </Checkbox>
              </FormControl>

              {/* Registered business entity */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <Checkbox
                    id='businessRegistrationRequired'
                    name='businessRegistrationRequired'  
                    onChange={formik.handleChange}
                    isChecked={formik.values.businessRegistrationRequired}                                        
                  >Registered business entity
                </Checkbox>
              </FormControl>

                <Divider borderColor='brand.medium' marginY='30px'/>

              {/* Cancellation policy */}
              <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <FormLabel htmlFor='houseCancellationPolicy' marginBottom='5px'>Cancellation policy</FormLabel>
                  <Radio
                      id='houseCancellationPolicy'
                      name='houseCancellationPolicy'   
                      width={{base: '100%', sm: '480px'}} 
                      value='24_hours'  
                      onChange={formik.handleChange}
                      isChecked={true}                                                                            
                      >No refund will be provided if cancelled within 24 hours.
                  </Radio>
              </FormControl>

                <Divider borderColor='brand.medium' marginY='30px'/>

                {/* Seat bookable  */}
                <FormControl onChange={() => setDataSaved(false)} mb="20px">
                <FormLabel htmlFor='seatBookable' marginBottom='5px'>Chair bookable</FormLabel>
                    <InputGroup>
                        <Checkbox
                            id='seatBookable'
                            name='seatBookable'  
                            onChange={formik.handleChange}
                            isChecked={formik.values.seatBookable}                                                                    
                        >Allow stylists to request bookings
                        </Checkbox>
                    </InputGroup>
                </FormControl>

                {submitRef ?
                  <button ref={submitRef} type="submit" style={{ display: 'none' }} />
                  :
                  <HStack marginTop='20px' marginBottom='5vh'>
                    <Spacer></Spacer>
                    <Button
                        type='submit'
                        rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
                        color='brand.medium'
                        variant='ghost'>
                        {dataSaved ? "Saved" : "Save"}
                    </Button>
                </HStack>
              }
              
                
            </form>
          </FormikProvider>

          <Modal isOpen={isOpenMessage} onClose={onCloseMessage}>
            <ModalOverlay />
            <ModalContent borderTop="8px"  borderColor="brand.medium">
              <ModalHeader>{modalContent?.heading}</ModalHeader>
              <ModalBody>
                <Text>{modalContent?.message}</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onCloseMessage}>OK</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>    

          <Modal isOpen={isOpenCreated} onClose={onCloseCreated}>
            <ModalOverlay />
            <ModalContent borderTop="8px"  borderColor="brand.medium">
              <ModalHeader>Chair Created</ModalHeader>
              <ModalBody>
                <Text>New chair sucessfully listed</Text>
              </ModalBody>
              <ModalFooter>
                <NavLink to="/shopmanageseats">
                  <Button colorScheme='blue' mr={3}>OK</Button>
                </NavLink>
              </ModalFooter>
            </ModalContent>
          </Modal>          

      </Flex>  

    </VStack>
  )
}
