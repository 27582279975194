import { Heading, Text, Divider } from '@chakra-ui/react'
import React from 'react'

export default function FrequentlyAskedQuestions() {
  return (
    <div>
      <Heading size='md' marginBottom='15px'>Frequently asked questions:</Heading>

      <Text marginBottom='5px'>Q: What are the charges for using the Electric Studio platform?</Text>
      <Text marginBottom='20px'>A: We currently are not charging for use of the Electric Studio platfrom. However styslits will need to pay salons directly for the chairs booked as per the advised changes upon booking. </Text>

      <Divider marginBottom='20px'/>

     
    </div>
  )
}
