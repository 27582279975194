import React, { useEffect, useState } from 'react'
import { shopAddress, shopDetails, shopProfile } from '../../Utils/Interfaces'
import { getDatabase, onValue, ref } from 'firebase/database'
import { Card, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

export default function ShopList() {

  const navigate = useNavigate();
  const [allShops, setAllShops] = useState<shopDetails[]>([])


  function fetchAllShops() {

    const dbRef = getDatabase()
     
    const shopsRef = ref(dbRef, `shops/auth_read`)
    
      onValue(shopsRef, async (snapshot) => {
  
        const allLocations: Array<shopDetails> = []
  
        snapshot.forEach((childSnapshot) => {

          const userFirebaseUid = childSnapshot.key
          const placeName = childSnapshot.child('shop_address').child('placeName').val()
          const firstName = childSnapshot.child('profile_settings').child('firstName').val()
          const lastName = childSnapshot.child('profile_settings').child('lastName').val()
          const contactNumber = childSnapshot.child('profile_settings').child('contactNumber').val()
          const emailAddress = childSnapshot.child('profile_settings').child('emailAddress').val()
          const placeAddress = childSnapshot.child('shop_address').child('placeAddress').val()
          const openHoursExist = childSnapshot.child('open_hours').exists()
          const seatsExists = childSnapshot.child('seats').exists()

          allLocations.push({userFirebaseUid: userFirebaseUid!, placeName: placeName, firstName: firstName, lastName: lastName, contactNumber: contactNumber, emailAddress: emailAddress, placeAddress: placeAddress,
            openHoursExist: openHoursExist, seatsExist: seatsExists})
          
          console.log(`Name is: ${placeName} -- Address is :${placeAddress}`) 
        
        }) 
        
  
        setAllShops(allLocations)
     
    })
  }

  useEffect(() => {
    fetchAllShops()
  },[])




  return (
    <div>

          <TableContainer padding='2px' >
            <Table fontSize='sm'>
              <Thead>
                <Tr backgroundColor='brand.lite' >
                  <Th textColor='white'>user ID</Th>
                  <Th textColor='white'>Salon name</Th>
                  <Th textColor='white'>First name</Th>
                  <Th textColor='white'>Last name</Th>
                  <Th textColor='white' isNumeric>Contact number</Th>
                  <Th textColor='white'>Email address</Th>
                  <Th textColor='white'>Shop address</Th>
                  <Th textColor='white'>Open hours set</Th>
                  <Th textColor='white'>Seat setup</Th>
                </Tr>
              </Thead>
              <Tbody>

                {allShops && 
          
                  allShops.map((element, index) => {
                    return (                
                    <Tr 
                      key={index}     
                      _hover={{backgroundColor: 'brand.liteAlpha'}}                 
                      
                      >
                      <Td 
                      onClick={() => navigate("/salondetails", { state: { shopUid: element.userFirebaseUid } })}
                      _hover={{cursor:'pointer', backgroundColor: 'brand.liteAlpha'}}
                      >{element.userFirebaseUid}</Td>
                      <Td>{element.placeName}</Td>
                      <Td>{element.firstName}</Td>
                      <Td>{element.lastName}</Td>
                      <Td>{element.contactNumber}</Td>
                      <Td>{element.emailAddress}</Td>
                      <Td>{element.placeAddress}</Td>
                      <Td textColor={element.openHoursExist ? "" : 'red'}>{element.openHoursExist ? "Yes" : "No"}</Td>
                      <Td textColor={element.seatsExist ? "" : 'red'}>{element.seatsExist ? "Yes" : "No"}</Td>

                    </Tr>
            
                    )
                  })  
                }

              </Tbody>
            </Table>
          </TableContainer>

    </div>
  )
}
