import { eventWithId } from '../Utils/Interfaces'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Card, CardBody, Flex, HStack, Heading, Icon, Show, Spacer, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import FirebaseStorageImage from './FirebaseStorageImage'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { MdEditCalendar } from 'react-icons/md'
import { UserContext } from '../context/UserContext'
import FirebaseStorageAvatar from './FirebaseStorageAvatar'
import ViewShopProfileImage from './ViewShopProfileImage'


export default function BookingCards(props: {allEvents: eventWithId[], selectedEvent: eventWithId | undefined, bookingStatus: string}) {

  const navigate = useNavigate()
  const {user, setUser} = useContext(UserContext)

  

  useEffect(() => {    
    console.log(`Scrolling to event: ${JSON.stringify(props.selectedEvent)}`)

    

    if (props.selectedEvent?.key) {
      const card = document.getElementById(props.selectedEvent!.key!)      

      // 👇 Will scroll smoothly to the top of the next section
      card!.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  },[props.selectedEvent])
  
  return (

    <>
    <Accordion index={0} allowToggle={false}>
    <AccordionItem>
      <h2>
        <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}_hover={{ cursor: 'auto' }}>
          <Box as="span" flex='1' textAlign='left'>
            {props.bookingStatus}
          </Box>
          {/* <AccordionIcon /> */}
        </AccordionButton>
      </h2>
        <AccordionPanel pb={4}>
          <Flex wrap='wrap' gap='20px' id='test'>            
            {
            props.allEvents.map((element, index) => {

              return (
                  
                  <Card
                  key={element.key}
                  id={element.key}
                  direction={{base: 'row', sm: 'row'}}          
                  overflow='hidden'
                  borderStyle='solid'
                  borderWidth='2px'
                  borderTop="8px"
                  borderTopColor='brand.medium'
                  width='100%'
                  transform={(props.selectedEvent?.key == element.key) ? 'scale(1.02)' : 'scale(1)'}
                  transition={(props.selectedEvent?.key == element.key) ? '.3s' : '.5s'}
                  borderColor={(props.selectedEvent?.key == element.key) ? 'brand.medium' : ''}
                  boxShadow='xl'
                  _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
                  onClick={() => {   
                    if (user.type == "stylists") {
                      navigate("/stylistviewbooking", { state: { selectedEvent: element } })
                    } else {
                      navigate("/shopviewbooking", { state: { selectedEvent: element } })
                    }
                  }}                
                  >
                
                    <Show above='sm'>
                      {user.type == "stylists" &&                        
                        // <FirebaseStorageImage imagePath={`shops/${element.shopFirebaseUid}/profile_image.jpg`} width='18vw' height='20vh'/>
                        <ViewShopProfileImage uID={element.shopFirebaseUid!} width='18vw' height='20vh'/>
                      }
                      {user.type == "shops" &&   
                      <Box padding='10px'>
                        <FirebaseStorageAvatar imagePath={`stylists/${element.stylistFirebaseUid}/profile_image.jpg`} size={'2xl'}/>
                      </Box>                     
                      }
                    </Show>

                    <Show below='sm'>
                    {user.type == "stylists" &&                        
                        // <FirebaseStorageImage imagePath={`shops/${element.shopFirebaseUid}/profile_image.jpg`} width='30%'/>
                        <ViewShopProfileImage uID={element.shopFirebaseUid!} width='30%'/>
                      }
                      {user.type == "shops" &&  
                      <Box padding='10px'>

                        <Show below='sm'>
                          <FirebaseStorageAvatar imagePath={`stylists/${element.stylistFirebaseUid}/profile_image.jpg`} size={'lg'}/>
                        </Show>


                        <Show above='sm'>
                          <FirebaseStorageAvatar imagePath={`stylists/${element.stylistFirebaseUid}/profile_image.jpg`} size={'2xl'}/>
                        </Show>
                      </Box>                      
                      }
                    </Show>
                
                    <CardBody padding='10px' flexGrow='1' minWidth='30%'>

                      <HStack>
                        {user.type == "stylists" &&
                        <Heading color='brand.medium' mb='5px' size='sm'>{element.placeName}</Heading>
                        }

                        {user.type == "shops" && 
                          <Heading color='brand.medium' mb='5px' size='sm'>{element.title}</Heading>
                        }
                        <Spacer/>
                        {(props.selectedEvent?.key == element.key)  &&          
                        <Icon as={MdEditCalendar} w={6} h={6} color='brand.medium'/> 
                        }
                      </HStack>

                        {user.type == "stylists" &&
                          <Text>Status: {element.title}</Text>             
                        }

                        {user.type == "shops" && 
                          <Text>Chair name: {element.seatName}</Text>  
                        }
                      
                      <Text >{`Date: ${format(new Date(`${element.date}`),"do MMMM yyyy")}`}</Text>
                      <Text>From: {element.startTime} - till: {element.endTime}</Text>
            
                  </CardBody>
                </Card>
                )
              })

            }
          </Flex>        
        </AccordionPanel>
      </AccordionItem>
    </Accordion>

</>

  )
}
