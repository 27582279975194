import { Box, Grid, GridItem, VStack, Heading, TableContainer, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import FirebaseStorageImage from '../../components/FirebaseStorageImage';
import { useLocation } from 'react-router-dom';
import SingleSalonMap from '../../components/SingleSalonMap';
import { useEffect, useState } from 'react';
import { get, getDatabase, ref } from "firebase/database";
import { businessHours, shopProfile } from '../../Utils/Interfaces';
import ShopSeats from '../../components/ShopSeats';
import FirebaseRtdbText from '../../components/FirebaseRtdbText';
import GalleryView from '../../components/GalleryView';
import { useLoadScript } from '@react-google-maps/api';
import ShopProfileImage from '../shop/ShopProfileImage';
import ViewShopProfileImage from '../../components/ViewShopProfileImage';

export default function SalonDetails() {

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
  const {isLoaded} = useLoadScript({googleMapsApiKey: configValue, libraries: ['places']})  

  const dbRef = getDatabase()
  const location = useLocation()
  const data = location.state ? location.state : JSON.parse(sessionStorage.getItem('SELECTED_SHOP')!) //If page reloaded then use the session storage values
  useEffect(() => {
    sessionStorage.setItem('SELECTED_SHOP', JSON.stringify(data))
  },[data]) 

  const [profileDetails, setProfileDetails] = useState<shopProfile>()
  const [businessHours, setBusinessHours] = useState<businessHours>()

  function fetchProfile() {
    const profileRef = ref(dbRef, `shops/auth_read/${data.shopUid}/profile_settings`);

    get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          setProfileDetails(snapshot.val())
        } else {
          console.log("No data available")         
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  function fetchBusinessHours() {
    const profileRef = ref(dbRef, `shops/auth_read/${data.shopUid}/open_hours`);

    get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          setBusinessHours(snapshot.val())
        } else {
          console.log("No data available")         
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  
  useEffect(() => {
    fetchProfile()
    fetchBusinessHours()
  },[]) 
  
  return (


    <Grid
      templateAreas={{base: `"shop-profile-image"
                            "salon-name"
                            "salon-description"
                            "open-hours"
                            "available-seats"
                            "gallery"
                            "map"`,
                      sm: `"salon-name salon-name"
                          "shop-profile-image map"
                          "salon-description open-hours"
                          "available-seats available-seats"                      
                          "gallery gallery"`}}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14}    
      gap='2'
    >

      <GridItem marginTop='20px' area={'salon-name'} colStart={{base: 1, sm: 3}} colSpan={9} paddingX={{base: '2vw', sm: '0vw'}}>
        <Heading size='lg'>
          <FirebaseRtdbText valuePath={`shops/auth_read/${data.shopUid}/shop_address/placeName`}/>
        </Heading>
      </GridItem>

      <GridItem area={'shop-profile-image'} colStart={{base: 1, sm: 3}} colSpan={{base: 12, sm: 4}}>
        <VStack alignItems='self-end'>
        <Box width='100%' height={{base: '35vh', sm: '45vh'}} overflow='hidden' borderTopLeftRadius={{base: '0', sm: '30'}} borderBottomLeftRadius={{base: '0', sm: '30'}}>
            <ViewShopProfileImage uID={data.shopUid} height='100%' width='100%' />
        </Box>
        </VStack>
      </GridItem>

      <GridItem  area={'map'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 4}}>
      <Box width='100%' height='45vh' overflow='hidden' borderTopRightRadius={{base: '0', sm: '30'}} borderBottomRightRadius={{base: '0', sm: '30'}}>            
          <SingleSalonMap shopUid={data.shopUid}/>
        </Box>
      </GridItem>

      <GridItem marginTop='10px' area={'salon-description'} colStart={{base: 1, sm: 3}} colSpan={{base: 12, sm: 4}} paddingX={{base: '2vw', sm: '0vw'}}>
        <Heading size='sm' marginBottom='5px' paddingTop={{base: '10px', sm: '30px'}} > Salon description</Heading>
        <Text>{profileDetails?.profileDescription}</Text>
      </GridItem>

      <GridItem marginTop='10px' area={'open-hours'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 4}} paddingX={{base: '2vw', sm: '0vw'}}>
      <Heading size='sm' marginBottom='5px' paddingTop={{base: '10px', sm: '30px'}} > Open hours</Heading>
      <Box overflow='hidden'>
        <TableContainer>
          <Table size='xs' fontSize={14}>
            <Tbody>
              <Tr>
                <Td>Monday</Td>
                <Td>{businessHours?.mondayOpen ? `${businessHours?.mondayOpenTime} - ${businessHours?.mondayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Tuesday</Td>
                <Td>{businessHours?.tuesdayOpen ? `${businessHours?.tuesdayOpenTime} - ${businessHours?.tuesdayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Wednesday</Td>
                <Td>{businessHours?.wednesdayOpen ? `${businessHours?.wednesdayOpenTime} - ${businessHours?.wednesdayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Thursday</Td>
                <Td>{businessHours?.thursdayOpen ? `${businessHours?.thursdayOpenTime} - ${businessHours?.thursdayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Friday</Td>
                <Td>{businessHours?.fridayOpen ? `${businessHours?.fridayOpenTime} - ${businessHours?.fridayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Saturday</Td>
                <Td>{businessHours?.saturdayOpen ? `${businessHours?.saturdayOpenTime} - ${businessHours?.saturdayCloseTime}`: `Closed`}</Td>
              </Tr>
              <Tr>
                <Td>Sunday</Td>
                <Td>{businessHours?.sundayOpen ? `${businessHours?.sundayOpenTime} - ${businessHours?.sundayCloseTime}`: `Closed`}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      </GridItem>

      <GridItem area={'available-seats'} colStart={{base: 1, sm: 3}} colSpan={{base: 12, sm: 8}} paddingX={{base: '2vw', sm: '0vw'}}>
        <Heading size='sm' paddingTop={{base: '10px', sm: '30px'}} marginBottom='10px'>Available chairs</Heading>
        <ShopSeats shopUid={data.shopUid}/>
      </GridItem>

      <GridItem area={'gallery'} marginBottom={{base: '5vh', sm: '10vh'}} colStart={{base: 1, sm: 3}} colSpan={{base: 12, sm: 8}} paddingX={{base: '2vw', sm: '0vw'}}>        
        <GalleryView storagePath={`shops/${data.shopUid}/gallery`}/>
      </GridItem>

    </Grid>
  )
}
