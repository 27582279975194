 import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Card, Center, CloseButton, Divider, FormControl, FormErrorMessage, HStack, Heading, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdAppRegistration, MdEmail, MdKey, MdOutlineLogin } from 'react-icons/md'
import { NavLink, useNavigate, Link as RouteLink } from 'react-router-dom'
import { AiFillApple } from "react-icons/ai";
import { Formik, Field } from 'formik'
import { FcGoogle } from 'react-icons/fc';
import { GoogleAuthProvider, UserCredential, getAuth, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, signOut} from 'firebase/auth'
import { auth } from '../../firebaseSetup'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { getDatabase, ref, child, get } from "firebase/database"
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import { isVisible } from '@testing-library/user-event/dist/utils'
 
 export default function Login() {

  const [show, setShow] = React.useState(false)
  const handleClickPasswordVisible = () => setShow(!show)
  const [showAlert, setShowAlert] = useState(false)
  const [loginErrorValue, setLoginErrorValue] = useState("")
  const navigate = useNavigate()
  const { isOpen, onOpen: onOpenModal, onClose: onCloseModel } = useDisclosure()
  const firebaseUser = useFirebaseAuth();
  const { setIdentity } = useTrackingCode();
  const [loading, setLoading] = useState(false)
  const [alertDetails, setAlertDetails] = useState<{status: "success" | "info" | "warning" | "error" | "loading" | undefined, title: string, description: string}>()
  const {isOpen: isVisible, onClose, onOpen,} = useDisclosure({ defaultIsOpen: false })


  const sendVerificationEmailAgain = () => {
        sendEmailVerification(firebaseUser!)
        .then(() => {
            console.log(`Email verification sent to ${firebaseUser!.email}`)
      
            signOut(auth)
            setAlertDetails({status: "success", title: "Email sent", description: "Please check your inbox. Check your spam folder!"})
            onOpen()
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
            setAlertDetails({status: "error", title: "Email failed", description: `Please contact us via the chat button below for assistance. Error: ${err.message}`})
            onOpen()
        })      
    }

    function googleSignin() {
        setLoading(true)
        const auth = getAuth()
        const provider = new GoogleAuthProvider()

        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(`Sucessfully logged on with Google: ${result.user}`)
                checkProfileAndNavigate(result.user.uid, result.user.email!)
            }).catch((error) => {
                console.log(error)
                setLoginErrorValue(error.message)
                setLoading(false)
                setShowAlert(true)
        })
    }

    function checkProfileAndNavigate(uId: string, email: string) {

    
        console.log(`Email address is: ${email}`)
        setIdentity(email) //Hubspot identity

        //If uid is an admin, go to the admin dashboard
        if (uId == "eoN1gQE0Nqe9YYW8RtP94Ay5xhZ2") {
            navigate('/admindashboard')
            return

        } else {
            const dbRef = ref(getDatabase())
    
            get(child(dbRef, `stylists/auth_read/${uId}`)).then((stylistSnapshot) => {
                if (stylistSnapshot.exists()) {
                    console.log(stylistSnapshot.val())
                    navigate('/stylistdashboard') //Go to stylistdashboard if profile is found 
                } else {
                    console.log("Stylist directory not found")
    
                    get(child(dbRef, `shops/auth_read/${uId}`)).then((shopSnapshot) => {
                        if (shopSnapshot.exists()) {
                            console.log(shopSnapshot.val())
                            navigate('/shopdashboard') //Go to shopedashboard if profile is found 
                        } else {
                            console.log("Shop directory not found")
                            navigate('/usertype') //Go to setup page if no profile foiund
                        }
                    });
                }
            }).catch((error) => {
                console.error(error);
                setLoading(false)
            });
        }        
    }

 
  return (


    <>
    
    {isVisible &&      
        <Alert status={alertDetails!.status} >
          <Spacer/>        
          <AlertIcon />
          <Box>
            <AlertTitle>{alertDetails?.title}</AlertTitle>
            <AlertDescription>
              {alertDetails?.description}
            </AlertDescription>
          </Box>
          <CloseButton
            onClick={onClose}
            alignSelf='flex-start'
            position='relative'
            right={-1}
            top={-1}
          />
          <Spacer/>
        </Alert>
      }
    <Center height='90vh'>


        <Card 
          maxW="480px"
          minW='300px'
          rounded={'lg'}
          bg='whiteAlpha.100'
          boxShadow={'lg'}
          padding='30px'
          alignItems='center'
          borderTop="8px"
          borderColor="brand.medium">

          <Heading as='h4' size='md' mb='20px' color='brand.medium'>Sign in with email address</Heading>  
       
          <Formik
              //These need to match the Field id values
              initialValues={{
                  email: "",
                  password: ""
              }}

              onSubmit={(values, { resetForm }) => {
                setLoading(true)
                setLoginErrorValue("")
                setShowAlert(false)

                signInWithEmailAndPassword(auth, values.email, values.password)
                  .then((userCredential) => {
                    console.log(`The user logged in: ${userCredential.user}`)
                    resetForm()

                    if (userCredential.user.emailVerified) {
                        checkProfileAndNavigate(userCredential.user.uid, userCredential.user.email!)
                    } else {
                        setLoading(true)
                        onOpenModal() //Open dialogue to ask if the user wants a new verification email
                    }
                  })
                  .catch((err) => {
                    const errorMessage = err.message
                    console.log(errorMessage)
                    setLoginErrorValue(errorMessage)
                    setLoading(false)
                    setShowAlert(true)
                })
              }}>

                {({handleSubmit, errors, touched}) => (

                    <form onSubmit={handleSubmit}>

                        {/* Email address */}
                        <FormControl mb="20px" isInvalid={!!errors.email && touched.email}>
                            <InputGroup>

                                <Field
                                    as={Input}
                                    id='email'
                                    type='email'
                                    name='email'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Email Address'
                                    paddingLeft='2.5rem'  
                                    validate={(value: string) => {

                                        const isValidEmailAddress = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)                                       
                                        if (!isValidEmailAddress) {
                                            return "Please enter a valid email address";
                                        }
                                    }}
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdEmail color='brand.medium' />
                                </InputLeftElement>

                            </InputGroup>

                            <FormErrorMessage>{errors.email}</FormErrorMessage>

                        </FormControl>

                        {/* Password */}
                        <FormControl mb="10px" isInvalid={!!errors.password && touched.password}>
                            <InputGroup size='md'>

                                <Field
                                    as={Input}
                                    id='password'
                                    type={show ? 'text' : 'password'}
                                    name='password'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Password'
                                    paddingLeft='2.5rem'
                                    validate={(value: string) => {

                                        const isAtLeast8Characters = value.length >= 8;
                                        const hasAtLeastOneSpecialCharacter = /[!@#$%^&*()_+]/.test(value);
                                        const hasAtLeastOneUpperCaseCharacter = /[A-Z]/.test(value);

                                        if (!isAtLeast8Characters) {
                                            return "Password needs to be at least 8 characters";
                                        }

                                        if (!hasAtLeastOneSpecialCharacter) {
                                            return "Password needs to have at least 1 special character";
                                        }

                                        if (!hasAtLeastOneUpperCaseCharacter) {
                                            return "Password needs to be at 1 upper case character";
                                        }
                                    }}                          
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdKey color='brand.medium' />
                                </InputLeftElement>

                                <InputRightElement width='4.5rem'>
                                    <IconButton onClick={handleClickPasswordVisible} aria-label='Search database' variant='ghost' icon={show ? <ViewIcon/> : <ViewOffIcon/>} />
                                </InputRightElement>

                            </InputGroup>

                            <FormErrorMessage>{errors.password}</FormErrorMessage>

                            <Box w='100%' mb='20px' display='flex' flexDirection='column' alignItems='end' >
                                <>
                                <Link as={RouteLink} to='/resetpassword' color='brand.medium'>Forgot Password?</Link> 
                                </>                                 
                            </Box>

                        </FormControl>

                        <Button
                            type="submit"
                            leftIcon={<MdOutlineLogin />}
                            maxWidth='450px'
                            width='330px'
                            minWidth='300px'
                            marginBottom='20px'
                            variant='solid'>
                            Sign in
                        </Button>

                    </form>

                )}
            </Formik>

            {/* If sign in fails, display a message to the user. */}
            {showAlert && (
                <Alert status='error'>
                  <AlertIcon />
                    {loginErrorValue}
                </Alert>
            )}


          <Text size ='sm' mb='20px'>Or...</Text>

          <HStack mb='40px' maxWidth='450px' width='330px' minWidth='300px'>
            <Button flex='1' variant={'outline'} leftIcon={<FcGoogle />} onClick={() => googleSignin()}>Sign in with Google</Button>
            {/* <Button leftIcon={<AiFillApple />} flex='1' variant='solid' backgroundColor='black'>Sign in with Apple</Button> */}
          </HStack>

          <Divider mb='20px' borderColor='brand.medium'/>

          <Center mb='20px'>
            <Text size ='sm'>Dont have an account</Text>
          </Center>

        
          <NavLink to="/register">
            <Button leftIcon={<MdAppRegistration />} maxWidth='450px' width='330px' minWidth='300px' variant='solid'>Sign up</Button>
          </NavLink>         

    </Card>

 
    {loading && 
        <Center width='100vw' height='100vh' zIndex='100' position='fixed' top='0px' left='0px' backgroundColor='blackAlpha.600'>
        <VStack>
            <Spinner
            color='brand.lite'
            size='xl'
            thickness='5px'
            speed='0.65s'
            />

            <Text textColor='white'>Loading</Text>
        </VStack>
        </Center>
    }

    


    <Modal isOpen={isOpen} onClose={onCloseModel} >
        <ModalContent borderTop='8px' borderColor='brand.medium'>
        <ModalHeader color='brand.medium'>Account not verified</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text mb='10px'>An email was previously sent to your inbox to verify your account. Would you like another veirfication email sent?</Text>
            <Text mb='10px'>Remember to check your junk folder.</Text>                   
        </ModalBody>

        <ModalFooter>
                <Button variant='outline' marginEnd='20px' onClick={()=> {
                    setLoading(false)
                    onCloseModel()
                    signOut(auth)
                }}>No</Button>
                <Button variant='outline' onClick={() => {
                    setLoading(false)
                    onCloseModel()
                    sendVerificationEmailAgain()

                }}>Yes</Button>
        </ModalFooter>
        </ModalContent>
    </Modal>


      

    </Center>
   </>
   )

 }
 