import { useEffect, useState } from 'react'
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { firebaseStorage } from '../firebaseSetup';
import { Image, ResponsiveValue } from '@chakra-ui/react';


export default function FirebaseStorageImage(props: {imagePath: string, width?: string, height?: string}) {

  const [profileImageUrl, setProfileImageUrl] = useState<string>()  

  const downloadProfileImage = (imageUrl: string) => {

    getDownloadURL(ref_storage(firebaseStorage, imageUrl))
    .then((url) => {
      console.log("URL is :", url)
      setProfileImageUrl(url)
    })
    .catch((error) => {   

      //Download a placeholder image
      getDownloadURL(ref_storage(firebaseStorage, `static_images/seat_placeholder.jpg`))
      .then((url) => {
        console.log("URL is :", url)
        setProfileImageUrl(url)
      })    

      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.log("Error getting URL, object not found")       
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log("Error getting URL, permission not granted")          
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Error getting URL,user cancelled the upload")          
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Error getting URL", error.code)
          
          break;
      }
    })

  }    

  useEffect(() => {
    console.log(`Path to shop image: ${props.imagePath}`)
    downloadProfileImage(props.imagePath)
  },[])

  return ( 
      <Image width={props.width} height={props.height} objectFit='cover' src={profileImageUrl}/>   
  )
}
