import { Center, Card, Heading, Text } from '@chakra-ui/react';

export default function NotFound() {
  return (
    <Center>
        <Card 
        width={{base: '95%', sm: '35%'}}
        marginTop='20vh'
        rounded={'lg'}
        bg='whiteAlpha.100'
        boxShadow={'lg'}
        padding='40px'
        alignItems='center'
        borderTop="8px"
        borderColor="brand.medium">

            <Heading as='h4' size='md' mb='20px' color='brand.medium'>Electric Studio</Heading>  

            <Text mb='10px'>Page not found.</Text>

            <Text>This site exists, but not a valid page. </Text>

        </Card>
    </Center>
  )
}
