import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { eventWithId } from '../../Utils/Interfaces'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { get, getDatabase, onValue, ref } from 'firebase/database'
import { Box, Center, Container, Grid, GridItem, Heading, Link, Show, SkeletonCircle, SkeletonText, Spinner, Text, VStack } from '@chakra-ui/react';
import BookingCards from '../../components/BookingCards'
import DashboardCalendar from '../../components/DashboardCalendar'
import { fetchSeats, fetchSingleStringValue, getBookingStatus, pastBooking } from '../../tools/ReturnFunctions'
import { Link as RouteLink, useNavigate } from 'react-router-dom'


export default function ShopDashboard() {
  
  const firebaseUser = useFirebaseAuth()
  const dbRef = getDatabase()
  
  const {user, setUser} = useContext(UserContext)
  if (user.type === '') setUser({type: "shops"}) 
  const navigate = useNavigate() 
  
  const [allEvents, setAllEvents] = useState<Array<eventWithId>>([])
  const [eventsPendingConfirmation, setEventsPendingConfirmation] = useState<Array<eventWithId>>([])
  const [eventsBookingConfirmed, setEventsBookingConfirmed] = useState<Array<eventWithId>>([])
  const [eventsBookingCompleted, setEventsBookingCompleted] = useState<Array<eventWithId>>([])
  const [eventsBookingCompletedToReview, setEventsBookingCompletedToReview] = useState<Array<eventWithId>>([])
  const [eventsDeclinedOrCancelled, setEventsDeclinedOrCancelled] = useState<Array<eventWithId>>([])
  const [loading, setLoading] = useState(true)

  const [selectedEvent, setSelectedEvent] = useState<eventWithId>()

  const [profileExists, setProfileExists] = useState(false)
  const [locationExists, setLocationExists] = useState(false)
  const [businessHoursExists, setBusinessHoursExist] = useState(false)
  const [seatsExist, setSeatsExist] = useState(false)

  async function checkSetup() {
    const profile = await fetchSingleStringValue(`shops/auth_read/${firebaseUser?.uid}/profile_settings/firstName`)
    if (profile) setProfileExists(true)
    
    const location = await fetchSingleStringValue(`shops/auth_read/${firebaseUser?.uid}/shop_address/placeAddress`)
    if (location) setLocationExists(true)

    const businessHours = await fetchSingleStringValue(`shops/auth_read/${firebaseUser?.uid}/open_hours/mondayOpenTime`)
    if (businessHours) setBusinessHoursExist(true)

    const allSeats = await fetchSeats(`shops/auth_read/${firebaseUser?.uid}/seats`)
    if (allSeats.length != 0) setSeatsExist(true)

    fetchAllCalendars()    
  }

  async function fetchAllCalendars() {

    const seatsRef = ref(dbRef, `shops/auth_write/${firebaseUser?.uid}/calendars/`)
    onValue(seatsRef, async (snapshot) => {

      const seatNames: Array<string> = []

      snapshot.forEach((childSnapshot) => {
        console.log(`Seat name is: ${childSnapshot.key}`)
        seatNames.push(childSnapshot.key!)        
      })

      console.log(`Got the seats: ${seatNames.valueOf()}`)
      fetchEvents(seatNames)
    })
  }


  async function fetchEvents(seats: Array<string>) {

    const allEvents: Array<eventWithId> = [] 
    const eventsPendingConfirmation: Array<eventWithId> = [] 
    const eventsBookingConfirmed: Array<eventWithId> = [] 
    const eventsBookingCompleted: Array<eventWithId> = [] 
    const eventsBookingCompletedToReview: Array<eventWithId> = [] 
    const eventsDeclinedOrCancelled: Array<eventWithId> = [] 
    
    seats.map(async(seat) => {
      
      console.log(`Seat path: ${`shops/auth_write/${firebaseUser?.uid}/calendars/${seat}`}`)
      
      const seatsRef = ref(dbRef, `shops/auth_write/${firebaseUser?.uid}/calendars/${seat}`)
      
      await get(seatsRef).then((snapshot) => {
        if (snapshot.exists()) {

          console.log(`Seat profile: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string

          snapshot.forEach((childSnapshot) => { 

            const event: eventWithId = ({...childSnapshot.val(), key: childSnapshot.key})  

            const startTime = new Date(`${event.date}T${event.startTime}:00.000Z`)
            const endTime = new Date(`${event.date}T${event.endTime}:00.000Z`)

            //Get timezone offset at data
            const offset = startTime.getTimezoneOffset() / 60

            //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
            startTime.setHours(startTime.getHours() + offset)
            endTime.setHours(endTime.getHours() + offset)
            console.log(`Key ${event.key} -- Start time: ${startTime} -- End time: ${endTime}`)

            const isPastBooking = pastBooking(event.date!, event.startTime!)
            const title = event.stylistName + " - " + getBookingStatus(isPastBooking, event.bookingConfirmed!, event.reviewKey, event.bookingDeclined, event.bookingCancelledBy)
            
            const bookingEvent = {...event, title: title, start: startTime, end: endTime}        
            
            console.log(`Event snapshot: ${JSON.stringify(childSnapshot.val())}`) 

            allEvents.push(bookingEvent)
            if (title.includes("Pending confirmation")) eventsPendingConfirmation.push(bookingEvent)
            if (title.includes("Booking confirmed")) eventsBookingConfirmed.push(bookingEvent)
            if (title.includes("to review")) {
              eventsBookingCompletedToReview.push(bookingEvent)
            } else {
              if (title.includes("Booking completed")) eventsBookingCompleted.push(bookingEvent)
            }
            if (title.includes("declined") || title.includes("Cancelled") || title.includes("Unconfirmed")) eventsDeclinedOrCancelled.push(bookingEvent)
          })  
          
        } else {
          console.log("No data available")     
        }
      }).catch((error) => {
        console.error(error)
      })

    })
    setAllEvents(allEvents)
    setEventsPendingConfirmation(eventsPendingConfirmation)  
    setEventsBookingConfirmed(eventsBookingConfirmed)  
    setEventsBookingCompleted(eventsBookingCompleted) 
    setEventsBookingCompletedToReview(eventsBookingCompletedToReview)   
    setEventsDeclinedOrCancelled(eventsDeclinedOrCancelled)  

    setLoading(false)
  }


  useEffect(() => {    
    checkSetup()   
  },[firebaseUser, user])



 
  return (
    <div>

      
      

      {allEvents &&
      <Grid
      templateAreas={{
        base: `"calendar-view"
              "event-list"`,
        md: `"event-list calendar-view"`}}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14} 
      padding={{base: 1, sm: 5}} 
      gap='2'>

        <GridItem paddingX={{base: 1, sm: 5}} area={'event-list'} colStart={1} colSpan={{base: 12, sm: 6}} marginTop='10px'>

          <Show above='sm'>

            {loading == true &&
              <>
                <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
                  <SkeletonCircle size='10' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
                  <SkeletonCircle size='10' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
                  <SkeletonCircle size='10' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
                  <SkeletonCircle size='10' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>          
              </> 
              }

              {allEvents.length == 0 &&
                <Center height={{base: '10vh', sm: '70vh'}} marginY='5vh'>
                  <VStack>

                    {profileExists == true && locationExists == true && businessHoursExists == true && seatsExist == true ?
                    <>
                      <Heading size='md'textColor='brand.medium'>No bookings have been made yet.</Heading>
                      <Heading size='sm' paddingBottom='20px'>Bookings will be listed here</Heading> 
                    </>
                    :
                    <>
                    <Heading size={{base: 'sm', sm: 'md'}} textColor='brand.medium'>Your profile is incomplete and cant be listed yet. </Heading>
                    <Heading size={{base: 'sm', sm: 'xs'}} paddingBottom='20px'>The following needs to be setup to list.</Heading> 
                    </>

                    }

                    {profileExists == false &&
                      <Link
                        onClick={()=> navigate("/shopsettings", { state: { tabIndex: 0 } })} 
                        color='brand.medium'>
                        Your salons bio, contact details and profile image.
                      </Link>   
                    }

                    {locationExists == false &&
                      <Link
                        onClick={()=> navigate("/shopsettings", { state: { tabIndex: 1 } })} 
                        color='brand.medium'>
                        The location of your salon.
                      </Link> 
                    }

                    {businessHoursExists == false &&
                      <Link
                        onClick={()=> navigate("/shopsettings", { state: { tabIndex: 2 } })} 
                        color='brand.medium'>
                        Your business operating hours.
                      </Link>                   
                    }

                    {seatsExist == false &&
                      <Link as={RouteLink} to='/seatprofilenew' color='brand.medium'>You need to list a chair.</Link> 
                    }

                  </VStack>            
                </Center>              
              }            
          </Show>


          
          {allEvents.length > 0 &&
            <Heading size='md' paddingTop='15px' marginBottom='15px'>Bookings:</Heading>
          }

          {eventsPendingConfirmation.length > 0 &&
            <BookingCards allEvents={eventsPendingConfirmation} selectedEvent={selectedEvent} bookingStatus='Pending confirmation'/>
          }
          {eventsBookingConfirmed.length > 0 && 
            <BookingCards allEvents={eventsBookingConfirmed} selectedEvent={selectedEvent} bookingStatus='Bookings confirmed'/>
          }
          {eventsBookingCompletedToReview.length > 0 && 
            <BookingCards allEvents={eventsBookingCompletedToReview} selectedEvent={selectedEvent} bookingStatus='Completed bookings - pending review'/>
          }
          {eventsBookingCompleted.length > 0 && 
            <BookingCards allEvents={eventsBookingCompleted} selectedEvent={selectedEvent} bookingStatus='Completed bookings'/>
          }
          {eventsDeclinedOrCancelled.length > 0 && 
            <BookingCards allEvents={eventsDeclinedOrCancelled} selectedEvent={selectedEvent} bookingStatus='Declined or cancelled'/>
          }
        </GridItem>


        <GridItem area={'calendar-view'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 6}}  paddingEnd={{base: 1, sm: 5}} position={{base: 'static', sm: 'fixed'}} right='0' top='11vh' width={{base: '100%', sm: '50%'}} marginTop='10px'>
          
          <Show below='sm'>

            {loading == true &&
              <>
                <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
                  <SkeletonCircle size='10' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>        
              </> 
            }
            

            {allEvents.length == 0 &&
              <Center height={{base: '15vh', sm: '70vh'}} marginY='5vh' >
                <VStack>

                  {profileExists == true && locationExists == true && businessHoursExists == true && seatsExist == true ?
                  <>
                    <Heading size='md'textColor='brand.medium'>No bookings have been made yet.</Heading>
                    <Heading size='sm' paddingBottom='20px'>Bookings will be listed here</Heading> 
                  </>
                  :
                  <>
                  <Heading size={{base: 'sm', sm: 'md'}} textColor='brand.medium'>Your profile is incomplete and cant be listed yet. </Heading>
                  <Heading size={{base: 'sm', sm: 'xs'}} paddingBottom='20px'>The following needs to be setup to list...</Heading> 
                  </>

                  }

                  {profileExists == false &&
                    <Link
                      onClick={()=> navigate("/shopsettings", { state: { tabIndex: 0 } })} 
                      color='brand.medium'>
                      Your salons bio, contact details and profile image.
                    </Link>   
                  }

                  {locationExists == false &&
                    <Link
                      onClick={()=> navigate("/shopsettings", { state: { tabIndex: 1 } })} 
                      color='brand.medium'>
                      The location of your salon.
                    </Link> 
                  }

                  {businessHoursExists == false &&
                    <Link
                      onClick={()=> navigate("/shopsettings", { state: { tabIndex: 2 } })} 
                      color='brand.medium'>
                      Your business operating hours.
                    </Link>                   
                  }

                  {seatsExist == false &&
                    <Link as={RouteLink} to='/seatprofilenew' color='brand.medium'>You need to list a chair.</Link> 
                  }

                </VStack>            
              </Center>              
            }


          </Show>         
          
          
          <DashboardCalendar allEvents={allEvents} setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent}/>
        </GridItem>
      </Grid>

      }
      
    </div>
  )
}
 