import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Center, Card, Heading, FormControl, InputGroup, InputLeftElement, Input, InputRightElement, IconButton, Button, HStack, Divider, Text, FormErrorMessage, Link, Alert, AlertIcon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, FormLabel, Box, Spinner, VStack } from '@chakra-ui/react'
import { Formik, Field } from 'formik'
import React, { useState } from 'react'
import { AiFillApple } from 'react-icons/ai'
import { MdEmail, MdKey, MdAppRegistration } from 'react-icons/md'
import { FcGoogle } from 'react-icons/fc';
import { Link as RouteLink, useNavigate } from 'react-router-dom'
import {GoogleAuthProvider, createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithPopup, signOut } from 'firebase/auth'
import { auth } from '../../firebaseSetup'
import { child, get, getDatabase, ref } from 'firebase/database'



//https://www.youtube.com/watch?v=C_U_KPMyU_8
export default function Register() {
  
const [show, setShow] = React.useState(false)
const handleClickPasswordVisible = () => setShow(!show)
const [passwordValue, setPasswordValue] = useState("")
const [showAlert, setShowAlert] = useState(false)
const [loginErrorValue, setLoginErrorValue] = useState("")
const [loading, setLoading] = useState(false)

const { isOpen, onOpen, onClose } = useDisclosure()
const navigate = useNavigate()
const navigateToLogin = () => {
    navigate('/login')
    onClose()
}

function googleSignin() {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    signInWithPopup(auth, provider)
        .then((result) => {
            console.log(`Sucessfully logged on with Google: ${result.user}`)
            checkProfileAndNavigate(result.user.uid)
        }).catch((error) => {
            console.log(error)
            setLoginErrorValue(error.message)
            setShowAlert(true)
    })
}

function checkProfileAndNavigate(uId: string) {
    
    const dbRef = ref(getDatabase())

    get(child(dbRef, `stylists/auth_read/${uId}`)).then((stylistSnapshot) => {
        if (stylistSnapshot.exists()) {
            console.log(stylistSnapshot.val())
            navigate('/stylistdashboard') //Go to stylistdashboard if profile is found 
        } else {
            console.log("Stylist directory not found")

            get(child(dbRef, `shops/auth_read/${uId}`)).then((shopSnapshot) => {
                if (shopSnapshot.exists()) {
                    console.log(shopSnapshot.val())
                    navigate('/shopdashboard') //Go to shopedashboard if profile is found 
                } else {
                    console.log("Shop directory not found")
                    navigate('/usertype') //Go to setup page if no profile found
                }
            });
        }
    }).catch((error) => {
        console.error(error);
    });
}


return (

    <Center height='90vh'>
        <Card 
          maxW="480px"
          minW='350px'
          rounded={'lg'}
          bg='whiteAlpha.100'
          boxShadow={'lg'}
          padding='40px'
          alignItems='center'
          borderTop="8px"
          borderColor="brand.medium">

            <Heading as='h4' size='md' mb='20px' color='brand.medium'>Register with email address</Heading>  

            
            <Formik

                //These need to match the Field id values
                initialValues={{
                    email: "",
                    password: "",
                    repeatPassword: ""
                }}

                onSubmit={(values, { resetForm }) => {

                    setLoading(true)                    
                    setLoginErrorValue("")
                    setShowAlert(false)
                    
                    createUserWithEmailAndPassword(auth, values.email, values.repeatPassword)
                    .then((userCredential) => {
                        console.log('User created:', userCredential.user)
                        setLoading(false)
                        resetForm();

                        sendEmailVerification(userCredential.user)
                        .then(() => {
                            console.log(`Email verification sent to ${userCredential.user.email}`)
                            setLoading(false)
                            onOpen()
                            signOut(auth)
                        })
                        .catch((err) => {
                            setLoading(false)
                            console.log(err.message)
                        })

                    })
                    .catch((err) => {
                        
                        setLoading(false)
                        const errorMessage = err.message
                        console.log(errorMessage)
                        setLoginErrorValue(errorMessage)
                        setShowAlert(true)
                    })
                }}

        >
                {({handleSubmit, errors, touched}) => (

                    <form onSubmit={handleSubmit}>


                        {/* Email address */}
                        <FormControl mb="20px" id='signUpForm' isInvalid={!!errors.email && touched.email}>
                            <InputGroup>


                                {/* Used to stop hubspot from tracking this form */}
                                {/* https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/td-p/246957?profile.language=en */}
                                <FormLabel htmlFor='amex' display='none'>AMEX</FormLabel>
                                <Field
                                as={Input}
                                type="hidden"
                                name="amex"
                                required="" 
                                display='none'
                                value=""                             
                                id="amex"
                                />

                                <Field
                                    as={Input}
                                    id='email'
                                    type='email'
                                    name='email'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Email Address'
                                    pl='2.5rem'  
                                    validate={(value: string) => {

                                        const isValidEmailAddress = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                                       
                                        if (!isValidEmailAddress) {
                                            return "Please enter a valid email address";
                                        }

                                    }}
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdEmail color='brand.medium' />
                                </InputLeftElement>

                            </InputGroup>

                            <FormErrorMessage>{errors.email}</FormErrorMessage>

                        </FormControl>


                        {/* Password */}
                        <FormControl mb="20px" isInvalid={!!errors.password && touched.password}>
                            <InputGroup size='md'>

                                <Field
                                    as={Input}
                                    id='password'
                                    type={show ? 'text' : 'password'}
                                    name='password'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Password'
                                    pl='2.5rem'
                                    validate={(value: string) => {

                                        setPasswordValue(value)

                                        const isAtLeast8Characters = value.length >= 8;
                                        const hasAtLeastOneSpecialCharacter = /[!@#$%^&*()_+]/.test(value);
                                        const hasAtLeastOneUpperCaseCharacter = /[A-Z]/.test(value);

                                        if (!isAtLeast8Characters) {
                                            return "Password needs to be at least 8 characters";
                                        }

                                        if (!hasAtLeastOneSpecialCharacter) {
                                            return "Password needs to have at least 1 special character";
                                        }

                                        if (!hasAtLeastOneUpperCaseCharacter) {
                                            return "Password needs to be at 1 upper case character";
                                        }

                                    }}                          
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdKey color='brand.medium' />
                                </InputLeftElement>

                                <InputRightElement width='4.5rem'>
                                    <IconButton onClick={handleClickPasswordVisible} aria-label='Search database' variant='ghost' icon={show ? <ViewIcon/> : <ViewOffIcon/>} />
                                </InputRightElement>


                            </InputGroup>

                            <FormErrorMessage>{errors.password}</FormErrorMessage>

                        </FormControl>


                        {/* Repeat password */}
                        <FormControl mb="20px" isInvalid={!!errors.repeatPassword && touched.repeatPassword}>
                            <InputGroup size='md'>

                                <Field
                                    as={Input}
                                    id='repeatPassword'
                                    type={show ? 'text' : 'password'}
                                    name='repeatPassword'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Password'
                                    pl='2.5rem'
                                    validate={(value: string) => {                                        

                                        if (value != passwordValue) {
                                            return "Passwords do not match"
                                        }                                 
                                        
                                    }}                          
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdKey color='brand.medium' />
                                </InputLeftElement>

                                <InputRightElement width='4.5rem'>
                                    <IconButton onClick={handleClickPasswordVisible} aria-label='Search database' variant='ghost' icon={show ? <ViewIcon/> : <ViewOffIcon/>} />
                                </InputRightElement>

                            </InputGroup>
                                                    
                            <FormErrorMessage>{errors.repeatPassword}</FormErrorMessage>

                        </FormControl>

                        <Center mb='40px'>
                            <Button
                                type="submit"
                                leftIcon={<MdAppRegistration />}
                                maxWidth='450px'
                                minWidth='330px'
                                disabled={true}
                                variant='solid'>
                                Register
                            </Button>
                        </Center>

                    </form>

                )}
            </Formik>

            {/* If sign in fails, display a message to the user. */}
            {showAlert && (
                <Alert status='error'>
                  <AlertIcon />
                    {loginErrorValue}
                </Alert>
            )}


            <Divider mb='20px' borderColor='brand.medium'/>

            <Text size ='sm' mb='20px'>Or...</Text>

            <HStack mb='40px' maxWidth='450px' width='330px' minWidth='300px'>
                <Button flex='1' variant={'outline'} leftIcon={<FcGoogle />} onClick={() => googleSignin()}>Sign up with Google</Button>
                {/* <Button leftIcon={<AiFillApple />} flex='1' variant='solid' backgroundColor='black'>Sign up with Apple</Button> */}
            </HStack>

            <Text>
                Already a user? <Link as={RouteLink} to='/login' color='brand.medium'>Login</Link> 
            </Text>           

        </Card>

        {loading && 
            <Center width='100vw' height='100vh' zIndex='100' position='fixed' top='0px' left='0px' backgroundColor='blackAlpha.600'>
            <VStack>
                <Spinner
                color='brand.lite'
                size='xl'
                thickness='5px'
                speed='0.65s'
                />

                <Text textColor='white'>Loading</Text>
            </VStack>
            </Center>
        }

        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent  borderTop='8px' borderColor='brand.medium' >
                <ModalHeader>Verify your account</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <Text mb='10px'>An email has been sent to your inbox with a link to verify your account.</Text>
                    <Text mb='10px'>Remember to check your junk folder.</Text>                   
                </ModalBody>

                <ModalFooter>
                      <Button variant='outline' onClick={navigateToLogin}>Go to login screen</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

    </Center>
    )
}
