import { useEffect, useState } from "react";
import { get, getDatabase, ref } from "firebase/database";
import { Text } from '@chakra-ui/react';

export default function FirebaseRtdbText(props: {valuePath: string}) {

  const dbRef = getDatabase()
  const [firebaseValue, setFirebaseValue] = useState()

  console.log(`Path is: ${props.valuePath}`)


  function fetchValue() {
    const profileRef = ref(dbRef, props.valuePath)


    get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`Shopshot value: ${snapshot.val()}`);
          setFirebaseValue(snapshot.val())
        } else {
          console.log("No data available")
        }
      }).catch((error) => {
        console.error(error);
      });
  }
  
  useEffect(() => {
    fetchValue()
  },[]) 


  return (
      <Text>{firebaseValue}</Text>  
  )
}
