import { HStack, Icon } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdStar, MdStarBorder, MdStarHalf, MdStarOutline } from 'react-icons/md'

export default function StarRatingStatic(props: {rating: number}) {

  console.log(`Rating is: ${props.rating}`)


  return (
    <div>
      <HStack paddingTop='20px'>

        {props.rating >= 1 ?      
          <Icon as={MdStar} w={6} h={6} color='#FFC107'/>      
        :
        <>
            {(props.rating < 1) && (props.rating > 0.5) ?

              <Icon as={MdStarHalf} w={6} h={6} color='#FFC107'/>
              :
              <Icon as={MdStarOutline} w={6} h={6} color='grey'/>
              
            }
        </>
        }   

        {props.rating >= 2 ?      
          <Icon as={MdStar} w={6} h={6} color='#FFC107'/>      
        :
        <>
            {(props.rating < 2) && (props.rating > 1.3) ?

              <Icon as={MdStarHalf} w={6} h={6} color='#FFC107'/>
              :
              <Icon as={MdStarOutline} w={6} h={6} color='grey'/>
              
            }
        </>
        }   
        {props.rating >= 3 ?      
          <Icon as={MdStar} w={6} h={6} color='#FFC107'/>      
        :
        <>
            {(props.rating < 3) && (props.rating > 2.3) ?

              <Icon as={MdStarHalf} w={6} h={6} color='#FFC107'/>
              :
              <Icon as={MdStarOutline} w={6} h={6} color='grey'/>
              
            }
        </>
        }   
        {props.rating >= 4 ?      
          <Icon as={MdStar} w={6} h={6} color='#FFC107'/>      
        :
        <>
            {(props.rating < 4) && (props.rating > 3.3) ?

              <Icon as={MdStarHalf} w={6} h={6} color='#FFC107'/>
              :
              <Icon as={MdStarOutline} w={6} h={6} color='grey'/>
              
            }
        </>
        }   
        {props.rating >= 5 ?      
          <Icon as={MdStar} w={6} h={6} color='#FFC107'/>      
        :
        <>
            {(props.rating < 5) && (props.rating > 4.3) ?

              <Icon as={MdStarHalf} w={6} h={6} color='#FFC107'/>
              :
              <Icon as={MdStarOutline} w={6} h={6} color='grey'/>
              
            }
        </>
        }   



      

   
      </HStack>
    </div>
  )
}
