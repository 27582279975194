import { useEffect, useState } from 'react'
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { firebaseStorage } from '../firebaseSetup';
import { Image, ResponsiveValue } from '@chakra-ui/react';
import { useLoadScript } from '@react-google-maps/api';
import { getDetails } from 'use-places-autocomplete';
import { fetchShopAddress } from '../tools/ReturnFunctions';


export default function ViewShopProfileImage(props: {uID: string, width?: string, height?: string}) {

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
  const {isLoaded} = useLoadScript({googleMapsApiKey: configValue, libraries: ['places']})  
  
  const [profileImageUrl, setProfileImageUrl] = useState<string>()

  const downloadProfileImage = () => {

    getDownloadURL(ref_storage(firebaseStorage, `shops/${props.uID}/profile_image.jpg`))
    .then((url) => {
      console.log("URL is :", url)
      setProfileImageUrl(url)
    })
    .catch(async(error) => {  
      
      const shopAddress = await fetchShopAddress(`shops/auth_read/${props.uID}/shop_address`)
  
      //If profile image has not been uploaded, use google photo
      const parameter = {placeId: shopAddress!.placeId, fields: ["photos"]}
      const details = await getDetails(parameter) as google.maps.places.PlaceResult
      setProfileImageUrl(details.photos![0].getUrl())


      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.log("Error getting URL, object not found")       
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log("Error getting URL, permission not granted")          
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Error getting URL,user cancelled the upload")          
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Error getting URL", error.code)
          
          break;
      }
    })

  }    

  useEffect(() => {
    if (isLoaded) downloadProfileImage()
  },[isLoaded])

  return ( 
      <Image objectFit='cover' src={profileImageUrl} width={props.width} height={props.height}/>   
  )
}
