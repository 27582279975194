import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { menuItem } from '../Utils/Interfaces'
import { fetchStylistMenu } from '../tools/ReturnFunctions'
import { Table, Thead, Tr, Th, Tbody, Td, Button, Spinner, Text } from '@chakra-ui/react'

export default function ViewStylistMenu(props: {stylistFirebaseUid: string})  {
 
  const [loading, setLoading] = useState<boolean>(true)
  const [menuList, setMenuList] = useState<Array<menuItem>>()
  const navigate = useNavigate()  


  async function fetchMenu() {
    console.log(`Fetching profile`)
    const menuList = await fetchStylistMenu(`stylists/auth_read/${props.stylistFirebaseUid}/menu_items`)
    setMenuList(menuList)
    console.log(`Fetched menu: ${menuList}`)
    setLoading(false)
  }

 
  useEffect(() => {
    fetchMenu()
  },[]) 

  
  return (
    <div>
      
      {loading && 
        <div>
          <Spinner
            color='brand.medium'
            size='xl'
            thickness='5px'
            speed='0.65s'/>
          <Text>Loading</Text>
        </div>
      }

      <Table width={{base: '95vw', sm: '600px'}}>
        {menuList?.length != 0 &&
          <Thead>
            <Tr>
              <Th  textAlign='left' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'md', sm: 'lg'}} borderBottomColor='brand.lite' borderBottomWidth='1px'>Service</Th>
              <Th  textAlign='center' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'md', sm: 'lg'}} borderBottomColor='brand.lite' borderBottomWidth='1px'>Price</Th>
            </Tr>
          </Thead>
        }

          <Tbody>
          {menuList && menuList.map((menuItem, index) => {

            return (
              <Tr key={index} >
                <Td paddingX={{base: '0px', sm: '12px'}} wordBreak='break-word' >{menuItem.menuDescription}</Td>
                <Td paddingX={{base: '0px', sm: '12px'}} wordBreak='break-word' textAlign='center' >{menuItem.itemPrice}</Td>
              </Tr>
            )
          })}                

          </Tbody>
        </Table>

        <Button width={{base: '95vw', sm: '600px'}} marginTop='20px' onClick={() => navigate(`/contactstylist/${props.stylistFirebaseUid}`)  }>Contact stylist</Button>

     

     
    
    </div>
  )
}
