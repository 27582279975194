import { Box, Card, CardBody, Container, Flex, Heading, Show, Spinner, Stack, Tag, TagCloseButton, TagLabel, Text, VStack } from '@chakra-ui/react';
import { GoogleMap, MarkerF, useLoadScript  } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { child, getDatabase, onValue, push, ref, ref as ref_database, remove, set } from "firebase/database";
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'

export default function StylistLocation() {

  //https://www.youtube.com/watch?v=9e-5QHpadi0&t=8s
  //https://www.youtube.com/watch?v=BL2XVTqz9Ek
  //https://github.com/leighhalliday/google-maps-intro/blob/main/pages/places.js
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: configValue,
    libraries: ["places"]
  })  

  if (!isLoaded) return (
    <div>
            <Spinner
              color='brand.medium'
              size='xl'
              thickness='5px'
              speed='0.65s'/>
            <Text>Loading search</Text>
            </div>
  )
  return <Places />
}


function Places() {

  const firebaseUser = useFirebaseAuth();
  const dbRef = getDatabase()
  
  const [locations, setLocations] = useState<{
    key: string,
    placeId: string,
    placeName: string
  }[]>([])
  
  function fetchLocations() {
   
    const locationsRef = ref(dbRef, `stylists/auth_read/${firebaseUser?.uid}/locations`);
    
      onValue(locationsRef, (snapshot) => {
  
        const allLocations: Array<{key: string, placeId: string, placeName: string}> = []
  
        snapshot.forEach((childSnapshot) => {
  
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const key: string = childSnapshot.key!
          const data: {placeId: string; placeName: string;} = childSnapshot.val()
          console.log(`Key: ${key} --- ID: ${data.placeId} --- name: ${data.placeName} --- name: ${data.placeName}`)  
          allLocations.push({key: key, placeId: data.placeId, placeName: data.placeName})       
        }); 
  
        setLocations(allLocations)
     
    })
  }
  
  useEffect(() => {
    console.log(`Fecthcing locations`)
    fetchLocations()
  },[firebaseUser]) 
  
  function addLocation(geoCode: google.maps.GeocoderResult, details: google.maps.places.PlaceResult) {
   
    const newPostKey = push(child(ref(dbRef), `stylists/auth_read/${firebaseUser?.uid}/locations`)).key;
  
    console.log(`New post key: ${newPostKey}`)
  
    set(ref_database(dbRef, `stylists/auth_read/${firebaseUser?.uid}/locations/${newPostKey}`), { 
      placeId: geoCode.place_id,
      placeName: details.name   
      })
      .then(() => {
        console.log('Saved location')    
      })  
  
  }
  
  const removeLocation = (key: string) => {
    remove(ref((dbRef), `stylists/auth_read/${firebaseUser?.uid}/locations/${key}`)).then(() => {
      console.log(`Entry deleted`)
    })  
  }
  
  //https://developers.google.com/maps/documentation/javascript/dds-boundaries/coverage
  const  {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({    
    requestOptions: { 
      componentRestrictions: {
        country: 'AU',
      },
      types: ['geocode'],    
    },      
  }) 
  
  //https://github.com/wellyshen/react-cool-onclickoutside
  // const ref = useOnclickOutside(() => {
  //   // When user clicks outside of the component, we can dismiss
  //   // the searched suggestions by calling this method
  //   clearSuggestions();
  // });
  
  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
  
    const results = await getGeocode({ address })

    const { lat, lng } = await getLatLng(results[0])
  
    const parameter = {
      placeId: results[0].place_id,
      fields: ["name", "geometry.location"],
    };
  
    const details = await getDetails(parameter) as google.maps.places.PlaceResult

    console.log(`Place details: ${JSON.stringify(results[0])} == ${JSON.stringify(details)}`)
    
    addLocation(results[0], details) 
    
    mapRef?.setZoom(11)
     const myLatlng = new google.maps.LatLng(lat, lng)
     mapRef?.panTo(myLatlng)   
  };
  
  const comboboxInputStyle = {    
    width: '385px',
    borderStyle: 'none', 
    padding: '0.5rem',
     
  }; 

  const comboboxInputStyleBase = {    
    width: '87vw',
    borderStyle: 'none', 
    padding: '0.5rem',     
  }; 

  const [zoom, setZoom] = useState(3.4)
  const [camera, setCamera] = useState(new google.maps.LatLng(-28, 133))
  const [mapRef, setMapRef] = useState<google.maps.Map>()  
  
  const onLoad = (map: google.maps.Map) => {
    
    // subscribe to changes
    map.addListener('mapcapabilities_changed', () => {
      const mapCapabilities = map.getMapCapabilities();
      
      if (!mapCapabilities.isDataDrivenStylingAvailable) {
        console.log('Data-driven styling is not available.');
        // Data-driven styling is *not* available, add a fallback.
        // Existing feature layers are also unavailable.
      }
    });
    

    setMapRef(map)    
  }

  async function updateBoundaries() {

    const featureLayer = mapRef!.getFeatureLayer(google.maps.FeatureType.LOCALITY);
    
    const featureStyleOptions: google.maps.FeatureStyleOptions = {
      strokeColor: '#f15e8a',
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: '#f15e8a',
      fillOpacity: 0.5,
    }   

    const placeIds: string[] = [];
    
    locations.forEach((element) => {
      console.log(`Place ID: ${element.placeId}`)
      placeIds.push(element.placeId)
    })

    console.log(`Place ID's: ${placeIds}`)
    
    // Apply the style to a single boundary.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    featureLayer.style = (options: { feature: { placeId: string; }; }) => {
      if (placeIds.includes(options.feature.placeId)) {
        // Your code here
      
        return featureStyleOptions;
      }
    }

    
    //Get lat and lng froms first location in locations array using placeId
    if (locations.length === 0) return
    const results = await getGeocode({ placeId: locations[0].placeId });
    const { lat, lng } = getLatLng(results[0])
    console.log(`Lat: ${lat} --- Lng: ${lng}`)
    
    mapRef?.setZoom(11)
     const myLatlng = new google.maps.LatLng(lat, lng)
     mapRef?.panTo(myLatlng)   
  }

  useEffect(() => {

    if (mapRef === undefined) return  
    updateBoundaries()
  },[mapRef, locations]) 

  const containerStyle = {
    width: '100vw',
    height: '55vh'
  }

  
  
  
  return (

    <>
      <VStack zIndex='20' position={{base: 'static', sm: 'absolute'}} top={{sm: '150px'}} width='100%'>        
      
        <Container borderStyle='solid' borderColor='brand.medium' borderWidth='1px' padding='5px' borderRadius='5px' width={{base: '90vw', sm: '400px'}} marginTop='10px' backgroundColor='white'>     
          <Combobox onSelect={handleSelect}>                  

            <Show above='sm'>
              <ComboboxInput
                value={value}
                className="pink"
                
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}    
                style={comboboxInputStyle}
                placeholder="Search a suburb"
              />
            </Show>

            <Show below='sm'>
              <ComboboxInput
                value={value}
                className="pink"
                
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}    
                style={comboboxInputStyleBase}
                placeholder="Search a suburb"
              />
            </Show>


            {/* <ComboboxPopover portal={true}> */}
              <ComboboxList
              style={{
                margin: 0,                                   
                        
              }}>
                {status === "OK" &&
                  data.map(({ place_id, description }) => (
                    <ComboboxOption key={place_id} value={description} />
                  ))}
              </ComboboxList>
            {/* </ComboboxPopover> */}
          </Combobox>
        </Container>

        <Container >  
          {
            locations.map((element, index) => {
              return (                  
                  <Tag
                    id={element.key}
                    key={index}
                    size='lg'
                    borderRadius='full'
                    variant='solid'
                    margin='10px'
                    onClick={() => removeLocation(element.key)}
                    backgroundColor='brand.medium'>
                    <TagLabel>{element.placeName}</TagLabel>                    
                    <TagCloseButton />
                  </Tag>
              )
            })
          }  
        </Container>
      </VStack>

      <Box id='box' position={{base: 'static', sm: 'absolute'}} top={{sm: '140px'}}>
        <GoogleMap
          id='map'
          zoom={zoom}
          center={camera}    
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          options={{
            streetViewControl: false,        
            mapTypeControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            mapId: '59bb7c0d21a4a246',
            restriction:{
              latLngBounds: {
                north: -10,
                south: -40,
                east: 160,
                west: 100,
              }
            }          
          }}      
          clickableIcons={false}>
        </GoogleMap>
     </Box>
    </>
  

    
  ) 
}

  




  

