import { Outlet, Navigate } from 'react-router-dom'
import { useFirebaseAuth } from '../context/FirebaseAuthContext';

const PrivateRoutes = () => {

    const firebaseUser = useFirebaseAuth();

    return(
      firebaseUser?.uid ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes