import { VStack, Heading, Flex, Tag, TagLabel, Spinner, Text, Box, Card, Button, CardBody, CardFooter, Divider, Image, HStack, Icon, Show } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { shopAddress, stylistLocation } from '../Utils/Interfaces'
import { fetchStylistLocations } from '../tools/ReturnFunctions'
import { GoogleMap, InfoBox, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api'
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete'
import { getDatabase, onValue, ref as ref_database } from 'firebase/database'
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { firebaseStorage } from '../firebaseSetup'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { MdVisibility, MdLocationOn } from 'react-icons/md'

export default function StylistViewWorkingLocations(props: {stylistFirebaseUid: string}) {

    //https://www.youtube.com/watch?v=9e-5QHpadi0&t=8s
  //https://www.youtube.com/watch?v=BL2XVTqz9Ek
  //https://github.com/leighhalliday/google-maps-intro/blob/main/pages/places.js
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: configValue,
    libraries: ["places"]
  })  

  if (!isLoaded) return (
    <div>
            <Spinner
              color='brand.medium'
              size='xl'
              thickness='5px'
              speed='0.65s'/>
            <Text>Loading search</Text>
            </div>
  )
  return <Places stylistFirebaseUid={props.stylistFirebaseUid} />
}


function Places(props: {stylistFirebaseUid: string}) {

  const [stylistLocations, setStylistLocations] = useState<Array<stylistLocation>>()
  const [shopLocations, setShopLocations] = useState(Array<shopAddress>)
  const [selectedLocation, setSelectedLocation] = useState<number | undefined>()
  const [mapRef, setMapRef] = useState<google.maps.Map>()  
  const [zoom, setZoom] = useState(3.4)
  const [camera, setCamera] = useState(new google.maps.LatLng(-28, 133))
  const { pathname } = useLocation()
  const [InfoWindow, setInfoWindow] = useState(false)
  const navigate = useNavigate()

  async function fetchLocations() {
    const locations = await fetchStylistLocations(`stylists/auth_read/${props.stylistFirebaseUid}/locations`)
    setStylistLocations(locations)
  }



  function fetchShopLocations() {

    const dbRef = getDatabase()
     
    const locationsRef = ref_database(dbRef, `shops/auth_read`);
    
      onValue(locationsRef, async (snapshot) => {
  
        const allLocations: Array<shopAddress> = []
  
        snapshot.forEach((childSnapshot) => {
  
          const placeAddress = childSnapshot.child('shop_address').child('placeAddress').val()
          const placeId = childSnapshot.child('shop_address').child('placeId').val()
          const placeLat = childSnapshot.child('shop_address').child('placeLat').val()
          const placeLng = childSnapshot.child('shop_address').child('placeLng').val()
          const placeName = childSnapshot.child('shop_address').child('placeName').val()
          const userFirebaseUid = childSnapshot.key

          const profileExists = childSnapshot.child('profile_settings').exists()
          const openHoursExists = childSnapshot.child('open_hours').exists()
          const seatsExists = childSnapshot.child('seats').exists()

          if (profileExists && openHoursExists && seatsExists) {
            if (placeId != undefined) allLocations.push({placeAddress: placeAddress, placeId: placeId, placeLat: placeLat, placeLng: placeLng, placeName: placeName, userFirebaseUid: userFirebaseUid })
            console.log(`Name is: ${placeName} -- Address is :${placeAddress}`) 
          }          
        }) 
        
        
        await Promise.all(allLocations.map(async(location) => {
          
          const imageUrl = await getDownloadURL(ref_storage(firebaseStorage, `shops/${location.userFirebaseUid}/profile_image.jpg`)).catch(async () => {
            //If profile image has not been uploaded, use google photo
            const parameter = {placeId: location.placeId, fields: ["photos"]}
            const details = await getDetails(parameter) as google.maps.places.PlaceResult
            return details.photos![0].getUrl()
          })
          console.log(`image URLs is: ${imageUrl}`)
          location.photoUrl = imageUrl
    
        }))
  
        setShopLocations(allLocations)     
    })
  }
  

  useEffect(() => {
    fetchLocations()
    fetchShopLocations()
  },[])

  const onLoad = (map: google.maps.Map) => {
    
    // subscribe to changes
    map.addListener('mapcapabilities_changed', () => {
      const mapCapabilities = map.getMapCapabilities();
      
      if (!mapCapabilities.isDataDrivenStylingAvailable) {
        console.log('Data-driven styling is not available.');
        // Data-driven styling is *not* available, add a fallback.
        // Existing feature layers are also unavailable.
      }
    })   

    setMapRef(map)    
  }

  async function updateBoundaries() {

    if (stylistLocations === undefined || stylistLocations.length === 0) return
    if (mapRef === undefined) return  

    const featureLayer = mapRef!.getFeatureLayer(google.maps.FeatureType.LOCALITY);
    
    const featureStyleOptions: google.maps.FeatureStyleOptions = {
      strokeColor: '#850034',
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: '#850034',
      fillOpacity: 0.5,
    }   

    const placeIds: string[] = [];
    
    stylistLocations.forEach((element) => {
      console.log(`Place ID: ${element.placeId}`)
      placeIds.push(element.placeId)
    })

    console.log(`Place ID's: ${placeIds}`)
    
    // Apply the style to a single boundary.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    featureLayer.style = (options: { feature: { placeId: string; }; }) => {
      if (placeIds.includes(options.feature.placeId)) {
        // Your code here      
        return featureStyleOptions;
      }
    }

    
    //Get lat and lng froms first location in locations array using placeId    
    const results = await getGeocode({ placeId: stylistLocations[0].placeId });
    const { lat, lng } = getLatLng(results[0])
    console.log(`Lat: ${lat} --- Lng: ${lng}`)
    
    mapRef?.setZoom(11)
     const myLatlng = new google.maps.LatLng(lat, lng)
     mapRef?.panTo(myLatlng)   
  }

  useEffect(() => {
    updateBoundaries()
  },[mapRef, stylistLocations]) 

  const containerStyle = {
    width: '100%',
    height: isMobile ? '60vh' : '50vh'  }


  return (
    <Box>

        {stylistLocations?.length !== 0 && stylistLocations &&

        <>         
          <Heading textAlign='center' marginBottom='10px' size='md' marginTop='20px'>Stylist working suburbs</Heading>      

          <Box id='box' borderRadius='10px' overflow='clip' width='100%'>
            <GoogleMap
              id='map'
              zoom={zoom}
              center={camera}    
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              options={{
                streetViewControl: false,        
                mapTypeControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                mapId: '59bb7c0d21a4a246',
                restriction:{
                  latLngBounds: {
                    north: -10,
                    south: -40,
                    east: 160,
                    west: 100,
                  }
                }          
              }}      
              clickableIcons={false}>
              
              { pathname != "/" &&
                shopLocations.map((element, index) => {

                  const myLatlng = new google.maps.LatLng(parseFloat(element.placeLat), parseFloat(element.placeLng))

                  return (

                    <Marker key={element.placeId} position={myLatlng} clickable={true}  icon={{
                      url: 'https://electricstudio.app/img/logo-icon.png', // Replace with the path to your custom icon image
                      scaledSize: new google.maps.Size(40, 40), // Adjust the size of the icon as needed
                    }} onClick={() => {

                      mapRef?.setZoom(12)
                      mapRef?.panTo(myLatlng)    
                      setSelectedLocation(index)
                      console.log(`Location added index: ${index}`)   
                      setInfoWindow(true)

                      //Scroll to selected card                    
                      const card = document.getElementById(element.placeId);
                      if (card) {
                        // 👇 Will scroll smoothly to the top of the next section
                        console.log(`card: ${card}`)
                        card.scrollIntoView({ behavior: 'smooth', block: 'center' })
                      }

                      console.log(`Marker name: ${element.placeName} Centre: ${camera.lat}`)

                      

                    }}></Marker>
                      

                  )
                })
              }  


            {/* <Show above='sm'> */}
              <Card
                position='absolute'
                top={{base: '4px', sm: '15px'}}
                left={{base: '4px', sm: 'unset'}}
                right={{base: '4px', sm: '15px'}}
                padding='10px'
                borderStyle='solid'
                borderWidth='1px'
                >
                  <Heading size='sm' alignSelf='center'>Key:</Heading>
                  <HStack marginBottom='5px'>
                    <Box width='30px' height='30px' backgroundColor='brand.darkAlpha' borderWidth='3px' borderColor='brand.dark'></Box>
                    <Text> Stylists working suburbs</Text>
                  </HStack>

                  <HStack>
                    <Image src='https://electricstudio.app/img/logo-icon.png' width='30px' height='30px' />  
                    <Text> Listed co-working salons</Text>
                  </HStack>
                </Card>
              {/* </Show> */}

            {selectedLocation !== undefined &&

            <>
            <Show above='sm'>
              <Card
                position='absolute'                
                top='15px'
                left='15px'
                bottom='15px'
                borderStyle='solid'
                borderWidth='1px'
                flexGrow='1'
                flexShrink='0'
                borderTop="8px"
                borderTopColor='brand.medium'
              >
                <CardBody>
                  <Image
                    
                        borderRadius='lg'
                        maxHeight='30vh'
                        width='100%'
                        objectFit='cover'
                        src={shopLocations[selectedLocation].photoUrl}/>   

                    <Heading size='sm' marginTop='15px'>{shopLocations[selectedLocation].placeName}</Heading> 
                </CardBody>

                <Divider width='90%' alignSelf='center'  />
                <CardFooter>                      
                  <Button
                    color='brand.medium'
                    variant='ghost'
                    width='100%'
                    leftIcon={<MdVisibility />}
                    onClick={() => {
                      navigate("/salondetails", { state: { shopUid: shopLocations[selectedLocation].userFirebaseUid } })
                    }}
                    >Salon details</Button>
                </CardFooter>
              </Card>
            </Show>

            <Show below='sm'>
            <Card
              position='absolute'                
              top='70%'
              left='4px'
              right='4px'
              bottom='4px'          
              borderTop="8px"
              borderTopColor='brand.medium'
              overflow='clip'
            >
              <HStack height='100%'>

                <Image
           
                  height='100%'
                  width='50%'
                  objectFit='cover'
                  src={shopLocations[selectedLocation].photoUrl}
                />   

                <VStack width='100%'>
                  <Heading size='sm'>{shopLocations[selectedLocation].placeName}</Heading> 
                  <Button
                    color='brand.medium'
                    variant='ghost'                   
                    leftIcon={<MdVisibility />}
                    onClick={() => {
                      navigate("/salondetails", { state: { shopUid: shopLocations[selectedLocation].userFirebaseUid } })
                    }}
                    >Salon details
                  </Button>                
                </VStack>          
              </HStack>  
            
            </Card>
            </Show>
            </>
            }
              
            </GoogleMap>
          </Box>

          
            
                

            <VStack paddingY='10px' width={{base: '95%', sm: '100%'}}>       
              
              <Flex  justifyContent='space-around' wrap='wrap' width={{base: '100%', sm: '300px'}}  >
                {
                  stylistLocations.map((element, index) => {
                    return (       
                                            
                        <Tag
                          id={element.key}
                          key={index}
                          size='lg'
                          borderRadius='full'
                          margin='px'
                          variant='solid'                 
                          backgroundColor='brand.medium'>
                          <TagLabel>{element.placeName}</TagLabel>                           
                        </Tag>
                    )
                  })
                }
              </Flex>

            </VStack>
        
      </>
      } 
    </Box>
  )
}
