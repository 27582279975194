import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { eventReference, eventWithId } from '../../Utils/Interfaces'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { get, getDatabase, onValue, ref } from 'firebase/database'
import { Box, Button, Center, Grid, GridItem, Heading, Show, SkeletonCircle, SkeletonText, Text, VStack } from '@chakra-ui/react';
import BookingCards from '../../components/BookingCards'
import DashboardCalendar from '../../components/DashboardCalendar'
import { getBookingStatus, pastBooking } from '../../tools/ReturnFunctions'
import { MdCalendarMonth } from 'react-icons/md'
import { NavLink } from 'react-router-dom'


export default function StylistDashboard() {
  
  const firebaseUser = useFirebaseAuth()
  const dbRef = getDatabase()
  const {user, setUser} = useContext(UserContext)  
  if (user.type === '') setUser({type: "stylists"})
  
  const [allEvents, setAllEvents] = useState<Array<eventWithId>>([])
  const [eventsPendingConfirmation, setEventsPendingConfirmation] = useState<Array<eventWithId>>([])
  const [eventsBookingConfirmed, setEventsBookingConfirmed] = useState<Array<eventWithId>>([])
  const [eventsBookingCompleted, setEventsBookingCompleted] = useState<Array<eventWithId>>([])
  const [eventsBookingCompletedToReview, setEventsBookingCompletedToReview] = useState<Array<eventWithId>>([])
  const [eventsDeclinedOrCancelled, setEventsDeclinedOrCancelled] = useState<Array<eventWithId>>([])
  const [loading, setLoading] = useState(true)

  const [selectedEvent, setSelectedEvent] = useState<eventWithId>()
   
  function getRefenences() {   
    
    const bookingRefs = ref(dbRef, `stylists/user_only/${firebaseUser?.uid}/bookings`)
    console.log(`Events ref: ${bookingRefs}`)

    onValue(bookingRefs, async (snapshot) => {

      const eventReferences: eventReference[] = []  

      //This foreach waits [perhaps as firebase]
      snapshot.forEach((childSnapshot) => {  
        eventReferences.push(childSnapshot.val())
      })     
    
      getEvents(eventReferences)     
  
    })
  }

  useEffect(() => {    
    getRefenences()
  },[firebaseUser, user])

async function getEvents(bookingRefs: eventReference[]) {

  const allEvents: Array<eventWithId> = [] 
  const eventsPendingConfirmation: Array<eventWithId> = [] 
  const eventsBookingConfirmed: Array<eventWithId> = [] 
  const eventsBookingCompletedToReview: Array<eventWithId> = [] 
  const eventsDeclinedOrCancelled: Array<eventWithId> = [] 

  await Promise.all(bookingRefs.map(async(bookingRef) => {

    const event = ref(dbRef, `shops/auth_write/${bookingRef.shopFirebaseUid}/calendars/${bookingRef!.seatName}/${bookingRef.eventKey}/`)   

    await get(event).then(async (snapshot) => {

      let updatedBooking: eventWithId = {...snapshot.val()}

      const startTime = new Date(`${updatedBooking.date}T${updatedBooking.startTime}:00.000Z`)
      const endTime = new Date(`${updatedBooking.date}T${updatedBooking.endTime}:00.000Z`)

      //Get timezone offset at data
      const offset = startTime.getTimezoneOffset() / 60

      //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
      startTime.setHours(startTime.getHours() + offset)
      endTime.setHours(endTime.getHours() + offset)

      const isPastBooking = pastBooking(updatedBooking.date!, updatedBooking.startTime!)
      const title = getBookingStatus(isPastBooking, updatedBooking.bookingConfirmed!, updatedBooking.reviewKey, updatedBooking.bookingDeclined, updatedBooking.bookingCancelledBy)

      updatedBooking = {...snapshot.val(), start: startTime, end: endTime, title: title, key: snapshot.key} as eventWithId //add the customised start and end times
      
      allEvents.push(updatedBooking)
      if (title.includes("Pending confirmation")) eventsPendingConfirmation.push(updatedBooking)
      if (title.includes("Booking confirmed")) eventsBookingConfirmed.push(updatedBooking)      
      if (title.includes("to review")) {
        eventsBookingCompletedToReview.push(updatedBooking)
      } else {
        if (title.includes("Booking completed")) eventsBookingCompleted.push(updatedBooking)
      }
      if (title.includes("declined") || title.includes("Cancelled") || title.includes("Unconfirmed")) eventsDeclinedOrCancelled.push(updatedBooking) 

    }).catch((error) => {
      console.error(error)
    }) 

  }))

  setAllEvents(allEvents)
  setEventsPendingConfirmation(eventsPendingConfirmation)  
  setEventsBookingConfirmed(eventsBookingConfirmed)  
  setEventsBookingCompleted(eventsBookingCompleted)  
  setEventsBookingCompletedToReview(eventsBookingCompletedToReview)  
  setEventsDeclinedOrCancelled(eventsDeclinedOrCancelled)  

  setLoading(false)
}



 
  return (
    <div>

      {eventsBookingCompleted &&
      <Grid
      templateAreas={{
            base: `"calendar-view"
            "event-list"`,
            md: `"event-list calendar-view"`}}
          gridTemplateColumns={'repeat(12, 1fr)'}
          fontSize={14} 
          padding={{base: 1, sm: 5}} 
          gap='2'>

        <GridItem paddingX={{base: 1, sm: 5}} area={'event-list'} colStart={1} colSpan={{base: 12, sm: 6}} >

          {loading == true ? 
           <>
           <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
             <SkeletonCircle size='10' />
             <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
           </Box>

           <Show above='sm'> 
            <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
            <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
            <Box padding='6' marginBottom='15px' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>  
           </Show>        
         </> 

          :

          <>
            <Show above='sm'>           

              {allEvents.length == 0 &&
              <Center height={{base: '10vh', sm: '70vh'}} marginY='5vh'>
                <VStack>
                  <Heading size='md'textColor='brand.medium'>No bookings have been made</Heading>
                  <Heading size='sm' paddingBottom='20px'>Bookings will be listed here</Heading>
                  <NavLink to="/stylistmap"><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}} borderRadius='50' paddingX={{base: '9px', sm: '18px'}}  borderWidth='1px' _hover={{ borderWidth:'2px' }} leftIcon={<MdCalendarMonth />} >Book</Button></NavLink>  
                </VStack>            
              </Center>
              }
            </Show>


            {allEvents.length > 0 &&
              <Heading size='md' paddingTop='15px' marginBottom='15px'>My bookings:</Heading>
            }
            
            {eventsPendingConfirmation.length > 0 &&
              <BookingCards allEvents={eventsPendingConfirmation} selectedEvent={selectedEvent} bookingStatus='Pending confirmation'/>
            }
            {eventsBookingConfirmed.length > 0 && 
              <BookingCards allEvents={eventsBookingConfirmed} selectedEvent={selectedEvent} bookingStatus='Bookings confirmed'/>
            }
            {eventsBookingCompletedToReview.length > 0 && 
              <BookingCards allEvents={eventsBookingCompletedToReview} selectedEvent={selectedEvent} bookingStatus='Completed bookings - pending review'/>
            }
            {eventsBookingCompleted.length > 0 && 
              <BookingCards allEvents={eventsBookingCompleted} selectedEvent={selectedEvent} bookingStatus='Completed bookings'/>
            }
            {eventsDeclinedOrCancelled.length > 0 && 
            <BookingCards allEvents={eventsDeclinedOrCancelled} selectedEvent={selectedEvent} bookingStatus='Declined or cancelled'/>
            }
          </>
          }

        </GridItem>


        <GridItem colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 6}}  paddingEnd={{base: 1, sm: 5}} position={{base: 'static', sm: 'fixed'}} right='0' top='11vh' width={{base: '100%', sm: '50%'}} marginTop='10px'>
          
        <Show below='sm'>

          {allEvents.length == 0 &&
          <Center height={{base: '10vh', sm: '70vh'}} marginY='5vh'>
            <VStack>
              <Heading size='md'textColor='brand.medium'>No bookings have been made</Heading>
              <Heading size='sm' paddingBottom='20px'>Bookings will be listed here</Heading>
              <NavLink to="/stylistmap"><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}} borderRadius='50' paddingX={{base: '9px', sm: '18px'}}  borderWidth='1px' _hover={{ borderWidth:'2px' }} leftIcon={<MdCalendarMonth />} >Book</Button></NavLink>  
            </VStack>            
          </Center>
          }
          </Show>
          
          <DashboardCalendar allEvents={allEvents} setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent}/>
        </GridItem>
      </Grid>

      }
      
    </div>
  )
}
 