import { useRef  } from 'react'
import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    VStack,
    Spacer,
    Button,
    HStack,
    Link,
    Flex,
    Text,
    Show,
    Heading
  } from '@chakra-ui/react'

import Gallery from '../common/Gallery';
import ProfileImage from '../common/ProfileImage';
import StylistLocation from '../stylist/StylistLocation';
import { MdOutlineLogin } from 'react-icons/md';
import { NavLink, Link as RouteLink } from 'react-router-dom'
import StylistProfile from '../stylist/StylistProfile';
import StylistMenu from '../stylist/StylistMenu';

export default function StylistSetup() {

  const steps = [
    { title: 'Profile', description: 'Your bio' },
    { title: 'Avatar', description: 'Profile Image' },
    { title: 'Gallery', description: 'Your brand' },
    { title: 'Location', description: 'Where you work' },
    { title: 'Services', description: 'Services menu' },
  ]
  
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })

  const activeStepText = steps[activeStep -1].description
  const submitRef = useRef<HTMLButtonElement>(null)


  return (  

        <VStack height='90%'>

          {/* Stylist details */}
          {(activeStep === 1) && 
          <Flex alignItems='center' marginTop='10vh'>
            <StylistProfile activeStep={activeStep} setActiveStep={setActiveStep}  submitRef={submitRef}/>
          </Flex>
          }

          {/* Profile pic */}
            {(activeStep === 2) && 
            <Flex alignItems='center' marginTop='20vh'>
              <ProfileImage/>
            </Flex>
          }

          {/* Gallery */}
            {(activeStep === 3) &&
            <VStack width='100%' marginTop={{base: '15vh', sm: '10vh'}} justifyContent='center'>
              <Heading size='md'>Gallery</Heading>
              <Gallery/>
            </VStack> 
            
          }

          {/* Location */}
            {(activeStep === 4) && 
              <VStack width='100%' marginTop={{base: '15px', sm: '0px'}} height={{base: '', sm: '70vh'}}>
                <StylistLocation/>                
              </VStack>
          }

          {/* Location */}
            {(activeStep === 5) && 
              <VStack width='100%' marginTop={{base: '5vh', sm: '5vh'}} padding='5px'>
                <Heading size='md'>Services</Heading>
                <StylistMenu/> 
              </VStack>
                           
          }


          <Spacer></Spacer>

          <HStack verticalAlign="center" paddingBottom='20px' minWidth='60vw' maxWidth='80vw'>


            {activeStep >= 1 &&
                <Link
                    fontSize="sm"
                    as={RouteLink}
                    to='/stylistdashboard'
                    paddingLeft='1.5rem'
                    color='brand.medium'>
                    Do it later
                </Link>
            }

              <Spacer></Spacer>

              {/* Stylist details */}
              {(activeStep === 1) && 
              <Button
                  onClick={() => submitRef.current?.click()}
                  rightIcon={<MdOutlineLogin />}
                  color='brand.medium'
                  variant='ghost'>
                  Next
              </Button>
              }

              {[2, 3, 4].includes(activeStep) &&
                <Button
                  type="submit"
                  rightIcon={<MdOutlineLogin />}
                  color='brand.medium'
                  onClick={() => setActiveStep(activeStep+1)}
                  variant='ghost'>
                  Next
              </Button>
              }

              { activeStep === 5 &&
                <NavLink to="/stylistdashboard">                 
                  <Button 
                      rightIcon={<MdOutlineLogin />}
                      color='brand.medium'
                      variant='ghost'>
                      Next
                  </Button>                  
                </NavLink>
              }


          </HStack>

        
     

          {/* Stepper */}
          <Stepper index={activeStep} size={{base: 'sm', sm: 'md'}} minWidth={{base: '90vw', sm: '60vw'}} maxWidth={{base: '90vw', sm: '80vw'}} paddingBottom='40px'>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator >

                  <Show above='sm'>
                    <StepStatus 
                      complete={<StepIcon /> }
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </Show>

                  <Show below='sm'>
                    <StepStatus 
                      complete={<StepIcon /> }
                    />
                  </Show>
                
                </StepIndicator>
                

                
                <Show above='sm'>
                  <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </Show>

                <StepSeparator />
           
              </Step>
            ))}
          </Stepper>

          <Show below='sm'>
            <Text paddingBottom='20px'>Step {activeStep}: <b>{activeStepText}</b></Text>
          </Show>

        </VStack>
  
  )
}
