import { VStack, FormControl, InputGroup, Input, InputLeftElement, FormErrorMessage, HStack, Spacer, Button, Textarea, Flex, Spinner, Text, FormLabel, Checkbox, Icon, useDisclosure, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Heading, RadioGroup, Radio, Stack, Th, Thead, Tr, Table, Td, Tbody, Center } from '@chakra-ui/react';
import { Formik, Field, useFormik, FormikProvider } from 'formik';
import { MdEmail, MdInfo, MdPerson, MdPhone, MdSave, MdSend, MdTaskAlt } from 'react-icons/md';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { useEffect, useState } from "react";
import { child, get, getDatabase, ref, ref as ref_database, set } from "firebase/database";
import { menuItem, stepsProps, stylistProfile } from '../../Utils/Interfaces';
import { fetchStylistMenu, fetchStylistProfile } from '../../tools/ReturnFunctions';
import * as Yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseStorageAvatar from '../../components/FirebaseStorageAvatar';
import { bookingRequestFromEndUserEmail, publicChatMessageEmail } from '../../Utils/MailNotification';

export default function ContactStylist() {

  const location = useLocation()
  console.log(`Location: ${JSON.stringify(location.pathname.split('/')[2])}`)

  const [stylistFirebaseUid, setStylistFirebaseUid] = useState<string>()

  function setUidFromPath() {
    setStylistFirebaseUid(location.pathname.split('/')[2])
  }

  useEffect(() => {
    setUidFromPath()
  },[])


  const [loading, setLoading] = useState<boolean>(true)
  const [profileDetails, setProfileDetails] = useState<stylistProfile>()
  const [menuList, setMenuList] = useState<Array<menuItem>>()
  const [dataSaved, setDataSaved] = useState<boolean>(false)  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  

  async function fetchProfile() {
    console.log(`Fetching profile`)
    const stylistProfile = await fetchStylistProfile(`stylists/auth_read/${stylistFirebaseUid}/profile_settings`)
    setProfileDetails(stylistProfile)
    console.log(`Fetched profile: ${stylistProfile}`)
    setLoading(false)
  }

  async function fetchMenu() {
    console.log(`Fetching profile`)
    const menuList = await fetchStylistMenu(`stylists/auth_read/${stylistFirebaseUid}/menu_items`)
    setMenuList(menuList)
    console.log(`Fetched menu: ${menuList}`)
    setLoading(false)
  }

 
 
  
  useEffect(() => {
    fetchProfile()
    fetchMenu()
  },[stylistFirebaseUid]) 

  const formik = useFormik({
        //These need to match the Field id values
        initialValues: {
          selectedMenuItem: "",  
          clientName: "",
          emailAddress: "",
          clientContactNumber: "",
          requestDetails: "",
          bookingDateTime: ""           
        },
        enableReinitialize: true,

        onSubmit: async (values) => { 

          console.log(`Form values: ${JSON.stringify(values)}`)

          const dateTime = new Date(values.bookingDateTime)

          const formattedDateTime = dateTime.toLocaleString('en-GB')

          console.log(`Formatted date time: ${formattedDateTime}`)
                          
          //Send mail notification to salon
          bookingRequestFromEndUserEmail(
            values.selectedMenuItem,  
            values.clientName,
            values.emailAddress,
            values.clientContactNumber,
            values.requestDetails,
            formattedDateTime
          ).then(() => {
              console.log(`Email sent`)
              setDataSaved(true)
              onOpen()
            })

        },

        //https://blog.shahednasser.com/how-to-create-and-validate-forms-in-react-using-formik-and-yup/
        validationSchema: Yup.object().shape({
            clientName: Yup.string().required('Required'),
            emailAddress: Yup.string().email('Invalid email address').required('Required'),
            bookingDateTime: Yup.string().required('Required'),            
          })
  })
  
  return (

    <div>
        {loading == true &&

            <Center alignItems="center" minHeight='60vh'>
                <VStack>
                    <Spinner
                        color='brand.medium'
                        size='xl'
                        marginBottom='20px'
                        thickness='5px'
                        speed='0.65s'/>
                    <Text>Loading</Text>
                </VStack>
            </Center>
        }

        {stylistFirebaseUid &&



          
                <VStack alignItems="center" id='form-stack' padding='30px'>

                  <FirebaseStorageAvatar imagePath={`stylists/${stylistFirebaseUid}/profile_image.jpg`} size={'2xl'}/>
                  <Heading size='md' marginTop='15px'>{profileDetails?.firstName} {profileDetails?.lastName}</Heading> 
                  
                  
                  <FormikProvider value={formik}>

                      <form onSubmit={formik.handleSubmit}>
                        <FormControl onChange={() => setDataSaved(false)} isInvalid={!!formik.errors && formik.touched.selectedMenuItem}>
                          <RadioGroup>
                            <Table minWidth={{base: '350px', sm: '500px'}}>
                              <Thead>
                                <Tr>
                                  <Th  textAlign='left' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'md', sm: 'lg'}} borderBottomColor='brand.lite' borderBottomWidth='1px'>Service</Th>
                                  <Th  textAlign='center' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'md', sm: 'lg'}} borderBottomColor='brand.lite' borderBottomWidth='1px'>Price</Th>
                                </Tr>
                              </Thead>

                              <Tbody id='menuItem' >                       
                                {menuList?.map((menuItem, index) => (                                   
                                  //Add a radio button for each menu item have the price next to the description in a table format
                                  <Tr key={index} >
                                    <Td paddingX={{base: '0px', sm: '12px'}} wordBreak='break-word'>
                                      <HStack>
                                        <Radio key={index} name='selectedMenuItem' onChange={formik.handleChange} value={menuItem.menuDescription} ></Radio>                                        
                                        <Text>{menuItem.menuDescription}</Text>
                                      </HStack>
                                      </Td>                                      
                                    <Td paddingX={{base: '0px', sm: '12px'}} wordBreak='break-word' textAlign='center'>{menuItem.itemPrice}</Td>     
                                  </Tr>
                                ))}
                              
                              </Tbody>
                            </Table>
                          </RadioGroup>
                        </FormControl>

                        {/* Name */}
                        <FormControl onChange={() => setDataSaved(false)} isInvalid={!!formik.errors && formik.touched.clientName}>
                            <VStack align="flex-start">
                              <FormLabel htmlFor='clientName' marginTop='20px'>*Your name</FormLabel>
                              <InputGroup>
                                  <Field
                                      as={Input}
                                      id='clientName'
                                      type='text'
                                      name='clientName'
                                      width={{base: '90vw', sm: '100%'}}
                                      borderColor='brand.medium'
                                      focusBorderColor='brand.medium'
                                      placeholder='Your name'
                                      paddingLeft='2.5rem'  
                                      onChange={formik.handleChange}
                                  />
                                  <InputLeftElement pointerEvents='none'>
                                      <MdPerson color='brand.medium' />
                                  </InputLeftElement>
                              </InputGroup>
                            </VStack>
                            <FormErrorMessage>{formik.errors.clientName}</FormErrorMessage>
                          </FormControl>

                         {/* Email */}
                         <FormControl onChange={() => setDataSaved(false)} isInvalid={!!formik.errors && formik.touched.emailAddress}>
                            <VStack align="flex-start">
                              <FormLabel htmlFor='emailAddress' marginTop='20px'>*Email address</FormLabel>
                              <InputGroup>
                                  <Field
                                      as={Input}
                                      id='emailAddress'
                                      type='text'
                                      name='emailAddress'
                                      width={{base: '90vw', sm: '100%'}}
                                      borderColor='brand.medium'
                                      focusBorderColor='brand.medium'
                                      placeholder='Email address'
                                      paddingLeft='2.5rem'  
                                      onChange={formik.handleChange}
                                  />
                                  <InputLeftElement pointerEvents='none'>
                                      <MdEmail color='brand.medium' />
                                  </InputLeftElement>
                              </InputGroup>
                            </VStack>
                            <FormErrorMessage>{formik.errors.emailAddress}</FormErrorMessage>
                          </FormControl>

                         {/* Phone */}
                         <FormControl marginBottom='15px' onChange={() => setDataSaved(false)} isInvalid={!!formik.errors && formik.touched.clientContactNumber}>
                            <VStack align="flex-start">
                              <FormLabel htmlFor='clientContactNumber' marginTop='20px'>Contact number</FormLabel>
                              <InputGroup>
                                  <Field
                                      as={Input}
                                      id='clientContactNumber'
                                      type='tel'
                                      name='clientContactNumber'
                                      width={{base: '90vw', sm: '100%'}}
                                      borderColor='brand.medium'
                                      focusBorderColor='brand.medium'
                                      placeholder='Contact number'
                                      paddingLeft='2.5rem'  
                                      onChange={formik.handleChange}
                                  />
                                  <InputLeftElement pointerEvents='none'>
                                      <MdPhone color='brand.medium' />
                                  </InputLeftElement>
                              </InputGroup>
                            </VStack>
                            <FormErrorMessage>{formik.errors.clientContactNumber}</FormErrorMessage>
                          </FormControl>

                            {/* Request remarks */}
                            <FormControl onChange={() => setDataSaved(false)}>
                                <VStack align="flex-start">
                                    <FormLabel htmlFor='requestDetails'>Request details</FormLabel>
                                    <InputGroup>

                                        <Field
                                            as={Textarea}
                                            size='md'
                                            resize='resize'
                                            id='requestDetails'
                                            type='text'
                                            height='20vh'
                                            name='requestDetails'
                                            borderColor='brand.medium'
                                            focusBorderColor='brand.medium'
                                            placeholder='Request details'
                                            onChange={formik.handleChange}
                                        />
                                </InputGroup>
                                </VStack>                  
                            </FormControl>

                      {/* Form control to select the time for the booking */}
                      <FormControl onChange={() => setDataSaved(false)} isInvalid={!!formik.errors && formik.touched.bookingDateTime}>
                        <FormLabel htmlFor='bookingTime' marginTop='20px'>*Requested date and time</FormLabel>
                          <InputGroup>
                            <Input                        
                              size="md"
                              id="bookingDateTime"
                              type='datetime-local'
                              width={{base: '90vw', sm: '100%'}}
                              borderColor='brand.medium'
                              focusBorderColor='brand.medium'
                              placeholder='Select Date and time'
                              onChange={formik.handleChange}
                              />
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.bookingDateTime}</FormErrorMessage>

                          <HStack marginTop='20px'>
                            <Spacer></Spacer>
                            <Button
                                type='submit'
                                rightIcon={dataSaved ? <MdTaskAlt /> : <MdEmail />}
                                color='brand.medium'
                                variant='outline'>
                                {dataSaved ? "Sent" : "Send request"}
                            </Button>
                          </HStack>

                      </FormControl>

                        
                      </form>

                  </FormikProvider>

                </VStack>

        }
        <Modal isOpen={isOpen} onClose={() => {onClose()}}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Email sent</ModalHeader>
            <ModalBody>
              <Text whiteSpace='pre-line'>Email sent to stylist</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                navigate(`/stylistandsalonmap/${stylistFirebaseUid}`)

                }}>OK</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
                

    </div>
  )
}
