import { 
  Tabs, 
  TabList, 
  Tab, 
  TabPanels, 
  TabPanel,
  Show,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Spacer} from '@chakra-ui/react'
import SupportChat from '../../components/SupportChat';
import ContactUs from '../../components/ContactUs';
import FrequentlyAskedQuestions from '../../components/FrequentlyAskedQuestions';
import { MdRemoveRedEye, MdKeyboardArrowRight, MdOutlineChat } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import UserGuideShop from '../../components/UserGuideShop';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export default function Support() {
  
  const navigate = useNavigate()
  const {user, setUser} = useContext(UserContext)

  return (
    <div>

    <Show below='sm'>
    <Accordion allowToggle>


      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              Frequently Asked Questions
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <FrequentlyAskedQuestions/>
        </AccordionPanel>
      </AccordionItem>     

      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              Contact us
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <ContactUs/>
          
          <Divider borderColor='brand.medium'/>

          <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
          onClick={() => {
            console.log(`view salon profile`)
            navigate("/supportchat")
          }}>          
            <HStack>
              <Icon as={MdOutlineChat} w={6} h={6} marginStart='10px' color='brand.medium'/> 
              <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Chat with us</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
          </Flex>

          <Divider borderColor='brand.medium'/>

        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              Privacy policy
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <PrivacyPolicy/>
        </AccordionPanel>
      </AccordionItem>

      {user.type == "shops" &&
      <AccordionItem>
        <h2>
          <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
              User Guide
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <UserGuideShop/>
        </AccordionPanel>
      </AccordionItem>
      }



    </Accordion>


    </Show>

    <Show above='sm'>
      
      <Tabs marginStart='40px' marginEnd='40px' isFitted  paddingTop='10x'  isLazy variant='line' marginTop='20px'>
        <TabList>
          <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Contact us</Tab>
          <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Privacy policy</Tab>
          {user.type == "shops" &&
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>User Guide</Tab>
          }
        </TabList>

        <TabPanels py="10px">
          
          {/* Support */}
          <TabPanel marginTop='20px'>
            <SupportChat/>
          </TabPanel>
          {/* Support */}


          {/* Privacy policy */}
          <TabPanel marginTop='20px'>
            <PrivacyPolicy/>
          </TabPanel>
          {/* Privacy policy */}

          {/* User guide */}
          <TabPanel marginTop='20px'>
            <UserGuideShop/>
          </TabPanel>
          {/* User guide */}
        </TabPanels>
      </Tabs>
    </Show>

  </div>
  )
}
