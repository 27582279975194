import { VStack, FormControl, InputGroup, Input, InputLeftElement, FormErrorMessage, Textarea, Flex, FormLabel, Button, HStack, Spacer, Spinner, Text } from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import { MdPerson, MdPhone, MdSave, MdTaskAlt } from 'react-icons/md';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getDatabase, ref as ref_database, set } from "firebase/database";
import { fetchShopProfile } from '../../tools/ReturnFunctions';
import { shopProfile, stepsProps } from '../../Utils/Interfaces'



export default function ShopProfile({ activeStep, setActiveStep, submitRef }: stepsProps) {

  const firebaseUser = useFirebaseAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [profileDetails, seProfileDetails] = useState<shopProfile>()
  const [dataSaved, setDataSaved] = useState<boolean>(false)  

  async function fetchProfile() {
    console.log(`Fetching profile`)
    const shopProfile = await fetchShopProfile(`shops/auth_read/${firebaseUser?.uid}/profile_settings`)
    seProfileDetails(shopProfile)
    console.log(`Fetched profile: ${shopProfile}`)
    setLoading(false)
  }

 
  useEffect(() => {
    fetchProfile()
  },[firebaseUser]) 
  
  return (
     <Flex alignItems="center" paddingTop={submitRef ? '4vh' : '0vh'}>

      {loading == true &&

      <Flex alignItems="center" minHeight='60vh'>
          <VStack>
              <Spinner
                  color='brand.medium'
                  size='xl'
                  marginBottom='20px'
                  thickness='5px'
                  speed='0.65s'/>
              <Text>Loading</Text>
          </VStack>
      </Flex>
      }

      {loading == false &&

         <VStack alignItems="center" id='form-stack' width={{base: '90vw', sm: '100%'}}>
             <Formik
             
                 //These need to match the Field id values
                 initialValues={{
                     firstName: profileDetails?.firstName,
                     lastName: profileDetails?.lastName,
                     contactNumber: profileDetails?.contactNumber,
                     profileDescription: profileDetails?.profileDescription
                 }}

                 onSubmit={(values) => { 

                    console.log(`Saving to database`)
                     
                     const dbRef = getDatabase()

                     set(ref_database(dbRef, `shops/auth_read/${firebaseUser?.uid}/profile_settings`), { 
                        userFirebaseUid: firebaseUser?.uid,
                        emailAddress: firebaseUser?.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        contactNumber: values.contactNumber,
                        profileDescription: values.profileDescription          
                     })
                     .then(() => {
                       console.log(`Saved profile`)
                       setDataSaved(true)
                       if (submitRef) setActiveStep!(3)
                     });

                 }}>

                     {({handleSubmit, errors, touched}) => (

                         <form onSubmit={handleSubmit} id='shop-profile-form' className='form-control'>

                             {/* First name */}
                             <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!errors.firstName && touched.firstName}>
                               <FormLabel htmlFor='firstName' marginBottom='2px'>*First name</FormLabel>
                                 <InputGroup>

                                     <Field
                                         as={Input}
                                         id='firstName'
                                         type='text'
                                         name='firstName'
                                         width={{base: '100%', sm: '480px'}}
                                         borderColor='brand.medium'
                                         focusBorderColor='brand.medium'
                                         placeholder='First name'
                                         paddingLeft='2.5rem'  
                                         validate={(value: string) => {                                            
                                             if (value == "") {
                                                 return "Please enter your first name";
                                             }
                                         }}
                                     />

                                     <InputLeftElement pointerEvents='none'>
                                         <MdPerson color='brand.medium' />
                                     </InputLeftElement>

                                 </InputGroup>

                                 <FormErrorMessage>{errors.firstName}</FormErrorMessage>

                             </FormControl>


                             {/* Last name */}
                             <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!errors.lastName && touched.lastName}>
                               <FormLabel htmlFor='lastName' marginBottom='2px'>*Last name</FormLabel>
                                 <InputGroup>

                                     <Field
                                         as={Input}
                                         id='lastName'
                                         type='text'
                                         name='lastName'
                                         borderColor='brand.medium'
                                         focusBorderColor='brand.medium'
                                         placeholder='Last name'
                                         paddingLeft='2.5rem'  
                                         validate={(value: string) => {                                            
                                             if (value == "") {
                                                 return "Please enter your last name";
                                             }
                                         }}
                                     />

                                     <InputLeftElement pointerEvents='none'>
                                         <MdPerson color='brand.medium' />
                                     </InputLeftElement>

                                 </InputGroup>

                                 <FormErrorMessage>{errors.lastName}</FormErrorMessage>

                             </FormControl>

                                {/* Contact number */}
                                <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!errors.contactNumber && touched.contactNumber}>
                                <FormLabel htmlFor='contactNumber' marginBottom='2px'>*Contact number</FormLabel>
                                 <InputGroup>

                                     <Field
                                         as={Input}
                                         id='contactNumber'
                                         type='text'
                                         name='contactNumber'
                                         borderColor='brand.medium'
                                         focusBorderColor='brand.medium'
                                         placeholder='Contact number'
                                         paddingLeft='2.5rem'  
                                         validate={(value: string) => {   
                                            
                                            if (!value) return "Phone number required";

                                            const isAtLeast8Characters = value.length >= 8
                                            const numbersWithPlusOptional = /^\+?\d+(?: ?\d+)*$/.test(value)                                            

                                            if (!isAtLeast8Characters) {
                                                return "Phone number needs to be at least 8 characters";
                                            }
                                            
                                            if (!numbersWithPlusOptional) {
                                                return "Phone numbers can only have digits, spaces and an optional + symbol";
                                            }                                            
                                    
                                         }}
                                     />

                                     <InputLeftElement pointerEvents='none'>
                                         <MdPhone color='brand.medium' />
                                     </InputLeftElement>

                                 </InputGroup>

                                 <FormErrorMessage>{errors.contactNumber}</FormErrorMessage>

                             </FormControl>

                             {/* Profile description */}
                             <FormControl onChange={() => setDataSaved(false)} mb="20px" isInvalid={!!errors.profileDescription && touched.profileDescription}>
                             <FormLabel htmlFor='profileDescription' marginBottom='2px'>*Profile description</FormLabel>
                                 <InputGroup>
                                     <Field
                                         as={Textarea}
                                         size='sm'
                                         resize='resize'
                                         id='profileDescription'
                                         type='text'
                                         height='15vh'
                                         name='profileDescription'
                                         borderColor='brand.medium'
                                         focusBorderColor='brand.medium'
                                         placeholder='Profile description'
                                         validate={(value: string) => {                                            
                                             if (value == "") {
                                                 return "Please a profile description";
                                             }
                                         }}
                                     />
                                 </InputGroup>

                                 <FormErrorMessage>{errors.profileDescription}</FormErrorMessage>

                                 {submitRef ?

                                    <button ref={submitRef} type="submit" style={{ display: 'none' }} />

                                    :
                                    <HStack marginTop='20px'>
                                        <Spacer></Spacer>
                                        <Button
                                            type='submit'
                                            rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
                                            color='brand.medium'
                                            variant='ghost'>
                                            {dataSaved ? "Saved" : "Save"}
                                        </Button>
                                    </HStack>
                                 }
                             </FormControl>

                         </form>
                     )}
             </Formik>
         </VStack>
      }
      
     </Flex>       
  )
}
