import { VStack, Button, Text, Spinner, Alert, AlertIcon, AlertDescription, Image, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Flex, Icon, Box, HStack, Show, Spacer } from '@chakra-ui/react';
import { getDownloadURL, ref as ref_storage, uploadBytes, listAll, StorageReference, deleteObject} from 'firebase/storage';
import { useContext, useEffect, useState } from 'react'
import { useDropzone  } from 'react-dropzone';
import { MdArrowCircleLeft, MdArrowCircleRight, MdCancel, MdClose, MdDelete, MdOutlineDelete, MdOutlineImageSearch, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { firebaseStorage } from '../../firebaseSetup';
import { format } from 'date-fns';
import { UserContext } from '../../context/UserContext';
import { compressImage } from '../../tools/ReturnFunctions';

export default function Gallery() {

  const firebaseUser = useFirebaseAuth();
  const {user, setUser} = useContext(UserContext)

  const [imageUrls, setImageUrls] = useState<{
    storageRef: StorageReference,
    downloadUrl: string
  }[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  const [currentImage, setCurrentImage] = useState<number>(0)
  const { isOpen, onOpen, onClose } = useDisclosure()


  //List images in users storage folder
  async function listImagesAndDownload() {

    setIsLoading(true) //start spinner

    const imagesRef = ref_storage(firebaseStorage, `${user.type}/${firebaseUser?.uid}/gallery`);

    console.log(`Image reg: ${imagesRef.fullPath}`)

    const imageReferences: {
      storageRef: StorageReference,
      downloadUrl: string
    }[] = []

          
    //Get a list of the file in the user folder
    const list = await listAll(imagesRef)

    await Promise.all(list.items.map(async(storageRef) => {
      console.log(`ItemRef (Sotrage reference) on sever: ${storageRef}`)
 
      const downloadUrl = await getDownloadURL(storageRef) //Download image
      console.log("ItemRef URL (String):", downloadUrl)

      imageReferences.push({storageRef: storageRef, downloadUrl: downloadUrl}) //Add storafeRef and downloadURK to state

    }))


    //Update the state with the new images 
    setImageUrls(imageReferences) //append download URL
    console.log(`Pushed downloaded URL's to itemUrls`)
    setIsLoading(false) //stop spinner

  }


  function deleteImage() {
    
    const filePath = imageUrls[currentImage].storageRef.fullPath
    console.log(`File path is is: ${filePath}`)                    
    
    deleteObject(ref_storage(firebaseStorage, filePath)).then(async () => {
      console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
      await listImagesAndDownload()                      
    })  
  }

  useEffect(() => {
    setImageUrls([])
    console.log(`Firebase user id: ${firebaseUser?.uid}`)
    console.log(`User type: ${user.type}`)
    if (firebaseUser?.uid) listImagesAndDownload()
  },[firebaseUser, user]) 

  function Previews(props: any) {

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/*': []
    },

    onDrop: async acceptedFiles => {

      setImageUrls([])
      setIsLoading(true)
      setError(false)

      await Promise.all(acceptedFiles.map(async (file) => {

        console.log(`File type is: ${file.name.substring(file.name.lastIndexOf(".") +1)}`) 


        //https://date-fns.org/v2.29.3/docs/format
        const date = new Date();
        const timestamp = `${format(date, 'dd-MM-yyyy--HH-mm-ss-SSS')}`

        console.log(`User type is: ${user.type}`)
        const imageRef = ref_storage(firebaseStorage, `${user.type}/${firebaseUser?.uid}/gallery/${timestamp}.${file.name.substring(file.name.lastIndexOf(".") +1)}`)

        const compressedFile = await compressImage(file)

        //Upload image
        await uploadBytes(imageRef, compressedFile)

      }))

      listImagesAndDownload()      
      setIsLoading(false)   

    }
  });

  return (
    <VStack spacing='10px' borderColor='brand.medium' borderWidth='1px'  width={imageUrls.length > 0 ? '250px' : '60vw'} height='150px' borderStyle='dashed' borderRadius='10px' _hover={{bg:"brand.mediumAlpha", cursor:'pointer'}} {...getRootProps()}>  
    <Icon marginTop='15px' as={MdOutlineImageSearch} w={20} h={20} color='brand.medium' /> 
   
        {isLoading ? <Spinner color='brand.medium'/> : isDragActive ? <Text>Drop the files here...</Text> : <Text>Drop gallery images here</Text>}
        {
          (error) && (
            <Alert status={error ? 'error' : 'success'} w={250} borderRadius={5} m={2}>
              <AlertIcon />
              <AlertDescription w={200}>{message}</AlertDescription>
            </Alert>
          )
        }       
        <input {...getInputProps()} />
    </VStack>  
        
  );
  }
   
  return (

      <Flex id='galleryRoot' alignItems="center">
        <VStack>
          <Flex fontSize='sm' wrap='wrap' justifyContent='space-around'  gap='20px' borderColor='brand.medium' borderWidth='1px' padding='20px' borderRadius='10px' maxWidth={{base: '100%', sm: '70%'}}>
            
            <Previews />       

            {imageUrls.length !== 0 &&                  
            
              imageUrls.map((element, index) => {
                return (
                  <Image
                    id={element.downloadUrl}
                    key={index}
                    onClick={() => {
                      setCurrentImage(index)
                      onOpen()
                    }}
                    width={{base: '45%', sm: '250px'}}
                    height={{base: '14vh', sm: '150px'}}
                    borderRadius={10}
                    objectFit='cover'
                    boxShadow='xl'
                    _hover={{ cursor:'pointer', transform: 'scale(1.05)', transition: '.3s' }}
                    src={element.downloadUrl}/>              
                )
              })                  
            }  

          </Flex>

          <Modal isOpen={isOpen} onClose={onClose}>

          <Show above='sm'>
            <Box 
                position='fixed'
                top='0px'
                left='0px'
                bottom='0px'
                right='0px'
                background='whiteAlpha.800'
                zIndex={500}           
              >

              <Icon as={MdCancel} position='fixed' top={30} right={30} boxSize={10} color='brand.medium' onClick={onClose} _hover={{ cursor:'pointer' }}/>
              <Icon as={MdDelete} 
                position='fixed' 
                bottom={30} 
                right={30} 
                boxSize={10} 
                color='brand.medium' 
                onClick={() => {
                    console.log(`Number of images ${imageUrls.length} - Current image: ${currentImage}`)
                    setCurrentImage(0)
                    deleteImage()
                    onClose()
                  }} _hover={{ cursor:'pointer' }}
              />

             

            
              <HStack 
                width='100%'
                height='100vh'>
                
              <Icon
                  as={MdArrowCircleLeft}
                  color='brand.medium'
                  boxSize={20}
                  paddingLeft={30}
                  _hover={{ cursor:'pointer' }}
                  onClick={() => {
                    console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                    if (currentImage == 0) {
                      setCurrentImage(imageUrls.length -1)
                    } else {
                      setCurrentImage(currentImage - 1)
                    }                    
                  }}
                  />

                <Spacer />


                {imageUrls.length != 0 && 
                
                  <Image                   
                    src={imageUrls[currentImage].downloadUrl} 
                    maxHeight='80vh'
                    maxWidth='80vw'
                    objectFit='cover'
                    />
                    } 

                <Spacer />
                
                <Icon
                    as={MdArrowCircleRight}
                    color='brand.medium'
                    boxSize={20}
                    paddingRight={30}                  
                    _hover={{ cursor:'pointer' }}
                    onClick={() => {
                      console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                      console.log(`Current image is: ${currentImage}`)
                      if (currentImage == imageUrls.length -1) {
                        setCurrentImage(0)
                      } else {
                        setCurrentImage(currentImage + 1)
                      }
                    }}
                    />
                    
              </HStack>

                
            
            </Box>   
          </Show>

          <Show below='md'>
            <Box 
                position='fixed'
                top='0px'
                left='0px'
                bottom='0px'
                right='0px'
                background='whiteAlpha.800'
                zIndex={500}           
              >

              <Icon as={MdCancel} position='fixed' top={30} right={30} boxSize={10} color='brand.medium' onClick={onClose} _hover={{ cursor:'pointer' }}/>
              <Icon as={MdDelete} 
                position='fixed' 
                bottom={30} 
                right={30} 
                boxSize={10} 
                color='brand.medium' 
                onClick={() => {
                    console.log(`Number of images ${imageUrls.length} - Current image: ${currentImage}`)
                    setCurrentImage(0)
                    deleteImage()
                    onClose()
                  }} _hover={{ cursor:'pointer' }}
              />

            
              <Box 
                width='100vw'
                height='100vh'
                alignContent='center'
                >
                
              <Icon
                  as={MdArrowCircleLeft}
                  color='brand.medium'
                  position='fixed'
                  top={'50%'}
                  left='5%'
                  boxSize={30}
                  _hover={{ cursor:'pointer' }}
                  onClick={() => {
                    console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                    if (currentImage == 0) {
                      setCurrentImage(imageUrls.length -1)
                    } else {
                      setCurrentImage(currentImage - 1)
                    }                    
                  }}
                  />




                {imageUrls.length != 0 && 

                  
                    <Image
                      src={imageUrls[currentImage].downloadUrl} 
                      maxHeight='100vh'
                      maxWidth='100vw'
                      objectFit='cover'/>         
                  } 


                
                <Icon
                    as={MdArrowCircleRight}
                    color='brand.medium'
                    position='fixed'
                    right='5%'
                    top='50%'
                    boxSize={30}                 
                    _hover={{ cursor:'pointer' }}
                    onClick={() => {
                      console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                      console.log(`Current image is: ${currentImage}`)
                      if (currentImage == imageUrls.length -1) {
                        setCurrentImage(0)
                      } else {
                        setCurrentImage(currentImage + 1)
                      }
                    }}
                    />
                    
              </Box>

                
            
            </Box>   
          </Show>


          </Modal> 

        </VStack>
      </Flex>
  )
}



