import { useEffect, useState } from 'react'
import { fetchStylistProfile, fetchStylistReviews } from '../tools/ReturnFunctions'
import { Flex, VStack, Heading, Tooltip, Text, Link, Collapse, useDisclosure, Box, Divider, Icon } from '@chakra-ui/react'
import { stylistProfile, stylistReview } from '../Utils/Interfaces'
import FirebaseStorageAvatar from './FirebaseStorageAvatar'
import GalleryCarosuel from './GalleryCarousel'
import StarRatingStatic from './StarRatingStatic'
import { format } from 'date-fns'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import StylistViewWorkingLocations from './StylistViewWorkingLocations'
import ViewStylistMenu from './ViewStylistMenu'

export default function StylistViewProfile(props: {stylistFirebaseUid: string}) {
  
  const [stylistProfile, setStylistProfile] = useState<stylistProfile | undefined>()
  const [rating, setRating] = useState<{rating: number, numberOfReviews: number}>({rating: 0, numberOfReviews: 0})
  const [stylistReviews, setStylistReviews] = useState<Array<stylistReview>>()
  const { isOpen, onToggle } = useDisclosure()

  async function fetchProfile() {
    const profile = await fetchStylistProfile(`stylists/auth_read/${props.stylistFirebaseUid}/profile_settings`)
    setStylistProfile(profile)
  }  

  async function fetchReviews() {
    const reviews = await fetchStylistReviews(`stylists/auth_write/${props.stylistFirebaseUid}/reviews`)

    let ratingSum = 0

    reviews.forEach((review) => {
      ratingSum = ratingSum + Number(review.rating)
    })

    console.log(`Total review stars: ${ratingSum} -- Number of reviews: ${reviews.length} -- Average: ${ratingSum / reviews.length} `)
    setRating({rating: ratingSum / reviews.length, numberOfReviews: reviews.length })
    setStylistReviews(reviews)  
  }

  useEffect(() => {
    fetchProfile()
    fetchReviews()
  },[])
  
  return (
    <div>

      <Flex wrap='wrap' gap='20px' rowGap={{base: '50px', sm: '50px'}} justifyContent='space-between' alignItems='baseline'>

        <VStack width={{base: '95%', sm: '400px'}}>
          <Heading size='md' paddingY='10px'>{stylistProfile?.firstName} {stylistProfile?.lastName}</Heading>                        
          <FirebaseStorageAvatar imagePath={`stylists/${props.stylistFirebaseUid}/profile_image.jpg`} size={'2xl'} />
        </VStack>

        {stylistProfile &&
        <VStack width={{base: '95%', sm: '400px'}}>
          <Heading size='md' >About:</Heading>
          <Text>{stylistProfile.profileDescription}</Text>
        </VStack>
        }     
          

        <GalleryCarosuel storagePath={`stylists/${props.stylistFirebaseUid}/gallery`}/>

        <Box width='100%'>
          <StylistViewWorkingLocations stylistFirebaseUid={props.stylistFirebaseUid} />
        </Box>

        <VStack width={{base: '95%', sm: '400px'}}>
          <Heading size='md' marginBottom='15px' >Qualifications:</Heading>
          <Icon as={AiOutlineSafetyCertificate} w={8} h={8} color='brand.medium'/>  
          <Text>Certification obtained in {stylistProfile?.hairdressingCertYear}</Text>
        </VStack>      

        <VStack width={{base: '100%', sm: '60%'}} marginBottom='30px'>
          <ViewStylistMenu stylistFirebaseUid={props.stylistFirebaseUid} />           
        </VStack>

        <VStack width={{base: '95%', sm: '400px'}} marginBottom='30px'>
          <Heading size='md' >Reviews:</Heading>
          <StarRatingStatic rating={rating.rating}/>
          <Tooltip label='View reviews' placement='bottom-end' hasArrow>
            <Link color='brand.medium' onClick={onToggle}>{rating.numberOfReviews > 0 ? rating.rating.toFixed(1) : 0} from {rating.numberOfReviews} reviews {rating.numberOfReviews > 1 ? `(See reviews)` : ''}</Link>
          </Tooltip>   
        </VStack>

      </Flex>

      {stylistReviews &&           

        stylistReviews.map((element, index) => {
          return (   
            <Collapse
              in={isOpen}
              animateOpacity    
              id={element.key}
              key={index}
                >    
                  
              <Divider borderColor='brand.medium' marginTop='20px' />                    
              <Box paddingY='20px'>
                <StarRatingStatic rating={element.rating}/>
                <Heading  paddingTop='10px' size='sm' color='brand.medium'>Salon: {element.shopName}</Heading>
                <Text>Review date: {format(new Date(element.timeStampPosted),"do MMMM yyyy")}</Text>
                <Text paddingY='10px'>Review: {element.reviewText}</Text>
              </Box>
            </Collapse>             
          )
        })
      }            

    </div>
  )
}
