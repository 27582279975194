import  { Dispatch, ReactNode, SetStateAction, useContext, useEffect } from "react";
import { createContext, useState } from "react";

//https://www.youtube.com/watch?v=D6kj5dgakz8
//https://www.youtube.com/watch?v=siVVovFRzfw

export type User = {
  type: string
}

export interface UserContextInterface {
  user: User,
  setUser: Dispatch<SetStateAction<User>>
}

const defaultState = {
  user: {
    type: ''
  },
  // setUser: (user: User) => {}
} as UserContextInterface

const getInitialState = () => {
  const user = localStorage.getItem("user") 
  console.log(`Obtained state: ${user}`)
  return user ? JSON.parse(user)  : defaultState.user;
}

export const UserContext = createContext(getInitialState())

type UserProviderProps = {
  children: ReactNode
}

export default function UserProvider({ children }: UserProviderProps) {

  const [user, setUser] = useState(getInitialState());

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    console.log(`User type ${user.type}`)
  }, [user]);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );

}












