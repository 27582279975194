import { useLocation, useNavigate } from 'react-router-dom';
import { eventWithId, stylistReview } from '../../Utils/Interfaces';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, Divider, Flex, Grid, GridItem, HStack, Heading, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import SeatDetails from '../../components/SeatDetails';
import { MdCancel, MdCheckCircle, MdKeyboardArrowRight, MdStars, MdTextsms } from 'react-icons/md';
import BookingDetails from '../../components/BookingDetails';
import StarRating from '../../components/StarRating';
import { useEffect, useState } from 'react';
import { ref, getDatabase, update, onValue, set, push, child } from 'firebase/database';
import { format } from 'date-fns';
import { eulaAccepted, fetchSingleStringValue, getBookingStatus, pastBooking } from '../../tools/ReturnFunctions';
import StylistViewProfile from '../../components/StylistViewProfile';
import { approveBookingEmail, cancelBookingEmail, declineBookingEmail, reviewBookingEmail } from '../../Utils/MailNotification';
import Eula from '../../components/Eula';
import SeatDetailsEvent from '../../components/SeatDetailsEvent';


export default function ShopViewBooking() {

  const navigate = useNavigate()
  const location = useLocation()

  const data = location.state ? location.state.selectedEvent : JSON.parse(sessionStorage.getItem('SELECTED_EVENT')!) //If page reloaded then use the session storage values
  useEffect(() => {
    sessionStorage.setItem('SELECTED_EVENT', JSON.stringify(selectedEvent))
  },[data]) 

  const [selectedEvent, setSelectedEvent] = useState<eventWithId>(data)
  console.log(`Selected event: ${JSON.stringify(selectedEvent)}`)  
  const [newRating, setNewRating] = useState(0)
  const [ratingStarError, setRatingStarError] = useState<boolean>(false)
  const [ratingMessageError, setRatingMessageError] = useState<boolean>(false)
  const { isOpen: isOpenEULA, onOpen: onOpenEULA, onClose: onCloseEULA } = useDisclosure()
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure()
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure()
  const { isOpen: isOpenDecline, onOpen: onOpenDecline, onClose: onCloseDecline } = useDisclosure()
  const { isOpen: isOpenReview, onOpen: onOpenReview, onClose: onCloseReview } = useDisclosure()
  const dbRef = getDatabase()
  const [alertDetails, setAlertDetails] = useState<{status: "success" | "info" | "warning" | "error" | "loading" | undefined, title: string, description: string}>()
  const [review, setReview] = useState<stylistReview>({rating: 0} as stylistReview)
  const [submitted, setSubmitted] = useState<boolean>(false)


  //Check if the selected time is in the past 
  const [isPastBooking, setPastBooking] = useState<boolean>(false)

  function listenForEventUpdates() {      
  
    const dbRef = getDatabase()
    const path = ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`)

    console.log(`Path: ${path} `)

    onValue(path, (snapshot) => {

      if (snapshot.exists()) {
        console.log(`Event details: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string       

        const updatedBooking: eventWithId = {...snapshot.val()}

        const startTime = new Date(`${updatedBooking.date}T${updatedBooking.startTime}:00.000Z`)
        const endTime = new Date(`${updatedBooking.date}T${updatedBooking.endTime}:00.000Z`)
  
        //Get timezone offset at data
        const offset = startTime.getTimezoneOffset() / 60
  
        //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
        startTime.setHours(startTime.getHours() + offset)
        endTime.setHours(endTime.getHours() + offset)
  
        const isPastBooking = pastBooking(updatedBooking.date!, updatedBooking.startTime!)
        const title = getBookingStatus(isPastBooking, updatedBooking.bookingConfirmed!, updatedBooking.reviewKey, updatedBooking.bookingDeclined, updatedBooking.bookingCancelledBy)
  
        setPastBooking(isPastBooking)
        setSelectedEvent({...snapshot.val(), start: startTime, title: title, end: endTime, key: snapshot.key})  //add the customised start and end times

      } else {
        console.log("No data available")     
      }
    })
   
  }

  useEffect(() => {
    listenForEventUpdates()
  },[])

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false })

  


  async function checkEula() {

    //Check that terms have been accepted
    const termsAccepted = await eulaAccepted(selectedEvent.shopFirebaseUid!, "shops")
    if (termsAccepted === false) {
      onOpenEULA()
    } else {
      confirmBooking()
    }
  }

  useEffect(() => {
    if (submitted) {
      onCloseEULA()
      confirmBooking()
    } 
  }, [submitted])


  async function confirmBooking() {


    console.log(`Updating: ${`shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`}`)

    update(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`), {
      bookingConfirmed: true
    }).then(async () => {

      const stylistEmail = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/emailAddress`)

      console.log(`Email address: ${stylistEmail}`)
      //Send mail notification to salon
      approveBookingEmail(
        stylistEmail,
        selectedEvent.placeName!,
        selectedEvent!.date!,
        selectedEvent!.startTime!,
        selectedEvent!.endTime!
      )

      console.log(`Booking confirmed`)   
      window.scrollTo(0, 0)
      onCloseConfirm() 
      setAlertDetails({status: "success", title: "Booking confirmed!", description: "This booking has been confirmed"})
      onOpen()
    }).catch((error) => {
    console.error(error)
    })
  }

  async function declineBooking() {

    console.log(`Updating: ${`shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`}`)

    const currentTime = format(new Date(), 'yyyy-MM-dd HH:mm')

    update(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`), {
      bookingDeclined: `shop - ${currentTime}`
    }).then(async () => {

      const stylistEmail = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/emailAddress`)

      console.log(`Email address: ${stylistEmail}`)
      //Send mail notification to salon
      declineBookingEmail(
        stylistEmail,
        selectedEvent.placeName!,
        selectedEvent!.date!,
        selectedEvent!.startTime!,
        selectedEvent!.endTime!
      )

      
      console.log(`Booking declined`)   
      window.scrollTo(0, 0)
      onCloseDecline() 
      setAlertDetails({status: "info", title: "Declined", description: "This request for booking has been declined."})
      onOpen()
    }).catch((error) => {
    console.error(error)
    })
  }

  async function cancelBooking() {

    console.log(`Updating: ${`shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`}`)

    const currentTime = format(new Date(), 'yyyy-MM-dd HH:mm')

    update(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`), {
      bookingCancelledBy: `shop - ${currentTime}`,
    }).then(async () => {

      const stylistEmail = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/emailAddress`)

      console.log(`Email address: ${stylistEmail}`)
      //Send mail notification to salon
      cancelBookingEmail(
        stylistEmail,
        selectedEvent.placeName!,
        selectedEvent!.date!,
        selectedEvent!.startTime!,
        selectedEvent!.endTime!
      )

      console.log(`Booking cancelled`)   
      window.scrollTo(0, 0)
      onCloseCancel() 
      setAlertDetails({status: "info", title: "Cancelled", description: "This booking has been cancelled"})
      onOpen()
    }).catch((error) => {
    console.error(error)
    })
  }

  async function submitReview() {

    const reviewKey = push(child(ref(dbRef), `stylists/auth_write/${selectedEvent.stylistFirebaseUid}/reviews`)).key;

    set(ref(dbRef, `stylists/auth_write/${selectedEvent.stylistFirebaseUid}/reviews/${reviewKey}`), { 
      stylistFirebaseUid: selectedEvent.stylistFirebaseUid,
      shopFirebaseUid: selectedEvent.shopFirebaseUid,
      shopName: selectedEvent.placeName,
      seatName: selectedEvent.seatName,
      bookingKey: selectedEvent.key,
      timeStampPosted: Date.now(),
      rating: newRating.toString(),
      reviewText: review.reviewText          
    })
    .then(() => {
      console.log(`Saved review`)

      update(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`), {
        reviewKey: reviewKey,
      }).then(async () => {

        const stylistEmail = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/emailAddress`)

        console.log(`Email address: ${stylistEmail}`)
        //Send mail notification to salon
        reviewBookingEmail(
          stylistEmail,
          selectedEvent.placeName!,
          newRating.toString(),
          review.reviewText,
        )

        console.log(`Review key added`)   
        window.scrollTo(0, 0)
        onCloseReview() 
        setAlertDetails({status: "info", title: "Review posted", description: "Review has been submitted"})
        onOpen()
      }).catch((error) => {
      console.error(error)
      }) 
    })
  }

  return (
    <>

      {isVisible &&      
        <Alert status={alertDetails!.status} >
          <Spacer/>        
          <AlertIcon />
          <Box>
            <AlertTitle>{alertDetails?.title}</AlertTitle>
            <AlertDescription>
              {alertDetails?.description}
            </AlertDescription>
          </Box>
          <CloseButton
            onClick={onClose}
            alignSelf='flex-start'
            position='relative'
            right={-1}
            top={-1}
          />
          <Spacer/>
        </Alert>
      }
      

      <Grid
        templateAreas={{base: `"stylist-profile"                         
                                "booking-details"
                                "seat-details"`,
                        sm: `"stylist-profile stylist-profile"
                            "booking-details seat-details"`}}
        gridTemplateColumns={'repeat(12, 1fr)'}
        fontSize={14} 
        marginTop='20px'       
        gap={{base: '20px', sm: '50px'}}
      >
      
        <GridItem area={'stylist-profile'} paddingX='5%' colStart={{base: 0, sm: 2}} colSpan={{base: 12, sm: 10}}>
          <StylistViewProfile stylistFirebaseUid={selectedEvent.stylistFirebaseUid!}/>
        </GridItem>

  
        <GridItem area={'booking-details'} colStart={{base: 1, sm: 2}} colSpan={{base: 12, sm: 5}} paddingBottom={{base: '0vh', sm: '5vh'}} paddingX={{base: '2vw', sm: '0vw'}}>

          <BookingDetails selectedEvent={selectedEvent}/>     

          <Divider borderColor='brand.medium' paddingTop='15px' height='5px'/>

          {selectedEvent.title?.includes("Booking completed") && !selectedEvent.reviewKey &&
            <>
              <Flex
              _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
              onClick={() => {
                console.log(`Review booking`)
                onOpenReview()
              }}>
                
                <HStack>
                  <Icon as={MdStars} w={6} h={6} marginStart='10px' color='brand.medium'/> 
                  <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Review stylist</Text> 
                </HStack>
                <Spacer/>
                <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
              </Flex>

              <Divider borderColor='brand.medium'/> 
            </>
          }

          {!selectedEvent.bookingConfirmed && !selectedEvent.bookingDeclined && !isPastBooking &&
            <>
              <Flex
              _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
              onClick={() => {
                console.log(`Accept booking`)
                onOpenConfirm()
              }}>
                
                <HStack>
                <Icon as={MdCheckCircle} w={6} h={6} marginStart='10px' color='brand.medium'/> 
                <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Accept booking request</Text> 
                </HStack>
                <Spacer/>
                <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
              </Flex>

              <Divider borderColor='brand.medium'/>   
          
              <Flex
                _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
                onClick={() => {
                  console.log(`Decline booking`)
                  onOpenDecline()
              }}>
                
                <HStack>
                <Icon as={MdCancel} w={6} h={6} marginStart='10px' color='brand.medium'/> 
                <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Decline booking request</Text> 
                </HStack>
                <Spacer/>
                <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
              </Flex>

              <Divider borderColor='brand.medium'/>     

            </>
          }

          <Flex 
            _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
            onClick={() => {
              navigate("/chat", { state: { selectedEvent: selectedEvent } })
          }}>

            <HStack>
              <Icon as={MdTextsms} w={6} h={6} marginStart='10px' color='brand.medium'/> 
              <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Chat with stylist:</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
          </Flex>

          <Divider borderColor='brand.medium'/>

          
          {selectedEvent.bookingConfirmed && !selectedEvent.bookingCancelledBy && !selectedEvent.bookingDeclined && !isPastBooking &&
            <>
              <Flex
              _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
              onClick={() => {
                console.log(`Cancel booking`)
                onOpenCancel()
                window.scrollTo({top: 0, behavior: "smooth"})
              }}>
                
                <HStack>
                <Icon as={MdCancel} w={6} h={6} marginStart='10px' color='brand.medium'/> 
                <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Cancel booking</Text> 
                </HStack>
                <Spacer/>
                <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
              </Flex>

              <Divider borderColor='brand.medium'/> 
            </>
          }

        </GridItem>

        <GridItem area={'seat-details'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 5}} paddingX={{base: '2vw', sm: '0vw'}}>            
          <SeatDetailsEvent seatDetails={selectedEvent}/>
        </GridItem>

        <Modal isOpen={isOpenConfirm} onClose={onCloseConfirm}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Accept booking request</ModalHeader>
            <ModalBody>
              <Text>Confirm booking request?</Text>
            </ModalBody>

            <ModalFooter>
              <Button variant='outline' mr={3} onClick={() => {
                // confirmBooking()
                checkEula()
                window.scrollTo({top: 0, behavior: "smooth"})
              }}>Yes</Button>
              <Button variant='outline' mr={3} onClick={onCloseConfirm}>Cancel</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenDecline} onClose={onCloseDecline}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Decline booking</ModalHeader>
            <ModalBody>
              <Text>Are you sure you want to decline this booking?</Text>
            </ModalBody>

            <ModalFooter>
              <Button variant='outline' mr={3} onClick={() => {
                declineBooking()
                window.scrollTo({top: 0, behavior: "smooth"})
              }}>Yes</Button>
              <Button variant='outline' mr={3} onClick={onCloseDecline}>Cancel</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenCancel} onClose={onCloseCancel}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Cancel booking</ModalHeader>
            <ModalBody>
              <Text>Are you sure you want to cancel this booking?</Text>
            </ModalBody>

            <ModalFooter>
              <Button variant='outline' mr={3} onClick={() => {
                cancelBooking()
                window.scrollTo({top: 0, behavior: "smooth"})
              }}>Yes</Button>
              <Button variant='outline' mr={3} onClick={onCloseCancel}>Cancel</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>

     
        <Modal isOpen={isOpenReview} onClose={onCloseReview}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalBody>

              <VStack width='100%' marginBottom='20px'>
                <ModalHeader>Post a review for {selectedEvent.stylistName}</ModalHeader>
                <StarRating rating={newRating} setRating={setNewRating} /> 
                {ratingStarError && 
                <Text textColor='red'>You need to select a rating</Text>
                }            
                <Text>Select a rating out of 5!</Text>
              </VStack>
              
              <Textarea
                id='remarksInput'
                size='resize'
                borderColor='brand.medium'
                focusBorderColor='brand.medium'
                borderRadius={10}
                padding='5px'
                marginTop='10px'
                placeholder=' * Provide a written review'
                onChange={(e) => {
                  setReview({ ...review, ...{reviewText: e.target.value} as stylistReview})
                }}
                />

                {ratingMessageError && 
                  <Text textColor='red' marginTop='5px' paddingStart='10px'>You need to enter a review</Text>
                }

            </ModalBody>

            <ModalFooter>
              <Button mr={3} variant='ghost'
              onClick={() => {      
                onCloseReview()
              }}>Cancel</Button>
              <Button onClick={() => { 
                if (newRating == 0) {
                  setRatingStarError(true)
                  return
                } 
                if (!review.reviewText) {
                  setRatingMessageError(true) 
                  return
                }                   
                submitReview()

              } }>submit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenEULA} onClose={onCloseEULA} >
        <ModalOverlay  />

          <VStack position='fixed' zIndex='2000' width='100vw' height='100vh' top='0' left='0' justifyContent='space-around'>

            <VStack backgroundColor='white' borderTop="8px"  borderColor="brand.medium" width={{base: '95%', sm: '70vw'}} padding='20px' borderRadius='10px' justifyContent='space-around'>
  
              <HStack width='100%'>
                <Heading size='md' color='brand.medium' paddingBottom='20px'>Accept terms of service to continue</Heading>
                <Spacer/>
              </HStack>

              <Eula setSubmitted={setSubmitted}/>

            </VStack>
          </VStack>
      </Modal>
      
      </Grid>   
    </>    
  )
}


