import * as React from "react";
import { User, onAuthStateChanged} from 'firebase/auth';
import { useEffect, useState } from "react";
import { auth } from "../firebaseSetup";


//https://dev.to/dchowitz/react-firebase-a-simple-context-based-authentication-provider-1ool
type firebaseUser = User | null;
type ContextState = { user: firebaseUser };

const FirebaseAuthContext = React.createContext<ContextState | undefined>(undefined);

type Props = {
    children?: React.ReactNode
  };

const FirebaseAuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<firebaseUser>(null);
  const value = { user };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

function useFirebaseAuth() {
    const context = React.useContext(FirebaseAuthContext);
    if (context === undefined) {
      throw new Error(
        "useFirebaseAuth must be used within a FirebaseAuthProvider"
      );
    }
    return context.user;
  }
  
  export { FirebaseAuthProvider, useFirebaseAuth };