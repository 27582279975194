import { VStack, Button, Text, Image, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Flex, Spinner, Heading, Box, HStack, Icon, Show, Spacer } from '@chakra-ui/react';
import { getDownloadURL, ref as ref_storage, listAll, StorageReference} from 'firebase/storage';
import { useEffect, useState } from 'react'
import { MdArrowCircleLeft, MdArrowCircleRight, MdCancel, MdClose, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { firebaseStorage } from '../firebaseSetup';


export default function GalleryView(props: {storagePath: string}) {

  const [imageUrls, setImageUrls] = useState<{
    storageRef: StorageReference,
    downloadUrl: string
  }[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState<number>(0)
  const { isOpen, onOpen, onClose } = useDisclosure()

  //List images in users storage folder
  async function listImagesAndDownload() {

    setIsLoading(true) //start spinner

    const imagesRef = ref_storage(firebaseStorage, props.storagePath);

    console.log(`Storage path: ${imagesRef.fullPath}`)

    const imageReferences: {
      storageRef: StorageReference,
      downloadUrl: string
    }[] = []

          
    //Get a list of the files in the user folder
    const list = await listAll(imagesRef)

    await Promise.all(list.items.map(async(storageRef) => {
      console.log(`ItemRef (Sotrage reference) on sever: ${storageRef}`)
 
      const downloadUrl = await getDownloadURL(storageRef) //Download image
      console.log("ItemRef URL (String):", downloadUrl)

      imageReferences.push({storageRef: storageRef, downloadUrl: downloadUrl}) //Add storafeRef and downloadURK to state

    }))

    //Update the state with the new images 
    setImageUrls(imageReferences) //append download URL
    console.log(`Pushed downloaded URL's to itemUrls`)
    setIsLoading(false) //stop spinner
  }

  useEffect(() => {
    setImageUrls([])
    listImagesAndDownload()
  },[]) 

  
   
  return (

    <>

    {imageUrls.length !== 0 &&
      <Heading size='sm' paddingTop={{base: '10px', sm: '30px'}} marginBottom='10px'>Gallery</Heading>
    }

    <Flex alignItems="center">

      {isLoading && 
        <div>
          <Spinner
            color='brand.medium'
            size='xl'
            thickness='5px'
            speed='0.65s'/>
          <Text>Loading search</Text>
        </div>
      }

      {imageUrls.length !== 0 &&
        <>
          
          <VStack>
            <Flex wrap='wrap' gap={{base: '10px', sm: '20px'}} justify='space-around'>
              {
                imageUrls.map((element, index) => {
                  return (
                    <Image
                      id={element.downloadUrl}
                      key={index}
                      onClick={() => {
                        setCurrentImage(index)
                        onOpen()
                      }}
                      width={{base: '30vw', sm: '300px'}}
                      height={{base: '10vh', sm: '22vh', lg: '18vh'}}
                      boxShadow='xl'
                      borderRadius={10}
                      objectFit='cover'
                      _hover={{ cursor:'pointer', transform: 'scale(1.05)', transition: '.3s' }}
                      src={element.downloadUrl}/>              
                  )
                })
              }

            </Flex>           
    

            {/* <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {imageUrls.length != 0 && <Image src={imageUrls[currentImage].downloadUrl} boxSize='-webkit-max-content'></Image>}
                </ModalBody>

                <ModalFooter>
                <Button
                    leftIcon={<MdOutlineKeyboardArrowLeft />}
                    color='brand.medium'
                    onClick={() => {
                      console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                      if (currentImage == 0) {
                        setCurrentImage(imageUrls.length -1)
                      } else {
                        setCurrentImage(currentImage - 1)
                      }                    
                    }}
                    variant='ghost'>
                    Previous
                  </Button>
                <Button
                    rightIcon={<MdOutlineKeyboardArrowRight />}
                    color='brand.medium'
                    onClick={() => {
                      console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
                      console.log(`Current image is: ${currentImage}`)
                      if (currentImage == imageUrls.length -1) {
                        setCurrentImage(0)
                      } else {
                        setCurrentImage(currentImage + 1)
                      }
                    }}
                    variant='ghost'>
                    Next
                  </Button>

                  <Button
                    rightIcon={<MdClose />}
                    color='brand.medium'
                    onClick={onClose}
                    variant='ghost'>
                    Close
                  </Button>                

                </ModalFooter>
              </ModalContent>
            </Modal>  */}

<Modal isOpen={isOpen} onClose={onClose}>

<Show above='sm'>
  <Box 
      position='fixed'
      top='0px'
      left='0px'
      bottom='0px'
      right='0px'
      background='whiteAlpha.800'
      zIndex={500}           
    >

    <Icon as={MdCancel} position='fixed' top={30} right={30} boxSize={10} color='brand.medium' onClick={onClose} _hover={{ cursor:'pointer' }}/>

  
    <HStack 
      width='100%'
      height='100vh'>
      
    <Icon
        as={MdArrowCircleLeft}
        color='brand.medium'
        boxSize={20}
        paddingLeft={30}
        _hover={{ cursor:'pointer' }}
        onClick={() => {
          console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
          if (currentImage == 0) {
            setCurrentImage(imageUrls.length -1)
          } else {
            setCurrentImage(currentImage - 1)
          }                    
        }}
        />

      <Spacer />


      {imageUrls.length != 0 && 
      
        <Image 
        
          src={imageUrls[currentImage].downloadUrl} 
          maxHeight='80vh'
          maxWidth='80vw'
          objectFit='cover'
          />

          } 

      <Spacer />
      
      <Icon
          as={MdArrowCircleRight}
          color='brand.medium'
          boxSize={20}
          paddingRight={30}                  
          _hover={{ cursor:'pointer' }}
          onClick={() => {
            console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
            console.log(`Current image is: ${currentImage}`)
            if (currentImage == imageUrls.length -1) {
              setCurrentImage(0)
            } else {
              setCurrentImage(currentImage + 1)
            }
          }}
          />
          
    </HStack>

      
  
  </Box>   
</Show>

<Show below='md'>
  <Box 
      position='fixed'
      top='0px'
      left='0px'
      bottom='0px'
      right='0px'
      background='whiteAlpha.800'
      zIndex={500}           
    >

    <Icon as={MdCancel} position='fixed' top={30} right={30} boxSize={10} color='brand.medium' onClick={onClose} _hover={{ cursor:'pointer' }}/>

  
    <Box 
      width='100vw'
      height='100vh'
      alignContent='center'
      >
      
    <Icon
        as={MdArrowCircleLeft}
        color='brand.medium'
        position='fixed'
        top={'50%'}
        left='5%'
        boxSize={30}
        _hover={{ cursor:'pointer' }}
        onClick={() => {
          console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
          if (currentImage == 0) {
            setCurrentImage(imageUrls.length -1)
          } else {
            setCurrentImage(currentImage - 1)
          }                    
        }}
        />




      {imageUrls.length != 0 && 

        
          <Image
            src={imageUrls[currentImage].downloadUrl} 
            maxHeight='100vh'
            maxWidth='100vw'
            objectFit='cover'/>         
        } 


      
      <Icon
          as={MdArrowCircleRight}
          color='brand.medium'
          position='fixed'
          right='5%'
          top='50%'
          boxSize={30}                 
          _hover={{ cursor:'pointer' }}
          onClick={() => {
            console.log(`Current image is: ${currentImage}. Number of images is ${imageUrls.length}`)
            console.log(`Current image is: ${currentImage}`)
            if (currentImage == imageUrls.length -1) {
              setCurrentImage(0)
            } else {
              setCurrentImage(currentImage + 1)
            }
          }}
          />
          
    </Box>

      
  
  </Box>   
</Show>


</Modal> 


      </VStack>

        </>
      }  
    </Flex>

    </>
  )
}



