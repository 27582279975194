import { useEffect, useState } from 'react'
import StylistViewProfile from '../../components/StylistViewProfile'
import { useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

export default function StylistAndSalonMap() {

  const location = useLocation()
  console.log(`Location: ${JSON.stringify(location.pathname.split('/')[2])}`)

  const [stylistFirebaseUid, setStylistFirebaseUid] = useState<string>()

  function setUidFromPath() {
    setStylistFirebaseUid(location.pathname.split('/')[2])
  }

  useEffect(() => {
    setUidFromPath()
  },[])

  return (

    <>
      {stylistFirebaseUid &&
        <Box paddingX={{base: '5%', sm: '10vw'}} paddingY='5vh'>
          <StylistViewProfile stylistFirebaseUid={stylistFirebaseUid}/>
        </Box>
      }
    </>   
    
  )
}
