import { Alert, AlertDescription, AlertIcon, Avatar, Container, Spinner, VStack, Text, Flex } from '@chakra-ui/react';
import { getDownloadURL, ref as ref_storage, uploadBytes } from 'firebase/storage';
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { UserContext } from '../../context/UserContext';
import { firebaseStorage } from '../../firebaseSetup';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { compressImage } from '../../tools/ReturnFunctions';


export default function ProfileImage() {

  const firebaseUser = useFirebaseAuth();
  const {user, setUser} = useContext(UserContext)
  const [profileImageUrl, setProfileImageUrl] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  

  const downloadProfileImage = (imageUrl: string) => {

    getDownloadURL(ref_storage(firebaseStorage, imageUrl))
    .then((url) => {
      console.log("URL is :", url)
      setProfileImageUrl(url)
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.log("Error getting URL, object not found")          
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log("Error getting URL, permission not granted")          
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Error getting URL,user cancelled the upload")          
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Error getting URL", error.code)
          
          break;
      }
    })
  }    

  useEffect(() => {
    console.log(`Firebase user id: ${firebaseUser?.uid}`)
    console.log(`User type: ${user.type}`)
    if (firebaseUser?.uid) downloadProfileImage(`${user.type}/${firebaseUser?.uid}/profile_image.jpg`)
  },[firebaseUser]) 

  //https://pablorocha.me/blog/firebase-storage-react-dropzone-2

  const uploadFromBlobAsync = async (object: { blobUrl: Blob, name: string }) => {
    if (!object.blobUrl || !object.name) return null
  
      console.log(`User type is: ${user.type}`)
      const imageRef = ref_storage(firebaseStorage, `${user.type}/${firebaseUser?.uid}/profile_image.jpg`);
      uploadBytes(imageRef, object.blobUrl).then(() => {
        console.log('Uploaded a blob!');
        downloadProfileImage(`${user.type}/${firebaseUser?.uid}/profile_image.jpg`)
      })   
  }


  //https://pablorocha.me/blog/firebase-storage-react-dropzone-2
  const onDrop = useCallback(async (acceptedFiles: any[]) => {

    const file = acceptedFiles?.[0]

    if (!file) {
      return
    }

    
    setIsLoading(true)
    setError(false)
    
    const compressedFile = await compressImage(file)

    try {
      await uploadFromBlobAsync({
        blobUrl: compressedFile,
        name: `${file.name}_${Date.now()}`,
      })
    } catch (e) {

      if (typeof e === "string") {
          setMessage(e)
      } else if (e instanceof Error) {
        setMessage(e.message) 
      }
      setIsLoading(false)
      setError(true)      
    }

    setIsLoading(false)

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false })

  return (
    <Flex alignItems="center" >
      <VStack>
        <Container textAlign="center" borderColor='brand.medium' borderWidth={1} borderStyle='dashed' width={{base: '90vw', sm: '400px'}} p={5} marginBottom={30} borderRadius={10} _hover={{bg:"brand.mediumAlpha", cursor:'pointer'}}  {...getRootProps()}>

          <VStack>
              <Avatar mb='10px' size='2xl' src={profileImageUrl} backgroundColor='brand.medium' />

              <input {...getInputProps()} />
              {isLoading ? <Spinner color='brand.medium'/> : isDragActive ? <Text>Drop the file here...</Text> : <Text>Drop a single profile pic here, or click to select</Text>}

              {
              (error) && (
                  <Alert status={error ? 'error' : 'success'} w={250} borderRadius={5} m={2}>
                  <AlertIcon />
                  <AlertDescription w={200}>{message}</AlertDescription>
                  </Alert>
              )
              }

          </VStack>  
        </Container>


      </VStack>

    </Flex>
  )
}
