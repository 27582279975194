import { Flex, Button, Spacer, Avatar, Image, Menu, MenuButton, MenuList, MenuItem, Box, HStack, Center, Show } from '@chakra-ui/react'
import { MdAppRegistration, MdEventSeat, MdExitToApp, MdHelp, MdHelpOutline, MdHomeWork, MdKey, MdMenu, MdOutlineEventSeat, MdSettings, MdChat } from 'react-icons/md';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { auth, firebaseStorage } from '../firebaseSetup'
import { signOut} from 'firebase/auth';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { MdCalendarMonth } from "react-icons/md";
import UserType from '../pages/userSetup/UserType';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';



export default function Navbar() {

  const firebaseUser = useFirebaseAuth()
  const navigate = useNavigate();
  const {user, setUser} = useContext(UserContext)
  const [profileImageUrl, setProfileImageUrl] = useState<string>()
  const { pathname } = useLocation()
  const { setPathPageView, setIdentity } = useTrackingCode()


  const downloadProfileImage = (imageUrl: string) => {

    getDownloadURL(ref_storage(firebaseStorage, imageUrl))
    .then((url) => {
      console.log("Profile image URL is :", url)
      setProfileImageUrl(url)
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.log("Error getting URL, object not found")          
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log(`Error getting URL, permission not granted, ${error}`)          
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Error getting URL,user cancelled the upload")          
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Error getting URL", error.code)
          
          break;
      }
    })
  }    

  useEffect(() => {
    console.log(`Firebase user id: ${firebaseUser?.uid}`)
    console.log(`User type: ${user.type}`)
    if (firebaseUser?.uid) downloadProfileImage(`${user.type}/${firebaseUser?.uid}/profile_image.jpg`)
    if (firebaseUser?.email) {
      setIdentity(firebaseUser.email);
    } else {
      setIdentity('');
    }
  },[firebaseUser, user]) 
  

  function logOut() {
    setProfileImageUrl(undefined)
    signOut(auth)
    .then(() => {
      setUser({type: ""})
      console.log(`Log will state UNDEFINED if the user sucessfully signed out: ${auth.currentUser?.email}`) //Log username, NULL if logged out sucessfully. 
      navigate('/') //Go to landing page when logged in
    })
    .catch((error) => {
      console.log(error.message)
    })
  }

  
  return (
    <Flex as="nav" height={{base: '7vh', sm: '7vh'}} marginBottom="20px" backgroundImage={pathname == "/" ? '' : '/img/scissors-hair-dryer-pattern-white-30.svg'} justify='space-between' padding='4px' alignItems='center' position='fixed' zIndex='100' backgroundColor={pathname == "/" ? '' : 'brand.lite'} left='0' top='0' width='100vw'>

      <Show breakpoint='(min-width: 300px)'>      
          <Image 
            src={pathname == "/" ? '' : '/img/logo-white-lite.svg' }
            height={{base: '90%', sm: '90%'}}
            margin='0px' 
            maxW={{base: '25vw', sm: '10vw'}} marginStart='1vw' 
            _hover={{cursor: 'pointer'}}
            onClick={() => {

                

                if (user.type == "shops" && pathname != ("/usertype" || "/shopsetup")) {
                  navigate('shopdashboard') 
                } else {
                  if (user.type == "stylists" && pathname != ("/usertype" || "/stylistsetup"))  {
                    navigate('stylistdashboard')
                  } else {
                    if (user.type == "admins") {
                      navigate('admindashboard') 
                    } else {

                      if (pathname != ("/usertype")) navigate('/')
                    }
                  }
                }                
          }}/>     
      </Show>
      
      <Spacer></Spacer>
   
      <Flex justify='space-between' gap='2' alignItems='center' >  
          
        {firebaseUser && pathname != "/usertype" &&
          <>
          {user.type == "stylists" && pathname != "/stylistsetup" &&
            <>
              <NavLink to="/stylistdashboard" ><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}} paddingX={{base: '9px', sm: '18px'}} borderRadius='50' borderWidth='1px' _hover={{ borderWidth:'2px' }} leftIcon={<MdHomeWork />} backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'}>Home</Button></NavLink>      
              <NavLink to="/stylistmap"><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}} borderRadius='50' paddingX={{base: '9px', sm: '18px'}}  borderWidth='1px' _hover={{ borderWidth:'2px' }} leftIcon={<MdCalendarMonth />} backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'}>Book</Button></NavLink>   
            </>
          }

          {user.type == "shops" && pathname != "/shopsetup" &&
            <>
              <NavLink to="/shopdashboard" ><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}}  paddingX={{base: '8px', sm: '18px'}} borderRadius='50' borderWidth='1px' _hover={{ borderWidth:'2px' }} leftIcon={<MdHomeWork />} backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'}>Home</Button></NavLink>      
              <NavLink to="/shopmanageseats"><Button variant='navbar' fontSize={{base: 'sm', sm: 'md'}}  paddingX={{base: '8px', sm: '18px'}} borderRadius='50'  borderWidth='1px' _hover={{ borderWidth:'2px' }}  leftIcon={<MdEventSeat />} backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'}>Chairs</Button></NavLink>   
            </>
          }
          </>       
        }

        <Menu>
            
          <MenuButton as={Box} borderWidth='1px' borderRadius='50' marginEnd='2vw' padding='5px' _hover={{ borderWidth:'2px', cursor:'pointer' }} backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'} >
            <HStack paddingStart='7px' >                
              <MdMenu size='20px' color='white'/>
              
              <Avatar
                src={firebaseUser ? profileImageUrl : ""}
                backgroundColor={pathname == "/" ? 'brand.medium' : 'brand.lite'}
                size='sm'
                marginEnd='2vw'
                marginStart='0vw' />
            </HStack>
          </MenuButton>

          <MenuList>         

              {firebaseUser ?   
              <>
              <MenuItem minH='48px' onClick={logOut}>
              <Avatar icon={<MdExitToApp  size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                <span>Sign out</span>
              </MenuItem>  

              {user.type == "stylists" && pathname != "/stylistsetup" &&
                <NavLink to="/stylistsettings">
                  <MenuItem minH='40px'>
                    <Avatar icon={<MdSettings size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                    <span>Settings</span>
                  </MenuItem>
                </NavLink> 
              }

              {user.type == "shops" &&
                <NavLink to="/shopsettings">
                  <MenuItem minH='40px'>
                    <Avatar icon={<MdSettings size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                    <span>Settings</span>
                  </MenuItem>
                </NavLink> 
              }

            

              <NavLink to="/support">
                <MenuItem minH='40px'>
                  <Avatar icon={<MdHelpOutline size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                  <span>Support</span>
                </MenuItem>
              </NavLink> 
              </>        

              :

              <div>
                <NavLink to="/login">
                  <MenuItem minH='48px'>
                  <Avatar icon={<MdKey  size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                    <span>Sign in</span>
                  </MenuItem>
                </NavLink>

                <NavLink to="/register">
                <MenuItem minH='48px'>
                <Avatar icon={<MdAppRegistration  size='20px'/>} size='sm' mr='12px' backgroundColor='brand.medium'></Avatar>
                  <span>Register</span>
                </MenuItem>
                </NavLink>
                </div>
              }
          </MenuList>  
        </Menu>
      </Flex>
      
   
    </Flex>

   
       
    
  )
}

