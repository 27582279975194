import { Flex, VStack, Divider, Input, Button, Text, Spacer, Heading, Box, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { getDatabase, onValue, push, child, ref, set } from 'firebase/database';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdSend } from 'react-icons/md';
import { adminChatMessage } from '../Utils/Interfaces';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import Cookies from 'js-cookie';
import { UserContext } from '../context/UserContext';
import { fetchSingleStringValue } from '../tools/ReturnFunctions';
import { chatMessageEmail, publicChatMessageEmail } from '../Utils/MailNotification';


export default function PopUpChat() {

  const dbRef = getDatabase()
  const {user, setUser} = useContext(UserContext)
  const [messages, setMessages] = useState<adminChatMessage[]>([])
  const [inputMessage, setInputMessage] = useState("")
  const [userDisplayName, setUserDisplayName] = useState<string>()
  const [userEmail, setUserEmail] = useState<string>()

  const firebaseUser = useFirebaseAuth()


  const userId = firebaseUser?.uid ? firebaseUser?.uid : Cookies.get('deviceId')
  const publicOrExistingUser = firebaseUser?.uid ? "chats" : "chats_public"

  async function getDisplayName() {
  
    const firstName = await fetchSingleStringValue(`${user.type}/auth_read/${firebaseUser?.uid}/profile_settings/firstName`)
    const lastName = await fetchSingleStringValue(`${user.type}/auth_read/${firebaseUser?.uid}/profile_settings/lastName`)

    if (firstName) {
      setUserDisplayName(`${firstName} ${lastName}`)
    } else {
      setUserDisplayName(firebaseUser!.email!)
    }    
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
      
    useEffect(() => {         
      if (messages.length) {
        elementRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' })  
      }
    },[messages.length])

    return <div ref={elementRef} />
  }

  function loadMessages()  {

    console.log('loading messages')
    
    const messagesRef = ref(dbRef, `admins/${publicOrExistingUser}/${userId}`)

    onValue(messagesRef, (snapshot) => {

      const allMessages: Array<adminChatMessage> = []  
        
        snapshot.forEach((childSnapshot) => {  
          allMessages.push(childSnapshot.val())
        })

      setMessages(allMessages)
    })        
  }

  const handleSendMessage = () => {

    if (!inputMessage.trim().length) {
      return;
    }


    const messageKey = push(child(ref(dbRef), `admins/${publicOrExistingUser}/${userId}`)).key;



    set(ref(dbRef, `admins/${publicOrExistingUser}/${userId}/${messageKey}`), { 
      message: inputMessage,
      recipentsDisplayName: "Admin",
      recipentsUid: "AdminUid",
      sendersUid: userId,
      timeStamp: Date.now(),
      usersDisplayName: userDisplayName ? userDisplayName : userId,
      usersUid: userId,
    })
    .then(() => {

      //Send mail notification to salon
      publicChatMessageEmail(
        "support@electricstudio.app",
        userDisplayName ? userDisplayName! : userId!,
        inputMessage
      )              
        console.log(`Message saved`)   
    })

    setInputMessage("");
  }

  useEffect(() => {
    loadMessages()
    if (firebaseUser?.uid) getDisplayName()

  },[])




  return (
    <div>
      
      <Flex height={{base: '94%', sm: '60vh'}} width='100%' flexDir="column" position={{base: 'fixed', sm: 'static'}} right='0' bottom='0' top='6vh'>
          
          <Flex id='chatMessages' w="100%" h="100%" overflowY='scroll' flexDirection="column" p="1" >

            <Spacer/>

            {/* {messages.length == 0 && 

              <Box padding='10px' marginBottom='5vh'>
              <Heading color='brand.medium' size='sm' >{`Please provide your name and email so we can get back to you if we're not available right now.`}</Heading>
                <Input placeholder='Name' marginTop='10px' onChange={(event) => setUserDisplayName(event.target.value)}>{userDisplayName}</Input>
                <Input placeholder='Email address' marginTop='10px' onChange={(event) => setUserEmail(event.target.value)}>{userDisplayName}</Input>
                <HStack marginTop='10px'>
                  <Spacer/>
                  <Button>Save</Button>
                </HStack>
              </Box>

            } */}

  
              {messages.length !== 0 &&      
       
                messages.map((item, index) => {
                  if (item.sendersUid == userId) {
                    return (
                      <Flex key={index} w="100%" justify="flex-end">
                        <VStack>
                        <Flex
                          bg="brand.medium"
                          borderRadius='10px'
                          color="white"
                          minW="130px"
                          maxW="350px"
                          marginTop="15px"
                          marginBottom='1px'
                          p="3"
                        >
                          <Text>{item.message}</Text>
                        </Flex>
                        <Text alignSelf='end' fontSize='xs' paddingBottom='5px'>{                        
                          format(new Date(item.timeStamp),"do MMMM yyyy HH:mm:ss")
                        }</Text>
                        </VStack>
                      </Flex>
                    );
                  } else {
                    return (
                      <Flex key={index} w="100%">
                          {/* <Image src='img/logo-icon.png' height='7vh' marginStart='1vw'/> */}
                        <Flex
                          bg="gray.100"
                          color="black"
                          minW="100px"
                          maxW="350px"
                          my="1"
                          p="3"
                        >
                          <Text>{item.message}</Text>
                        </Flex>
                      </Flex>
                    );
                  }
                })
              }
              
                <AlwaysScrollToBottom />
            </Flex>
    
  
              <Divider alignSelf='center' width='95%' borderColor="brand.medium" />
            <VStack >
  
              <Flex w="95%" padding='10px'>
                <Input
                  placeholder="New message..."
                  border="none"
                  borderRadius="5px"
                  focusBorderColor='brand.medium'
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                <Button
                  borderRadius="none"
                  color='brand.medium'
                  rightIcon={ <MdSend color='brand.medium' />}
                  variant='ghost'
                  _hover={{backgroundColor: ''}}
                  disabled={inputMessage.trim().length <= 0}
                  onTouchEnd={(e) => {e.preventDefault()}}
                  onTouchStart={handleSendMessage}
                  onClick={handleSendMessage}
                >
        
                </Button>
              </Flex>
            </VStack>
  
          </Flex> 
    </div>
  )
}
