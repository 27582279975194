import { Button, Card, CardBody, Container, Flex, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, Spinner, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption } from '@reach/combobox'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { Dispatch, SetStateAction, useState } from 'react'
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete'
import { shopAddress } from '../../Utils/Interfaces'
import { getDatabase, ref, set } from 'firebase/database'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'

interface StepsProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  submitRef: React.RefObject<HTMLButtonElement>;
}

export default function ShopLocationSetup({ activeStep, setActiveStep, submitRef }: StepsProps) {

  //https://www.youtube.com/watch?v=9e-5QHpadi0&t=24s

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: configValue,
    libraries: ['places']
  })  
  
  if (!isLoaded) return (
    <div>
      <Spinner
        marginTop='30vh'
        color='brand.medium'
        size='xl'
        thickness='5px'
        speed='0.65s'/>
      <Text>Loading search</Text>
    </div>
  )
  return <Map activeStep={activeStep} setActiveStep={setActiveStep}  submitRef={submitRef}/>
}


function Map({ activeStep, setActiveStep, submitRef }: StepsProps) {

  const [zoom, setZoom] = useState(3.4)
  const [camera, setCamera] = useState(new google.maps.LatLng(-28, 133))
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const [selected, setSelected] = useState<null | { lat: number; lng: number }>(null)
  const [place, setPlace] = useState<shopAddress>()
  const { isOpen, onOpen, onClose } = useDisclosure()


  const containerStyle = {
    width: '100vw',
    height: '60vh'
  }

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map) 
  }

  const  {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({    
    requestOptions: { 
      componentRestrictions: {
        country: 'AU',
      },
      types: ['establishment'],    
    },      
  }) 

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
  
    const results = await getGeocode({ address })  
    
    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
    
    const parameter = {
      placeId: results[0].place_id,
      fields: ["name", "formatted_address", "geometry.location", "photos"],
    }
  
    const details = await getDetails(parameter) as google.maps.places.PlaceResult

    console.log(`Details: ${JSON.stringify(details)}`)

    setPlace({
      placeId: results[0].place_id,
      placeName: details.name,
      placeAddress: details.formatted_address,
      placeLat: lat.toString(),
      placeLng: lng.toString(),
      photoUrl: details.photos ?  details.photos![0].getUrl() : 'img/placeholder.jpg' 
     } as shopAddress)

     mapRef?.setZoom(13)
     const myLatlng = new google.maps.LatLng(lat, lng)
     mapRef?.panTo(myLatlng)    
  }
  
  const comboboxInputStyle = {    
    width: '385px',
    borderStyle: 'none', 
    padding: '0.5rem',
     
  }; 

  const comboboxInputStyleBase = {    
    width: '87vw',
    borderStyle: 'none', 
    padding: '0.5rem',
    
  }

  const firebaseUser = useFirebaseAuth()

  function savePlace() {
    const dbRef = getDatabase()
    console.log(`Sacing place`)

    if (place) {

      console.log(`Place ID: ${place.placeId}`)
      
      set(ref(dbRef, `shops/auth_read/${firebaseUser?.uid}/shop_address`), { 
        placeId: place?.placeId,
        placeName: place?.placeName,
        placeAddress: place?.placeAddress,
        placeLat: place?.placeLat,
        placeLng: place?.placeLng,          
      })
      .then(() => {
        setActiveStep(2)
      });
    } else {
      console.log(`Place not defined`)
      onOpen()
    }

  }


  return (  
    
    <div>

      <Flex id='searchFlex' justifyContent='space-around' zIndex='200' position='fixed' top='10vh' left='1px' width='100%'>
        <VStack alignSelf='center'>  
          <div>
            <VStack>               
              <Container borderStyle='solid' borderColor='brand.medium' backgroundColor='white' borderWidth='1px' padding='5px' borderRadius='5px' width={{base: '90vw', sm: '400px'}}>     
                <VStack width='100%'>
                  <Combobox onSelect={handleSelect}>                

                    <Show above='sm'>
                      <ComboboxInput
                        value={value}
                        className="pink"                    
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}    
                        style={comboboxInputStyle}
                        placeholder="Search your salon"
                      />
                    </Show>

                    <Show below='sm'>
                      <ComboboxInput
                        value={value}
                        className="pink"                    
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}    
                        style={comboboxInputStyleBase}
                        placeholder="Search your salon"
                      />
                    </Show>

                    {/* <ComboboxPopover portal={true}> */}
                      <ComboboxList
                      style={{
                        margin: 0,                                   
                                
                      }}>
                        {status === "OK" &&
                          data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                          ))}
                      </ComboboxList>
                    {/* </ComboboxPopover> */}
                  </Combobox>
                </VStack>
              </Container>
            </VStack>
          </div>
        </VStack>
      </Flex>

      {/* <Container height={{base: '80vh', sm: '65vh'}} width={{base: '100vw', sm: '100vw'}} padding='0px'> */}
     
        <GoogleMap
          id='map'
          zoom={zoom}
          center={camera}    
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          options={{
            streetViewControl: false,        
            mapTypeControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            restriction:{
              latLngBounds: {
                north: -10,
                south: -40,
                east: 160,
                west: 100,
              }
            }
          
          }}      
          clickableIcons={false}>

          {selected && <Marker position={selected} />}

          {place &&


            <Card width={{base: '95%', sm: '-webkit-fit-content'}} margin='10px' maxH='95%' position={{base: 'fixed', sm: 'relative'}} bottom={{base: '30vh', sm: '0px'}}>
              <CardBody>

                <Show above='sm'>
                  <Image
                    src={place.photoUrl}
                    fallbackSrc='img/placeholder.jpg'
                    borderRadius='lg'
                    objectFit='cover'
                    maxH={{base: '20vh', sm: '30vh'}}
                  />

                </Show>
                <Stack mt={{base: '0', sm: '6'}} spacing='3'>
                  <Heading size='md'>{place.placeName}</Heading>
                  <Text>{place.placeAddress}</Text>
                </Stack>
              </CardBody>
            </Card>
          // </Show>


          }
        </GoogleMap> 

    

      <button ref={submitRef} onClick={() => savePlace()} style={{ display: 'none' }} />  

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderTop='8px' borderColor='brand.medium' >
          <ModalHeader>Select a location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please select a location to continue.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>

    
  )

}




