import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Navbar from "../components/Navbar"
import { Box, Grid, GridItem } from "@chakra-ui/react"
import ScrollToTop from "../Utils/ScrollToTop"
import { useContext, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { zhCN } from "date-fns/locale";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";


export default function RootLayout() {

  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { setPathPageView, setTrackPageView } = useTrackingCode();


  useEffect(() => {
    const path = sessionStorage.getItem('CURRENT_PATH')
    if (path) navigate(path)
  },[]) 

  useEffect(() => {
    console.log(`Current path: ${pathname}`)
    sessionStorage.setItem('CURRENT_PATH', pathname)
    setPathPageView(pathname);
    setTrackPageView()
  },[pathname]) 



  return (

    <>
      <Navbar />        
      <Box height={{base: '7vh', sm: '7vh'}}></Box>

      <Outlet />        
      <ScrollToTop />    



    </>      


    
  )
}
