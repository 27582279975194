import { Box, Button, Center, HStack, Link, Show, Spacer, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, VStack, useSteps } from '@chakra-ui/react'
import { useContext, useRef } from 'react'
import { UserContext } from '../../context/UserContext'
import { MdOutlineLogin } from 'react-icons/md'
import { NavLink, Link as RouteLink } from 'react-router-dom'
import Gallery from '../common/Gallery'
import ProfileImage from '../common/ProfileImage'
import ShopLocationSetup from './ShopLocationSetup'
import ShopProfile from '../shop/ShopProfile'
import ShopOpenHours from '../shop/ShopOpenHours'
import ShopProfileImage from '../shop/ShopProfileImage'
import SeatProfileNew from '../shop/SeatProfileNew'


export default function ShopSetup() {

  const steps = [
    { title: 'Salon', description: 'Location' },
    { title: 'Profile', description: 'Salon profile' },
    { title: 'Open hours', description: 'Salon hours' },
    { title: 'Profile image', description: 'Salon brand' },
    { title: 'Gallery', description: 'Your salon' },
    { title: 'Chair', description: 'List a chair'},
  ]
  
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  })

  const activeStepText = steps[activeStep -1].description

  const {user, setUser} = useContext(UserContext)
  const submitRef = useRef<HTMLButtonElement>(null);
  
  return (
    
    <VStack minHeight='92%' justifyContent='space-around'>

    

    {/* Shop location */}
    {(activeStep === 1) && 
      <ShopLocationSetup activeStep={activeStep} setActiveStep={setActiveStep} submitRef={submitRef}/>
    }

    <Spacer></Spacer>

    {/* Profile details */}
    {(activeStep === 2) && 
      <ShopProfile activeStep={activeStep} setActiveStep={setActiveStep} submitRef={submitRef}/>       
    }

    {/* Open hours */}
    {(activeStep === 3) &&    
    <Center marginTop={{base: '10px', sm: '5vh'}} height='60vh'>
      <ShopOpenHours activeStep={activeStep} setActiveStep={setActiveStep} submitRef={submitRef}/> 
    </Center>      
    }

    {/* Profile image */}
    {(activeStep === 4) &&   
    <Center marginTop={{base: '10px', sm: '5vh'}} height='60vh'>
      <ShopProfileImage/> 
    </Center>       
    }

    {/* Gallery */}
    {(activeStep === 5) &&    
    <Center marginTop={{base: '10px', sm: '5vh'}} paddingTop={{base: '5vh'}} minHeight='60vh'>
      <Gallery/> 
    </Center>      
    }

    {/* Add seat */}
    {(activeStep === 6) &&    
    <Center marginTop={{base: '10px', sm: '15vh'}} >
      <SeatProfileNew activeStep={activeStep} setActiveStep={setActiveStep} submitRef={submitRef}/> 
    </Center>      
    }

    



    <Spacer></Spacer>

    <HStack verticalAlign="center" paddingBottom='20px' minWidth='60vw' maxWidth='80vw'>


      {activeStep >= 1 &&
          <Link
              fontSize="sm"
              as={RouteLink}
              to='/shopdashboard'
              paddingLeft='1.5rem'
              color='brand.medium'>
              Do it later
          </Link>
      }

        <Spacer></Spacer>

        {[1, 2, 3].includes(activeStep) &&
          <Button
            type="submit"
            rightIcon={<MdOutlineLogin />}
            color='brand.medium'
            onClick={() => submitRef.current?.click()}            
            variant='ghost'>
            Next
        </Button>
        }

        { activeStep === 4 &&           
            <Button 
                rightIcon={<MdOutlineLogin />}
                color='brand.medium'
                onClick={() => setActiveStep(5)}
                variant='ghost'>
                Next
            </Button>
        }

        { activeStep === 5 &&           
            <Button 
                rightIcon={<MdOutlineLogin />}
                color='brand.medium'
                onClick={() => setActiveStep(6)}                 
                variant='ghost'>
                Next
            </Button>
        }

        { activeStep === 6 &&              
            <Button 
                rightIcon={<MdOutlineLogin />}
                color='brand.medium'
                onClick={() => {
                  submitRef.current?.click()
                }}  
                variant='ghost'>
                Next
            </Button>               

        }


    </HStack> 


    {/* Stepper */}
    <Stepper index={activeStep} size={{base: 'sm', sm: 'sm'}} minWidth={{base: '90vw', sm: '60vw'}} maxWidth={{base: '90vw', sm: '90vw'}} paddingBottom={{base: '20px', sm: '40px'}}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator >

            <Show above='sm'>
              <StepStatus 
                complete={<StepIcon /> }
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </Show>

            <Show below='sm'>
              <StepStatus 
                complete={<StepIcon /> }
              />
            </Show>
          
          </StepIndicator>
          
          <Show above='sm'>
            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
          </Show>

          <StepSeparator />
     
        </Step>
      ))}
    </Stepper>

    <Show below='sm'>
      <Text>Step {activeStep}: <b>{activeStepText}</b></Text>
    </Show>

    </VStack>

)
  
}
