import { get, getDatabase, ref, set } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import { currentEula, submitProp, userEulaStatus } from '../Utils/Interfaces'
import { Box, Button, Checkbox, Container, Heading, Spinner, Text, VStack } from '@chakra-ui/react'
import { useFirebaseAuth } from '../context/FirebaseAuthContext'
import { MdSave, MdTaskAlt } from 'react-icons/md'
import { getDate } from 'date-fns/fp'
import { format } from 'date-fns'
import { UserContext } from '../context/UserContext'

export default function Eula({ setSubmitted }: submitProp) {

  const firebaseUser = useFirebaseAuth()
  const dbRef = getDatabase()
  const {user, setUser} = useContext(UserContext)
  const [currentEulaVersion, setCurrentEulaVersion] = useState<currentEula>()
  const [userEulaStatus, setUserEulaStatus] = useState<userEulaStatus>()
  const [eulaAccepted, setEulaAccepted] = useState<boolean>() 

  

  function getEula() {
    //Get current eula version      
    get(ref(dbRef, `admins/eula`)).then(async (snapshot) => {
      if (snapshot.exists()) {
        setCurrentEulaVersion(snapshot.val())
      } else {
        console.log("Cant find current eula version")
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  function getEulaStatus() {
    //Get users EULA and determine if current version has been agreed to        
    get(ref(dbRef, `${user.type}/user_only/${firebaseUser?.uid}/eula`)).then((snapshot) => {
      if (snapshot.exists()) {
        setUserEulaStatus(snapshot.val())
      } else {
        console.log(`Cant find users EULA folder`)
        setEulaAccepted(false)
      }
    })
  }

  function setAcceptedStatus() {
    if (userEulaStatus && currentEulaVersion) {
      if (currentEulaVersion.eulaVersionDate === userEulaStatus.eulaVersionDate && userEulaStatus.eulaAccepted) {
        console.log(`Latest EULA version accepted`)
        setEulaAccepted(true)
      } else {
        setEulaAccepted(false)
      }    
    }
  }

  useEffect(() => {
    getEula()    
  },[]) 

  useEffect(() => {
    if (firebaseUser?.uid) getEulaStatus()   
  },[currentEulaVersion]) 

  useEffect(() => {
    setAcceptedStatus()   
  },[userEulaStatus]) 


  function eulaAgree() { 

    //https://date-fns.org/v1.29.0/docs/format
    const date = new Date()      

    set(ref(dbRef, `${user.type}/user_only/${firebaseUser?.uid}/eula`), { 
      eulaAccepted: true,
      eulaText: currentEulaVersion?.eulaText,
      eulaVersionDate: currentEulaVersion?.eulaVersionDate,
      eulaAcceptedDate: `${format(date, 'yyyy-MM-dd')}`
      })
      .then(() => {
          console.log(`Profile updated`)
          setEulaAccepted(true)
          if (setSubmitted) setSubmitted(true)
         
      })
  } 

  return (   
    
    <div>

      {!currentEulaVersion && 
        <div>
          <Spinner
            color='brand.medium'
            size='xl'
            thickness='5px'
            speed='0.65s'/>
          <Text>Loading search</Text>
        </div>
      }

      {(currentEulaVersion) &&
  
        <VStack>
        <Box borderStyle='solid' borderWidth='1px' borderColor='brand.medium' borderRadius='10px' padding='10px'>
          <Text whiteSpace='pre-line' fontSize='sm' overflow='auto' height='60vh'>{currentEulaVersion?.eulaText}</Text>
  
        </Box>
          <Text alignSelf='end'  paddingEnd='10px'>EULA (End User License Agreement) version dated: {currentEulaVersion.eulaVersionDate}</Text>
  
          <Button
            rightIcon={eulaAccepted ? <MdTaskAlt /> : <MdSave />}
            color='brand.medium'   
            alignSelf='end'    
            onClick={() => eulaAgree()}
            variant='ghost'>
              {eulaAccepted ? "EULA Accepted" : "Accept Terms"}
          </Button>
        </VStack> 
      }
    </div>

  )
}
