import { useContext, useEffect, useState } from 'react'
import { Heading, Text, Card, VStack } from '@chakra-ui/react'
import { adminChatEngagement } from '../../Utils/Interfaces'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { UserContext } from '../../context/UserContext'
import { fetchChatEngagements } from '../../tools/ReturnFunctions'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ChatList() {

  const location = useLocation();
  const data = location.state
  console.log(`Chats folder: ${data.chatsFolder}`)

  const {user, setUser} = useContext(UserContext)
  const firebaseUser = useFirebaseAuth()
  const [existingChatEngagements, setExistingChatEngagements] = useState<Array<adminChatEngagement>>([])
  const navigate = useNavigate()

  async function loadExistingChatEngagements() {
    const chatEngagements = await fetchChatEngagements(`admins/${data.chatsFolder}`)
    setExistingChatEngagements(chatEngagements)
  }

  useEffect(() => {    
    loadExistingChatEngagements()
  },[firebaseUser, user])

  return (

    <>
    <VStack>

      <Heading color='brand.medium' size='md' paddingTop='100px'>{data.chatsFolder == "chats" ? "Clients chat" : "Public chats"}</Heading> 

      {existingChatEngagements && 

        existingChatEngagements.map((item, index) => {
          return (
            <Card
              key={index} 
              margin='10px' 
              padding='10px' 
              borderColor='brand.medium'
              borderStyle='solid'
              borderWidth='1px'
              width={{base: '95vw', sm: '340px'}}
              _hover={{ cursor:'pointer' }}
              onClick={() => {
              navigate("/userchat", { state: { chatsFolder: data.chatsFolder, usersDisplayName: item.usersDisplayName, userUid: item.userUid } })
              console.log(`Selected: ${item.usersDisplayName}`)
            }}>
              <Text>{item.usersDisplayName}</Text>

            </Card>
          )
        })

      }
    </VStack>
    </>
  )
}
