import { Box, Button, Card, CardBody, Container, Flex, HStack, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, Spacer, Spinner, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption } from '@reach/combobox'
import { GoogleMap, Marker, MarkerF, useLoadScript } from '@react-google-maps/api'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete'
import { shopAddress } from '../../Utils/Interfaces'
import { getDatabase, ref, set } from 'firebase/database'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { MdTaskAlt, MdSave } from 'react-icons/md'
import { fetchShopAddress } from '../../tools/ReturnFunctions'

export default function ShopLocation() {

  //https://www.youtube.com/watch?v=9e-5QHpadi0&t=24s

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: configValue,
    libraries: ['places']
  })  
  
  if (!isLoaded) return (
    <div>
      <Spinner
        marginTop='30vh'
        color='brand.medium'
        size='xl'
        thickness='5px'
        speed='0.65s'/>
      <Text>Loading search</Text>
    </div>
  )
  return <Map/>
}


function Map() {

  const [zoom, setZoom] = useState(3.4)
  const [camera, setCamera] = useState(new google.maps.LatLng(-28, 133))
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const [selected, setSelected] = useState<null | { lat: number; lng: number }>(null)
  const [place, setPlace] = useState<shopAddress>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [dataSaved, setDataSaved] = useState<boolean>(false) 
  const firebaseUser = useFirebaseAuth() 

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map) 
  }

  async function fetchExistingAddress() {

    if (firebaseUser?.uid) {

      console.log(`fucning id is: ${firebaseUser.uid}`)

      const shopAddress = await fetchShopAddress(`shops/auth_read/${firebaseUser!.uid}/shop_address`)
  
      if (shopAddress) {
  
        const parameter = {
          placeId: shopAddress.placeId,
          fields: ["photos"],
        }
      
        const details = await getDetails(parameter) as google.maps.places.PlaceResult  
    
        setPlace({
          placeId: shopAddress.placeId,
          placeName: shopAddress?.placeName,
          placeAddress: shopAddress?.placeAddress,
          placeLat: shopAddress?.placeLat,
          placeLng: shopAddress?.placeLng,
          photoUrl: details.photos ?  details.photos![0].getUrl() : 'img/placeholder.jpg' 
         } as shopAddress)
         
         const lat = Number(shopAddress.placeLat)
         const lng = Number(shopAddress.placeLng)    
         
         console.log(`Lat and land is : ${lat} - ${lng}`)
         setSelected({ lat: lat, lng: lng })
         
         mapRef?.setZoom(13)
         
         const myLatlng = new google.maps.LatLng(Number(shopAddress.placeLat), Number(shopAddress.placeLng))
         mapRef?.panTo(myLatlng)    
  
      }
    }


  }

  useEffect(() => {
    fetchExistingAddress()
  },[firebaseUser, mapRef]) 


  const containerStyle = {
    width: '100vw',
    height: '65vh'
  }



  const  {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({    
    requestOptions: { 
      componentRestrictions: {
        country: 'AU',
      },
      types: ['establishment'],    
    },      
  }) 

  const handleSelect = async (address: string) => {
    setDataSaved(false)
    console.log(`Address is ${address}`)
    setValue(address, false);
    clearSuggestions()
  
    const results = await getGeocode({ address })  
    
    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
    console.log(`set selected ${lat} ${lng}`)
    
    const parameter = {
      placeId: results[0].place_id,
      fields: ["name", "formatted_address", "geometry.location", "photos"],
    }
  
    const details = await getDetails(parameter) as google.maps.places.PlaceResult

    console.log(`Details: ${JSON.stringify(details)}`)

    setPlace({
      placeId: results[0].place_id,
      placeName: details.name,
      placeAddress: details.formatted_address,
      placeLat: lat.toString(),
      placeLng: lng.toString(),
      photoUrl: details.photos ?  details.photos![0].getUrl() : 'img/placeholder.jpg' 
     } as shopAddress)

     mapRef?.setZoom(13)
     const myLatlng = new google.maps.LatLng(lat, lng)
     mapRef?.panTo(myLatlng)    
  }
  
  const comboboxInputStyle = {    
    width: '385px',
    borderStyle: 'none', 
    padding: '0.5rem',
     
  }; 

  const comboboxInputStyleBase = {    
    width: '87vw',
    borderStyle: 'none', 
    padding: '0.5rem',
    
  }

  

  function savePlace() {
    const dbRef = getDatabase()
    console.log(`Sacing place`)

    if (place) {

      console.log(`Place ID: ${place.placeId}`)
      
      set(ref(dbRef, `shops/auth_read/${firebaseUser?.uid}/shop_address`), { 
        placeId: place?.placeId,
        placeName: place?.placeName,
        placeAddress: place?.placeAddress,
        placeLat: place?.placeLat,
        placeLng: place?.placeLng,          
      })
      .then(() => {
        console.log(`Saved location`)
        setDataSaved(true)
      });
    } else {
      console.log(`Place not defined`)
      onOpen()
    }

  }


  return (  
    
    <Box>

      <Flex id='searchFlex' justifyContent='space-around' zIndex='20' position={{base: 'relative', sm: 'fixed'}} top={{base: '20px', sm: '20vh'}} width='100%'>
        <VStack alignSelf='center'>  
          <div>
            <VStack>               
              <Container borderStyle='solid' borderColor='brand.medium' backgroundColor='white' borderWidth='1px' padding='5px' borderRadius='5px' width={{base: '90vw', sm: '400px'}}>     
                <VStack width='100%'>
                  <Combobox onSelect={handleSelect}>                

                    <Show above='sm'>
                      <ComboboxInput
                        value={value}
                        className="pink"                    
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}    
                        style={comboboxInputStyle}
                        placeholder="Search your salon"
                      />
                    </Show>

                    <Show below='sm'>
                      <ComboboxInput
                        value={value}
                        className="pink"                    
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}    
                        style={comboboxInputStyleBase}
                        placeholder="Search your salon"
                      />
                    </Show>

                    {/* <ComboboxPopover portal={true}> */}
                      <ComboboxList
                      style={{
                        margin: 0,                                   
                                
                      }}>
                        {status === "OK" &&
                          data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                          ))}
                      </ComboboxList>
                    {/* </ComboboxPopover> */}
                  </Combobox>
                </VStack>
              </Container>
            </VStack>
          </div>
        </VStack>
      </Flex>
     
     <Box id='box' position='relative' top={{base: '-7vh', sm: '0px'}} >
        <GoogleMap
          id='map'
          zoom={zoom}
          center={camera}    
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          options={{
            streetViewControl: false,        
            mapTypeControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            restriction:{
              latLngBounds: {
                north: -10,
                south: -40,
                east: 160,
                west: 100,
              }
            }
          
          }}      
          clickableIcons={false}>

          {selected && <MarkerF position={selected} />}

          <Show above='sm'>
            {place &&

              <Card maxW='sm' margin='10px' maxH='95%'>
                <CardBody>
                  <Image
                    src={place.photoUrl}
                    fallbackSrc='img/placeholder.jpg'
                    borderRadius='lg'
                    objectFit='cover'
                    maxH='40vh'
                  />
                  <Stack mt='6' spacing='3'>
                    <Heading size='md'>{place.placeName}</Heading>
                    <Text>{place.placeAddress}</Text>
                  </Stack>
                </CardBody>
              </Card>
            }
          </Show>

        </GoogleMap>
     </Box>

     <Show below='sm'>
      {place &&

        <Card margin='10px' position='relative' top={{base: '-7vh', sm: '0px'}}>
          {/* <CardBody> */}
            {/* <Image
              src={place.photoUrl}
              fallbackSrc='img/placeholder.jpg'
              borderRadius='lg'
              objectFit='cover'
              height='20vh'
              width='100%'
            /> */}
            <Stack padding='20px' spacing='3'>
              <Heading size='sm'>{place.placeName}</Heading>
              <Text fontSize='small'>{place.placeAddress}</Text>
            </Stack>
          {/* </CardBody> */}
        </Card>
      }     

     </Show>


 

      <HStack margin='20px' position='relative' top={{base: '-7vh', sm: '0px'}}>
          <Spacer/>
          <Button
            rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
            onClick={() => savePlace()}
            color='brand.medium'
            variant='ghost'>
            {dataSaved ? "Saved" : "Save"}
          </Button>
        </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderTop='8px' borderColor='brand.medium' >
          <ModalHeader>Select a location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please select a location to continue.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>

    
  )

}




