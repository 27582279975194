import { VStack, Heading, Divider, HStack, Icon, Text, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, List, ListIcon, ListItem } from '@chakra-ui/react'
import { MdAttachMoney, MdCancel, MdDone, MdInfo } from 'react-icons/md'
import { eventWithId, seatProfile } from '../Utils/Interfaces'

export default function SeatDetailsEvent(props: {seatDetails: eventWithId}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenDaily, onOpen: onOpenDaily, onClose: onCloseDaily } = useDisclosure()


  return (
    <div>
      <VStack align='start' marginBottom='5vh'>

        <Heading paddingTop={{base: '0px', sm: '15px'}} size='md'>Chair details:</Heading>   

        {props.seatDetails.hourlyRate &&
            <HStack>
              <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
              <Text>{`Hourly rate (Inc GST): $${props.seatDetails.hourlyRate}`}</Text>
            </HStack>
        }

        {props.seatDetails.dailyRate &&        
        <HStack>
          <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
          <Text>{`Daily rate (Inc GST): $${props.seatDetails.dailyRate}`}</Text>
          <Icon onClick={onOpenDaily} as={MdInfo} w={6} h={6} color='grey' _hover={{ cursor:'pointer'}}/>  
        </HStack>
        }


        <HStack>
          <Icon as={MdCancel} w={6} h={6} color='brand.medium'/>  
          <Text>{props.seatDetails.houseCancellationPolicy === "24_hours" ? "Cancellation policy: 24 Hours" : "Free"}</Text>
          <Icon onClick={onOpen} as={MdInfo} w={6} h={6} color='grey' _hover={{ cursor:'pointer'}}/>  
        </HStack>

        <Divider borderColor='brand.medium' paddingTop='15px'/>

        <Heading paddingTop='10px' size='md'>Amenities provided:</Heading>
        <Text>{props.seatDetails.amenityBasinShampooConditioner ? "• Basin with shampoo and conditioner: Yes" : "• Basin with shampoo and conditioner: No"}</Text>
        <Text>{props.seatDetails.amenityTowels ? "• Towels provided: Yes" : "• Towels provided: No"}</Text>
        <Text>{props.seatDetails.amenityTeaCoffee ? "• Tea and Coffee provided: Yes" : "• Tea and Coffee provided: No"}</Text>
        <Text>{props.seatDetails.amenityHairDryer ? "• Hair dryer provided: Yes" : "• Hair dryer provided: No"}</Text>
        <Text>{props.seatDetails.amenityStorageLocker ? "• Storage locker provided: Yes" : "• Storage locker provided: No"}</Text>
        <Text>{props.seatDetails.amenityParking ? "• Free parking provided: Yes" : "• Free parking provided: No"}</Text>
     

        <Divider borderColor='brand.medium' paddingTop='15px'/>

        <Heading size='md'>Housekeeping:</Heading>
        <Text paddingEnd={20}>{props.seatDetails.cleaningProvided == "salon" ? "Salon will take care of cleaning the station between customers." : "Stylist is responsible for cleaning their station. Cleaning equiment and products provided by salon."}</Text>

        <Divider borderColor='brand.medium' paddingTop='15px'/>

        <Heading size='md'>Other:</Heading>
        <Text paddingEnd={20}>{props.seatDetails.insuranceRequired ? "Stylist must have their own insurance." : "Stylist is covered by salon insurance."}</Text>
        <Text paddingEnd={20}>{props.seatDetails.businessRegistrationRequired ? "Stylist must have their own business registration." : "Stylist is covered by salon business registration."}</Text>
      </VStack>

      <Modal isOpen={isOpenDaily} onClose={onCloseDaily}>
        <ModalOverlay />
        <ModalContent borderTop="8px"  borderColor="brand.medium">
          <ModalHeader>Daily booking rate</ModalHeader>
          <ModalBody>
            <Text>When booking. Daily rate will automatically be applied when selecting from salons opening to closing time.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseDaily}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderTop="8px"  borderColor="brand.medium">
          <ModalHeader>Cancellation policy definition</ModalHeader>
          <ModalBody>
            <Text>No refund will be provided if cancelled within 24 hours.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
