import { get, getDatabase, limitToFirst, onValue, query, ref } from "firebase/database"
import { googleTimeZone, currentEula, shopProfile, userEulaStatus, stylistProfile, businessHours, shopAddress, seatProfile, eventWithId, stylistLocation, stylistReview, adminChatEngagement, stylistProfileReference, menuItem } from "../Utils/Interfaces";
import axios from "axios";
import { addMinutes, areIntervalsOverlapping, millisecondsToHours } from "date-fns";
import { Event } from "react-big-calendar";
import { getDownloadURL, ref as ref_storage } from "firebase/storage";
import { firebaseStorage } from "../firebaseSetup";
import { BlockquoteHTMLAttributes } from "react";
import imageCompression from "browser-image-compression";
import { getDetails } from "use-places-autocomplete";



  //=======================================
  //Check if latest EULA has been accepted
  export async function eulaAccepted(firebaseUser: string, userTypeFolder: string): Promise<boolean> {

    const dbRef = getDatabase()

    let isAccepted = false

    //Get current eula version      
    await get(ref(dbRef, `admins/eula`)).then(async (snapshot) => {
      if (snapshot.exists()) {
        const currentEulaVersion: currentEula = snapshot.val()   

        //Get users EULA and determine if current version has been agreed to        
        await get(ref(dbRef, `${userTypeFolder}/user_only/${firebaseUser}/eula`)).then((snapshot) => {
        if (snapshot.exists()) {
          const userEulaStatus: userEulaStatus = snapshot.val()
          if (currentEulaVersion.eulaVersionDate === userEulaStatus.eulaVersionDate && userEulaStatus.eulaAccepted) {
            console.log(`Latest EULA version accepted`)
            isAccepted = true
          } else {
            console.log(`Latest EULA version NOT accepted`)
          }    
        } else {
          console.log(`Cant find users EULA folder`)
        }
        }).catch((error) => {
          console.error(error)
        })  
      } else {
        console.log("Cant find current eula version")
      }
      }).catch((error) => {
        console.error(error)
      })      
      console.log(`Is accepted: ${isAccepted}`)
      return isAccepted
  }
  //=======================================


  //=======================================
  //Get UTC offset from Google Time Zone API
  export async function getUtcOffset(lat: GLfloat, lng: GLfloat, timeStamp: string) : Promise<googleTimeZone> {    

    let timeZoneOffset : googleTimeZone = {
      dstOffset: 0,
      rawOffset: 0,
      status: "error",
      timeZoneId: "error",
      timeZoneName: "error"
    }
    
    const apiKey: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
  
    const config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C-${lng}&timestamp=${timeStamp}&key=${apiKey}`,
      headers: { }
    }  
  
    await axios(config)
    .then(function (response) {
      console.log(`Axois response: ${JSON.stringify(response.data)}`)
      timeZoneOffset = response.data
    })
    .catch(function (error) {
      console.log(error)
    })
    
    return timeZoneOffset   
  }
  //=======================================

  //=======================================
  //Fetch shop profile from realtime database
  export async function fetchShopProfile(path: string): Promise<shopProfile | undefined>   {

    let shopProfile: shopProfile | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path);

    await get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`Profile data: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
          shopProfile = snapshot.val()
        } else {
          console.log("No data available")         
        }
      }).catch((error) => {
        console.error(error);
      })
      return shopProfile
  }
  //=======================================

  //=======================================
  //Fetch stylist profile from realtime database
  export async function fetchStylistProfile(path: string): Promise<stylistProfile | undefined>  {

    let stylistProfile: stylistProfile | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`Profile data: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        stylistProfile = snapshot.val()
      } else {
        stylistProfile = undefined
        console.log("No data available")         
      }
    }).catch((error) => {
      console.error(error)
      stylistProfile = undefined
    })
      return stylistProfile
  }
  //=======================================


  //=======================================
  //Check for overlapping events
  export async function overlappingEvents(newEventStart: Date, newEventEnd: Date, existingEventslist: Event[] ): Promise<boolean> {

    let overlapping = false    

    Promise.all(existingEventslist.map((event) => {    

      const overlap = areIntervalsOverlapping(
        { start: addMinutes(newEventStart, 1), end: newEventEnd },
        { start: event.start as Date, end: event.end as Date }
      )

      if (overlap == true) {
        console.log(`Overlapping event: ${event.start} == ${event.end}`)
        overlapping = true  

      } else {
        console.log(`Not overlap event: ${overlap}`)
      }
    }))

    return overlapping    
  }
  //=======================================


  //=======================================
  //Fetch single string value from realtime database
  export async function fetchSingleStringValue(path: string): Promise<string>  {

    let singleValue = ""

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`String read: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        singleValue = snapshot.val()
      } else {
        console.log("No data available")         
      }
    }).catch((error) => {
      console.error(error);
    })
      
    return singleValue
  }
  //=======================================


  //=======================================
  //Fetch image URL from firebase stroage
  export async function getImageUrl(imageUrl: string): Promise<string>  {

    let imageURL = ""

    await getDownloadURL(ref_storage(firebaseStorage, imageUrl))
    .then((url) => {
      console.log("URL is :", url)
      imageURL = url
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.log("Error getting URL, object not found")          
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log("Error getting URL, permission not granted")          
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Error getting URL,user cancelled the upload")          
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Error getting URL", error.code)
          
          break;
      }
    })

    return imageURL
  } 
  //=======================================



  //=======================================
  //Fetch business hours from realtime database
  export async function fetchBusinessHours(path: string): Promise<businessHours | undefined>  {

    let businessHours: businessHours | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`business hours: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        businessHours = snapshot.val()
      } else {
        console.log("No data available")         
      }
    }).catch((error) => {
      console.error(error);
    })
      return businessHours
  }
  //=======================================


  //=======================================
  //Fetch shopaddress
  export async function fetchShopAddress(path: string): Promise<shopAddress | undefined>  {

    let shopAddress: shopAddress | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`Shop address: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        shopAddress = snapshot.val()
      } else {
        console.log("No data available")         
      }
    }).catch((error) => {
      console.error(error);
    })
      return shopAddress
  }
  //=======================================

  //=======================================
  //Fetch seat profile
  export async function fetchSeatProfile(path: string): Promise<seatProfile | undefined>  {

    let seatProfile: seatProfile | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`Seat profile: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        seatProfile = snapshot.val()
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })
      return seatProfile
  }
  //=======================================


  //=======================================
  //Fetch all seats
  export async function fetchSeats(path: string): Promise<Array<seatProfile>> {

    const allSeats: Array<seatProfile> = []  

    const dbRef = getDatabase()     
    const seatsRef = ref(dbRef, path)  
    
    await get(seatsRef).then((snapshot) => {
      if (snapshot.exists()) {

        console.log(`Seat profile: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string

        snapshot.forEach((childSnapshot) => { 
          console.log(`snapshot: ${JSON.stringify(childSnapshot.val())}`) 
          allSeats.push(childSnapshot.val())
        })  
        
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })

  return allSeats
  }
  //=======================================

  //=======================================
  //Fetch event
  export async function fetchSingleEvent(path: string): Promise<eventWithId | undefined>  {

    let event: eventWithId | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    await get(profileRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(`Event: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        event = snapshot.val()
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })
      return event
  }
  //=======================================

  
  //=======================================
  //Listen for event updates
  export async function listenForEventUpdates(path: string) : Promise<eventWithId | undefined> {

    let event: eventWithId | undefined

    const dbRef = getDatabase()
    const profileRef = ref(dbRef, path)

    onValue(profileRef, (snapshot) => {
      if (snapshot.exists()) {
        console.log(`Event: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string
        event = snapshot.val()
        return event
      } else {
        console.log("No data available")     
      }
    })
      return event    
  }
  //=======================================


  //=======================================
  //Fetch stylist locations
  export async function fetchStylistLocations(path: string): Promise<Array<stylistLocation>> {

    const allLocations: Array<stylistLocation> = []  

    const dbRef = getDatabase()     
    const locationRef = ref(dbRef, path)  
    
    await get(locationRef).then((snapshot) => {
      if (snapshot.exists()) {

        console.log(`Location: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string

        snapshot.forEach((childSnapshot) => { 
          console.log(`snapshot: ${JSON.stringify(childSnapshot.val())}`) 
          allLocations.push(childSnapshot.val())
        })  
        
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })

  return allLocations
  }
  //=======================================

  //=======================================
  //Fetch stylist reviews
  export async function fetchStylistReviews(path: string): Promise<Array<stylistReview>> {

    const allReviews: Array<stylistReview> = []  

    const dbRef = getDatabase()     
    const reviewsRef = ref(dbRef, path)  
    
    await get(reviewsRef).then((snapshot) => {
      if (snapshot.exists()) {

        console.log(`Locations: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string

        snapshot.forEach((childSnapshot) => { 
          console.log(`snapshot: ${JSON.stringify(childSnapshot.val())}`) 
          allReviews.push(childSnapshot.val())
        })  
        
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })

  return allReviews
  }
  //=======================================


  //=======================================
  //Determine if booking is in the past of future
  export function pastBooking(date: string, startTime: string) : boolean {

    const startDateTime = new Date(`${date}T${startTime}:00.000Z`)     
    
    const offset = startDateTime.getTimezoneOffset() / 60 //Get timezone offset at data    
    startDateTime.setHours(startDateTime.getHours() + offset) 
    const currentTime = new Date() 

    console.log(`Current time: ${currentTime} - Start time: ${startDateTime} - Hours to start ${millisecondsToHours(startDateTime.valueOf() - currentTime.valueOf())}`)
    
    if (currentTime > startDateTime) {
      console.log(`Booking is in the past`)
      return true
    } else {
      console.log(`Booking is in the future`)
      return false  
    }
  }
  //=======================================

    //=======================================
  //Determine if booking starts within 24 hours in the past of future
  export function bookingLessThanTwentyFourHours(date: string, startTime: string) : boolean {

    const startDateTime = new Date(`${date}T${startTime}:00.000Z`)     
    
    const offset = startDateTime.getTimezoneOffset() / 60 //Get timezone offset at data    
    startDateTime.setHours(startDateTime.getHours() + offset) 
    const currentTime = new Date() 

    console.log(`Current time: ${currentTime} - Start time: ${startDateTime} - Hours to start ${millisecondsToHours(startDateTime.valueOf() - currentTime.valueOf())}`)
    
    if (millisecondsToHours(startDateTime.valueOf() - currentTime.valueOf()) < 24) {
      console.log(`Booking is within 24 hours`)
      return true
    } else {
      console.log(`Booking is more than 24 hours away`)
      return false  
    }
  }
  //=======================================


  //=======================================
  //Get the status of a booking
  export function getBookingStatus(isPastBooking: boolean, bookingConfirmed: boolean, reviewKey?: string, bookingDeclined?: string, bookingCancelledBy?: string) : string {

    let bookingStatus = ""

    if (isPastBooking) {
      console.log(`Booking is in the past`)
      if (bookingConfirmed) bookingStatus = "Booking completed"
      if (bookingConfirmed && !reviewKey) bookingStatus = "Booking completed - to review"
      if (!bookingConfirmed) bookingStatus = "Unconfirmed booking - past booking"
      if (bookingDeclined) bookingStatus = "Booking declined - past booking"
      if (bookingCancelledBy?.includes("shop")) bookingStatus = "Cancelled by salon  - past booking"
      if (bookingCancelledBy?.includes("stylist")) bookingStatus = "Cancelled by stylist  - past booking"
    } else {
      console.log(`Booking is in the future`)
      if (bookingConfirmed) bookingStatus =  "Booking confirmed"
      if (!bookingConfirmed) bookingStatus =  "Pending confirmation"
      if (bookingDeclined) bookingStatus =  "Booking declined"
      if (bookingCancelledBy?.includes("shop")) bookingStatus =  "Cancelled by salon"
      if (bookingCancelledBy?.includes("stylist")) bookingStatus =  "Cancelled by stylist"
    }

  return bookingStatus
  
  }


  //=======================================
  // Color of calendar events
  export function eventColorWeek(title: string) : object {

    if (title == undefined) {
      return {
        style: {
          backgroundColor: "#f15e8a",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

   

    if (title.includes("Pending")) {
      return {
        style: {
          backgroundColor: "#f15e8a",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

    if (title.includes("Booking confirmed")) {
      return {
        style: {
          backgroundColor: "#ba275d",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

    if (title.includes("completed")) {
      return {
        style: {
          backgroundColor: "#ba275d",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

    if (title.includes("Cancelled")) {
      return {
        style: {
          backgroundColor: "black",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

    if (title.includes("declined")) {
      return {
        style: {
          backgroundColor: "black",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }


    if (title.includes("Closed")) {
      return {
        style: {
          backgroundColor: "#7f7f7f",
          borderColor: "white",
          writingMode: 'vertical-rl',
          textAlign: 'center'    
        }
      }
    }

    return {
      style: {
        backgroundColor: "#bab8b9",
        borderColor: "white",
        writingMode: 'vertical-rl',
        textAlign: 'center'    
      }
    }   
  }
  //=======================================



  //=======================================
  //Fetch all existing admin chat engagements
  export async function fetchChatEngagements(path: string) : Promise<Array<adminChatEngagement>>  {

    const allEngagements: Array<adminChatEngagement> = []  

    const dbRef = getDatabase()     
    const seatsRef = ref(dbRef, path)  
    
    await get(seatsRef).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((childSnapshot) => { 
          console.log(`snapshot: ${JSON.stringify(childSnapshot.val())}`) 
          
          const userUid = childSnapshot.key

          childSnapshot.forEach((grandChildSnapshot) => {
            const usersDisplayName = grandChildSnapshot.child('usersDisplayName').val()
            allEngagements.push({userUid: userUid, usersDisplayName: usersDisplayName} as adminChatEngagement)
            return true
          })   

          
        })  
        
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })

    console.log(`All admin chat engagmeents: ${JSON.stringify(allEngagements)}`)

    return allEngagements
    
  }
  //=======================================


  //=======================================
  //Compress image
  export async function compressImage(uncompressedFile: File) : Promise<File> {

    //https://github.com/Donaldcwl/browser-image-compression#readme
    console.log(`originalFile size ${uncompressedFile.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: .15,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const compressedFile = await imageCompression(uncompressedFile, options)

    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)

    return compressedFile

  }


  //=======================================
  //Fetch stylist menu
  export async function fetchStylistMenu(path: string): Promise<Array<menuItem>> {

    const allMenuItems: Array<menuItem> = []  

    const dbRef = getDatabase()     
    const menuRef = ref(dbRef, path)  
    
    await get(menuRef).then((snapshot) => {
      if (snapshot.exists()) {

        console.log(`Menu list: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string

        snapshot.forEach((childSnapshot) => { 
          console.log(`snapshot: ${JSON.stringify(childSnapshot.val())}`) 
          allMenuItems.push(childSnapshot.val())
        })  
        
      } else {
        console.log("No data available")     
      }
    }).catch((error) => {
      console.error(error);
    })

  return allMenuItems
  }
  //=======================================

