import { 
  Tabs, 
  TabList, 
  Tab, 
  TabPanels, 
  TabPanel,
  Heading,
  VStack,
  Flex,
  Show,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center} from '@chakra-ui/react'

import Gallery from '../common/Gallery';
import Eula from '../../components/Eula';
import ResetPassword from '../authentication/ResetPassword';
import ShopProfile from './ShopProfile';
import ShopOpenHours from './ShopOpenHours';
import ShopLocation from './ShopLocation';
import ShopProfileImage from './ShopProfileImage';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function ShopSettings() {

  const location = useLocation()
  const [tabIndex] = useState(location.state ? location.state.tabIndex : 0)
  
  return (

    <div>

      <Show below='sm'>
        <Accordion allowToggle defaultIndex={[tabIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Profile
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ShopProfileImage/>
              <ShopProfile/>
          </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Shop location
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding='0px'>
              <ShopLocation />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Business hours
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding='10px'>
              <ShopOpenHours />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Gallery
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} alignContent='center'>
              <Center>
                <Gallery />    
              </Center>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Terms of service
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Eula/>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Reset password
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ResetPassword/>  
            </AccordionPanel>
          </AccordionItem>
          
        </Accordion>
      </Show>

      <Show above='sm'>
        
        <Tabs marginStart='40px' marginEnd='40px' isFitted  paddingTop='30x'  isLazy variant='line' marginTop='20px' defaultIndex={tabIndex}>
          <TabList>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Profile</Tab>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Salon location</Tab>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Business hours</Tab>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Gallery</Tab>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Terms of service</Tab>
            <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Reset password</Tab>
          </TabList>

          <TabPanels py="5px">
            
            {/* Profile */}
            <TabPanel>
              <Flex justify='space-around' wrap='wrap' gap='40px' >

                <VStack>
                  <Heading size='md' marginBottom='10px'>Profile details</Heading>
                  <ShopProfile/>
                </VStack>

                <VStack>
                  <Heading size='md' marginBottom='10px'>Salon logo</Heading>
                  <ShopProfileImage/>
                </VStack>

              </Flex>
            </TabPanel>
          {/* Profile */}

            {/* Shop location */}
            <TabPanel>
              <VStack>
                <ShopLocation />
              </VStack>
            </TabPanel>
            {/* Shop open  hours */}
            
            {/* Shop open  hours */}
            <TabPanel marginTop='40px'>
              <VStack>
                <Heading size='md' marginBottom='10px'>Business hours</Heading>
                <ShopOpenHours />
              </VStack>
            </TabPanel>
            {/* Shop open  hours */}


            {/* Gallery */}
            <TabPanel marginTop='40px'>
              <VStack>
                <Heading size='md' marginBottom='10px'>Your gallery</Heading>
                <Gallery />
              </VStack>
            </TabPanel>
            {/* Gallery */}

            {/* Terms of service */}
            <TabPanel marginTop='40px'>
              <Heading size='md' marginBottom='10px'>Terms:</Heading>
              <Eula/>
            </TabPanel>
            {/* Terms of service */}

            {/* Reset password */}
            <TabPanel  marginTop='40px'>
                <ResetPassword/>    
            </TabPanel>
            {/* Terms of service */}

          </TabPanels>
        </Tabs>
      </Show>

    </div>
  )
}