import { useContext, useState } from 'react'
import { eventWithId } from '../Utils/Interfaces'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, ModalFooter, Button, ModalCloseButton, useDisclosure } from '@chakra-ui/react'
import { format, getDay, parse, startOfWeek } from 'date-fns'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import enGB from 'date-fns/locale/en-GB'
import { useFirebaseAuth } from '../context/FirebaseAuthContext'
import { UserContext } from '../context/UserContext'


export default function DashboardCalendar(props: {allEvents: eventWithId[], setSelectedEvent: React.Dispatch<React.SetStateAction<eventWithId | undefined>>, selectedEvent: eventWithId | undefined } ) {

  const [bookingError, setBookingError] = useState<{ title: string; message: string; }>()
  const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure()
  const {user, setUser} = useContext(UserContext)
  

  const firebaseUser = useFirebaseAuth();

  function handleSelectEvent(event: eventWithId) { 
    
    if (event.stylistFirebaseUid === firebaseUser?.uid || user.type == "shops" ) {      
      console.log(`Event in calendar ${JSON.stringify(event)}`)
      props.setSelectedEvent(event)
      } else {
        setBookingError({title: "Another users booking", message: "This is another stylists booking."})
        onOpenError()
      }    
  }

  const locales = { 'en-GB': enGB, }
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
  })

  return (

    <div>

      <Calendar
        defaultView='month'
        events={props.allEvents}
        localizer={localizer}
        views={['month']}
        onSelectEvent={handleSelectEvent}
        selectable
        style={{height: '87vh'}}
        eventPropGetter={(event) => {
          
          //https://stackoverflow.com/questions/70664536/how-to-style-react-big-calendar-border
          //Set event styles          
          
          const title = event.title as string
          console.log(`Event title: ${title}`) 

          const background = (event.key === props.selectedEvent?.key) ? "#8e0439" : "#f15e8a"           

          return {
            style: {
              backgroundColor: background,
              borderColor: "white"    
            }
          }          
      }}        

        //Set current day style
        dayPropGetter={(date) => {

          const today = new Date()          
          if (format(date, 'yyyy-MM-dd') == format(today, 'yyyy-MM-dd')) {

            return {
              style: {
                backgroundColor: "#f15e8a48",
                
            
              }
            }
          } else {
            return {
              style: {
                backgroundColor: "white"
              }
            }

          }
        }}                
      />

      {bookingError &&
        <Modal isOpen={isOpenError} onClose={() => {
          onCloseError()
          }}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>{bookingError.title}</ModalHeader>
            <ModalBody>
              <Text whiteSpace='pre-line'>{bookingError.message}</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                onCloseError()
                }}>OK</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }
    </div>
  )
}
