import { useLocation, useNavigate } from 'react-router-dom';
import { eventWithId } from '../../Utils/Interfaces';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, Divider, Flex, Grid, GridItem, HStack, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import FirebaseStorageImage from '../../components/FirebaseStorageImage';
import SingleSalonMap from '../../components/SingleSalonMap';
import SeatDetails from '../../components/SeatDetails';
import { MdCancel, MdKeyboardArrowRight, MdRemoveRedEye, MdTextsms } from 'react-icons/md';
import BookingDetails from '../../components/BookingDetails';
import { format } from 'date-fns';
import { getDatabase, onValue, ref, update } from 'firebase/database';
import { useEffect, useState } from 'react';
import { bookingLessThanTwentyFourHours, fetchSingleStringValue, getBookingStatus, listenForEventUpdates, pastBooking } from '../../tools/ReturnFunctions';
import { stylistCancelBookingEmail } from '../../Utils/MailNotification';
import SeatDetailsEvent from '../../components/SeatDetailsEvent';


export default function StylistViewBooking() {

  const navigate = useNavigate()
  const location = useLocation()
  // const data = location.state

  const data = location.state ? location.state.selectedEvent : JSON.parse(sessionStorage.getItem('SELECTED_EVENT')!) //If page reloaded then use the session storage values
  useEffect(() => {
    sessionStorage.setItem('SELECTED_EVENT', JSON.stringify(selectedEvent))
  },[data]) 

  const [selectedEvent, setSelectedEvent] = useState<eventWithId>(data)
  console.log(`Selected event: ${JSON.stringify(selectedEvent)}`)  
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure()
  const [alertDetails, setAlertDetails] = useState<{status: "success" | "info" | "warning" | "error" | "loading" | undefined, title: string, description: string}>()
  const dbRef = getDatabase()

    //Check if the selected time is in the past 
    const [pastbooking, setPastBooking] = useState<boolean>(() => {
      const isPastBooking = pastBooking(selectedEvent.date!, selectedEvent.startTime!)
      console.log(`Past booking: ${isPastBooking}`)
      return isPastBooking 
    })

    function listenForEventUpdates() {      
  
      const dbRef = getDatabase()
      const path = ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`)

      console.log(`Path: ${path} `)
  
      onValue(path, (snapshot) => {

        if (snapshot.exists()) {
          console.log(`Event details: ${JSON.stringify(snapshot.val())}`) //JSON.stringify() converts the object to a string       

          const updatedBooking: eventWithId = {...snapshot.val()}

          const startTime = new Date(`${updatedBooking.date}T${updatedBooking.startTime}:00.000Z`)
          const endTime = new Date(`${updatedBooking.date}T${updatedBooking.endTime}:00.000Z`)
    
          //Get timezone offset at data
          const offset = startTime.getTimezoneOffset() / 60
    
          //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
          startTime.setHours(startTime.getHours() + offset)
          endTime.setHours(endTime.getHours() + offset)
    
          const isPastBooking = pastBooking(updatedBooking.date!, updatedBooking.startTime!)
          const title = getBookingStatus(isPastBooking, updatedBooking.bookingConfirmed!, updatedBooking.reviewKey, updatedBooking.bookingDeclined, updatedBooking.bookingCancelledBy)
    
          setSelectedEvent({...snapshot.val(), start: startTime, title: title, end: endTime, key: snapshot.key})  //add the customised start and end times

        } else {
          console.log("No data available")     
        }
      })
     
    }
 
    useEffect(() => {
      listenForEventUpdates()
    },[])

    async function cancelBooking() {

      console.log(`Updating: ${`shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`}`)

      const lessthanTwentyFourHours = bookingLessThanTwentyFourHours(selectedEvent.date!, selectedEvent.startTime!)

      if (lessthanTwentyFourHours) {

        onCloseCancel() 
        setAlertDetails({status: 'error', title: 'Booking is within 24 hours', description: 'The booking starts within 24 hours and cant be cancelled'})
        onOpen()

      } else {
        const currentTime = format(new Date(), 'yyyy-MM-dd HH:mm')
  
        update(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/calendars/${selectedEvent.seatName}/${selectedEvent.key}`), {
          bookingCancelledBy: `stylist - ${currentTime}`,
        }).then(async () => {

          const salonEmail = await fetchSingleStringValue(`shops/auth_read/${selectedEvent.shopFirebaseUid}/profile_settings/emailAddress`)

          console.log(`Email address: ${salonEmail}`)
          //Send mail notification to salon
          stylistCancelBookingEmail(
            salonEmail,
            selectedEvent.stylistName!,
            selectedEvent!.date!,
            selectedEvent!.startTime!,
            selectedEvent!.endTime!
          )    
          
          console.log(`Booking cancelled`)   
          window.scrollTo(0, 0)
          onCloseCancel() 
          setAlertDetails({status: "info", title: "Cancelled", description: "This booking has been cancelled"})
          onOpen()
        }).catch((error) => {
        console.error(error)
        })
      }
    }

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false })

  return (

    <>    

    {isVisible &&      
      <Alert status={alertDetails!.status}>
        <Spacer/>        
        <AlertIcon />
        <Box>
          <AlertTitle>{alertDetails?.title}</AlertTitle>
          <AlertDescription>
            {alertDetails?.description}
          </AlertDescription>
        </Box>
        <CloseButton
          onClick={onClose}
          alignSelf='flex-start'
          position='relative'
          right={-1}
          top={-1}
        />
        <Spacer/>
      </Alert>
    }

    <Grid
      templateAreas={{base: `"seat-image"
                              "booking-details"
                              "seat-details"
                              "salon-map"`,
                      sm: `"seat-image salon-map"
                           "booking-details seat-details"`}}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14} 
      marginTop={{base: '0px', sm: '20px'}}
      gap='5'
    >    
      <GridItem area={'seat-image'} colStart={{base: 0, sm: 3}} colSpan={{base: 12, sm: 4}}>
        <Box height={{base: '30vh', sm: '45vh'}} width='100%' overflow='hidden' borderTopLeftRadius={{base: '0', sm: '30'}}>
          <FirebaseStorageImage width='100%' height='100%' imagePath={`shops/${selectedEvent.shopFirebaseUid}/seats/${selectedEvent.seatName}_image.jpg`} />
        </Box>
      </GridItem>

      <GridItem area={'salon-map'} colStart={{base: 0, sm: 7}} colSpan={{base: 12, sm: 4}} >
        <Box width='100%' height='45vh' overflow='hidden'  borderTopRightRadius={{base: '0', sm: '30'}}>            
          <SingleSalonMap shopUid={selectedEvent.shopFirebaseUid!}/>
        </Box>
      </GridItem> 
  
      <GridItem area={'booking-details'} colStart={{base: 1, sm: 3}} colSpan={{base: 12, sm: 4}} paddingBottom={{base: '0vh', sm: '5vh'}} paddingX={{base: '2vw', sm: '0vw'}}>

        <BookingDetails selectedEvent={selectedEvent}/>      
        
        <Divider borderColor='brand.medium' paddingTop='15px' height='5px'/>

        <Flex 
        _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
        onClick={() => {
          navigate("/chat", { state: { selectedEvent: selectedEvent } })
        }}>
          <HStack>
          <Icon as={MdTextsms} w={6} h={6} marginStart='10px' color='brand.medium'/> 
          <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Chat with salon:</Text> 
          </HStack>
          <Spacer/>
          <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>

        <Divider borderColor='brand.medium'/>

        <Flex
        _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
        onClick={() => {
          console.log(`view salon profile`)
          navigate("/salondetails", { state: { shopUid: selectedEvent.shopFirebaseUid } })
        }}>          
          <HStack>
            <Icon as={MdRemoveRedEye} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>View salon profile</Text> 
          </HStack>
          <Spacer/>
          <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>

        <Divider borderColor='brand.medium'/>

        {!selectedEvent.bookingCancelledBy && !selectedEvent.bookingDeclined && !pastbooking &&
            <>
              <Flex
              _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
              onClick={() => {
                console.log(`Cancel booking`)
                onOpenCancel()
              }}>
                
                <HStack>
                <Icon as={MdCancel} w={6} h={6} marginStart='10px' color='brand.medium'/> 
                <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Cancel booking</Text> 
                </HStack>
                <Spacer/>
                <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
              </Flex>

              <Divider borderColor='brand.medium'/> 
            </>
          }

      </GridItem>

      <GridItem area={'seat-details'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 4}} paddingX={{base: '2vw', sm: '0vw'}}>            
        <SeatDetailsEvent seatDetails={selectedEvent}/>
      </GridItem>

      <Modal isOpen={isOpenCancel} onClose={onCloseCancel}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Cancel booking</ModalHeader>
            <ModalBody>
              <Text>Are you sure you want to cancel this booking?</Text>
            </ModalBody>

            <ModalFooter>
              <Button variant='outline' mr={3} onClick={() => {
                cancelBooking()
                window.scrollTo({top: 0, behavior: "smooth"})
              }}>Yes</Button>
              <Button variant='outline' mr={3} onClick={onCloseCancel}>Cancel</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>

    </Grid>
  </>
  )
}
