import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../context/UserContext'
import { child, getDatabase, onValue, push, ref, set } from 'firebase/database'
import { adminChatMessage } from '../Utils/Interfaces'
import { useFirebaseAuth } from '../context/FirebaseAuthContext'
import { Grid, GridItem, Heading, Flex, VStack, Avatar, Divider, Input, Button, Text, Show, Image, Spacer } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MdSend } from 'react-icons/md'
import ContactUs from './ContactUs'
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions'
import { fetchSingleStringValue } from '../tools/ReturnFunctions'
import { chatMessageEmail } from '../Utils/MailNotification'

export default function SupportChat() {

  const {user, setUser} = useContext(UserContext)
  const firebaseUser = useFirebaseAuth()
  const dbRef = getDatabase()
  const [messages, setMessages] = useState<adminChatMessage[]>([])
  const [inputMessage, setInputMessage] = useState("")
  const [userDisplayName, setUserDisplayName] = useState("")

  async function getDisplayName() {
  
      const firstName = await fetchSingleStringValue(`${user.type}/auth_read/${firebaseUser?.uid}/profile_settings/firstName`)
      const lastName = await fetchSingleStringValue(`${user.type}/auth_read/${firebaseUser?.uid}/profile_settings/lastName`)

      if (firstName) {
        setUserDisplayName(`${firstName} ${lastName}`)
      } else {
        setUserDisplayName(firebaseUser!.email!)
      }    
  }


  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
      
    useEffect(() => {         
      if (messages.length) {
        elementRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' })  
      }
    },[messages.length])

    return <div ref={elementRef} />
  }

  function loadMessages()  {

    console.log('loading messages')

    const dbRef = getDatabase()
    const messagesRef = ref(dbRef, `admins/chats/${firebaseUser?.uid}`)

    onValue(messagesRef, (snapshot) => {

      const allMessages: Array<adminChatMessage> = []  
        
        snapshot.forEach((childSnapshot) => {  
          allMessages.push(childSnapshot.val())
        })

      setMessages(allMessages)
    })        
  }

  const handleSendMessage = () => {

    if (!inputMessage.trim().length) {
      return;
    }

    const messageKey = push(child(ref(dbRef), `admins/chats/${firebaseUser?.uid}`)).key;

    set(ref(dbRef, `admins/chats/${firebaseUser?.uid}/${messageKey}`), { 
      message: inputMessage,
      recipentsDisplayName: "Admin",
      recipentsUid: "AdminUid",
      sendersUid: firebaseUser?.uid,
      timeStamp: Date.now(),
      userIsShop: (user.type === "shops") ? true : false,
      usersDisplayName: userDisplayName,
      usersUid: firebaseUser?.uid,
    })
    .then(() => {
      
      //Send mail notification to salon
      chatMessageEmail(
        "support@electricstudio.app",
        userDisplayName,
        inputMessage
      )

        console.log(`Message saved`)   
    })

    setInputMessage("");
  }

  useEffect(() => {
    loadMessages()
    getDisplayName()
  },[firebaseUser, user])



  return (

    <Grid
      templateAreas={`"booking-details chat"`}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14} 
      gap='10'
      marginTop={{base: '50px', sm: '0px'}}  
    >

      <Show above='sm'>        
        <GridItem area={'booking-details'} colStart={2} colSpan={5}>
          <ContactUs/>
          <FrequentlyAskedQuestions/>
        </GridItem>
      </Show>

      {/* <GridItem area={'chat'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 5}}>

      <Show above='sm'> 
        <Heading size='md' marginBottom='15px'>Chat with Electric Studio support team:</Heading>
      </Show>


        <Flex w={["100%"]} height={{base: '94%%', sm: '70vh'}} flexDir="column" borderColor='brand.medium' borderWidth='1px' borderRadius='5px' paddingTop={1} alignSelf='end' position={{base: 'fixed', sm: 'static'}} right='0' bottom='0' top='6vh'>
          
        <Flex id='chatMessages' w="100%" h="100%" overflowY='scroll' flexDirection="column" p="1">

          

            {messages.length !== 0 &&      
     
              messages.map((item, index) => {
                if (item.sendersUid === firebaseUser?.uid) {
                  return (
                    <Flex key={index} w="100%" justify="flex-end">
                      <VStack>
                      <Flex
                        bg="brand.medium"
                        borderRadius='10px'
                        color="white"
                        minW="130px"
                        maxW="350px"
                        marginTop="15px"
                        marginBottom='1px'
                        p="3"
                      >
                        <Text>{item.message}</Text>
                      </Flex>
                      <Text alignSelf='end' fontSize='xs' paddingBottom='5px'>{                        
                        format(new Date(item.timeStamp),"do MMMM yyyy HH:mm:ss")
                      }</Text>
                      </VStack>
                    </Flex>
                  );
                } else {
                  return (
                    <Flex key={index} w="100%">
                        <Image src='img/logo-icon.png' height='7vh' marginStart='1vw'/>
                      <Flex
                        bg="gray.100"
                        color="black"
                        minW="100px"
                        maxW="350px"
                        my="1"
                        p="3"
                      >
                        <Text>{item.message}</Text>
                      </Flex>
                    </Flex>
                  );
                }
              })
            }
            
              <AlwaysScrollToBottom />
          </Flex>
  

            <Divider alignSelf='center' width='95%' borderColor="brand.medium" />
          <VStack >

            <Flex w="95%" padding='10px'>
              <Input
                placeholder="New message..."
                border="none"
                borderRadius="5px"
                focusBorderColor='brand.medium'
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              <Button
                borderRadius="none"
                color='brand.medium'
                rightIcon={ <MdSend color='brand.medium' />}
                variant='ghost'
                _hover={{backgroundColor: ''}}
                disabled={inputMessage.trim().length <= 0}
                onTouchEnd={(e) => {e.preventDefault()}}
                onTouchStart={handleSendMessage}
                onClick={handleSendMessage}
              >
      
              </Button>
            </Flex>
          </VStack>

        </Flex>   
      </GridItem> */}
      
    </Grid>


  )
}
