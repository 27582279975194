import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { FirebaseAuthProvider } from './context/FirebaseAuthContext';
import {theme} from './Theme'
import UserProvider from './context/UserContext';
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  
  <React.StrictMode>
    <FirebaseAuthProvider>
      <UserProvider>
        <ChakraProvider theme={theme}> 
          <HelmetProvider>
            <App />          
          </HelmetProvider>
        </ChakraProvider>
      </UserProvider>
    </FirebaseAuthProvider>    
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
