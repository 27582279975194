import { Center, Card, Heading, FormControl, InputGroup, InputLeftElement, Input, Button, Text, FormErrorMessage, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, VStack, Spinner } from '@chakra-ui/react'
import { Formik, Field } from 'formik'
import { auth } from '../../firebaseSetup'
import { MdEmail, MdLockReset } from 'react-icons/md'
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';




//https://www.youtube.com/watch?v=C_U_KPMyU_8
export default function ResetPassword() {

    const firebaseUser = useFirebaseAuth()
    const navigate = useNavigate()
    const {user, setUser} = useContext(UserContext)
    const [loading, setLoading] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()


    const navigateToLogin = () => {
        if (firebaseUser?.uid) {
            setUser({type: ""})
            signOut(auth)
        }
        navigate('/login')
        
        onClose()
     }

  
return (

    <Center height='60vh'>
        <Card 
          maxW="400px"
          minW='350px'
          rounded={'lg'}
          bg='whiteAlpha.100'
          boxShadow={'lg'}
          padding='30px'
          borderTop="8px"
          alignSelf='center'
          borderColor="brand.medium">

            <Heading as='h4' size='md' mb='20px' color='brand.medium'>Reset password</Heading>  
            <Text mb='20px'>An email will be sent for resetting your password. {"\n"}</Text>
            <Text mb='20px'>Remember to check your junk folder.</Text>

            
            <Formik

                //These need to match the Field id values
                initialValues={{
                    email: firebaseUser?.email ? firebaseUser.email : ""
                }}

                onSubmit={(values) => {
                    setLoading(true)
                    sendPasswordResetEmail(auth, values.email)
                    .then(() => {
                        console.log("Password reset email sent.")
                        setLoading(false)
                        onOpen()
                    })
                    .catch((error) => {
                        
                        console.log(`Error sending password reset email: ${error.message} `)
                    });            


                }}

        >
                {({handleSubmit, errors, touched}) => (

                    <form onSubmit={handleSubmit}>

                        {/* Email address */}
                        <FormControl mb="20px" isInvalid={!!errors.email && touched.email}>
                            <InputGroup>

                                <Field
                                    as={Input}
                                    id='email'
                                    type='email'
                                    name='email'
                                    borderColor='brand.medium'
                                    focusBorderColor='brand.medium'
                                    placeholder='Email Address'
                                    pl='2.5rem'  
                                    validate={(value: string) => {

                                        const isValidEmailAddress = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)  
                                       
                                        if (!isValidEmailAddress) {
                                            return "Please enter a valid email address";
                                        }

                                    }}
                                />

                                <InputLeftElement pointerEvents='none'>
                                    <MdEmail color='brand.medium' />
                                </InputLeftElement>

                            </InputGroup>

                            <FormErrorMessage>{errors.email}</FormErrorMessage>

                        </FormControl>

                        <Center mb='40px'>
                            <Button
                                type="submit"
                                leftIcon={<MdLockReset />}
                                maxWidth='450px'
                                minWidth='330px'
                                disabled={true}
                                variant='solid'>
                                Send
                            </Button>
                        </Center>

                    </form>

                )}
            </Formik>

            {loading && 
                <Center width='100vw' height='100vh' zIndex='100' position='fixed' top='0px' left='0px' backgroundColor='blackAlpha.600'>
                <VStack>
                    <Spinner
                    color='brand.lite'
                    size='xl'
                    thickness='5px'
                    speed='0.65s'
                    />

                    <Text textColor='white'>Loading</Text>
                </VStack>
                </Center>
            }


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent  borderTop='8px' borderColor='brand.medium' >
                <ModalHeader>Password reset email</ModalHeader>
                <ModalBody>
                    <Text mb='10px'>An email has been sent to your inbox with a link to reset your password.</Text>
                    <Text mb='10px'>Remember to check your junk folder.</Text>                   
                </ModalBody>

                <ModalFooter>
                      <Button variant='outline' onClick={navigateToLogin}>Go to login screen</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

        </Card>
    </Center>
    )
}
