import { child, getDatabase, onValue, push, ref, set } from 'firebase/database'
import { adminChatMessage } from '../../Utils/Interfaces'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { useEffect, useRef, useState } from 'react'
import { Flex, VStack, Divider, Input, Button, Text } from '@chakra-ui/react'
import FirebaseStorageAvatar from '../../components/FirebaseStorageAvatar'
import { MdSend } from 'react-icons/md'
import { format } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { chatMessageEmail } from '../../Utils/MailNotification'
import { fetchSingleStringValue } from '../../tools/ReturnFunctions'

export default function UserChat() {

  
  const location = useLocation();
  const data = location.state
  console.log(`user chat ${data.chatsFolder} ${data.usersDisplayName} ${data.userUid}`)

  const dbRef = getDatabase()
  const firebaseUser = useFirebaseAuth()
  const [inputMessage, setInputMessage] = useState("")
  const [messages, setMessages] = useState<adminChatMessage[]>([])
  const [recipentEmail, setRecipentEmail] = useState<string>()
  
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
    useEffect(() => elementRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" }))    
    return <div ref={elementRef} />
  }
  
  const getUserEmail = async (userIsShop: boolean) => {

    console.log(`Message ne: ${userIsShop}`)

    if (userIsShop) {
      const emailTo = await fetchSingleStringValue(`shops/auth_read/${data.userUid}/profile_settings/emailAddress`) as unknown as string
      setRecipentEmail(emailTo)
    } else {
      const emailTo = await fetchSingleStringValue(`stylists/auth_read/${data.userUid}/profile_settings/emailAddress`) as unknown as string
      setRecipentEmail(emailTo)
  }
}
 

  function loadMessages()  {

    console.log('loading messages')
    
    const messagesRef = ref(dbRef, `admins/${data.chatsFolder}/${data.userUid}`)

    onValue(messagesRef, (snapshot) => {

      const allMessages: Array<adminChatMessage> = []  
        
        snapshot.forEach((childSnapshot) => {  
          allMessages.push(childSnapshot.val())
        })

      setMessages(allMessages)
      if (data.chatsFolder == "chats") getUserEmail(allMessages[0].userIsShop)
    })        
  }

  useEffect(() => {    
    loadMessages()
  },[data.userUid])

  const handleSendMessage = () => {

    if (!inputMessage.trim().length) {
      return;
    }

    
    const messageKey = push(child(ref(dbRef), `admins/${data.chatsFolder}/${data.userUid}`)).key;


    set(ref(dbRef, `admins/${data.chatsFolder}/${data.userUid}/${messageKey}`), { 
      message: inputMessage,
      recipentsDisplayName: data.usersDisplayName,
      recipentsUid: data.userUid,
      sendersUid: firebaseUser?.uid,
      timeStamp: Date.now(),
      userIsShop: false,
      usersDisplayName: "Support",
      usersUid: firebaseUser?.uid,
    })
    .then(() => {
        console.log(`Message saved`)   

        if (data.chatsFolder == "chats" && recipentEmail) {

          //Send mail notification to salon
          chatMessageEmail(
            recipentEmail,
            "Electric Studio support",
            inputMessage
          )
        }

    })

    setInputMessage("");
  }

  
  return (
    <div>
      <VStack>

          <Flex w={{base: '100%', sm: '50%'}} height={{base: '94%%', sm: '70vh'}} flexDir="column" borderColor='brand.medium' borderWidth='1px' borderRadius='5px' paddingTop={1} marginTop={{base: '0px', sm: '10vh'}} position={{base: 'fixed', sm: 'static'}} right='0' bottom='0' top='6vh'>
          
          <Flex id='chatMessages' w="100%" h="100%" overflowY='scroll' flexDirection="column" p="1">
  
              {messages.length !== 0 &&      
       
                messages.map((item, index) => {
                  
                  if (item.sendersUid === firebaseUser?.uid) {
                    return (
                      <Flex key={index} w="100%" justify="flex-end">
                        <VStack>
                        <Flex
                          bg="brand.medium"
                          borderRadius='10px'
                          color="white"
                          minW="130px"
                          maxW="350px"
                          marginTop="15px"
                          marginBottom='1px'
                          p="3"
                        >
                          <Text>{item.message}</Text>
                        </Flex>
                        <Text alignSelf='end' fontSize='xs' paddingBottom='5px'>{                        
                          format(new Date(item.timeStamp),"do MMMM yyyy HH:mm:ss")
                        }</Text>
                        </VStack>
                      </Flex>
                    );
                  } else {
                    if (item.sendersUid == data.userUid) {
                    
                    return (
                      <Flex key={index} w="100%">
                        <FirebaseStorageAvatar imagePath={`${item.userIsShop ? 'shops' : 'stylists'}/${item.sendersUid}/profile_image.jpg`} size={'md'}/>
                        <Flex
                          bg="gray.100"
                          color="black"
                          minW="100px"
                          maxW="350px"
                          my="1"
                          p="3"
                        >
                          <Text>{item.message}</Text>
                        </Flex>
                      </Flex>
                    );
                    }
                  }
                })
              }
              
                <AlwaysScrollToBottom />
            </Flex>
    
  
              <Divider alignSelf='center' width='95%' borderColor="brand.medium" />
            <VStack >
  
              <Flex w="95%" padding='10px'>
                <Input
                  placeholder="New message..."
                  border="none"
                  borderRadius="5px"
                  focusBorderColor='brand.medium'
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                <Button
                  borderRadius="none"
                  color='brand.medium'
                  rightIcon={ <MdSend color='brand.medium' />}
                  variant='ghost'
                  _hover={{backgroundColor: ''}}
                  disabled={inputMessage.trim().length <= 0}
                  onClick={handleSendMessage}
                >
        
                </Button>
              </Flex>
            </VStack>
  
          </Flex>   
      </VStack>
    </div>
  )
}




