import { Heading, HStack, Icon, Divider, Text, Link, Show, Flex, Spacer } from '@chakra-ui/react'
import React from 'react'
import { MdKeyboardArrowRight, MdMailOutline, MdOutlineChat } from 'react-icons/md'


export default function ContactUs() {
  return (
    <div>
      <Heading size='md' marginBottom='15px'>Reach out to us:</Heading>

      <Text marginBottom='20px'>Got questions or comments? Think we could do better? Whatever the case, reach out!</Text>

      {/* <HStack marginBottom='20px'>
        <Icon as={MdMailOutline} w={6} h={6} color='brand.medium'/>  
        <Text marginBottom='5px'>Email:     <Link color='brand.medium'>support@electricstudio.app</Link></Text>
      </HStack> */}

      <Divider borderColor='brand.medium'/>

      <Flex
      _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
      onClick={() => {
        console.log(`email`)
        window.open('mailto:support@electricstudio.app?subject=test=Body%20goes%20here')
        
      }}>          
        <HStack>
          <Icon as={MdMailOutline} w={6} h={6} marginStart='10px' color='brand.medium'/> 
          <Text marginBottom='5px'>Email support@electricstudio.app</Text>
        </HStack>
        <Spacer/>
        <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
      </Flex>

      <Show above='sm'>
        <Divider marginBottom='20px' borderColor='brand.medium'/>
      </Show>





    </div>
  )
}
