import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Checkbox, CloseButton, Flex, HStack, Input, Spacer, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { businessHours, stepsProps } from '../../Utils/Interfaces'
import { getDatabase, ref, set } from 'firebase/database'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { fetchBusinessHours } from '../../tools/ReturnFunctions'
import { MdTaskAlt, MdSave } from 'react-icons/md'


export default function ShopOpenHours({activeStep, setActiveStep, submitRef }: stepsProps) {

  const firebaseUser = useFirebaseAuth()
  const [loading, setLoading] = useState<boolean>(true)  
  const [dataSaved, setDataSaved] = useState<boolean>(false)  
  const [alertDetails, setAlertDetails] = useState<{status: "success" | "info" | "warning" | "error" | "loading" | undefined, title: string, description: string}>()

  const [businessHours, setBusinessHours] = useState<businessHours>({
    mondayOpen: true,
    mondayOpenTime: '09:30',
    mondayCloseTime: '18:30',
    tuesdayOpen: true,
    tuesdayOpenTime: '09:30',
    tuesdayCloseTime: '18:30',
    wednesdayOpen: true,
    wednesdayOpenTime: '09:30',
    wednesdayCloseTime: '18:30',
    thursdayOpen: true,
    thursdayOpenTime: '09:30',
    thursdayCloseTime: '18:30',
    fridayOpen: true,
    fridayOpenTime: '09:30',
    fridayCloseTime: '18:30',
    saturdayOpen: true,
    saturdayOpenTime: '09:30',
    saturdayCloseTime: '18:30',
    sundayOpen: true,
    sundayOpenTime: '09:30',
    sundayCloseTime: '18:30',
  })

  async function fetchOpenHours() {
    const openHours = await fetchBusinessHours(`shops/auth_read/${firebaseUser?.uid}/open_hours`)
    if (openHours) setBusinessHours(openHours)
    setLoading(false)
  }

  useEffect(() => {
    fetchOpenHours()
  },[firebaseUser])  

  const [openTimeError, setOpenTimeError] = useState<string>()

  function saveOpenHours() {
    const dbRef = getDatabase()
    console.log(`Saving Open hours`)

    if (Number(businessHours.mondayOpenTime.split(':', 1)) > Number(businessHours.mondayCloseTime.split(':', 1))) {
      setOpenTimeError(`Mondays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.tuesdayOpenTime.split(':', 1)) > Number(businessHours.tuesdayCloseTime.split(':', 1))) {
      setOpenTimeError(`Tuesdays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.wednesdayOpenTime.split(':', 1)) > Number(businessHours.wednesdayCloseTime.split(':', 1))) {
      setOpenTimeError(`Wednesdays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.thursdayOpenTime.split(':', 1)) > Number(businessHours.thursdayCloseTime.split(':', 1))) {
      setOpenTimeError(`Thursdays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.fridayOpenTime.split(':', 1)) > Number(businessHours.fridayCloseTime.split(':', 1))) {
      setOpenTimeError(`Fridays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.saturdayOpenTime.split(':', 1)) > Number(businessHours.saturdayCloseTime.split(':', 1))) {
      setOpenTimeError(`Saturdays open time is later than closing time`)  
      return                    
    } 
    if (Number(businessHours.sundayOpenTime.split(':', 1)) > Number(businessHours.sundayCloseTime.split(':', 1))) {
      setOpenTimeError(`Sundays open time is later than closing time`)  
      return                    
    } 

    
    set(ref(dbRef, `shops/auth_read/${firebaseUser?.uid}/open_hours`), { 
      mondayOpen: businessHours.mondayOpen,
      mondayOpenTime: businessHours.mondayOpenTime,
      mondayCloseTime: businessHours.mondayCloseTime,
      tuesdayOpen: businessHours.tuesdayOpen,
      tuesdayOpenTime: businessHours.tuesdayOpenTime,
      tuesdayCloseTime: businessHours.tuesdayCloseTime,
      wednesdayOpen: businessHours.wednesdayOpen,
      wednesdayOpenTime: businessHours.wednesdayOpenTime,
      wednesdayCloseTime: businessHours.wednesdayCloseTime,
      thursdayOpen: businessHours.thursdayOpen,
      thursdayOpenTime: businessHours.thursdayOpenTime,
      thursdayCloseTime: businessHours.thursdayCloseTime,
      fridayOpen: businessHours.fridayOpen,
      fridayOpenTime: businessHours.fridayOpenTime,
      fridayCloseTime: businessHours.fridayCloseTime,
      saturdayOpen: businessHours.saturdayOpen,
      saturdayOpenTime: businessHours.saturdayOpenTime,
      saturdayCloseTime: businessHours.saturdayCloseTime,
      sundayOpen: businessHours.sundayOpen,
      sundayOpenTime: businessHours.sundayOpenTime,
      sundayCloseTime: businessHours.sundayCloseTime,               
    })
    .then(() => {
      console.log(`Open hours saved`)
      setDataSaved(true)
      if (submitRef) setActiveStep!(4)
    })
  }

   return (
    <div>

      {loading == true &&

        <Flex alignItems="center" minHeight='60vh'>
            <VStack>
                <Spinner
                    color='brand.medium'
                    size='xl'
                    marginBottom='20px'
                    thickness='5px'
                    speed='0.65s'/>
                <Text>Loading</Text>
            </VStack>
        </Flex>
      }

    {loading == false &&
    
      <TableContainer width={{base: '95vw', sm: '60vw'}} fontSize={{base: 'xs', sm: 'md'}}>
        <HStack>
          <Spacer/>
          <Text textColor='red'>{openTimeError}</Text>
          <Spacer/>

        </HStack>
       
        <Table variant='simple' >
          <Thead>
            <Tr >
              <Th  textAlign='left' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'xs', sm: 'md'}}>Day</Th>
              <Th  textAlign='center' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'xs', sm: 'md'}}>Open</Th>
              <Th  textAlign='center' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'xs', sm: 'md'}}>Open time</Th>
              <Th  textAlign='center' paddingX={{base: '0px', sm: '12px'}} fontSize={{base: 'xs', sm: 'md'}}>Close time</Th>
            </Tr>
          </Thead>

          <Tbody>

            {/* Monday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Monday</Td>
              <Td padding={{base: '0px', sm: '5px'}} textAlign='center'>
                <Checkbox
                  isChecked={businessHours.mondayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, mondayOpen: !businessHours.mondayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.mondayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.mondayOpenTime}
                    onChange={(event) => {
                    setBusinessHours({...businessHours, mondayOpenTime: event.target.value })

                    if (event.target.value > businessHours.mondayCloseTime) {
                      setOpenTimeError(`Mondays open time is later than closing time`)                      
                    } else {
                      setOpenTimeError("")
                    }

                    setDataSaved(false)
                  }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.mondayOpen && 
                  <Input
                    size="md"
                    type="time" 
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}                  
                    value={businessHours.mondayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, mondayCloseTime: event.target.value })

                      if (event.target.value < businessHours.mondayOpenTime) {
                        setOpenTimeError(`Mondays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }            

                      setDataSaved(false)
                  }}
                    />
                }
              </Td>
            </Tr>

            {/* Tuesday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Tuesday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.tuesdayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, tuesdayOpen: !businessHours.tuesdayOpen})
                    setDataSaved(false)
                }}
                paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.tuesdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.tuesdayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, tuesdayOpenTime: event.target.value })

                      if (event.target.value > businessHours.tuesdayCloseTime) {
                        setOpenTimeError(`Tuesdays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.tuesdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.tuesdayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, tuesdayCloseTime: event.target.value })

                      if (event.target.value < businessHours.tuesdayOpenTime) {
                        setOpenTimeError(`Tuesdays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>

            {/* Wednesday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Wednesday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.wednesdayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, wednesdayOpen: !businessHours.wednesdayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.wednesdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.wednesdayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, wednesdayOpenTime: event.target.value })

                      if (event.target.value > businessHours.wednesdayCloseTime) {
                        setOpenTimeError(`Wednesdays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.wednesdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.wednesdayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, wednesdayCloseTime: event.target.value })

                      if (event.target.value < businessHours.wednesdayOpenTime) {
                        setOpenTimeError(`Wednesdays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>

            {/* Thursday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Thursday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.thursdayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, thursdayOpen: !businessHours.thursdayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.thursdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.thursdayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, thursdayOpenTime: event.target.value })

                      if (event.target.value > businessHours.thursdayCloseTime) {
                        setOpenTimeError(`Thursdays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.thursdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.thursdayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, thursdayCloseTime: event.target.value })

                      if (event.target.value < businessHours.thursdayOpenTime) {
                        setOpenTimeError(`Thursdays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>

            {/* Friday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Friday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.fridayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, fridayOpen: !businessHours.fridayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.fridayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.fridayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, fridayOpenTime: event.target.value })

                      if (event.target.value > businessHours.fridayCloseTime) {
                        setOpenTimeError(`Fridays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.fridayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.fridayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, fridayCloseTime: event.target.value })

                      if (event.target.value < businessHours.fridayOpenTime) {
                        setOpenTimeError(`Fridays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>

          
            {/* Saturday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Saturday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.saturdayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, saturdayOpen: !businessHours.saturdayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.saturdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.saturdayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, saturdayOpenTime: event.target.value })

                      if (event.target.value > businessHours.saturdayCloseTime) {
                        setOpenTimeError(`Saturdays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.saturdayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    paddingX={{base: '5px', sm: '12px'}}
                    value={businessHours.saturdayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, saturdayCloseTime: event.target.value })

                      if (event.target.value < businessHours.saturdayOpenTime) {
                        setOpenTimeError(`Saturdays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>

          
            {/* Sunday */}
            <Tr height='52px'>
              <Td padding={{base: '0px', sm: '5px'}}>Sunday</Td>
              <Td padding={{base: '0px', sm: '5px'}}  textAlign='center'>
                <Checkbox
                  isChecked={businessHours.sundayOpen}
                  onChange={() => {
                    setBusinessHours({...businessHours, sundayOpen: !businessHours.sundayOpen})
                    setDataSaved(false)
                  }}
                  paddingEnd={{base: '0px', sm: '50px'}}
                />
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.sundayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    padding={{base: '2px', sm: '12px'}}
                    value={businessHours.sundayOpenTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, sundayOpenTime: event.target.value })

                      if (event.target.value > businessHours.sundayCloseTime) {
                        setOpenTimeError(`Sundays open time is later than closing time`)                      
                      } else {
                        setOpenTimeError("")
                      }

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
              <Td padding={{base: '0px', sm: '5px'}}>
                {businessHours.sundayOpen && 
                  <Input
                    size="md"
                    type="time"
                    fontSize={{base: 'xs', sm: 'md'}}
                    padding={{base: '2px', sm: '12px'}}
                    value={businessHours.sundayCloseTime}
                    onChange={(event) => {
                      setBusinessHours({...businessHours, sundayCloseTime: event.target.value })

                      if (event.target.value < businessHours.sundayOpenTime) {
                        setOpenTimeError(`Sundays closing time is earlier than open time`)                      
                      } else {
                        setOpenTimeError("")
                      }  

                      setDataSaved(false)
                    }}
                    />
                }
              </Td>
            </Tr>          

          </Tbody>
        </Table>

        {submitRef ? 
          <button ref={submitRef} onClick={() => saveOpenHours()} style={{ display: 'none' }} />  
    
          :

          <HStack marginTop='20px'>
            <Spacer/>
            <Button
              rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
              onClick={() => saveOpenHours()}
              color='brand.medium'
              variant='ghost'>
              {dataSaved ? "Saved" : "Save"}
            </Button>
          </HStack>        
      }



      </TableContainer>
      
    }



    </div>
  )
}
