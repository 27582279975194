import { extendTheme } from "@chakra-ui/react";

  
export const theme = extendTheme({
    colors: {

 
        brand: {
            dark: "#850034",
            darkAlpha: "#85003450",
            medium: "#ba275d",
            mediumAlpha: "#ba275d26",
            lite: "#f15e8a",
            liteAlpha: "#f15e8a26",
            
        },
    },

    components: {
        
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: {
                        backgroundColor: 'brand.medium',  
                        borderColor: 'brand.medium',
                        _hover: {
                            backgroundColor: 'brand.lite',  
                            borderColor: 'brand.medium',            
                        },       
                    },
                    _hover: {
                        backgroundColor: 'brand.medium',  
                        borderColor: 'brand.medium',            
                    },             
                },
            },      
        },

        Radio: {
            baseStyle: {
                control: {
                    _checked: {
                        backgroundColor: 'brand.medium',  
                        borderColor: 'brand.medium',
                        _hover: {
                            backgroundColor: 'brand.lite',  
                            borderColor: 'brand.medium',            
                        },       
                    },
                    _hover: {
                        backgroundColor: 'brand.medium',  
                        borderColor: 'brand.medium',            
                    },             
                },
            },      
        },

      Button: {
        // The styles all button have in common
        baseStyle: {
            fontWeight: 'base',
            textTransform: 'base',
            borderRadius: 'base',
        },

        sizes: {
            sm: {
            fontSize: 'sm',
            px: 4, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
            },
            md: {
            fontSize: 'md',
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
            },
        },
        // Two variants: outline and solid
        variants: {
            outline: {
                border: '1px solid',
                borderColor: 'brand.medium',
                color: 'brand.medium',
                _hover: { bg: "brand.mediumAlpha" },
                _focus: {
                    bg: 'brand.medium',
                    color: 'white',
                    transform: 'scale(0.98)',
                    borderColor: 'brand.medium',
                }
        
            },

            ghost: {
                _hover: { bg: "brand.mediumAlpha" }
                },

                solid: {
                color: 'white',
                backgroundColor: 'brand.medium',
                _hover: {
                    bg: "brand.mediumAlpha",
                    color: 'brand.medium',
                    borderColor: 'brand.medium'
            
                }
            },

          

            navbar: {
                color: 'white',
                backgroundColor: 'brand.lite',
                
                _hover: {
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                },
                _focus: {
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            },
        },

        // The default size and variant values
        defaultProps: {
            size: 'md',
            variant: 'outline',
        },
      },

      Stepper: {
        //https://github.com/chakra-ui/chakra-ui/issues/7648
        //https://chakra-ui.com/docs/components/stepper/usage
        //https://chakra-ui.com/docs/styled-system/component-style

        baseStyle: {
            title: {
                color: "brand.medium",
            },
            indicator: {
                borderColor: "brand.medium",
                "&[data-status=complete]": {
                    background: "brand.medium"
                },
                "&[data-status=active]": {
                    borderColor: "brand.medium"
                }
            },
            separator: {
                background: "brand.mediumAlpha",
                "&[data-status=complete]": {
                    background: "brand.medium"
                }
                
            },
        },
      }
    },
  })