import { useEffect, useState } from 'react';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { Button, Input, Box, VStack, IconButton, Flex, Spinner, Text, FormControl, HStack, Show } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { DeleteIcon } from '@chakra-ui/icons';
import { menuItem } from '../../Utils/Interfaces';
import { getDatabase, onValue, ref, ref as ref_database, update } from "firebase/database";
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { MdDragHandle, MdSave, MdTaskAlt } from 'react-icons/md';



export default function StylistMenu() {  

  const useStrictDroppable = (loading: boolean) => {

    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
      let animation: any

      if (!loading) {
        animation = requestAnimationFrame(() => setEnabled(true));
      }

      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false)
      }
  }, [loading])

  return [enabled]
}

  const firebaseUser = useFirebaseAuth();
  const dbRef = getDatabase()
  const [enabled] = useStrictDroppable(false);
  const [items, setItems] = useState<Array<menuItem>>()
  const [dataSaved, setDataSaved] = useState<boolean>(false)  

  function fetchMenu() {
    const menuRef = ref(dbRef, `stylists/auth_read/${firebaseUser?.uid}/menu_items`);
    onValue(menuRef, (snapshot) => {

      if (snapshot.exists()) {
        console.log(`Menu exists: ${JSON.stringify(snapshot.val())}`)
        setItems(snapshot.val());
      } else {  
        console.log(`Menu does not exist`)
        setItems([{ menuDescription: '', itemPrice: 0 }, { menuDescription: '', itemPrice: 0 }, { menuDescription: '', itemPrice: 0 }])
      }
    })
  }
  
  useEffect(() => {
    console.log(`Fetching menu`)
    fetchMenu()
  },[firebaseUser]) 

  useEffect(() => {
    console.log(`Items updated: ${JSON.stringify(items)}`)
  },[items]) 

  useEffect(() => {
    console.log(`Enabled: ${enabled}`)
    
  },[enabled]) 



  const formik = useFormik({
    //These need to match the Field id values
    initialValues: items ? { items: items } : { items: [{ menuDescription: '', itemPrice: 0 }] },
    enableReinitialize: true,

    onSubmit: async (values) => { 

      console.log(`Saving`)
                        
      console.log(`Values: ${JSON.stringify(values)}`)       

      const menu_items = values.items

      //Save only the items within values into the database
      update(ref_database(dbRef, `stylists/auth_read/${firebaseUser?.uid}`), { 
        menu_items          
        })
        .then(() => {
          console.log('Saved menu')    
          setDataSaved(true)
        })
      },

      // validationSchema: Yup.object().shape({
      //   servicesList: Yup.array().of(
      //     Yup.object().shape({
      //       menuDescription: Yup.string().label('Service description').required('Required'),
      //       itemPrice: Yup.string().label('Price').required('Required'),
      //     })
      //   ),
        
      // })

  
  })

  return (

    <>

    {items ?
      <div>

      <FormikProvider value={formik}>
     
        <form onSubmit={formik.handleSubmit}>

          {/* <FieldArray
            name='items'
            render={arrayHelpers => ( */}
            
            <FieldArray
            name="servicesList"
            render={arrayHelpers => (

          
            <DragDropContext
              onDragEnd={(result) => {
                const { source, destination } = result;
                if (!destination) {
                  return;
                }
                const items = Array.from(formik.values.items);
                const [reorderedItem] = items.splice(source.index, 1);
                items.splice(destination.index, 0, reorderedItem);

                formik.setFieldValue('items', items)
                setItems(items)
                console.log(`Items: ${JSON.stringify(items)}`)
              }}
            >

            {enabled && 

              
              <Droppable droppableId="droppable">
                {(provided) => (
                  <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={4}>
                    {formik.values.items.map((item, index) => (
                      <Draggable key={index} draggableId={`item-${index}`} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            display="flex"
                            alignItems="center"
                            width="100%"
                            borderColor={'brand.medium'}
                            borderWidth={1}
                            padding={2}
                            backgroundColor={'white'}
                            borderRadius={5}
                          >

                            <FormControl onChange={() => setDataSaved(false)}>

                              <HStack width='100%'>

                            
                                <Field
                                  as={Input}
                                  id={`items.${index}.menuDescription`}
                                  name={`items.${index}.menuDescription`}
                                  value={item.menuDescription}
                                  type='text'
                                  borderColor='brand.medium'
                                  focusBorderColor='brand.medium'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Service description"
                                  width={{ base: '80%', md: '400px'}}                             
                                  required                                 
                                />
                              
                        
                              <Field
                                  as={Input}
                                  id={`items.${index}.itemPrice`}
                                  name={`items.${index}.itemPrice`}
                                  value={item.itemPrice}
                                  type='number'
                                  borderColor='brand.medium'
                                  focusBorderColor='brand.medium'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="$"
                                  width={{ base: '20%', md: '80px'}}
                               
                                  required
                                  />

                                  <Show above='sm'>
                                    <IconButton
                                      aria-label="Reorder"
                                      icon={<MdDragHandle />}                           
                                      {...provided.dragHandleProps}
                                    />
                                    <IconButton
                                      aria-label="Remove item"
                                      icon={<DeleteIcon />}                                                      
                                      onClick={() => {
                                        const items = [...formik.values.items];
                                        items.splice(index, 1);
                                        formik.setFieldValue('items', items)
                                      }}
                                    />
                                  </Show>

                                </HStack>

                                <Show below='md'>
                                    <HStack width='100%' paddingTop='2'>

                                 
                                      <IconButton
                                        aria-label="Reorder"
                                        icon={<MdDragHandle />}   
                                        padding={2}                        
                                        {...provided.dragHandleProps}
                                        />
                                      <IconButton
                                        aria-label="Remove item"
                                        icon={<DeleteIcon />}    
                                        padding={2}                                                   
                                        onClick={() => {
                                          const items = [...formik.values.items];
                                          items.splice(index, 1);
                                          formik.setFieldValue('items', items)
                                        }}
                                      />
                                  </HStack>
                                </Show>


                            
                              </FormControl>
                            
                            {/* <FormErrorMessage>{formik.errors.items}</FormErrorMessage> */}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            }



            </DragDropContext>
              )}
            />

            <FormControl isInvalid={Boolean(formik.errors.items)}>

              <Button mt={4} onClick={() => formik.setFieldValue('items', [...formik.values.items, ''])}>
                Add Item
              </Button>
              
              <Button
                type='submit'
                rightIcon={dataSaved ? <MdTaskAlt /> : <MdSave />}
                color='brand.medium'
                mt={4}
                ml={4}
                variant='outline'>
                {dataSaved ? "Saved" : "Save"}
                
            </Button>

          </FormControl>
          

        </form>
   
    </FormikProvider>

    </div>

    :
      <Flex alignItems="center" minHeight='60vh'>
        <VStack>
            <Spinner
                color='brand.medium'
                size='xl'
                marginBottom='20px'
                thickness='5px'
                speed='0.65s'/>
            <Text>Loading</Text>
        </VStack>
      </Flex>
    }

   

    
   </> 
  )
}


