import { useContext } from 'react'
import { RiScissorsFill, RiStoreFill } from 'react-icons/ri'
import {
  Heading,
  VStack,
  Icon,
  Link,
  Card,
  Flex,
  Image,
} from '@chakra-ui/react'
import { UserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom';


// interface ChildProps {
//   activeStep: number;
//   setActiveStep: Dispatch<SetStateAction<number>>;
// }

export default function UserType() {
  
  const {user, setUser} = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <Flex alignItems="center" height={{base: '30vh', sm: '60vh'}} marginTop={{base: '10vh', sm: '10vh'}} gap='30px' wrap='wrap' justifyContent='center'>

      {/* <Link>
        <Card width={{base: '90vw', sm: '400px'}} borderColor='brand.medium' margin='15px' borderWidth='1px' _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }} mb='20px' padding='20px' onClick={() => {
          setUser({type: "stylists"})
          navigate('/stylistsetup') 
        }} >           
            <VStack>
              <Icon as={RiScissorsFill} w={12} h={12} color='brand.medium'/>                          
              <Heading size='md'>I am a freelance hair stylist</Heading>              
            </VStack>
        </Card>
      </Link> */}

      <Image 
        src='img/IM-A-FREELENCE-STYLIST.svg' 
        _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}
        borderRadius='10px'
        height={{base: '30vh', sm: '40vh'}}
        onClick={() => {
          setUser({type: "stylists"})
          navigate('/stylistsetup') 
        }}
        />
    
      <Image 
        src='img/I-OWN-A-SALON.svg' 
        _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}
        borderRadius='10px'
        height={{base: '30vh', sm: '40vh'}}
        onClick={() => {
          setUser({type: "shops"})
          navigate('/shopsetup') 
        }}
        />
    



{/*       
      <Link>
        <Card width={{base: '90vw', sm: '400px'}} borderColor='brand.medium' margin='15px' borderWidth='1px' _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }} mb='20px' padding='20px' onClick={() => {
          setUser({type: "shops"})
          navigate('/shopsetup')
        }} >
          <VStack>
              <Icon as={RiStoreFill} w={12} h={12} color='brand.medium' /> 
              <Heading size='md' >I am a salon owner</Heading>
          </VStack>
        </Card>
      </Link> */}
    </Flex>    
  )
}
