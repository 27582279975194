import { eventWithId } from '../Utils/Interfaces'
import { Heading, HStack, Icon, Text } from '@chakra-ui/react'
import { format, millisecondsToMinutes } from 'date-fns'
import { MdLocationOn, MdEventSeat, MdCalendarMonth, MdAccessTime, MdAttachMoney, MdMailOutline } from 'react-icons/md'
import FirebaseRtdbText from './FirebaseRtdbText'

export default function BookingDetails(props : {selectedEvent: eventWithId}) {


  return (
    <div>

      <Heading size='md' paddingTop={{base: '0px', sm: '15px'}} marginBottom='15px'>Booking status: {props.selectedEvent.title}</Heading>

      <HStack marginBottom='10px'>
        <Icon as={MdLocationOn} w={6} h={6} color='brand.medium'/>  
        <Text>Salon: </Text>
        <Text>
        <FirebaseRtdbText valuePath={`shops/auth_read/${props.selectedEvent.shopFirebaseUid}/shop_address/placeName`}/>  
      </Text>
      </HStack>

      <HStack  marginBottom='10px'>
        <Icon as={MdEventSeat} w={6} h={6} color='brand.medium'/>  
        <Text>Chair name: {props.selectedEvent.seatName}</Text>
      </HStack>

      <HStack marginBottom='10px'>
        <Icon as={MdCalendarMonth} w={6} h={6} color='brand.medium'/>  
        <Text> Booking date: {format(new Date(`${props.selectedEvent!.date}`),"do MMMM yyyy")}</Text>
      </HStack>

      <HStack  marginBottom='10px'>
        <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
        <Text marginBottom='5px'>Start time: {props.selectedEvent!.startTime}</Text>
      </HStack>    

      <HStack  marginBottom='10px'>
        <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
        <Text marginBottom='5px'>End time: {props.selectedEvent!.endTime}</Text>
      </HStack>  

      {props.selectedEvent.hourlyRate &&
        <HStack  marginBottom='10px'>
          <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
          <Text marginBottom='5px'>Booking charges (Inc GST): ${((millisecondsToMinutes(props.selectedEvent.end!.valueOf() - props.selectedEvent.start!.valueOf()) / 60) * Number(props.selectedEvent!.hourlyRate)).toFixed(2)} (Hourly rate)</Text>
        </HStack> 
      }   

      {props.selectedEvent.dailyRate &&
        <HStack  marginBottom='10px'>
          <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
          <Text marginBottom='5px'>Booking charges: ${props.selectedEvent.dailyRate} (Daily rate)</Text>
        </HStack> 
      }    


      <HStack  marginBottom='10px'>
        <Icon as={MdMailOutline} w={6} h={6} color='brand.medium'/>  
        <Text marginBottom='5px'>Remarks for salon: {props.selectedEvent.remarks}</Text>
      </HStack>
    </div>
  )
}
