import { Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export default function PrivacyPolicy() {
  return (
    <Stack width={{base: '100%', sm: '60vw'}} padding='30px'>
      <Heading size='lg' color='brand.medium'>Overview</Heading>
      <Text>{`In this Privacy Policy, 'we', 'us', 'Electric Studio' or 'our' means Electric Studio. Electric Studio is committed to respecting privacy. This Privacy Policy sets out how we collect, store, use and disclose your personal information.`}</Text>
      <Text>{`By providing personal information to Electric Studio, you consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy and any other arrangements that apply between us. We may change our Privacy Policy at any time which will be published on our website.`}</Text>
      <Text>{`Personal information includes information or data about an individual that is reasonably identifiable. For example, this may include name, age, gender, postcode and contact details. It may also include financial information, including credit card information.`}</Text>
      

      <Heading size='lg' paddingTop='40px' color='brand.medium'>What personal information do we collect?</Heading>
      <Text>We may collect the following types of personal information: </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • Name: </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Street or mailing address</Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Email address(es)</Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Telephone number(s) and other contact details  : </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Age and/or date of birth</Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Credit card information</Text>
      <Text paddingStart='40px'paddingBottom='20px'> • Your device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information;</Text>
      <Text paddingStart='40px'paddingBottom='20px'> • details of the services we have provided to you or that you have enquired about, including any additional information necessary to deliver those services and respond to your enquiries; </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • any additional information relating to you that you provide to us directly through our website or app or indirectly through your use of our website or app or online presence or through other websites or accounts from which you permit us to collect information; </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • information you provide to us through surveys; or </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • any other personal information that may be required in order to facilitate your dealings with us. </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • We may collect these types of personal information either directly from you, or from third parties. We may collect this information when you: </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • register on our website or app; </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • communicate with us through correspondence, chats, email, or when you share information with us from other social applications, services or websites; </Text>
      <Text paddingStart='40px'paddingBottom='20px'> • interact with our sites, services or content. </Text>
      <Text>In addition, when you register as a freelance stylist or salon owner with us, we may collect certain information from you (including your name, contact details, government issued ID, qualifications, insurances, financial information including your credit card details and relevant records checks) and others who may be able to provide information to us to assist in our decision on whether or not to register you as a user of Electric Studio services.  </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Why do we collect, use and disclose personal information? </Heading>
      <Text>We may collect, hold, use and disclose your personal information for the following purposes: </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to enable you to access and use our app and website services;</Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to operate, protect, improve and optimise our website and services, business and our users’ experience, such as to perform analytics, conduct research and for advertising and marketing; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to send you marketing and promotional messages and other information that may be of interest to you; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to administer surveys, or other promotional activities or events sponsored or managed by us; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties; and </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • to consider your application to be a freelance stylist, Salon Owner or Electric Studio app user;  </Text>
      <Text>We may send you direct marketing communications and information about our services and/or products. This may take the form of emails, SMS, mail or other forms of communication, in accordance with the Spam Act and the Privacy Act. You may opt-out of receiving marketing materials from us by contacting us using the details set out below or by using the opt-out facilities provided (e.g. an unsubscribe link). </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>To whom do we disclose your personal information?</Heading>
      <Text>We may disclose personal information for the purposes described in this privacy policy to: </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • our employees and related bodies corporate; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • third party suppliers and service providers (including providers for the operation of our websites and/or our business or in connection with providing our products and services to you); </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • payment systems operators (eg merchants receiving card payments); </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • our existing or potential agents, business partners or partners; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • our sponsors or promoters of any competition that we conduct via our services; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • anyone to whom our assets or businesses (or any part of them) are transferred; </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • specific third parties authorised by you to receive information held by us; and/or </Text>
      <Text paddingStart='40px' paddingBottom='20px'> • other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law. </Text>
      <Text as='u'>Disclosure of personal information outside Australia </Text>
      <Text>We may disclose personal information outside of Australia to third party suppliers and cloud providers located in Singapore and the United States. </Text>
      <Text>When you provide your personal information to us, you consent to the disclosure of your information outside of Australia and acknowledge that we are not required to ensure that overseas recipients handle that personal information in compliance with Australian Privacy Law. We will, however, take reasonable steps to ensure that any overseas recipient will deal with such personal information in a way that is consistent with the Australian Privacy Principles.  </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Using our website and cookies</Heading>
      <Text>
        We may collect personal information about you when you use and access our website. 
        While we do not use browsing information to identify you personally, we may record certain information about your use of our website, such as which pages you visit, the time and date of your visit and the internet protocol address assigned to your computer. 
        We may also use cookies or other similar tracking technologies on our website that help us track your website usage and remember your preferences. Cookies are small files that store information on your computer, TV, mobile phone or other device. They enable the entity that put the cookie on your device to recognise you across different websites, services, devices and/or browsing sessions. You can disable cookies through your internet browser but our websites may not work as intended for you if you do so. 
        We may also use cookies to enable us to collect data that may include personal information. For example, where a cookie is linked to your account, it will be considered personal information under the Privacy Act. We will handle any personal information collected by cookies in the same way that we handle all other personal information as described in this Privacy Policy. 
      </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Security</Heading>
      <Text>We may hold your personal information in either electronic or hard copy form. We take reasonable steps to protect your personal information from misuse, interference and loss, as well as unauthorised access, modification or disclosure and we use a number of physical, administrative, personnel and technical measures to protect your personal information. However, we cannot guarantee the security of your personal information. </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Links</Heading>
      <Text>Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites, and have no control over or rights in those linked websites. The privacy policies that apply to those other websites may differ substantially from our Privacy Policy, so we encourage individuals to read them before using those websites. </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Accessing or correcting your personal information</Heading>
      <Text>
        You can access the personal information we hold about you by contacting us using the information below. Sometimes, we may not be able to provide you with access to all of your personal information and, where this is the case, we will tell you why. We may also need to verify your identity when you request your personal information. 
        If you think that any personal information we hold about you is inaccurate, please contact us and we will take reasonable steps to ensure that it is corrected. 
      </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Making a complaint</Heading>
      <Text>
          If you think we have breached the Privacy Act, or you wish to make a complaint about the way we have handled your personal information, you can contact us using the details set out. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, we will provide you with information about the further steps you can take. 
      </Text>

      <Heading size='lg' paddingTop='40px' color='brand.medium'>Contact us</Heading>
      <Text>
        For further information about our Privacy Policy or practices, or to access or correct your personal information, or make a complaint, please contact us using the details set out below: 
      </Text>

      <Text 
        marginBottom='5px'
        as='u'
        _hover={{ cursor:'pointer', color: 'brand.medium' }}
        onClick={() => {
          window.open('mailto:support@Electric Studio.app?subject=test=Body%20goes%20here')
        }}
      >support@Electric Studio.app
      </Text>





    </Stack>
  )
}

