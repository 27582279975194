import { useContext, useEffect, useState } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { addDays, endOfDay, getTime, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday, millisecondsToHours, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, setHours, setMinutes, startOfDay } from 'date-fns'
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar'
import { child, get, getDatabase, onValue, push, ref, set } from 'firebase/database'
import {  newEvent, eventWithId, seatProfile, shopProfile, businessHours } from '../../Utils/Interfaces'
import { Button, Center, Checkbox, Container, HStack, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react'
import enGB from 'date-fns/locale/en-GB'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import millisecondsToMinutes from 'date-fns/esm/millisecondsToMinutes/index.js'
import { eulaAccepted, eventColorWeek, fetchShopProfile, fetchSingleStringValue, fetchStylistLocations, fetchStylistProfile, getBookingStatus, overlappingEvents, pastBooking } from '../../tools/ReturnFunctions'
import { newBookingEmail } from '../../Utils/MailNotification'
import { useNavigate } from 'react-router-dom'
import { MdAccessTime, MdAttachMoney, MdCalendarMonth, MdVerifiedUser } from 'react-icons/md'
import Eula from '../../components/Eula'
import { UserContext } from '../../context/UserContext'

export default function StylistSeatCalendar(props: { shopUid: string, seatName: string, seatProfile: seatProfile }) {

  const firebaseUser = useFirebaseAuth();
  const dbRef = getDatabase()
  const navigate = useNavigate()
  const [profileDetails, setProfileDetails] = useState<shopProfile>()
  const [businessHours, setBusinessHours] = useState<businessHours>()
  const [stylistName, setStylistName] = useState<{firstName: string, lastName: string}>()
  const [placeName, setPlaceName] = useState<string>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const {user, setUser} = useContext(UserContext)
  
  const [checkBoxError, setCheckBoxError] = useState<string>()
  const [businessRegChecked, setBusinessRegChecked] = useState<boolean>(false)
  const [insuranceChecked, setInsuranceChecked] = useState<boolean>(false)


  async function fetchProfile() {
    const shopProfile = await fetchShopProfile(`shops/auth_read/${props.shopUid}/profile_settings`)
    setProfileDetails(shopProfile)
  }

  async function fetchShopName() {
    const shopName = await fetchSingleStringValue(`shops/auth_read/${props.shopUid}/shop_address/placeName`)
    setPlaceName(shopName)
  }

  async function fetchBusinessHours() {
    const profileRef = ref(dbRef, `shops/auth_read/${props.shopUid}/open_hours`);

    get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          setBusinessHours(snapshot.val())
        } else {
          console.log("No data available")         
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchProfile()
    fetchShopName()
    fetchBusinessHours()
  }, [])

  async function fetchStylistname() {
    const firstName = await fetchSingleStringValue(`stylists/auth_read/${firebaseUser?.uid}/profile_settings/firstName`)
    const lastName = await fetchSingleStringValue(`stylists/auth_read/${firebaseUser?.uid}/profile_settings/lastName`)
    setStylistName({firstName: firstName, lastName: lastName})
  }

  useEffect(() => {
    fetchStylistname()
  }, [firebaseUser])

  const [events, setEvents] = useState<eventWithId[]>([])
  const eventsListBuilder: Array<eventWithId> = []

  //https://www.saltycrane.com/cheat-sheets/typescript/date-fns/latest/
  async function addClosedHours() {

    eventsListBuilder.length = 0

    if (profileDetails) {

      const currentDate = new Date()
      console.log(`Today is: ${currentDate}`)

      //Monday - Set closed days
      if (businessHours?.mondayOpen === false) {
        if (isMonday(currentDate)) { //Use isMonday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed" })
            i = i + 7
          }

        } else { //Use nextMonday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextMonday(addDays(currentDate, i))), end: endOfDay(nextMonday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.mondayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.mondayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isMonday(currentDate)) {  //Use isMonday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextMonday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextMonday(startOfDay(addDays(currentDate, i))), end: nextMonday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextMonday(addDays(newCloseDateTime, i)), end: nextMonday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Tuesday - Set closed days
      if (businessHours?.tuesdayOpen === false) {
        if (isTuesday(currentDate)) { //Use isTuesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed" })
            i = i + 7
          }

        } else { //Use nextTuesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextTuesday(addDays(currentDate, i))), end: endOfDay(nextTuesday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.tuesdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.tuesdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isTuesday(currentDate)) {  //Use isTuesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextTuesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextTuesday(startOfDay(addDays(currentDate, i))), end: nextTuesday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextTuesday(addDays(newCloseDateTime, i)), end: nextTuesday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Wednesday - Set closed days
      if (businessHours?.wednesdayOpen === false) {
        if (isWednesday(currentDate)) { //Use isWednesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextWednesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextWednesday(addDays(currentDate, i))), end: endOfDay(nextWednesday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.wednesdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.wednesdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isWednesday(currentDate)) {  //Use isWednesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextWednesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextWednesday(startOfDay(addDays(currentDate, i))), end: nextWednesday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextWednesday(addDays(newCloseDateTime, i)), end: nextWednesday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Thursday - Set closed days
      if (businessHours?.thursdayOpen === false) {
        if (isThursday(currentDate)) { //Use isThursday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextThursday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextThursday(addDays(currentDate, i))), end: endOfDay(nextThursday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.thursdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.thursdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isThursday(currentDate)) {  //Use isThursday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextThursday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextThursday(startOfDay(addDays(currentDate, i))), end: nextThursday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextThursday(addDays(newCloseDateTime, i)), end: nextThursday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Friday - Set closed days
      if (businessHours?.fridayOpen === false) {
        if (isFriday(currentDate)) { //Use isFriday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextFriday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextFriday(addDays(currentDate, i))), end: endOfDay(nextFriday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.fridayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.fridayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isFriday(currentDate)) {  //Use isFriday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextFriday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextFriday(startOfDay(addDays(currentDate, i))), end: nextFriday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextFriday(addDays(newCloseDateTime, i)), end: nextFriday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Saturday - Set closed days
      if (businessHours?.saturdayOpen === false) {
        if (isSaturday(currentDate)) { //Use isSaturday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSaturday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextSaturday(addDays(currentDate, i))), end: endOfDay(nextSaturday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.saturdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.saturdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isSaturday(currentDate)) {  //Use isSaturday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSaturday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextSaturday(startOfDay(addDays(currentDate, i))), end: nextSaturday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextSaturday(addDays(newCloseDateTime, i)), end: nextSaturday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Sunday - Set closed days
      if (businessHours?.sundayOpen === false) {
        if (isSunday(currentDate)) { //Use isSunday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSunday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextSunday(addDays(currentDate, i))), end: endOfDay(nextSunday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.sundayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.sundayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isSunday(currentDate)) {  //Use isSunday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSunday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextSunday(startOfDay(addDays(currentDate, i))), end: nextSunday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextSunday(addDays(newCloseDateTime, i)), end: nextSunday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      fetchEvents()
    }
  }

  useEffect(() => {
    addClosedHours()
  }, [businessHours])

  async function fetchEvents() {

    const dbRef = getDatabase()
    const seatsRef = ref(dbRef, `shops/auth_write/${props.shopUid}/calendars/${props.seatName}`)
    onValue(seatsRef, async (snapshot) => {
      const allEvents: Array<eventWithId> = []      

       snapshot.forEach((childSnapshot) => {
        allEvents.push({...childSnapshot.val(), key: childSnapshot.key})        
      })

      await Promise.all(allEvents.map(async(event) => {

        const startTime = new Date(`${event.date}T${event.startTime}:00.000Z`)
        const endTime = new Date(`${event.date}T${event.endTime}:00.000Z`)

        //Get timezone offset at data
        const offset = startTime.getTimezoneOffset() / 60

        //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
        startTime.setHours(startTime.getHours() + offset)
        endTime.setHours(endTime.getHours() + offset)

        console.log(`Key ${event.key} -- Start time: ${startTime} -- End time: ${endTime}`)


        let title = ""

        if (event.stylistFirebaseUid === firebaseUser?.uid) {
          const isPastBooking = pastBooking(event.date!, event.startTime!)
          title = getBookingStatus(isPastBooking, event.bookingConfirmed!, event.reviewKey, event.bookingDeclined, event.bookingCancelledBy)  
        } else {
          title = "Other users boooking"
        }        
        
        const bookingEvent = {...event, title: title, start: startTime, end: endTime}
        eventsListBuilder.push(bookingEvent)

      }))

      setEvents(eventsListBuilder)
    })
    
  }

  const { isOpen: isOpenBooking, onOpen: onOpenBooking, onClose: onCloseBooking } = useDisclosure()
  const { isOpen: isOpenRequested, onOpen: onOpenRequested, onClose: onCloseRequested } = useDisclosure()
  const { isOpen: isOpenViewExisting, onOpen: onOpenViewExisting, onClose: onCloseViewExisting } = useDisclosure()
  const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure()
  const { isOpen: isOpenEULA, onOpen: onOpenEULA, onClose: onCloseEULA } = useDisclosure()
  const { isOpen: isOpenNewBookingError, onOpen: onOpenNewBookingError, onClose: onCloseNewBookingError } = useDisclosure()
  const { isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure()
  const { isOpen: isOpenShop, onOpen: onOpenShop, onClose: onCloseShop } = useDisclosure()

  const [newEvent, setNewEvent] = useState<newEvent>()
  const [eventDuration, setEventDuration] = useState<GLfloat>()
  const [bookingError, setBookingError] = useState<{ title: string; message: string; }>()
  const [newBookingError, setNewBookingError] = useState<{ title: string; message: string; }>()

  const [startEndTime, setStartEndTime] = useState<{start: Date, end: Date}>()

  

  async function handleSelectSlot ({ start, end }: Event) {

    //Check if user is logged in, if not, open login modal
    if (!firebaseUser) {
      console.log(`User not logged in`)
      onOpenLogin()
      return
    }

    if (firebaseUser && user.type == "shops") {
      onOpenShop()
      return
    }



    // if (firebaseUser && user.type == "stylists") {
    //   navigate("/seatdetailscalendar", { state: { shopUid: element.userFirebaseUid, seatName: element.seatName } })
    // } else {
    //   if (firebaseUser && user.type == "shops") {
    //     onOpenShop()
    //   } else {
    //     console.log("user is not logged in")
    //     navigate("/seatdetailscalendar", { state: { shopUid: element.userFirebaseUid, seatName: element.seatName } })
    //     // onOpen()
    //   }
    // }

    //Check that terms have been accepted
    const termsAccepted = await eulaAccepted(firebaseUser!.uid, "stylists")
    if (termsAccepted === false) {
      setStartEndTime({start: start!, end: end!})  
      onOpenEULA()
    } else {
      makeBooking(start!, end!)
    }

  }

  async function makeBooking(start: Date, end: Date) {

    

    //Check that profile is setup
    if (stylistName?.firstName == "") {
      setNewBookingError({ title: "Profile setup not complete", message: "Please setup your profile details, working locations and profile image before making a booking" })
      onOpenNewBookingError()
      return
    }

    //Check that locattions have been added
    const locations = await fetchStylistLocations(`stylists/auth_read/${firebaseUser?.uid}/locations`)
    if (locations.length == 0) {
      setNewBookingError({ title: "Profile setup not complete", message: "Please setup your profile details, working locations and profile image before making a booking" })
      onOpenNewBookingError()
      return
    }

    if (start && end) {

      setEvents((prev) => [...prev, { start, end }])      

      //Check if minimum time has been selected.
      console.log(`Minimum duration: ${Number(props.seatProfile.minimumHours)} == Booking duration: ${millisecondsToHours(end.valueOf() - start.valueOf())}`)
      if (Number(props.seatProfile.minimumHours) <= millisecondsToHours(end.valueOf() - start.valueOf())) {
        setEventDuration(millisecondsToMinutes(end.valueOf() - start.valueOf()) / 60)
        console.log('Long enough')
      } else {
        console.log(`Too short`)
        setNewBookingError({ title: `Minimum booking duration not met`, message: `You have selected a ${millisecondsToMinutes(end.valueOf() - start.valueOf()) / 60} hour booking. \n\n The minimum time for booking this chair is ${props.seatProfile.minimumHours} hours.` })
        onOpenNewBookingError()
        return
      }

      //Check if the selected time is in the past
      const currentTime = new Date()
      if (currentTime > start) {
        console.log(`Booking is in the past`)
        setNewBookingError({ title: `Booking is in the past`, message: `Selected time is in the past. Select a time in the future.` })
        onOpenNewBookingError()
        return
      } else {
        console.log(`Booking is in the future`)
      }

      //Define start and end times. 
      const dateString = `${format(start, 'yyyy-MM-dd')}`
      const startTime = `${format(start, 'HH:mm')}`
      const endTime = `${format(end, 'HH:mm')}`
      console.log(`Date: ${dateString} == startTime: ${startTime} == endTime: ${endTime}`)
      

      //Determine if daily or hourly rate
      let dailyOrHourlyRate = "hourly" 
      const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const isDayFunctions = [isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday];

      for (let i = 0; i < daysOfWeek.length; i++) {
        if (isDayFunctions[i](start)) {

          const openTime = businessHours?.[`${daysOfWeek[i]}OpenTime` as keyof typeof businessHours] as string;
          const closeTime = businessHours?.[`${daysOfWeek[i]}CloseTime` as keyof typeof businessHours] as string;
          console.log(`${daysOfWeek[i]} open time: ${openTime}`);
          console.log(`${daysOfWeek[i]} close time: ${closeTime}`);

          const startTimeString = `${String(start.getHours()).padStart(2, '0')}:${String(start.getMinutes()).padStart(2, '0')}`;
          const endTimeString = `${String(end.getHours()).padStart(2, '0')}:${String(end.getMinutes()).padStart(2, '0')}`;
          console.log(`Start time: ${startTimeString}`);
          console.log(`End time: ${endTimeString}`);

          if (openTime === startTimeString && closeTime === endTimeString) {
            console.log(`Daily rate`);
            dailyOrHourlyRate = "daily";
          }
        }
      }

      //If not a full rays booking and hourlyRate is false, then throw error
      if (dailyOrHourlyRate == "hourly" && props.seatProfile.hourlyRateEnabled == false) {
        setNewBookingError({ title: "Hourly rate not available", message: "This seat is not available for hourly bookings, select the entire day" })
        onOpenNewBookingError()
        return
      }
      

     

      setNewEvent({
        seatName: props.seatName,
        date: dateString,
        startTime: startTime,
        endTime: endTime,
        remarks: newEvent?.remarks,
        hourlyRate: dailyOrHourlyRate == "hourly" ? props.seatProfile.hourlyRate : undefined,
        dailyRate: dailyOrHourlyRate == "daily" ? props.seatProfile.dailyRate : undefined,
        amenityBasinShampooConditioner: props.seatProfile.amenityBasinShampooConditioner,
        amenityTowels: props.seatProfile.amenityTowels,
        amenityHairDryer: props.seatProfile.amenityHairDryer,
        amenityStorageLocker: props.seatProfile.amenityStorageLocker,
        amenityParking: props.seatProfile.amenityParking,
        amenityOther: props.seatProfile.amenityOther,
        cleaningProvided: props.seatProfile.cleaningProvided,
        insuranceRequired: props.seatProfile.insuranceRequired,
        businessRegistrationRequired: props.seatProfile.businessRegistrationRequired,
        houseCancellationPolicy: props.seatProfile.houseCancellationPolicy,
        shopFirebaseUid: props.seatProfile.userFirebaseUid,
        stylistFirebaseUid: firebaseUser!.uid,
        stylistName: `${stylistName?.firstName} ${stylistName?.lastName}`,
        bookingConfirmed: false,
        placeName: placeName!
      })

      //Conflicts with closed days, hours and other bookings. 
      await addClosedHours()
      console.log(`Day of week for booking is: ${getDay(start)}`)
      console.log(`New event start: ${start}`)
      console.log(`New event end: ${end}`) 
      console.log(`Events list length: ${eventsListBuilder.length}`)

      const overlappingEvent = await overlappingEvents(start, end, eventsListBuilder)
      if (overlappingEvent === true) {
        setNewBookingError({title: "Overlapping events", message: "Salon if closed or there is another booking at this time"})
        setEvents((prev) => [...prev, { start, end }])
        onOpenNewBookingError()
        return
      } 
      
      setEvents((prev) => [...prev, { start, end }]) 
      onOpenBooking()
    }
  }

  useEffect(() => {
    if (submitted) {
      makeBooking(startEndTime!.start!, startEndTime!.end!)
      onCloseEULA()
    } 
  }, [submitted])
  
  async function bookSeat() {
    console.log(`remarks: ${newEvent?.remarks}`)

  
    const newEventKey = push(child(ref(dbRef), `shops/auth_write/${newEvent?.shopFirebaseUid}/calendars/${newEvent?.seatName}`)).key

    const bookingData: newEvent = {
      seatName: newEvent!.seatName,
      date: newEvent!.date,
      startTime: newEvent!.startTime,
      endTime: newEvent!.endTime,
      remarks: newEvent?.remarks ? newEvent.remarks : "",
      amenityBasinShampooConditioner: newEvent!.amenityBasinShampooConditioner,
      amenityTowels: newEvent!.amenityTowels,
      amenityHairDryer: newEvent!.amenityHairDryer,
      amenityStorageLocker: newEvent!.amenityStorageLocker,
      amenityParking: newEvent!.amenityParking,
      amenityOther: newEvent?.amenityOther,
      cleaningProvided: newEvent!.cleaningProvided,
      houseCancellationPolicy: newEvent!.houseCancellationPolicy,
      shopFirebaseUid: newEvent!.shopFirebaseUid,
      stylistFirebaseUid: newEvent!.stylistFirebaseUid,
      stylistName: newEvent!.stylistName,
      bookingConfirmed: newEvent!.bookingConfirmed,
      placeName: newEvent!.placeName,
      insuranceRequired: newEvent!.insuranceRequired,
      businessRegistrationRequired: newEvent!.businessRegistrationRequired,
    };

    if (newEvent?.hourlyRate == undefined) {
      bookingData.dailyRate = newEvent?.dailyRate;
    } else {
      bookingData.hourlyRate = newEvent?.hourlyRate;
    }

    set(ref(dbRef, `shops/auth_write/${newEvent?.shopFirebaseUid}/calendars/${newEvent?.seatName}/${newEventKey}`), bookingData)
      .then(() => {
        console.log(`Booking saved`);
        onCloseBooking();
        addClosedHours();
        onOpenRequested();
      });
    
      
      
      set(ref(dbRef, `stylists/user_only/${newEvent?.stylistFirebaseUid}/bookings/${newEventKey}`), {
        eventKey: newEventKey,
        shopFirebaseUid: newEvent!.shopFirebaseUid,
        seatName: newEvent!.seatName        
      })
      .then(async () => {
          console.log(`Booking reference saved`)

          //Send mail notification to salon
          newBookingEmail(
            profileDetails!.emailAddress,
            stylistName!.firstName!,
            stylistName!.lastName!,
            newEvent!.date,
            newEvent!.startTime,
            newEvent!.endTime
          )       
        });
  }


  const [selectedEvent, setSelectedEvent] = useState<eventWithId>()

  function handleSelectEvent(event: eventWithId) {
    console.log(event.key)
    if (event.key === "closed") {
      setBookingError({title: "Shop closed", message: "Shop is closed at this time"})
      onOpenError()
    } else {
      if (event.stylistFirebaseUid === firebaseUser?.uid) {
        setSelectedEvent(event)
        onOpenViewExisting()
      } else {
        setBookingError({title: "Another users booking", message: "This is another stylists booking."})
        onOpenError()
      }
    }
  }
      
    const locales = { 'en-GB': enGB, }
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
  })

  const formats = {
    eventTimeRangeFormat: () => { 
      return ""
    },  
  }
  
  return (

    <div>

      <Calendar
        defaultView='week'
        events={events}
        localizer={localizer}
        views={['week']}
        formats={formats}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        style={{ height: '100%' }}
        eventPropGetter={(event) => {
          
          //https://stackoverflow.com/questions/70664536/how-to-style-react-big-calendar-border
          //Set event styles
          
          const title = event.title as string
          console.log(`Event title: ${title}`)  

          return eventColorWeek(title)

      }}        

        //Set current day style
        dayPropGetter={(date) => {

          const today = new Date()          
          if (format(date, 'yyyy-MM-dd') == format(today, 'yyyy-MM-dd')) {

            return {
              style: {
                backgroundColor: "#f15e8a48"
              }
            }
          } else {
            return {
              style: {
                backgroundColor: "white"
              }
            }

          }
        }}        
      />

      {newEvent &&
        <Modal isOpen={isOpenBooking} onClose={() => {
          setEvents(events.slice(0, -1))
          onCloseBooking()
        }}>
          <ModalOverlay  />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Confirm booking request</ModalHeader>
            <ModalBody>

              <HStack marginBottom='10px'>
                <Icon as={MdCalendarMonth} w={6} h={6} color='brand.medium'/>  
                <Text> Booking date: {format(new Date(`${newEvent.date}`),"do MMMM yyyy")}</Text>
              </HStack>             

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>Start time: {newEvent.startTime}</Text>
              </HStack>    

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>End time: {newEvent.endTime}</Text>
              </HStack>      

              <HStack  marginBottom='10px'>
                <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
                {newEvent.hourlyRate &&
                <Text marginBottom='5px'>Booking charges: ${(eventDuration! * Number(newEvent.hourlyRate)).toFixed(2)} (Hourly rate)</Text>
                }
                {newEvent.dailyRate &&
                <Text marginBottom='5px'>Booking charges: ${newEvent.dailyRate} (Daily rate)</Text>
                }
              </HStack> 


              <Textarea
                id='remarksInput'
                size='resize'
                borderColor='brand.medium'
                focusBorderColor='brand.medium'
                borderRadius={10}
                padding='5px'
                marginTop='10px'
                placeholder='Remarks for salon'
                onChange={(e) => {
                  setNewEvent({ ...newEvent, ...{remarks: e.target.value} as newEvent })
                }}
                />

              {newEvent.insuranceRequired &&
              <HStack  marginBottom='10px' marginTop='15px'>
                <Spacer/>
                <Text marginBottom='5px'>I have by own insurance</Text>
                <Checkbox 
                  size='lg' 
                  colorScheme='brand'
                  isChecked={insuranceChecked}
                  onChange={(e) => setInsuranceChecked(e.target.checked)}
                   />
              </HStack>
              }

              {newEvent.businessRegistrationRequired &&
              <HStack  marginBottom='10px' marginTop='15px'>
                <Spacer/>
                <Text marginBottom='5px'>I have by own business entity</Text>
                <Checkbox
                  size='lg'
                  colorScheme='brand'
                  isChecked={businessRegChecked}
                  onChange={(e) => setBusinessRegChecked(e.target.checked)}
                />
              </HStack>
              }

              {checkBoxError &&
              <Text textAlign='end' color='red' fontSize='sm' marginTop='5px'>{checkBoxError}</Text>
              }

            </ModalBody>

            <ModalFooter>
              <Button mr={3} variant='ghost'
              onClick={() => {
                setEvents(events.slice(0, -1));
                onCloseBooking()
              }}>Cancel</Button>
              <Button 
                onClick={() => {   
                  
                  if (newEvent.businessRegistrationRequired && !businessRegChecked) {
                    setCheckBoxError("Please confirm that you have a business registration")   
                    return
                  }  
                  if (newEvent.insuranceRequired && !insuranceChecked) {
                    setCheckBoxError("Please confirm that you have insurance")   
                    return
                  }

                  bookSeat()   
                 
                }}>Request</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      <Modal isOpen={isOpenRequested} onClose={onCloseRequested}>
        <ModalOverlay />
        <ModalContent borderTop="8px"  borderColor="brand.medium">
          <ModalHeader>Booking request submitted</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text whiteSpace='pre-line'>Booking request sent to the salon. Please await acceptance.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseRequested}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {selectedEvent &&

        <Modal isOpen={isOpenViewExisting} onClose={onCloseViewExisting}>
          <ModalOverlay  />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Existing booking</ModalHeader>
            <ModalBody>

              <HStack marginBottom='10px'>
                <Icon as={MdCalendarMonth} w={6} h={6} color='brand.medium'/>  
                <Text> Booking date: {format(new Date(`${selectedEvent!.date}`),"do MMMM yyyy")}</Text>
              </HStack>

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>Start time: {selectedEvent!.startTime}</Text>
              </HStack>    

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>End time: {selectedEvent!.endTime}</Text>
              </HStack>      

              <HStack  marginBottom='10px'>
                <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
                {selectedEvent.hourlyRate &&
                <Text marginBottom='5px'>Booking charges: ${((millisecondsToMinutes(selectedEvent.end!.valueOf() - selectedEvent.start!.valueOf()) / 60) * Number(selectedEvent!.hourlyRate)).toFixed(2)} (Hourly rate)</Text>
                }
                {selectedEvent.dailyRate &&
                <Text marginBottom='5px'>Booking charges: ${selectedEvent.dailyRate} (Daily rate)</Text>
                }              
              </HStack> 

            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onCloseViewExisting} variant='ghost'>Close</Button>
              <Button onClick={() => {
                navigate("/stylistviewbooking", { state: { selectedEvent: selectedEvent } })
              }}>Manage booking</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {newBookingError &&
        <Modal isOpen={isOpenNewBookingError} onClose={() => {
          setEvents(events.slice(0, -1))
          onCloseNewBookingError()
          }}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>{newBookingError.title}</ModalHeader>
            <ModalBody>
              <Text whiteSpace='pre-line'>{newBookingError.message}</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                setEvents(events.slice(0, -1))
                onCloseNewBookingError()
                }}>OK</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {bookingError &&
        <Modal isOpen={isOpenError} onClose={() => {
          onCloseError()
          }}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>{bookingError.title}</ModalHeader>
            <ModalBody>
              <Text whiteSpace='pre-line'>{bookingError.message}</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                onCloseError()
                }}>OK</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      <Modal isOpen={isOpenLogin} onClose={onCloseLogin}>
        <ModalOverlay />
        <ModalContent borderTop="8px"  borderColor="brand.medium">
          <ModalHeader>Please login to make a booking</ModalHeader>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {
              onCloseLogin()
              navigate("/register")
            }}>Register</Button>

            <Button colorScheme='blue' mr={3} onClick={() => {
              onCloseLogin()
              navigate("/login")
            }}>Login</Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenShop} onClose={onCloseShop}>
        <ModalOverlay />
        <ModalContent borderTop="8px"  borderColor="brand.medium">
          <ModalHeader>You need to be logged in as a stylist to make a booking</ModalHeader>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseShop}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenEULA} onClose={onCloseEULA} >
        <ModalOverlay   />

          <VStack position='fixed' zIndex='2000' width='100vw' height='100vh' top='0' left='0' justifyContent='space-around'>

            <VStack  backgroundColor='white' borderTop="8px"  borderColor="brand.medium" width={{base: '95%', sm: '70vw'}} height={{base: '95%', sm: ''}} padding='20px' borderRadius='10px' justifyContent='space-around'>
  
              <HStack width='100%'>
                <Heading size='md' color='brand.medium' paddingBottom='20px'>Accept terms of service to continue</Heading>
                <Spacer/>
              </HStack>

              <Eula setSubmitted={setSubmitted}/>

            </VStack>
          </VStack>
      </Modal>
      
    </div>
  )
}



