import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { seatProfile } from '../../Utils/Interfaces';
import { getDatabase, onValue, ref } from 'firebase/database';
import { Box, Heading, VStack, Text, Divider, Grid, GridItem, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Container } from '@chakra-ui/react';
import FirebaseStorageImage from '../../components/FirebaseStorageImage';
import SeatDetails from '../../components/SeatDetails';
import ShopSeatCalendar from './ShopSeatCalendar';

export default function ShopSeatDetailsCalendar() {

  const location = useLocation();
  // const data = location.state

  const data = location.state ? location.state : JSON.parse(sessionStorage.getItem('SELECTED_UID_SEATNAME')!) //If page reloaded then use the session storage values
  useEffect(() => {
    sessionStorage.setItem('SELECTED_UID_SEATNAME', JSON.stringify(userUidAndSeatName))
  },[data]) 

  const [userUidAndSeatName] = useState<{ shopUid: string, seatName: string }>(data)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [seatProfile, setSeatProfile] = useState<seatProfile>()

  async function fetchSeat() {
    const dbRef = getDatabase()     
    const seatRef = ref(dbRef, `shops/auth_read/${userUidAndSeatName.shopUid}/seats/${userUidAndSeatName.seatName}`)    
      onValue(seatRef, async (snapshot) => {  
        setSeatProfile(snapshot.val())     
    })
  }
  
  useEffect(() => {
    fetchSeat()
  },[])

  return (
    <div>
    {seatProfile &&

      <Grid
      templateAreas={{base: `"seat-details"
                            "calendar-view"`,
                            sm: `"seat-details calendar-view"`}}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14} 
      padding={{base: 0, sm: 5}}    
      gap='2'>

        <GridItem paddingStart={{base: 0, sm: 5}} paddingEnd={{base: 0, sm: 10}} area={'seat-details'} colStart={1} colSpan={{base: 12, sm: 5}}>
          <VStack align='start'>

            <Box height={{base: '30vh', sm: '40vh'}} width={{base: '100%', sm: '35vw'}} overflow='hidden' borderRadius={{base: 0, sm: 30}}>
                <FirebaseStorageImage imagePath={`shops/${userUidAndSeatName.shopUid}/seats/${userUidAndSeatName.seatName}_image.jpg`}/>
            </Box>

            <Heading paddingX={{base: '2vw', sm: '0vw'}}>{userUidAndSeatName.seatName}</Heading>
            <Heading paddingTop='15px' paddingX={{base: '2vw', sm: '0vw'}} size='md'>Chair description</Heading>

            <Text alignSelf='start' paddingX={{base: '2vw', sm: '0vw'}}>{seatProfile.seatDescription}</Text>

            <Container paddingX={{base: '2vw', sm: '0vw'}}>
              <Divider borderColor='brand.medium'  paddingTop='15px' marginBottom='15px'/>
              <SeatDetails seatDetails={seatProfile}/>
            </Container>

          </VStack>

        </GridItem>

        <GridItem area={'calendar-view'} colStart={{base: 1, sm: 6}} colSpan={{base: 12, sm: 7}}>
          {seatProfile &&
            <ShopSeatCalendar shopUid={userUidAndSeatName.shopUid} seatName={userUidAndSeatName.seatName} seatProfile={seatProfile}/>
          }
        </GridItem>

      </Grid>
    }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancellation policy definition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>No refund will be provided if cancelled within 24 hours.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </div>      
  )
}
