import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyBcxj-zi2RIjDNGFf2AX0R0pKQwVT1H2Jk",
    authDomain: "co-studio-29e23.firebaseapp.com",
    databaseURL: "https://co-studio-29e23-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "co-studio-29e23",
    storageBucket: "co-studio-29e23.appspot.com",
    messagingSenderId: "247295097593",
    appId: "1:247295097593:web:432f2af183de89cde08028",
    measurementId: "G-PWHQXZ45T4"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const database = getDatabase(app);
  export const firebaseStorage = getStorage(app);
  export const firebaseFirestore= getFirestore(app);



  //https://firebase.google.com/docs/app-check/web/debug-provider
  //Remove for production builds
  // (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true
  


  //Firebase App check 
  //https://firebase.google.com/docs/app-check/web/recaptcha-enterprise-provider?hl=en&authuser=1&_gl=1*19ii3hz*_ga*MTMyNDYyNTE4NS4xNjM2NTAyNjg1*_ga_CW55HF8NVT*MTY5NDU3MjY1NS4yMTcuMS4xNjk0NTcyNjg4LjAuMC4w#web-modular-api
  export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_CAPTCHA_API_KEY as string),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
   
  
  //Log the status change when users log in and out
  //https://firebase.google.com/docs/auth/web/manage-users
  onAuthStateChanged(auth, (user) => {    
    if (user) {
      console.log(`User logged in: ${user.email}`)
      

    } else {
      // User is signed out
      console.log(`User logged out`)
    }
  })


  
  