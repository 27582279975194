import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'
import { stylistProfileReference } from '../../Utils/Interfaces'
import { Flex, Card, CardBody, Heading, Divider, VStack, Tag, TagLabel, Text } from '@chakra-ui/react'
import FirebaseStorageAvatar from '../../components/FirebaseStorageAvatar'
import { useNavigate } from 'react-router-dom'


export default function LandingStylists() {

  const [allStylists, setAllStylists] = useState<stylistProfileReference[]>([])
  const navigate = useNavigate()  
  const dbRef = getDatabase()

  async function fetchAllStylists() {

    
    const stylistsRef = ref(dbRef, `stylists/auth_read`)
    
    onValue(stylistsRef, async (snapshot) => {
      
      const allStylists: Array<stylistProfileReference> = []

      snapshot.forEach((childSnapshot) => { 

        if (childSnapshot.child("profile_settings").exists()) {
          const firstName = childSnapshot.child("profile_settings").child("firstName").val() 
          const lastName = childSnapshot.child("profile_settings").child("lastName").val() 
          const userFirebaseUid = childSnapshot.child("profile_settings").child("userFirebaseUid").val() 
          const profileDescription = childSnapshot.child("profile_settings").child("profileDescription").val() 
          const listedProfile = childSnapshot.child("profile_settings").child("listedProfile").val() 

          const locations: Array<string> = []

          childSnapshot.child("locations").forEach((location) => {
            locations.push(location.child("placeName").val())
          })
         

          if (locations.length !== 0 && profileDescription != undefined && listedProfile === true)  {
            allStylists.push({firstName: firstName, lastName: lastName, userFirebaseUid: userFirebaseUid, locations: locations});
          }
          
        }

      })

      // const filteredStylists: Array<stylistProfileReference> = []

      // await Promise.all(
      //   allStylists.map(async (element) => {
      //     const image = await getImageUrl(`stylists/${element.userFirebaseUid}/profile_image.jpg`)
      //     console.log(`Image URL1: ${JSON.stringify(image)}`)
      //     // If image is not found, dont add it to the list
      //     if (image !== "") {
      //       filteredStylists.push(element)
      //     }
      //   })
      // )

      setAllStylists(allStylists)
      console.log(`All filteredStylists references: ${JSON.stringify(allStylists)}`)

    })
  }

  
  useEffect(() => {
    fetchAllStylists() 
  },[])

  
  

  return (
    <div>
      {allStylists &&
      <>
        <Flex justify='space-between' wrap='nowrap' gap='20px' padding='15px'>
            {
              allStylists.map((element, index) => {

                return (

                  <Card
                    key={index}
                    borderStyle='solid'
                    borderWidth='1px'
                    width={{base: '90vw', sm: '250px'}}
                    flexGrow='1'
                    flexShrink='0'
                    borderTop="8px"
                    borderTopColor='brand.medium'
                    borderColor=''
                    _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
                    onClick={() => {
                      navigate(`/stylistandsalonmap/${element.userFirebaseUid}`)                    
                    }}
                    >
                      <CardBody>

                        <VStack>
                          <FirebaseStorageAvatar imagePath={`stylists/${element.userFirebaseUid}/profile_image.jpg`} size={'2xl'}/>
                          <Heading size='md' marginTop='15px'>{element.firstName} {element.lastName}</Heading> 

                          {element.locations?.length &&
                          <>
                            <Divider width='90%' alignSelf='center'  />


                            <Text>Stylist working locations</Text>

                            
                            <Flex  justifyContent='space-around' wrap='wrap' width={{base: '100%', sm: '300px'}}  >
                              {
                                element.locations!.map((element, index) => {
                                  return (       
                                                          
                                      <Tag
                                        key={index}
                                        size='lg'
                                        borderRadius='full'
                                        variant='solid'
                                        margin='5px'                     
                                        backgroundColor='brand.medium'>
                                        <TagLabel>{element}</TagLabel>                            
                            
                                      </Tag>
                                  )
                                })
                              }
                            </Flex>
                          </>


                          }

                        </VStack>
      
                      </CardBody>

                      
                  </Card>
                )
              })
            }       
         </Flex>
      </>
      }

    </div>
  )
}
