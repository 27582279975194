import { useState } from 'react'
import { Avatar, Box, Button, Card, Center, Collapse, Container, Divider, Flex, Grid, GridItem, HStack, Heading, Icon, Image, Show, Spacer, Stack, Text, Tooltip, VStack, createIcon, useDisclosure } from '@chakra-ui/react'
import { benefit } from '../../Utils/Interfaces'
import { MdAddBusiness, MdAppRegistration, MdCancel, MdChat, MdDone, MdKeyboardArrowDown, MdKeyboardArrowUp, MdMailOutline, MdOutlineMoneyOff, MdVisibility } from 'react-icons/md'
import { FaCalendarCheck, FaPiggyBank } from 'react-icons/fa'
import { RiUserStarFill } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import PopUpChat from '../../components/PopUpChat'
import { nanoid } from 'nanoid'
import Cookies from 'js-cookie'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { appCheck } from '../../firebaseSetup'
import { Helmet } from 'react-helmet-async'
import StylistMap from '../stylist/StylistMap'
import LandingMap from './LandingMap'
import { isMobile } from 'react-device-detect';
import LandingStylists from './LandingStylists'

export default function Landing() {


  const navigate = useNavigate()
  // const [chatVisible, setChatVisible] =useState(false)
  const firebaseUser = useFirebaseAuth()
  const appCheckCaptcha = appCheck.app
  const { isOpen: isOpenSalon, onToggle: onToggleSalon } = useDisclosure()
  const { isOpen: isOpenStylist, onToggle: onToggleStylist } = useDisclosure()

  console.log(`App check captcha: ${appCheckCaptcha.options.authDomain}`)

 
  //Cookie for deviceId, to maintain support chat ID
  const deviceId = Cookies.get('deviceId')
  if (!deviceId) {
    Cookies.set('deviceId', nanoid(20), { expires: 365 })
  }
  

  const salonBenifits: benefit[] = [
    {
      title: 'Fill empty stations',
      message: 'List unoccupied chairs for stylists to book, generating revenue from your unutilised space. '
    },
    {
      title: 'Reduce risk',
      message: 'The co-working business model reduces fixed staffing costs, while still generating revenue through the rental of your facility.'
    },
    {
      title: 'Protect your brand',
      message: 'Vet stylists before accepting bookings, platform code-of-conduct, stylist reviews and Electric Studio governance provided to foster appropriate etiquette.'
    },
    {
      title: 'Simple to operate',
      message: 'Administration effort to list chairs and manage bookings is simple and minimal. Set up in 10 minutes and wait for revenue to come in.'
    },
    {
      title: 'More clients',
      message: 'Reduce the need to attract your own clients by letting stylists bring their own clientele into your salon.'
    },
    {
      title: 'Full flexiblity',
      message: `Rent by the day or the hour. For hourly bookings set the minimum number of hours you're happy to rent out the chair for.`
    },
  ]

  const stylistBenifits: benefit[] = [
    {
      
      title: 'Work when and where you want!',
      message: 'Ability to work the hours that fit your schedule, in a salon. Work across different locations to suit your clients preference.',
      icon: FaCalendarCheck
    },
    {
      title: 'Build a reputation as a trustworthy partner',
      message: 'Trust is essential in any business relationship. Positive reviews through past bookings builds confidence from new salons, making it easier to engage with new salons.',
      icon: RiUserStarFill
    },
    {
      title: 'Reduce costs through partial day bookings',
      message: 'Book by the hour, optimizing the cost of renting chairs and giving you flexibility to work on your terms.',
      icon: FaPiggyBank
      
    },
    {
      title: 'Platform use is currently free!',
      message: 'Use of the Electric Studio platform is not currently charged. Pay the specified fee directly to the salon after your booking.',
      icon: MdOutlineMoneyOff
    },
    
  ]

  // const Arrow = createIcon({
  //   displayName: 'Arrow',
  //   viewBox: '0 0 72 24',
  //   path: (
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
  //       fill="currentColor"
  //     />
  //   ),
  // })

  //SEO and Meta tags
  const siteTitle = "Co-working platform for salons and freelance stylists | Electric Studio"
  const siteDescription = "Enabling salons to list chairs for rent, and freelance hairdressers to book with ease."
  const siteUrl = "https://electricstudio.app"
  const siteImage = "https://electricstudio.app/img/OG-IMAGE.svg"

  
  return (

    <div>

    <Helmet>
      <title>{siteTitle}</title>
      <meta name='description' content={siteDescription} />
      <link rel='canonical' href='/'/>

      { /* Facebook tags */ }
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content={"website"} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:image" content={siteImage} />

      {/* Google search tage */}
      <meta property="name" content={siteTitle} />
      <meta property="description" content={siteDescription} />
      <meta property="image" content={siteImage} />


      {/* Twitter tags */}
      <meta property="twitter:card" content={"summary_large_image"} />
      <meta property="twitter:title" content={siteTitle} />
      <meta property="twitter:description" content={siteDescription} />
      <meta property="twitter:image" content={siteImage} />

    </Helmet>

      <VStack width='100%' bgGradient='linear(to bottom, brand.medium, brand.liteAlpha)' minHeight={{base: '93%', sm: '93vh'}} >

      <Grid templateColumns='repeat(12, 1fr)' gap={6}>

        <GridItem colStart={{base: 2, sm: 2}} colSpan={{base: 10, sm: 5}}>
    
            <Image src='img/logo-round-white.svg' alt='hairdressing jobs' height={{base: '27vh', sm: '35vh'}} marginTop={{base: '2vh', sm: '2vh'}} marginStart='-20px'/>   

            <Heading as='h1' textColor='white'>THE RENT-A-CHAIR PLATFORM FOR SALONS AND FREELANCE STYLISTS</Heading>
           
            <Heading textColor='white' marginTop='3vh' marginBottom='10px' size={{base: 'sm', sm: 'md'}}>Salons: Transform vacant chairs into additional income!</Heading>    

            <Heading textColor='white' marginBottom='10px' size={{base: 'sm', sm: 'md'}}>Stylists: Connect with clients. Work where you want, when you want.</Heading>    

            <Heading textColor='white' marginBottom='3vh' size={{base: 'sm', sm: 'md'}}>Clients: Find your perfect stylist in our directory.</Heading>    

            <Stack direction={{base: 'column', sm: 'row'}} paddingTop='10px'>
              <Button 
                borderRadius="none" 
                backgroundColor='brand.medium' 
                borderStartRadius='100px' 
                borderEndRadius={{base: '100px', sm: 'none'}}
                _hover={{ borderColor: 'white', borderWidth: '1px' }} 
                rightIcon={<MdKeyboardArrowDown width={10} height={10} color='white'/>} 
                textColor='white' 
                onClick={() => {
                  const salonSection = document.getElementById('salon-section')
                  if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })

                }}>
                  Salon benefits
              </Button>

              <Button 
              borderEndRadius={{base: '100px', sm: 'none'}}
              borderStartRadius={{base: '100px', sm: 'none'}}
              backgroundColor='brand.medium' 
              _hover={{ borderColor: 'white', borderWidth: '1px' }} 
              rightIcon={<MdKeyboardArrowDown width={10} height={10} color='white'/>} 
              textColor='white' 
              onClick={() => {
                const salonSection = document.getElementById('stylist-section')
                if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })

              }}>
                Stylist benefits</Button>

              <Button 
              borderEndRadius={{base: '100px', sm: '100px'}}
              borderStartRadius={{base: '100px', sm: 'none'}}
              backgroundColor='brand.medium' 
              _hover={{ borderColor: 'white', borderWidth: '1px' }} 
              rightIcon={<MdKeyboardArrowDown width={10} height={10} color='white'/>} 
              textColor='white' 
              onClick={() => {
                const salonSection = document.getElementById('listed-stylists-section')
                if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })

              }}>
                Browse stylists</Button>

              {/* <Show above='sm'>
                <Button 
                    borderEndRadius='100px' 
                    backgroundColor='brand.medium' 
                    textColor='white' 
                    _hover={{ borderColor: 'white', borderWidth: '1px'}}  
                    rightIcon={<MdAppRegistration  size='20px'/>} 
                    onClick={() => navigate('/register')} 
                    >Salons and Stylists - Register, its free
                </Button>  
              </Show>                 */}

              <Spacer/>
            </Stack>  

         

        

        </GridItem>


      <GridItem colStart={{base: 1, sm: 8}} colSpan={{base: 12, sm: 4}}>

          {!firebaseUser?.uid &&
            <VStack>

              <Show above='sm'>
                <Image src='img/SALON-DEMO3.gif' height='93vh' paddingTop='2vh' _hover={{ cursor:'pointer', transform: 'scale(1.15)', transition: '.3s' }} onClick={() => navigate('/register')}  />               
              </Show>   

                <Show below='sm'>
                  <Heading  textColor='white' size='md' marginTop='3vh' marginBottom='10px' textAlign='center' width='350px'>Sign up now, platform use is currently free!</Heading>
                  <HStack>
                    <Button 
                      borderRadius='100px' 
                      backgroundColor='brand.medium' 
                      textColor='white' 
                      size={{base: 'md', sm: 'lg'}} 
                      _hover={{ borderColor: 'white', borderWidth: '1px', backgroundColor: 'brand.medium' }}  
                      leftIcon={<MdAppRegistration  size='20px'/>} 
                      onClick={() => navigate('/register')} 
                      >Register
                      </Button>                                 
                  </HStack>
              </Show>  
            </VStack> 
          }



      </GridItem>

      </Grid> 

      <Spacer/>
      
      <VStack>
        <HStack>
          <Image width={{base: '40vw', sm: '20vw'}} src='img/hands-in-air.png' alt='rent chairs'/>
          <Image width={{base: '40vw', sm: '20vw'}}  src='img/hands-in-air.png' alt='salon revenue'/>
          <Show above='sm'>
          <Image width='20vw'  src='img/hands-in-air.png' alt='full flexibility'/>
          <Image width='20vw'  src='img/hands-in-air.png' alt='alternative revenue stream'/>
          </Show>
        </HStack>
      </VStack>


      </VStack>    

      
        <Center marginTop='40px' marginBottom='20px' width='100%' >
          <VStack>
            <Heading color='white' textColor='brand.medium'>LISTED SALONS</Heading>
            <Box height='4px' width='100px' backgroundColor='brand.medium' />
          </VStack>
        </Center>   
        <Divider borderColor='brand.medium'/>
        <LandingMap/> 
        <Divider borderColor='brand.medium'/>

        <Center id='listed-stylists-section' marginTop='40px' marginBottom='20px' width='100%' >
          <VStack>
            <Heading color='white' textColor='brand.medium'>LISTED STYLISTS</Heading>
            <Box height='4px' width='100px' backgroundColor='brand.medium' />
          </VStack>
        </Center>   
        <Divider borderColor='brand.medium'/>

        <Show below='sm'>
        <Container overflow='scroll' padding='0px'>
          <LandingStylists/> 
        </Container>
        </Show>

        <Show above='sm'>     
          <LandingStylists/>    
        </Show>


     
        <Divider borderColor='brand.medium'/>
     


        <VStack id='salon-section' paddingTop={{base: '5vh', sm: '5vh'}} minH='100vh'>
          <Center backgroundImage='img/scissors-hair-dryer-pattern.svg' width={{base: '90%', sm: '700px'}} padding='60px' marginBottom='5vh'>
            <VStack>
              <Heading size='md' color='brand.medium'>BUSINESS BENEFITS</Heading>
              <Heading color='brand.medium'>FOR SALONS</Heading>
              <Box height='4px' width='100px' backgroundColor='brand.medium' />
            </VStack>
          </Center>  

          <Center borderColor='brand.medium' borderWidth='5px' padding='20px' borderRadius='100px' >          
            <Heading size={{base: 'sm', sm: 'md'}} color='brand.medium' >{`IT'S CURRENTLY COMPLETELY FREE!`}</Heading>        
          </Center> 


          <Flex gap='40px' wrap='wrap' justifyContent='space-around' width={{base: '100%', sm: '70vw'}} paddingY='5vh'>
            {
              salonBenifits.map((element, index) => {
                return (
                  <Box key={index} width={{base: '90%', sm: '400px'}}>

                    <HStack>
                      <Icon as={MdDone} w={10} h={10} color='brand.medium' paddingBottom='10px'/>
                      <Heading size='md' color='brand.medium' paddingBottom='10px'>{element.title}</Heading>                            
                    </HStack>

                    <Text paddingStart='50px' >{element.message}</Text>

                  </Box>
                )
              })
            }
          </Flex> 

          <Show below='sm'>
            <Image src='img/SALON-DEMO.gif' paddingBottom='3vh'/>
          </Show>

          <Button 
              borderRadius="100px" 
              backgroundColor='brand.medium' 
              _hover={{ borderColor: 'white', borderWidth: '1px' }} 
              rightIcon={isOpenSalon ? <MdKeyboardArrowUp width={10} height={10} color='white'/> : <MdKeyboardArrowDown width={10} height={10} color='white'/>  } 
              textColor='white' 
              onClick={() => {

                if (isMobile) {
                  onToggleSalon()
                } else {
                  const salonSection = document.getElementById('image-how-it-works')
                  if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              }}>
                How does it work?
          </Button>

        
            
        </VStack>

     
          
        



        <Collapse in={isOpenSalon} animateOpacity>         
          <Image src='img/SALONS-HOW-DOES-IT-WORK-MOBILE.svg' paddingTop='3vh' width='100vw'/>
        </Collapse>


        <VStack id='stylist-section' minH='100vh' marginTop='10vh'>
          <Center width='100%' height='20vh' backgroundColor='brand.medium' backgroundImage='img/scissors-hair-dryer-pattern-white-30.svg'>
            <VStack>
              <Heading color='white' size='md'>BUSINESS BENEFITS</Heading>
              <Heading color='white'>FOR FREELANCE STYLISTS</Heading>
              <Box height='4px' width='100px' backgroundColor='white' />
            </VStack>
          </Center>          

          <Flex gap='40px' wrap='wrap' justifyContent='space-around' width={{base: '100%', sm: '75vw'}} paddingY='5vh'>
            {
              stylistBenifits.map((element, index) => {
                return (
                  <Card key={index} width={{base: '90%', sm: '250px'}} height='450px' padding='20px' shadow='2xl' >

                    <VStack>
                      <Box borderColor='brand.lite' borderWidth='2px' padding='30px' marginBottom='20px' backgroundColor='brand.lite' borderRadius='100px'>
                        <Icon as={element.icon} w='80px' h='80px' color='white' /> 
                      </Box>
                      <Heading textAlign='center' size='md' textColor='brand.medium' paddingBottom='10px'>{element.title}</Heading>  
                      <Text textAlign='center'>{element.message}</Text>
                    </VStack>                   

                  </Card>
                )
              })
            }
          </Flex> 

            {/* <Button 
              borderRadius="100px" 
              backgroundColor='brand.medium' 
              _hover={{ borderColor: 'white', borderWidth: '1px' }} 
              rightIcon={<MdKeyboardArrowDown width={10} height={10} color='white'/>} 
              textColor='white' 
              onClick={() => {
                const salonSection = document.getElementById('image-how-it-works')
                if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })

              }}>
                How does it work?
          </Button> */}

          <Button 
              borderRadius="100px" 
              backgroundColor='brand.medium' 
              _hover={{ borderColor: 'white', borderWidth: '1px' }} 
              rightIcon={isOpenStylist ? <MdKeyboardArrowUp width={10} height={10} color='white'/> : <MdKeyboardArrowDown width={10} height={10} color='white'/>  } 
              textColor='white' 
              onClick={() => {

                if (isMobile) {
                  onToggleStylist()
                } else {
                  const salonSection = document.getElementById('image-how-it-works')
                  if (salonSection) salonSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              }}>
                How does it work?
          </Button>

        </VStack>

        <Collapse in={isOpenStylist} animateOpacity>         
          <Image src='img/STYLISTS-HOW-DOES-IT-WORK-MOBILE.svg' paddingTop='3vh' width='100vw'/>
        </Collapse>

        <Show above='sm'>
          <VStack paddingTop='10vh'>
            <Image id='image-how-it-works' src='img/how-does-it-work-combined-website.svg' width='100vw'/>
          </VStack>
        </Show>



        <Flex backgroundColor='brand.medium' height='8vh' width='100%' alignItems='center' gap={100} marginTop={isOpenStylist ? '0vh' : isMobile ? '5vh' : '0vh'}>

          <Show above='sm'>
          <Image src='img/logo-white-medium.svg' alt='employee beauty' height={{base: '90%', sm: '50%'}} maxW='20vw' marginStart='1vw' _hover={{cursor: 'pointer'}}/>
            <Spacer/>
          </Show>

          <HStack 
          marginEnd='50px'
              _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
              onClick={() => {
                console.log(`email`)
                window.open('mailto:support@electricstudio.app?subject=test=Body%20goes%20here')
                
              }}>
            <Icon as={MdMailOutline} w={6} h={6} marginStart='10px' color='white'/> 
            <Text marginBottom='5px'color='white' as='u'>support@electricstudio.app</Text>
          </HStack>

        </Flex>


      

     
     

     
    </div>
  )
}


