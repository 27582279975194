import { Avatar, Button, Container, Divider, Flex, Grid, GridItem, HStack, Heading, Input, Show, Spacer, Text, VStack } from '@chakra-ui/react'
import { child, getDatabase, onValue, push, ref, set } from 'firebase/database'
import { useEffect, useRef, useState } from 'react'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { fetchSingleStringValue } from '../../tools/ReturnFunctions'
import { chatMessage, eventWithId } from '../../Utils/Interfaces'
import { format } from 'date-fns'
import { MdSend } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import BookingDetails from '../../components/BookingDetails'
import SeatDetails from '../../components/SeatDetails'
import FirebaseStorageAvatar from '../../components/FirebaseStorageAvatar'
import { chatMessageEmail } from '../../Utils/MailNotification'
import ViewShopProfileImage from '../../components/ViewShopProfileImage'
import SeatDetailsEvent from '../../components/SeatDetailsEvent'


export default function Chat() {

  const location = useLocation();

  const data = location.state ? location.state.selectedEvent : JSON.parse(sessionStorage.getItem('SELECTED_EVENT')!) //If page reloaded then use the session storage values
  const selectedEvent = data as eventWithId
  console.log(`Selected event: ${JSON.stringify(selectedEvent)}`)  

  useEffect(() => {
    sessionStorage.setItem('SELECTED_EVENT', JSON.stringify(selectedEvent))
  },[data]) 


  const firebaseUser = useFirebaseAuth()
  const dbRef = getDatabase()
  const [messages, setMessages] = useState<chatMessage[]>([])
  const [inputMessage, setInputMessage] = useState("")
  const [theirDisplayName, setTheirDisplayName] = useState("")
  const [sendersDiaplayName, setSendersDiaplayName] = useState("")
  const [theirimagePath, setTheirImagePath] = useState<string>()
  const [recipentEmail, setRecipentEmail] = useState<string>("")

  const getTheirDisplayName = async () => {
  
    if (firebaseUser?.uid === selectedEvent.shopFirebaseUid) { 
      const displayName = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/firstName`) as unknown as string
      setTheirDisplayName(displayName)
      const emailTo = await fetchSingleStringValue(`stylists/auth_read/${selectedEvent.stylistFirebaseUid}/profile_settings/emailAddress`) as unknown as string
      setRecipentEmail(emailTo)
      setTheirImagePath(`stylists/${selectedEvent.stylistFirebaseUid}/profile_image.jpg`)
      setSendersDiaplayName(selectedEvent.placeName!)
      
    } else {
      const displayName = await fetchSingleStringValue(`shops/auth_read/${selectedEvent.shopFirebaseUid}/shop_address/placeName`) as unknown as string
      setTheirImagePath(`shops/${selectedEvent.shopFirebaseUid}/profile_image.jpg`)
      const emailTo = await fetchSingleStringValue(`shops/auth_read/${selectedEvent.shopFirebaseUid}/profile_settings/emailAddress`) as unknown as string
      setRecipentEmail(emailTo)
      setTheirDisplayName(displayName)
      setSendersDiaplayName(selectedEvent.stylistName!)
    }
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
      
    useEffect(() => {         
      if (messages.length) {
        elementRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' })  
      }
    },[messages.length])

    return <div ref={elementRef} />
  }

  function loadMessages()  {

    const dbRef = getDatabase()
    const messagesRef = ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/chats/${selectedEvent.stylistFirebaseUid}`)

    onValue(messagesRef, (snapshot) => {

      const allMessages: Array<chatMessage> = []  
        
        snapshot.forEach((childSnapshot) => {  
          allMessages.push(childSnapshot.val())
        })

      setMessages(allMessages)
    })        
  }

  const handleSendMessage = () => {

    if (!inputMessage.trim().length) {
      return;
    }

    console.log({
      sendersUid: firebaseUser?.uid,
      theirDisplayName: theirDisplayName,
      shopId: selectedEvent.shopFirebaseUid,
      stylistUid: selectedEvent.stylistFirebaseUid,
      timeStamp: Date.now(),
      message: inputMessage 
    })

    const messageKey = push(child(ref(dbRef), `shops/auth_write/${selectedEvent.shopFirebaseUid}/chats/${selectedEvent.stylistFirebaseUid}`)).key;

    set(ref(dbRef, `shops/auth_write/${selectedEvent.shopFirebaseUid}/chats/${selectedEvent.stylistFirebaseUid}/${messageKey}`), { 
      sendersUid: firebaseUser?.uid,
      theirDisplayName: theirDisplayName,
      shopId: selectedEvent.shopFirebaseUid,
      stylistUid: selectedEvent.stylistFirebaseUid,
      timeStamp: Date.now(),
      message: inputMessage 
    })
    .then(async () => {

      //Send mail notification to salon
      chatMessageEmail(
        recipentEmail,
        sendersDiaplayName,
        inputMessage
      )

        console.log(`Message saved`)   
    })

    setInputMessage("");

   
  }

  useEffect(() => {
    getTheirDisplayName()
    loadMessages()
  },[])
  
  return (

    <Grid
      id='chatGrid'
      templateAreas={`"booking-details chat"`}
      gridTemplateColumns={'repeat(12, 1fr)'}
      fontSize={14} 
      marginTop={{base: '50px', sm: '0px'}}   
      gap='5'
      
    >

    <Show above='sm'>      
      <GridItem area={'booking-details'} colStart={2} colSpan={5}>
        <BookingDetails selectedEvent={selectedEvent}/>  
        <SeatDetailsEvent seatDetails={selectedEvent}/>
      </GridItem>
    </Show>

    <GridItem id='chatGridItem' area={'chat'} colStart={{base: 1, sm: 7}} colSpan={{base: 12, sm: 5}} h={{base: '60vh', sm: '76vh'}} >

      <Show above='sm'> 
        <Heading size='md' paddingTop={{base: '28px', sm: '15px'}} paddingStart='5px' marginBottom='15px'>Chat with {theirDisplayName}</Heading>
      </Show>
      
      <Flex w={["100%"]} height={{base: '94%', sm: '70vh'}} flexDir="column" borderColor='brand.medium' borderWidth='1px' borderRadius='5px' paddingTop={1} alignSelf='end' position={{base: 'fixed', sm: 'static'}} right='0' bottom='0' top='6vh'>    
          
          <Flex id='chatMessages' w="100%" h="100%" overflowY='scroll' flexDirection="column" p="1">

          <Spacer/>

            {messages.length !== 0 && theirimagePath &&     
     
              messages.map((item, index) => {
                if (item.sendersUid === firebaseUser?.uid) {
                  return (
                    <Flex key={index} w="100%" justify="flex-end">
                      <VStack>
                      <Flex
                        bg="brand.medium"
                        borderRadius='10px'
                        color="white"
                        minW="130px"
                        maxW="350px"
                        marginTop="15px"
                        marginBottom='1px'
                        p="3"
                      >
                        <Text wordBreak='break-word'>{item.message}</Text>
                      </Flex>
                      <Text alignSelf='end' fontSize='xs' paddingBottom='5px'>{                        
                        format(new Date(item.timeStamp),"do MMMM yyyy HH:mm:ss")
                      }</Text>
                      </VStack>
                    </Flex>
                  );
                } else {
                  return (
                    <Flex key={index} w="100%" justify="flex-start" >
                    <VStack>
                      <HStack>

                        {firebaseUser?.uid === selectedEvent.shopFirebaseUid ? 
                          <FirebaseStorageAvatar imagePath={theirimagePath} size={'md'}/>   
                          :
                          <Avatar icon={<ViewShopProfileImage uID={selectedEvent.shopFirebaseUid!} width='100%' height='100%'/> } overflow='clip'/>
                        }
          
                        
                    <Flex
                      bg="brand.lite"
                      borderRadius='10px'
                      color="white"
                      minW="130px"
                      maxW="350px"
                      marginTop="15px"
                      marginBottom='1px'
                      p="3"
                      >
                      <Text wordBreak='break-word'>{item.message}</Text>
                    </Flex>
                      </HStack>
                    <Text alignSelf='end' fontSize='xs' paddingBottom='5px'>{                        
                      format(new Date(item.timeStamp),"do MMMM yyyy HH:mm:ss")
                    }</Text>
                    </VStack>
                  </Flex>
                  );
                }
              })
            }           
              <AlwaysScrollToBottom />
          </Flex>
  

            <Divider alignSelf='center' width='95%' borderColor="brand.medium" />
          <VStack >

            <Flex w="95%" padding='10px'>
              <Input
                placeholder="New message..."
                border="none"
                borderRadius="5px"
                id='inputMessage'
                focusBorderColor='brand.medium'
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              <Button
                borderRadius="none"
                color='brand.medium'
                id='sendButton'
                rightIcon={ <MdSend color='brand.medium' />}
                variant='ghost'
                _hover={{backgroundColor: ''}}
                disabled={inputMessage.trim().length <= 0}
                onTouchEnd={(e) => {e.preventDefault()}}
                onTouchStart={handleSendMessage}
                onClick={handleSendMessage}
              >
      
              </Button>
            </Flex>
          </VStack>

        </Flex>   
    </GridItem>
      
    </Grid>


  )
}
