import { useEffect, useState } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { addDays, endOfDay, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, setHours, setMinutes, startOfDay } from 'date-fns'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import { get, getDatabase, onValue, ref } from 'firebase/database'
import {  eventWithId, seatProfile, shopProfile, businessHours } from '../../Utils/Interfaces'
import { Button, HStack, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useFocusEffect } from '@chakra-ui/react'
import enGB from 'date-fns/locale/en-GB'
import millisecondsToMinutes from 'date-fns/esm/millisecondsToMinutes/index.js'
import { fetchShopProfile, getBookingStatus, pastBooking } from '../../tools/ReturnFunctions'
import { useNavigate } from 'react-router-dom'
import { MdAccessTime, MdAttachMoney, MdCalendarMonth, MdPerson } from 'react-icons/md'

export default function ShopSeatCalendar(props: { shopUid: string, seatName: string, seatProfile: seatProfile }) {

  const dbRef = getDatabase()
  const navigate = useNavigate()
  const [profileDetails, setProfileDetails] = useState<shopProfile>()
  const [businessHours, setBusinessHours] = useState<businessHours>()
  const [events, setEvents] = useState<eventWithId[]>([])
  const eventsListBuilder: Array<eventWithId> = []
  const { isOpen: isOpenViewExisting, onOpen: onOpenViewExisting, onClose: onCloseViewExisting } = useDisclosure()
  const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure()
  const [bookingError, setBookingError] = useState<{ title: string; message: string; }>()
  const [selectedEvent, setSelectedEvent] = useState<eventWithId>()
  const [bookingStatus, setBookingStatus] = useState<string>()

  async function fetchProfile() {
    const shopProfile = await fetchShopProfile(`shops/auth_read/${props.shopUid}/profile_settings`)
    setProfileDetails(shopProfile)
  }

  async function fetchBusinessHours() {
    const profileRef = ref(dbRef, `shops/auth_read/${props.shopUid}/open_hours`);

    get(profileRef).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          setBusinessHours(snapshot.val())
        } else {
          console.log("No data available")         
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchProfile()
    fetchBusinessHours()
  }, [])

  //https://www.saltycrane.com/cheat-sheets/typescript/date-fns/latest/
  async function addClosedHours() {

    eventsListBuilder.length = 0

    if (profileDetails) {

      const currentDate = new Date()
      console.log(`Today is: ${currentDate}`)

      //Monday - Set closed days
      if (businessHours?.mondayOpen === false) {
        if (isMonday(currentDate)) { //Use isMonday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed" })
            i = i + 7
          }

        } else { //Use nextMonday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextMonday(addDays(currentDate, i))), end: endOfDay(nextMonday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.mondayOpenTime as string
        console.log(`Monday open tie: ${openTime}`)
      
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.mondayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isMonday(currentDate)) {  //Use isMonday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextMonday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextMonday(startOfDay(addDays(currentDate, i))), end: nextMonday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextMonday(addDays(newCloseDateTime, i)), end: nextMonday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Tuesday - Set closed days
      if (businessHours?.tuesdayOpen === false) {
        if (isTuesday(currentDate)) { //Use isTuesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed" })
            i = i + 7
          }

        } else { //Use nextTuesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextTuesday(addDays(currentDate, i))), end: endOfDay(nextTuesday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.tuesdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.tuesdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isTuesday(currentDate)) {  //Use isTuesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextTuesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextTuesday(startOfDay(addDays(currentDate, i))), end: nextTuesday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextTuesday(addDays(newCloseDateTime, i)), end: nextTuesday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Wednesday - Set closed days
      if (businessHours?.wednesdayOpen === false) {
        if (isWednesday(currentDate)) { //Use isWednesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextWednesday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextWednesday(addDays(currentDate, i))), end: endOfDay(nextWednesday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.wednesdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.wednesdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isWednesday(currentDate)) {  //Use isWednesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextWednesday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextWednesday(startOfDay(addDays(currentDate, i))), end: nextWednesday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextWednesday(addDays(newCloseDateTime, i)), end: nextWednesday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Thursday - Set closed days
      if (businessHours?.thursdayOpen === false) {
        if (isThursday(currentDate)) { //Use isThursday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextThursday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextThursday(addDays(currentDate, i))), end: endOfDay(nextThursday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.thursdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.thursdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isThursday(currentDate)) {  //Use isThursday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextThursday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextThursday(startOfDay(addDays(currentDate, i))), end: nextThursday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextThursday(addDays(newCloseDateTime, i)), end: nextThursday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Friday - Set closed days
      if (businessHours?.fridayOpen === false) {
        if (isFriday(currentDate)) { //Use isFriday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextFriday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextFriday(addDays(currentDate, i))), end: endOfDay(nextFriday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.fridayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.fridayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isFriday(currentDate)) {  //Use isFriday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextFriday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextFriday(startOfDay(addDays(currentDate, i))), end: nextFriday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextFriday(addDays(newCloseDateTime, i)), end: nextFriday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Saturday - Set closed days
      if (businessHours?.saturdayOpen === false) {
        if (isSaturday(currentDate)) { //Use isSaturday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSaturday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextSaturday(addDays(currentDate, i))), end: endOfDay(nextSaturday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.saturdayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.saturdayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isSaturday(currentDate)) {  //Use isSaturday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSaturday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextSaturday(startOfDay(addDays(currentDate, i))), end: nextSaturday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextSaturday(addDays(newCloseDateTime, i)), end: nextSaturday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      //Sunday - Set closed days
      if (businessHours?.sundayOpen === false) {
        if (isSunday(currentDate)) { //Use isSunday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(addDays(currentDate, i)), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSunday to create a event for the closed days over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed for the day", start: startOfDay(nextSunday(addDays(currentDate, i))), end: endOfDay(nextSunday(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      } else { //If salon is open for the day, set the closed hours

        //Get open time
        const openTime = businessHours?.sundayOpenTime as string
        const [openHours, openMinutes] = openTime.split(':')
        let newOpenDateTime = setHours(currentDate, Number(openHours))
        newOpenDateTime = setMinutes(newOpenDateTime, Number(openMinutes))

        //Get closed time
        const closeTime = businessHours?.sundayCloseTime as string
        const [closeHours, closeMinutes] = closeTime.split(':')
        let newCloseDateTime = setHours(currentDate, Number(closeHours))
        newCloseDateTime = setMinutes(newCloseDateTime, Number(closeMinutes))

        if (isSunday(currentDate)) {  //Use isSunday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: startOfDay(addDays(currentDate, i)), end: addDays(newOpenDateTime, i), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: addDays(newCloseDateTime, i), end: endOfDay(addDays(currentDate, i)), key: "closed"  })
            i = i + 7
          }

        } else { //Use nextSunday to create a event for the closed HOURS over the next 12 months
          let i = -182
          while (i < 364) {
            eventsListBuilder.push({ title: "Closed", start: nextSunday(startOfDay(addDays(currentDate, i))), end: nextSunday(addDays(newOpenDateTime, i)), key: "closed"  })
            eventsListBuilder.push({ title: "Closed", start: nextSunday(addDays(newCloseDateTime, i)), end: nextSunday(endOfDay(addDays(currentDate, i))), key: "closed"  })
            i = i + 7
          }
        }
      }

      fetchEvents()
    }
  }

  useEffect(() => {
    addClosedHours()
  }, [businessHours])

  async function fetchEvents() {

    const dbRef = getDatabase()
    const seatsRef = ref(dbRef, `shops/auth_write/${props.shopUid}/calendars/${props.seatName}`)
    onValue(seatsRef, async (snapshot) => {
      const allEvents: Array<eventWithId> = []      

       snapshot.forEach((childSnapshot) => {
        allEvents.push({...childSnapshot.val(), key: childSnapshot.key})        
      })

      await Promise.all(allEvents.map(async(event) => {

        const startTime = new Date(`${event.date}T${event.startTime}:00.000Z`)
        const endTime = new Date(`${event.date}T${event.endTime}:00.000Z`)

        //Get timezone offset at data
        const offset = startTime.getTimezoneOffset() / 60

        //Set the times with the offset so that calendar done not sppear to take into account differences in timezone. Everything is assumed to be at shops local timezone
        startTime.setHours(startTime.getHours() + offset)
        endTime.setHours(endTime.getHours() + offset)
        console.log(`Key ${event.key} -- Start time: ${startTime} -- End time: ${endTime}`)

        const isPastBooking = pastBooking(event.date!, event.startTime!)
        const title = event.stylistName + " - " + getBookingStatus(isPastBooking, event.bookingConfirmed!, event.reviewKey, event.bookingDeclined, event.bookingCancelledBy)
        
        const bookingEvent = {...event, title: title, start: startTime, end: endTime}
        eventsListBuilder.push(bookingEvent)
      }))

      setEvents(eventsListBuilder)
    })    
  }

  function handleSelectEvent(event: eventWithId) {
    console.log(event.key)
    if (event.key === "closed") {
      setBookingError({title: "Shop closed", message: "Shop is closed at this time"})
      onOpenError()
    } else {
        setSelectedEvent(event)

        //Check if the selected time is in the past        
        const isPastBooking = pastBooking(event.date!, event.startTime!)
        const bookingStatus = getBookingStatus(isPastBooking, event.bookingConfirmed!, event.reviewKey, event.bookingDeclined, event.bookingCancelledBy)
        setBookingStatus(bookingStatus)
        onOpenViewExisting()
    }
  }
      
    const locales = { 'en-GB': enGB, }
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
  })

  const formats = {
    eventTimeRangeFormat: () => { 
      return ""
    },
  
  };
  
  return (

    <div>

      <Calendar
        defaultView='week'
        events={events}
        localizer={localizer}
        views={['week']}
        formats={formats}
        onSelectEvent={handleSelectEvent}
        selectable
        style={{ height: '100%' }}
        eventPropGetter={(event) => {
          
          //https://stackoverflow.com/questions/70664536/how-to-style-react-big-calendar-border
          //Set event styles          
          const title = event.title as string
          console.log(`Event title: ${title}`)  
          
          if (title.includes("Pending")) {
            return {
              style: {
                backgroundColor: "#f15e8a",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }

          if (title.includes("Booking confirmed")) {
            return {
              style: {
                backgroundColor: "#ba275d",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }

          if (title.includes("completed")) {
            return {
              style: {
                backgroundColor: "#ba275d",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }

          if (title.includes("Cancelled")) {
            return {
              style: {
                backgroundColor: "black",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }

          if (title.includes("declined")) {
            return {
              style: {
                backgroundColor: "black",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }


          if (title.includes("Closed")) {
            return {
              style: {
                backgroundColor: "#7f7f7f",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            }
          }            
          
          return {
              style: {
                backgroundColor: "#bab8b9",
                borderColor: "white",
                writingMode: 'vertical-rl',
                textAlign: 'center'    
              }
            } 
      }}        

        //Set current day style
        dayPropGetter={(date) => {

          const today = new Date()          
          if (format(date, 'yyyy-MM-dd') == format(today, 'yyyy-MM-dd')) {

            return {
              style: {
                backgroundColor: "#f15e8a48"
              }
            }
          } else {
            return {
              style: {
                backgroundColor: "white"
              }
            }

          }
        }}        
      />
      
      {selectedEvent &&

        <Modal isOpen={isOpenViewExisting} onClose={onCloseViewExisting}>
          <ModalOverlay  />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>Existing booking: {bookingStatus}</ModalHeader>
            <ModalBody>

              <HStack marginBottom='10px'>
                <Icon as={MdPerson} w={6} h={6} color='brand.medium'/>  
                <Text>Stylist: {selectedEvent.stylistName}</Text>
              </HStack>

              <HStack marginBottom='10px'>
                <Icon as={MdCalendarMonth} w={6} h={6} color='brand.medium'/>  
                <Text> Booking date: {format(new Date(`${selectedEvent!.date}`),"do MMMM yyyy")}</Text>
              </HStack>

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>Start time: {selectedEvent!.startTime}</Text>
              </HStack>    

              <HStack  marginBottom='10px'>
                <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>  
                <Text marginBottom='5px'>End time: {selectedEvent!.endTime}</Text>
              </HStack>      

              <HStack  marginBottom='10px'>
                <Icon as={MdAttachMoney} w={6} h={6} color='brand.medium'/>  
                {selectedEvent.hourlyRate &&
                <Text marginBottom='5px'>Booking charges: ${((millisecondsToMinutes(selectedEvent.end!.valueOf() - selectedEvent.start!.valueOf()) / 60) * Number(selectedEvent!.hourlyRate)).toFixed(2)} (Hourly rate)</Text>
                }
                {selectedEvent.dailyRate &&
                <Text marginBottom='5px'>Booking charges: ${selectedEvent.dailyRate} (Daily rate)</Text>
                }              
              </HStack> 

            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onCloseViewExisting} variant='ghost'>Close</Button>
              <Button onClick={() => {
                navigate("/shopviewbooking", { state: { selectedEvent: selectedEvent } })
              }}>Manage booking</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

      {bookingError &&
        <Modal isOpen={isOpenError} onClose={() => {
          // setEvents(events.slice(0, -1))
          onCloseError()
          }}>
          <ModalOverlay />
          <ModalContent borderTop="8px"  borderColor="brand.medium">
            <ModalHeader>{bookingError.title}</ModalHeader>
            <ModalBody>
              <Text whiteSpace='pre-line'>{bookingError.message}</Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                // setEvents(events.slice(0, -1))
                onCloseError()
                }}>OK</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      }
    </div>
  )
}



