import { Divider, Flex, Grid, GridItem, HStack, Icon, Spacer, Text } from '@chakra-ui/react'
import {useContext} from 'react'
import { MdBusiness, MdChat, MdEditDocument, MdKey, MdKeyboardArrowRight } from 'react-icons/md'
import { UserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom';

export default function AdminDashboard() {

  const navigate = useNavigate();
  const {user, setUser} = useContext(UserContext)
  if (user.type === '') setUser({type: "admins"})  


  return (
    <div>

      <Grid
          templateAreas={{
            sm: `"side-menu main-pannel"`}}
          gridTemplateColumns={'repeat(12, 1fr)'}
          fontSize={14}
          padding='10px'
          gap='2'>
      
     

      <GridItem area={'side-menu'} colStart={1} colSpan={{base: 12, sm: 3}}>
     
        <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
       
          onClick={() => {
            navigate("/shoplist", { state: { chatsFolder: "chats" } })      
          }}>            
            <HStack>
            <Icon as={MdBusiness} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Salons</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>
        <Divider borderColor='brand.medium'/> 
     
        <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
       
          onClick={() => {
            navigate("/chatlist", { state: { chatsFolder: "chats" } })      
          }}>            
            <HStack>
            <Icon as={MdChat} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Customer chat</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>
        <Divider borderColor='brand.medium'/> 

        <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
       
          onClick={() => {
            navigate("/chatlist", { state: { chatsFolder: "chats_public" } })    
          }}>            
            <HStack>
            <Icon as={MdChat} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Public chat</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>
        <Divider borderColor='brand.medium'/> 

        <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
      
          onClick={() => {
            console.log(`Cancel booking`)        
          }}>            
            <HStack>
            <Icon as={MdEditDocument} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Edit EULA</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>
        <Divider  borderColor='brand.medium'/> 

        <Flex
          _hover={{ cursor:'pointer', transform: 'scale(1.02)', transition: '.3s' }}
      
          onClick={() => {
            console.log(`Cancel booking`)        
          }}>            
            <HStack>
            <Icon as={MdKey} w={6} h={6} marginStart='10px' color='brand.medium'/> 
            <Text fontSize={15} paddingTop='10px' paddingBottom='10px'>Reset password</Text> 
            </HStack>
            <Spacer/>
            <Icon as={MdKeyboardArrowRight} w={10} h={10} color='brand.medium'/>            
        </Flex>
        <Divider  borderColor='brand.medium'/> 


      </GridItem>

      <GridItem area={'main-pannel'} colStart={6} colSpan={{base: 6, sm: 10}}>

        
       


      </GridItem>

      </Grid>
      

    </div>
  )
}
