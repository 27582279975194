//React router imports
//https://www.youtube.com/watch?v=iXsM6NkEmFc&list=PL4cUxeGkcC9hcnIeryurNMMcGBHp7AYlP
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from 'react-router-dom'

// layouts and pages
import RootLayout from './layouts/RootLayout'
import StylistSettings from './pages/stylist/StylistSettings'
import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';
import ResetPassword from './pages/authentication/ResetPassword';
import NotFound from './pages/NotFound';
import Landing from './pages/landing/Landing';
import StylistSetup from './pages/userSetup/StylistSetup';
import ShopDashboard from './pages/shop/ShopDashboard';
import StylistDashboard from './pages/stylist/StylistDashboard';
import StylistMap from './pages/stylist/StylistMap';
import SalonDetails from './pages/stylist/SalonDetails';
import SeatDetailsCalendar from './pages/stylist/SeatDetailsCalendar';
import StylistViewBooking from './pages/stylist/StylistViewBooking';
import Chat from './pages/common/Chat';
import Support from './pages/common/Support';
import SupportChat from './components/SupportChat';
import UserType from './pages/userSetup/UserType';
import ShopSetup from './pages/userSetup/ShopSetup';
import ShopSettings from './pages/shop/ShopSettings';
import ShopManageSeats from './pages/shop/ShopManageSeats';
import SeatProfile from './pages/shop/SeatProfile';
import ShopSeatDetailsCalendar from './pages/shop/ShopSeatDetailsCalendar';
import ShopViewBooking from './pages/shop/ShopViewBooking';
import PrivateRoutes from './layouts/PriavteRoutes';
import AdminDashboard from './pages/admin/AdminDashboard';
import ChatList from './pages/admin/ChatList';
import UserChat from './pages/admin/UserChat';
import PrivacyPolicy from './components/PrivacyPolicy';
import PopUpChat from './components/PopUpChat';
import SeatProfileNew from './pages/shop/SeatProfileNew';
import ShopList from './pages/admin/ShopList';
import UserGuideShop from './components/UserGuideShop';
import Usermgmt from './pages/authentication/Usermgmt';
import StylistAndSalonMap from './pages/public/StylistAndSalonMap';
import ContactStylist from './pages/public/ContactStylist';





// router and routes
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>

      <Route index element={<Landing />} />      
      <Route path="*" element={<NotFound/>} />
      <Route path="privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="userguideshop" element={<UserGuideShop/>} />

      <Route path="login" element={<Login />} />
      <Route path="usermgmt" element={<Usermgmt/>} />
      <Route path="register" element={<Register />} />
      <Route path="resetpassword" element={<ResetPassword />} />      
      <Route path="popupchat" element={<PopUpChat />} />      
      <Route path="salondetails" element={<SalonDetails/>} />
      <Route path="seatdetailscalendar" element={<SeatDetailsCalendar/>} />
      <Route path="stylistandsalonmap/:userFirebaseUid" element={<StylistAndSalonMap/>} />
      <Route path="contactstylist/:userFirebaseUid" element={<ContactStylist/>} />

      <Route element={<PrivateRoutes />}>

        <Route path="admindashboard" element={<AdminDashboard />} />
        <Route path="shoplist" element={<ShopList />} />
        <Route path="chatlist" element={<ChatList />} />
        <Route path="userchat" element={<UserChat />} />

        <Route path="stylistsettings" element={<StylistSettings />} />
        <Route path="shopsettings" element={<ShopSettings />} />
        <Route path="support" element={<Support />} />
        <Route path="supportchat" element={<SupportChat />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="stylistsetup" element={<StylistSetup />} />
        <Route path="shopsetup" element={<ShopSetup />} />
        <Route path="shopdashboard" element={<ShopDashboard />} />
        <Route path="shopmanageseats" element={<ShopManageSeats />} />
        <Route path="shopseatdetailscalendar" element={<ShopSeatDetailsCalendar />} />
        <Route path="seatprofile" element={<SeatProfile />} />
        <Route path="seatprofilenew" element={<SeatProfileNew />} />
        <Route path="stylistdashboard" element={<StylistDashboard />} />
        <Route path="stylistmap" element={<StylistMap />} />
        <Route path="stylistviewbooking" element={<StylistViewBooking/>} />
        <Route path="shopviewbooking" element={<ShopViewBooking/>} />
        <Route path="chat" element={<Chat/>} />        
      </Route>

    </Route>
  )
)

function App() {
    
  return (
    <RouterProvider router={router} />  
  );
}

export default App;
