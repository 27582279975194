import { Box, Button, Card, CardBody, Center, Flex, HStack, Heading, Icon, Image, Show, SkeletonCircle, SkeletonText, Spacer, Spinner, Text, Tooltip, VStack, WrapItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdAccessTime, MdAddCircle, MdCancel, MdOutlineAttachMoney, MdOutlineCalendarMonth } from 'react-icons/md'
import { NavLink, useNavigate } from 'react-router-dom'
import { useFirebaseAuth } from '../../context/FirebaseAuthContext'
import { seatProfile } from '../../Utils/Interfaces'
import { fetchSeats } from '../../tools/ReturnFunctions'
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { firebaseStorage } from '../../firebaseSetup'
import { CalendarIcon, EditIcon } from '@chakra-ui/icons'

export default function ShopManageSeats() {

  const firebaseUser = useFirebaseAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [seatProfiles, setSeatProfiles] = useState<Array<seatProfile>>([])

  async function fetchAllSeats() {
    const allSeats = await fetchSeats(`shops/auth_read/${firebaseUser?.uid}/seats`)

    await Promise.all(allSeats.map(async(seat) => {
          
      const imageUrl = await getDownloadURL(ref_storage(firebaseStorage, `shops/${firebaseUser?.uid}/seats/${seat.seatName}_image.jpg`)).catch(async () => {
        return await getDownloadURL(ref_storage(firebaseStorage, `static_images/seat_placeholder.jpg`))
      })
      console.log(`image URLs is: ${imageUrl}`)
      seat.imageUrl = imageUrl
    }))
    
    setIsLoading(false)
    setSeatProfiles(allSeats)
  }

  useEffect(() => {
    fetchAllSeats()
  },[firebaseUser]) 
  
  return (

    <Center minH='90vh'>
      <VStack width='70%'>

      {isLoading == true && 
        <div>
          <Box padding='6' boxShadow='lg' bg='white' width='70vw' marginBottom='20px'>
            <SkeletonCircle size='10' />
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
          <Box padding='6' boxShadow='lg' bg='white' width='70vw' marginBottom='20px'>
            <SkeletonCircle size='10' />
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
          <Box padding='6' boxShadow='lg' bg='white' width='70vw' marginBottom='20px'>
            <SkeletonCircle size='10' />
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
        </div>
      }

      {isLoading == false &&
        <>
          {seatProfiles?.length == 0 &&
            <>
              <Heading paddingBottom='5vh' size='lg'>You need to list a chair for bookings</Heading>

              <NavLink to="/seatprofilenew">
                <Button leftIcon={<MdAddCircle />} borderRadius='100px' size='xxl' variant='navbar' padding='20px' paddingEnd='30px' fontSize='2xl' _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}>Add a new chair</Button>
              </NavLink>
            </>
          }

          {seatProfiles?.length >= 1 &&
            <>
              <Flex wrap='wrap' gap='20px' marginY='5vh' direction={{base: 'column', sm: 'row'}} >
                {seatProfiles.map((element, index) => {

                    return (

                      <Card
                        key={element.seatName}
                        direction={{base: 'column', sm: 'row'}}                
                        borderTop="8px"
                        borderColor="brand.medium"
                        margin={{base: '10px', sm: '0px'}}
                        width={{base: '95vw', sm: '60vw'}}
                        flexGrow='1'
                        overflow='hidden'
                        boxShadow='xl'             
                        >

                        <Image
                          id={element.seatName}
                          key={index}
                          src={element.imageUrl}
                          objectFit='cover'
                          width={{base: '90vw', sm: '30%'}}
                          height={{base: '23vh', sm: '20vh'}}
                          alignSelf='center'
                          borderRadius='15px'
                          margin='15px'
                          />  

                          <Flex width='100%' wrap='wrap' alignItems='center' paddingEnd={{base: '5px', sm: '20px'}}>
                          <VStack>
                              <CardBody padding='0px' paddingStart='15px' paddingBottom='15px'>
                                <Heading color='brand.medium' mb='5px' mt='10px' size='md'>{element.seatName}</Heading>

                                {element.hourlyRateEnabled == true &&

                                <>
                                  <HStack  marginBottom='10px'>
                                    <Icon as={MdOutlineAttachMoney} w={6} h={6} color='brand.medium'/>  
                                    <Text >{`Hourly rate (Inc GST): $${element.hourlyRate}`}</Text>                                
                                  </HStack>

                                  <HStack  marginBottom='10px'>
                                    <Icon as={MdAccessTime} w={6} h={6} color='brand.medium'/>                            
                                    <Text >{`Minimum hours: ${element.minimumHours}`}</Text>
                                  </HStack>
                                </>                                
                                }

                                {element.dailyRate != "" &&
                                  <HStack  marginBottom='10px'>
                                    <Icon as={MdOutlineAttachMoney} w={6} h={6} color='brand.medium'/>  
                                    <Text >{`Daily rate (Inc GST): $${element.dailyRate}`}</Text>                                
                                  </HStack>
                                }


                                <HStack  marginBottom='10px'>
                                  <Icon as={MdCancel} w={6} h={6} color='brand.medium'/>                            
                                  <Text >{element.houseCancellationPolicy === "24_hours" ? "Cancellation policy: 24 Hours" : "Free"}</Text>
                                </HStack>

                                <HStack  marginBottom='10px'>
                                  <Icon as={MdOutlineCalendarMonth} w={6} h={6} color='brand.medium'/>
                                  <Text >{element.seatBookable ? "Chair bookable: Yes" : "Chair bookable: No"}</Text>
                                </HStack>

                              </CardBody>
                          </VStack>

                          <Spacer/>

                          <WrapItem alignSelf='self-start' marginTop='15px' marginEnd='30px'>
                              <Tooltip label='View chair calendar' placement='bottom-end' hasArrow>                          
                                <CalendarIcon 
                                  w={{base: 6, sm: 8}} 
                                  h={{base: 6, sm: 8}} 
                                  color="brand.medium" 
                                  _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}
                                  onClick={() => {
                                    navigate("/shopseatdetailscalendar", { state: { shopUid: element.userFirebaseUid, seatName: element.seatName } })
                                  }}
                                  />       
                              </Tooltip>
                          </WrapItem>

                          <WrapItem alignSelf='self-start' marginTop='15px' marginEnd={{base: '15px', sm: '0px'}}>
                              <Tooltip label='Edit chair' placement='bottom-end' hasArrow>                          
                                <EditIcon 
                                  w={{base: 6, sm: 8}} 
                                  h={{base: 6, sm: 8}} 
                                  color="brand.medium" 
                                  _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}
                                  onClick={() => {   
                                    navigate("/seatprofile", { state: { selectedSeat: element } })
                                  }} />       
                              </Tooltip>
                          </WrapItem>
                          </Flex>
                      </Card>
                    )
                  })
                }       
              </Flex>

              <Button leftIcon={<MdAddCircle />} onClick={() => navigate("/seatprofilenew")} borderRadius='100px' position='fixed' bottom={{base: '10vh', sm: '10vh'}} right={{base: '2vh', sm: '2vh'}} size={{base: 'lg', sm: 'xl'}} variant='navbar' padding='20px' paddingEnd='30px' fontSize='xl' shadow='2xl' _hover={{ cursor:'pointer', transform: 'scale(1.1)', transition: '.3s' }}>Add a new chair</Button>
            </> 
          }
        </>
      }


      </VStack>
    </Center>      

  )
}
