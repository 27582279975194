import { Button, Card, CardBody, CardFooter, Divider, Flex, HStack, Heading, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { getDatabase, onValue, ref } from 'firebase/database';
import { useContext, useEffect, useState } from 'react'
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { seatProfile } from '../Utils/Interfaces';
import { firebaseStorage } from '../firebaseSetup';
import { useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { UserContext } from '../context/UserContext';

export default function ShopSeats(props: {shopUid: string}) {


  const [seatProfiles, setSeatProfiles] = useState<Array<seatProfile>>([])
  const navigate = useNavigate()

  async function fetchSeats() {

    const dbRef = getDatabase()     
    const seatsRef = ref(dbRef, `shops/auth_read/${props.shopUid}/seats`)    
      onValue(seatsRef, async (snapshot) => {  
        const allSeats: Array<seatProfile> = []  
        
        snapshot.forEach((childSnapshot) => {  
          allSeats.push(childSnapshot.val())
        })

        await Promise.all(allSeats.map(async(seat) => {
          
          const imageUrl = await getDownloadURL(ref_storage(firebaseStorage, `shops/${props.shopUid}/seats/${seat.seatName}_image.jpg`)).catch(async () => {
            return await getDownloadURL(ref_storage(firebaseStorage, `static_images/seat_placeholder.jpg`))
          })
          
          console.log(`image URLs is: ${imageUrl}`)
          seat.imageUrl = imageUrl
    
        }))
  
        setSeatProfiles(allSeats)     
    })
  }
  
  useEffect(() => {
    fetchSeats()
  },[]) 

  return (
    <div>

      <Flex wrap='wrap' gap='20px'>
          {
            seatProfiles.map((element, index) => {

              return (

                <Card
                  key={element.seatName}
                  direction='row'
                  minWidth='300px'
                  borderTop="8px"
                  borderColor="brand.medium"
                  flexGrow='1'
                  overflow='hidden'
                  boxShadow='xl'                  
                  >

                  <Image
                    id={element.seatName}
                    key={index}
                    src={element.imageUrl}
                    objectFit='cover'
                    width='35%'
                    height='150px'
                    />  

                    <Flex width='100%' wrap='wrap' alignItems='center' paddingEnd={{base: '5px', sm: '20px'}}>
                    <VStack>
                        <CardBody padding='0px' paddingStart='15px'>

                          <Heading color='brand.medium' mb='5px' size='sm'>{element.seatName}</Heading>
                          {element.hourlyRateEnabled != false &&                          
                            <>
                              <Text >{`Hourly rate (Inc GST): $${element.hourlyRate}`}</Text>
                              <Text >{`Minimum hours: ${element.minimumHours}`}</Text>
                            </>
                          }
                          {element.dailyRate &&
                            <Text >{`Daily rate (Inc GST): $${element.dailyRate}`}</Text>
                          }
                          {/* <Text >{element.houseCancellationPolicy === "24_hours" ? "Cancellation policy: 24 Hours" : "Free"}</Text> */}
                        </CardBody>
                    </VStack>

                    <Spacer/>

                    <Button
                          color='brand.medium'
                          variant='ghost'
                          leftIcon={<MdOutlineCalendarMonth />}
                          onClick={() => {
                            navigate("/seatdetailscalendar", { state: { shopUid: element.userFirebaseUid, seatName: element.seatName } })                            
                          }}
                          >Make a booking</Button>

                    </Flex>
                </Card>
              )
            })
          }       
      </Flex>

      

      
      
    </div>
  )
}
