import { Box, Button, Card, CardBody, CardFooter, Center, Divider, Flex, Grid, GridItem, Heading, Image, Show, SkeletonCircle, SkeletonText, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getDatabase, onValue, ref } from 'firebase/database';
import { getDownloadURL, ref as ref_storage } from 'firebase/storage';
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { firebaseStorage } from '../../firebaseSetup';
import { MdLocationOn, MdOutlineCalendarMonth, MdVisibility } from 'react-icons/md';
import { useNavigate  } from 'react-router-dom';
import { getDetails } from 'use-places-autocomplete';
import { shopAddress } from '../../Utils/Interfaces';


export default function LandingMap() {
  //https://www.youtube.com/watch?v=9e-5QHpadi0&t=24s

  //Load the Google maps script
  const configValue: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string)
  const {isLoaded} = useLoadScript({googleMapsApiKey: configValue, libraries: ['places']})  
 

  const [locations, setLocations] = useState(Array<shopAddress>)

  const [selectedLocation, setSelectedLocation] = useState<number | undefined>()

  function fetchLocations() {

    const dbRef = getDatabase()
     
    const locationsRef = ref(dbRef, `shops/auth_read`);
    
      onValue(locationsRef, async (snapshot) => {
  
        const allLocations: Array<shopAddress> = []
  
        snapshot.forEach((childSnapshot) => {
  
          const placeAddress = childSnapshot.child('shop_address').child('placeAddress').val()
          const placeId = childSnapshot.child('shop_address').child('placeId').val()
          const placeLat = childSnapshot.child('shop_address').child('placeLat').val()
          const placeLng = childSnapshot.child('shop_address').child('placeLng').val()
          const placeName = childSnapshot.child('shop_address').child('placeName').val()
          const userFirebaseUid = childSnapshot.key

          const profileExists = childSnapshot.child('profile_settings').exists()
          const openHoursExists = childSnapshot.child('open_hours').exists()
          const seatsExists = childSnapshot.child('seats').exists()

          if (profileExists && openHoursExists && seatsExists) {
            if (placeId != undefined) allLocations.push({placeAddress: placeAddress, placeId: placeId, placeLat: placeLat, placeLng: placeLng, placeName: placeName, userFirebaseUid: userFirebaseUid })
            console.log(`Name is: ${placeName} -- Address is :${placeAddress}`) 
          }          
        }) 
        
        
        await Promise.all(allLocations.map(async(location) => {
          
          const imageUrl = await getDownloadURL(ref_storage(firebaseStorage, `shops/${location.userFirebaseUid}/profile_image.jpg`)).catch(async () => {
            //If profile image has not been uploaded, use google photo
            const parameter = {placeId: location.placeId, fields: ["photos"]}
            const details = await getDetails(parameter) as google.maps.places.PlaceResult
            return details.photos![0].getUrl()
          })
          console.log(`image URLs is: ${imageUrl}`)
          location.photoUrl = imageUrl
    
        }))
  
        setLocations(allLocations)     
    })
  }
  
  useEffect(() => {
    if (isLoaded) fetchLocations()
  },[isLoaded]) 


  
  if (!isLoaded) return (
   
    <Center width='100vw' height='75vh'>
      <VStack>
        <Spinner
          color='brand.medium'
          size='xl'
          thickness='5px'
          speed='0.65s'
        />

        <Text>Loading map</Text>
      </VStack>
    </Center>
 
  )
  return <Map locations={locations} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
}

interface locationProps {
  locations: {
    placeAddress: string;
    placeId: string;
    placeLat: string;
    placeLng: string;
    placeName: string;
    userFirebaseUid: string | null;
    photoUrl?: string 
  }[],
  selectedLocation: number | undefined;
  setSelectedLocation: Dispatch<SetStateAction<number | undefined>> 
}

function Map({ locations, selectedLocation, setSelectedLocation }: locationProps) {

  const [zoom, setZoom] = useState(3)
  const [camera, setCamera] = useState(new google.maps.LatLng(-28, 133))
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const navigate = useNavigate();

  const containerStyle = {
    width: '100%',
    height: '100%'
  }

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map) 
  }

  return (

    <div>
  
      <Grid
        templateAreas={{base: `"map"
                              "cards"`,
                              sm: `"cards map"`
                              }}  
        templateRows='repeat(1, 1fr)'
        templateColumns='repeat(12, 1fr)'
      >

        <GridItem area={'cards'} colStart={{base: 1, sm: 1}} colSpan={{base: 12, sm: 3}} overflow='auto' height={{base: '100%', sm: '75vh'}}>

          {locations.length == 0 ?

          <>
          <Box padding='6' boxShadow='lg' bg='white'>
            <SkeletonCircle size='10' />
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>

          <Show above='sm'>
            <Box padding='6' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>

            <Box padding='6' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>

            <Box padding='6' boxShadow='lg' bg='white'>
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
          </Show>

          </>


        :
          <Flex justify='space-between' wrap={{base: 'nowrap', sm: 'wrap'}} gap='20px' padding='15px'>
            {
              locations.map((element, index) => {

                return (

                  <Card
                    key={index}
                    id={element.placeId}
                    borderStyle='solid'
                    borderWidth='1px'
                    width={{base: '90vw', sm: '250px'}}
                    flexGrow='1'
                    flexShrink='0'
                    borderTop="8px"
                    borderTopColor='brand.medium'
                    borderColor={(selectedLocation == index) ? 'brand.medium' : ''}
                    onClick={() => {
                      mapRef?.setZoom(13)
                      const myLatlng = new google.maps.LatLng(parseFloat(element.placeLat), parseFloat(element.placeLng))
                      mapRef?.panTo(myLatlng)    
                      setSelectedLocation(index)
                    }}
                    >
                      <CardBody>
                        <Image
                              key={index}
                              borderRadius='lg'
                              maxHeight={{base: '20vh', sm: '30vh'}}
                              width='100%'
                              objectFit='cover'
                              src={element.photoUrl}/>   

                          <Heading size='md' marginTop='15px'>{element.placeName}</Heading> 
                      </CardBody>

                      <Divider width='90%' alignSelf='center'  />
                      <CardFooter>                      
                        <Button
                          color='brand.medium'
                          variant='ghost'
                          width='100%'
                          leftIcon={<MdVisibility />}
                          onClick={() => {
                            navigate("/salondetails", { state: { shopUid: element.userFirebaseUid } })
                          }}
                          >Salon details</Button>
                        
                        <Button
                          color='brand.medium'
                          variant='ghost'
                          width='100%'
                          onClick={() => {
                            mapRef?.setZoom(12)
                            const myLatlng = new google.maps.LatLng(parseFloat(element.placeLat), parseFloat(element.placeLng))
                            mapRef?.panTo(myLatlng)    
                            setSelectedLocation(index)
                          }}
                          leftIcon={<MdLocationOn />}
                          >Location</Button>                      
                      </CardFooter>
                  </Card>
                )
              })
            }       
          </Flex>
        }


        </GridItem>


        <GridItem area={'map'} colStart={{base: 1, sm: 4}} colSpan={{base: 12, sm: 9}} width={{base: '100%', sm: '100%'}} height={{base: '50vh', sm: '75vh'}}>

          <GoogleMap
            id='map'
            zoom={zoom}
            center={camera}    
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            options={{
              streetViewControl: false,        
              mapTypeControl: false,
              fullscreenControl: false,
              keyboardShortcuts: false,
              restriction:{
                latLngBounds: {
                  north: -10,
                  south: -40,
                  east: 160,
                  west: 100,
                }
              }            
            }}      
            clickableIcons={false}>

              { 
                locations.map((element, index) => {

                  const myLatlng = new google.maps.LatLng(parseFloat(element.placeLat), parseFloat(element.placeLng))

                  return (

                    <Marker key={element.placeId} position={myLatlng} clickable={true} onClick={() => {

                      mapRef?.setZoom(12)
                      mapRef?.panTo(myLatlng)    
                      setSelectedLocation(index)
                      console.log(`Location added index: ${index}`)   

                      //Scroll to selected card                    
                      const card = document.getElementById(element.placeId);
                      if (card) {
                        // 👇 Will scroll smoothly to the top of the next section
                        console.log(`card: ${card}`)
                        card.scrollIntoView({ behavior: 'smooth', block: 'center' })
                      }

                      console.log(`Marker name: ${element.placeName} Centre: ${camera.lat}`)
                    }}></Marker>
                      

                  )
                })
              }  

              {locations.length == 0 &&
              <Center width='100%' height='90vh'>
                <VStack>
                  <Spinner
                    color='brand.medium'
                    size='xl'
                    thickness='5px'
                    speed='0.65s'/>
                </VStack>
              </Center>
              }

          </GoogleMap>


        </GridItem>
      </Grid>  

      

   
    </div>   
    
  )
}