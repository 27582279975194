import { addDoc, collection } from "firebase/firestore";
import { firebaseFirestore } from "../firebaseSetup";


export const newBookingEmail = (
  recipentEmail: string,
  stylistFirstName: string,
  stylistLastName: string,
  bookingDate: string,
  bookingStartTime: string,
  bookingEndTime: string
) => {

  const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "new_booking",
      data: {
        first_name: stylistFirstName,
        last_name: stylistLastName,
        date: bookingDate,
        start_time: bookingStartTime,
        end_time: bookingEndTime
      }
    }
  })
}

export const stylistCancelBookingEmail = (
  recipentEmail: string,
  stylistName: string,
  bookingDate: string,
  bookingStartTime: string,
  bookingEndTime: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "stylist_cancel_booking",
      data: {
        stylist_name: stylistName,
        date: bookingDate,
        start_time: bookingStartTime,
        end_time: bookingEndTime
      }
    }
  })
}

export const approveBookingEmail = (
  recipentEmail: string,
  salonName: string, 
  bookingDate: string,
  bookingStartTime: string,
  bookingEndTime: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "approve_booking",
      data: {
        salon: salonName,
        date: bookingDate,
        start_time: bookingStartTime,
        end_time: bookingEndTime
      }
    }
  })
}

export const declineBookingEmail = (
  recipentEmail: string,
  salonName: string, 
  bookingDate: string,
  bookingStartTime: string,
  bookingEndTime: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "decline_booking",
      data: {
        salon: salonName,
        date: bookingDate,
        start_time: bookingStartTime,
        end_time: bookingEndTime
      }
    }
  })
}

export const cancelBookingEmail = (
  recipentEmail: string,
  salonName: string, 
  bookingDate: string,
  bookingStartTime: string,
  bookingEndTime: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "shop_cancel_booking",
      data: {
        salon: salonName,
        date: bookingDate,
        start_time: bookingStartTime,
        end_time: bookingEndTime
      }
    }
  })
}

export const reviewBookingEmail = (
  recipentEmail: string,
  salonName: string, 
  reviewText: string,
  bookingReview: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "review_posted",
      data: {
        salon_name: salonName,
        booking_rating: reviewText,
        booking_review: bookingReview
      }
    }
  })
}

export const chatMessageEmail = (
  recipentEmail: string,
  senderName: string, 
  messageText: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "chat_message",
      data: {
        sender_name: senderName,
        chat_message: messageText
      }
    }
  })
}

export const publicChatMessageEmail = (
  recipentEmail: string,
  senderName: string, 
  messageText: string
  ) => {

    const mailRef = collection(firebaseFirestore, 'mail')
  return addDoc(mailRef, {

    to: recipentEmail,
    template: {
      name: "public_chat_message",
      data: {
        sender_name: senderName,
        chat_message: messageText
      }
    }
  })
}

export const bookingRequestFromEndUserEmail = (
  selectedMenuItem: string,
  clientName: string,
  emailAddress: string, 
  clientContactNumber: string, 
  requestDetails: string, 
  bookingDateTime: string
  ) => {

      const mailRef = collection(firebaseFirestore, 'mail')
    return addDoc(mailRef, {

      to: `${emailAddress}, "support@electricstudio.app`, 
      template: {
        name: "end_user_booking_request",
        data: {
          client_name: clientName,
          client_email: emailAddress,
          client_contact_number: clientContactNumber,
          booking_date_time: bookingDateTime,
          selected_menu_item: selectedMenuItem,
          request_details: requestDetails

        }
      }
    })
}


  

