import { 
  Tabs, 
  TabList, 
  Tab, 
  TabPanels, 
  TabPanel,
  Heading,
  VStack,
  Flex,
  Show,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Center} from '@chakra-ui/react'
import StylistProfile from './StylistProfile'
import Gallery from '../common/Gallery';
import ProfileImage from '../common/ProfileImage';
import StylistLocation from './StylistLocation';
import Eula from '../../components/Eula';
import ResetPassword from '../authentication/ResetPassword';
import SupportChat from '../../components/SupportChat';
import StylistViewProfile from '../../components/StylistViewProfile';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import StylistMenu from './StylistMenu';


export default function StylistSettings() {

  const firebaseUser = useFirebaseAuth();
  
  return (

    <div>

      {firebaseUser && 

      <>
        <Show below='sm'>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Profile
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <StylistProfile/>
          </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Profile Image
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ProfileImage/>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Your working suburbs
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} padding={0}>
              <StylistLocation />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Gallery
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} alignContent='center'>
              <Center>
              <Gallery />    
              </Center>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  My services menu
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} padding={2}>
            <StylistMenu />
            </AccordionPanel>
          </AccordionItem>


          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  View profile and reviews
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} alignContent='center'>
              <Center>
              <StylistViewProfile stylistFirebaseUid={firebaseUser!.uid}/>   
              </Center>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Terms of service
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Eula/>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: 'brand.lite', color: 'white' }}>
                <Box as="span" flex='1' textAlign='left'>
                  Reset password
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ResetPassword/>  
            </AccordionPanel>
          </AccordionItem>


        </Accordion>


        </Show>

        <Show above='sm'>
          
          <Tabs marginStart='40px' marginEnd='40px' isFitted  paddingTop='30x'  isLazy variant='line' marginTop='20px'>
            <TabList>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Profile</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Your working suburbs</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Gallery</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>My services</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>View profile and reviews</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Terms of service</Tab>
              <Tab _selected={{ borderBottomColor: 'brand.medium'}}>Reset password</Tab>
            </TabList>

            <TabPanels py="10px">
              
              {/* Profile */}
              <TabPanel>
                <Flex marginTop='10px' justify='space-around' wrap='wrap' gap='40px' >

                  <VStack>
                    <Heading size='md'>Profile details</Heading>
                  <StylistProfile/>
                  </VStack>

                  <VStack>
                    <Heading size='md' >Profile Image</Heading>
                    <ProfileImage/>
                  </VStack>

                </Flex>
              </TabPanel>
            {/* Profile */}

            {/* your working suburbs */}
            <TabPanel>
              <Flex marginTop='10px' justify='space-around' wrap='wrap' gap='40px' >

                <VStack>
                  <StylistLocation />
                </VStack>

              </Flex>
            </TabPanel>
            {/* your working suburbs */}

            {/* Gallery */}
            <TabPanel marginTop='40px'>
              <VStack>
                <Heading size='md' marginBottom='10px'>Your gallery</Heading>
                <Gallery />
              </VStack>
            </TabPanel>
            {/* Gallery */}

            {/* My Services */}
            <TabPanel marginTop='40px'>
              <VStack>
                <Heading size='md' marginBottom='10px'>My services menu</Heading>
                <StylistMenu />
              </VStack>
            </TabPanel>
            {/* My Services */}

            {/* View profile and reviews */}
            <TabPanel marginTop='40px'>
              <VStack>
                <StylistViewProfile stylistFirebaseUid={firebaseUser!.uid}/>
              </VStack>
            </TabPanel>
            {/* View profile and reviews  */}

            {/* Terms of service */}
            <TabPanel >
              <Heading size='md' marginBottom='10px'>Terms:</Heading>
              <Eula/>
            </TabPanel>
            {/* Terms of service */}

            {/* Reset password */}
            <TabPanel>
                <ResetPassword/>    
            </TabPanel>
            {/* Terms of service */}

            </TabPanels>
          </Tabs>
        </Show>
      </>
      

      }


    </div>
  )
}