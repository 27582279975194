import React from 'react'
import { benefit, userGuide } from '../Utils/Interfaces'
import { Box, Card, Flex, Heading, Icon, VStack, Text, Image, HStack, Spacer, Show } from '@chakra-ui/react'

export default function UserGuideShop() {

  const guideSteps: userGuide[] = [
    {
      title: '1. Register with email address or Google',
      message: 'If registering with email. An email will be sent to your inbox for verification.',
      imageDesktop: 'img/screenshots/register.png',
      imageMobile: 'img/screenshots/mobile/1-register.png'
    },
    {
      title: '2. Once logged in, select "I am a salon owner"',
      message: '',
      imageDesktop: 'img/screenshots/user-type.JPG',
      imageMobile: 'img/screenshots/mobile/2-user-type.png'
    },
    {
      title: '3. Find your salon using the search box and click next',
      message: 'Note that your salon needs to be listed on Google to use Electric Studio.',
      imageDesktop: 'img/screenshots/salon-location-setup.JPG',
      imageMobile: 'img/screenshots/mobile/3-salon-location.png'
    },
    {
      title: '4. Complete your salon profile',
      message: 'Note that your name and number will only be shared with Electric Studio. The profile description will be listed for tylists to read. ',
      imageDesktop: 'img/screenshots/salon-profile.JPG',
      imageMobile: 'img/screenshots/mobile/4-salon-profile.png'
    },
    {
      title: '5. Configure your salons open hours',
      message: 'This is required so that stylists only request bookings when your salon is open.',
      imageDesktop: 'img/screenshots/salon-open-hours.JPG',
      imageMobile: 'img/screenshots/mobile/5-salon-onen-hours.png'
    },
    {
      title: '6. Add your salons profile image',
      message: 'Preferabley your salon logo or shopfront image.',
      imageDesktop: 'img/screenshots/salon-profile-image.JPG',
      imageMobile: 'img/screenshots/mobile/6-salon-profile-image.png'
    },
    {
      title: '7. Add images to your salon gallery',
      message: 'This will give stylists a view of your salons vibe',
      imageDesktop: 'img/screenshots/salon-gallery.JPG',
      imageMobile: 'img/screenshots/mobile/7-salon-gallery.png'
    },
    {
      title: '8. Click `Chairs` up the top right to add a chair.',
      message: 'Once the profile setup is complete, a blank dashboard page will be shown. You will now need to list a seat. Click chairs up the top right.',
      imageDesktop: 'img/screenshots/salon-dashboard.JPG',
      imageMobile: 'img/screenshots/mobile/8-salon-dashboard-empty.png'
    },
    {
      title: '9. Click `Add a new chair`',
      message: '',
      imageDesktop: 'img/screenshots/salon-add-first-chair.JPG',
      imageMobile: 'img/screenshots/mobile/9-salon-no-chairs.png'
    },
    {
      title: '10. Complete the for and click submit. ',
      message: '',
      imageDesktop: 'img/screenshots/salon-new-chair.JPG',
      imageMobile: 'img/screenshots/mobile/10-salon-add-chair.png'
    },
    {
      title: '11. You will now see your chairs listed. ',
      message: 'Once the chair is created, your profile is complete, open hours are provided and a your salon location is entered. Stylists can make bookings.',
      imageDesktop: 'img/screenshots/salon-chairs-list.JPG',
      imageMobile: 'img/screenshots/mobile/11-salon-listed-chair.png'
    },
    {
      title: '12. When a booking request is made, it will be listed on your home page.',
      message: 'You will also be sent an email notification. Select the booking to approve.',
      imageDesktop: 'img/screenshots/salon-dashboard-pending-booking.JPG',
      imageMobile: 'img/screenshots/mobile/12-salon-panding-confirmation.png'
    },
    {
      title: '13. View the stylists booking request.',
      message: 'There are options to approve and decline the booking. You can also chat to the stylist.',
      imageDesktop: 'img/screenshots/salon-view-booking.JPG',
      imageMobile: 'img/screenshots/mobile/13-salon-view-request.png'
    },
    {
      title: '14. Accept the booking.',
      message: '',
      imageDesktop: 'img/screenshots/salon-accept-booking.JPG',
      imageMobile: 'img/screenshots/mobile/14-accept-booking.png'
    },
    {
      title: '15. Update profile.',
      message: 'If you need to edit your profile, open hours, etc... This can be done in the settings page.',
      imageDesktop: 'img/screenshots/salon-settings.JPG',
      imageMobile: 'img/screenshots/mobile/15-salon-update-profile.png'
    },
    
  ]

  return (
    <div>

            <Flex wrap='wrap'  marginTop='30px' gap='30px' justify='center'>
            {
              guideSteps.map((element, index) => {
                return (
                  <Card key={index} padding='20px' shadow='2xl' >

                    <Show above='sm'>
                      <HStack>
                        <Box paddingX='30px' width='30vw'>
                          <Heading  size='md' textColor='brand.medium' paddingBottom='10px'>{element.title}</Heading>  
                          <Text >{element.message}</Text>
                        </Box>
                        <Spacer/> 
                        <Image src={element.imageDesktop} alt='hairdressing jobs' width='50vw' borderColor='brand.medium' borderStyle='solid' borderWidth='1px'/> 
                      </HStack>
                    </Show>


                    <Show below='sm'>
                      <VStack>                     
                        <Heading  size='md' textColor='brand.medium' paddingBottom='10px'>{element.title}</Heading>  
                        <Text >{element.message}</Text>                   
                        <Spacer/> 
                        <Image src={element.imageMobile} alt='hairdressing jobs' width='95%' borderColor='brand.medium' borderStyle='solid' borderWidth='1px'/> 
                      </VStack>                   
                    </Show>

                  </Card>
                )
              })
            }
          </Flex>
          
    </div>
  )
}
