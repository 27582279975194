import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react'
import { Auth, applyActionCode, getAuth } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { MdLogin } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export default function Usermgmt() {

  const auth = getAuth()
  const navigate = useNavigate()


  const [confirmationMessage, setConfirmationMessage] = useState(false)
  const [invalidOrExpired, setInvalidOrExpired] = useState(false)
  

  
  //https://firebase.google.com/docs/auth/custom-email-handler?hl=en&authuser=0
  //Get URL paramaters
  const queryParameters = new URLSearchParams(window.location.search)
  const mode = queryParameters.get("mode")
  const actionCode = queryParameters.get("oobCode") as string
  const continueUrl = queryParameters.get("continueUrl")
  const lang = queryParameters.get("lang") || 'en'

  useEffect(() => {
    // Handle the user management action.
    switch (mode) {
      // case 'resetPassword':
      //   // Display reset password handler and UI.
      //   handleResetPassword(auth, actionCode, continueUrl, lang);
      //   break;
      // case 'recoverEmail':
      //   // Display email recovery handler and UI.
      //   handleRecoverEmail(auth, actionCode, lang);
      //   break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
        // Error: invalid mode.
    }    
  },[]) 


    

    function handleVerifyEmail(auth: Auth, actionCode: string, continueUrl: string | null, lang: string) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      applyActionCode(auth, actionCode).then((resp) => {
        // Email address has been verified.
    
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        setConfirmationMessage(true)
    
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.

      }).catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log(`Verification code invalid or expired: ${error}`)
        setInvalidOrExpired(true)
      });
    }

 




  return (
   

    <VStack height='90vh' justifyContent='center' id='stack'>

      {confirmationMessage &&
      <>
        <Heading color='brand.medium' size='md' textAlign='center' width='90%'>Your email address has been verified.</Heading>
        <Text size='lg' paddingY='15px' textAlign='center' width='90%'>You can now login</Text>
        <Button 
          borderRadius='100px'    
          rightIcon={<MdLogin  size='20px'/>} 
          onClick={() => navigate('/login')} 
          >Login
        </Button>  
      </>          
      }

      {invalidOrExpired &&
      <>
        <Heading color='brand.medium' size='md' textAlign='center' width='90%'>Either the verification code has expired, or you have already verified your email.</Heading>
        <Text size='lg' paddingY='15px' textAlign='center' width='90%'>Please try logging in. If it has expired, you will be asked if verify again</Text>
        <Button 
          borderRadius='100px'    
          rightIcon={<MdLogin  size='20px'/>} 
          onClick={() => navigate('/login')} 
          >Login
        </Button>  
      </>          
      }






    </VStack>

    
  )
}
